import React, { Dispatch, Fragment, useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// import parent_select_location_sample from '../../../assets/parent_select_location_sample.png';
import { IStateType } from '../../../store/models/root.interface';
import * as actions from '../../../store/actions/index.actions';
import SweetAlert from 'react-bootstrap-sweetalert';
import Map from '../../Maps/Map';
import Loader from 'react-loader-spinner';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Translate } from '../../../utils/Translate';

const EditUnassignedStudent: React.FC = memo((props: any) => {
  let id = '0';
  id = JSON.parse(new URLSearchParams(props.location.search).get('id') || '0');
  const dispatch: Dispatch<any> = useDispatch();
  const trip: any = useSelector(
    (state: IStateType) => state.requestedtrips.trip
  );
  const areas = useSelector((state: IStateType) => state.assigntrip.areas);
  const trips = useSelector((state: IStateType) => state.managetrips.trips);

  const loadingTrip = useSelector(
    (state: IStateType) => state.requestedtrips.loadingTrip
  );
  const errorMsg = useSelector(
    (state: IStateType) => state.requestedtrips.error
  );
  const t = new Translate()

  useEffect(() => {
    dispatch(actions.fetchArea());
    dispatch(actions.fetchTrips(1, 1000));

    dispatch(actions.fetchRequestedTrip(id));
  }, []);

  const [selectedTripId, updatePendingSelectedTripId] = useState<string>('');
  const [
    selectedStudentAreaId,
    updatePendingStudentSelectedAreaId,
  ] = useState<string>('');
  const [
    selectedDirectionId,
    updatePendingSelectedDirectionId,
  ] = useState<string>('');
  const [writtenAddress, updatePendingWrittenAddress] = useState<string>('');
  const [
    handingInstructions,
    updatePendingHandingInstructions,
  ] = useState<string>('');
  const [collectorName, updatePendingCollectorName] = useState<string>('');
  const [collectorID, updatePendingCollectorID] = useState<string>('');
  const [coordinates, setCoordinates] = useState<any>();
  const msg = useSelector((state: IStateType) => state.requestedtrips.msg);

  const onConfirm = function (e: any) {
    e.preventDefault();
    const data = {
      requestedtrip_id: id,
      parent_id: trip.parent_id,
      student_id: trip.student_id,
      location_lat: coordinates.lat,
      location_lng: coordinates.lng,
      address: writtenAddress,
      area_id: selectedStudentAreaId,
      trip_id: selectedTripId,
      direction: selectedDirectionId,
      handing_instructions: handingInstructions,
      collector_name: collectorName,
      collector_id: collectorID,
    };
    dispatch(actions.confirmTrip(data));
  };

  useEffect(() => {
    if (loadingTrip === false) {
      updatePendingWrittenAddress(trip.address);
      updatePendingHandingInstructions(trip.handing_instructions);
      updatePendingCollectorName(trip.collector_name);
      updatePendingCollectorID(trip.collector_id);
      if (trip.toschool == 1 && trip.tohome == 1) {
        updatePendingSelectedDirectionId("both");
      } else if (trip.toschool == 1) {
        updatePendingSelectedDirectionId("to the school");

      } else {
        updatePendingSelectedDirectionId("to home");

      }
      // updatePendingSelectedTripId(trip.trip_id);
      updatePendingStudentSelectedAreaId(trip.area_id);
      setCoordinates({ lat: +trip.location_lat, lng: +trip.location_lng });
    }
  }, [loadingTrip]);

  const getChangeLocation = (coords: any) => {
    setCoordinates(coords);
  };

  const onCancel = () => {
    dispatch(actions.clearMsgReqtrips());
  };

  const onSuccessCancel = () => {
    dispatch(actions.clearMsgAssginTrip());
    props.history.push('/PendingStudents');
  };

  let forError = null;
  if (errorMsg) {
    forError = (
      <SweetAlert
        danger
        title='Warning'
        onConfirm={onCancel}
        onCancel={onCancel}
      >
        {errorMsg}
      </SweetAlert>
    );
  }

  let forSuccess = null;
  if (msg) {
    forSuccess = (
      <SweetAlert
        success
        title='Success'
        onConfirm={onSuccessCancel}
        onCancel={onSuccessCancel}
      >
        {msg}
      </SweetAlert>
    );
  }


  const [address, setAddress] = React.useState('');

  const handleSelect = async (value: any) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    // console.log(latLng);
    setCoordinates(latLng);
  };

  return (
    <Fragment>
      <Link
        to='/PendingStudents'
        className='btn btn-danger text-white font-weight-bold'
      >
        {t.translate("Back")}
      </Link>

      {loadingTrip === false ? (
        <form onSubmit={(e) => onConfirm(e)} className='row'>
          {forSuccess}
          {forError}
          <div className='col-md-12'>
            <div className='row'>
              <div className='col-md-3 form-group justify-content-center text-center'>
                <label className='text-white font-weight-bold text-center'>
                  {t.translate("Student name")}
                </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder={trip.name}
                  aria-label='Trip Name'
                  aria-describedby='Entering Trip Name'
                  disabled
                />
              </div>
              <div className='col-md-3 form-group justify-content-center text-center'>
                <label className='text-white font-weight-bold text-center'>
                  {t.translate("Ivy-ID")}
                </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder={trip.student_id}
                  aria-label='Trip Name'
                  aria-describedby='Entering Trip Name'
                  disabled
                />
              </div>
              <div className='col-md-3 form-group justify-content-center text-center'>
                <label className='text-white font-weight-bold text-center'>
                  {t.translate("Parent")}
                </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder={trip.displayname}
                  aria-label='Trip Name'
                  aria-describedby='Entering Trip Name'
                  disabled
                />
              </div>
              <div className='col-md-3 form-group justify-content-center text-center'>
                <label className='text-white font-weight-bold text-center'>
                  {t.translate("Parent Mobile")}
                </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder={trip.mobile}
                  aria-label='Trip Name'
                  aria-describedby='Entering Trip Name'
                  disabled
                />
              </div>
            </div>
          </div>
          <div className='d-flex mt-2 form-group justify-content-center text-center'>
            <div className='col-12'>
              <div className='col-12 row justify-content-center align-items-center'>
                <div className="row justify-content-center align-items-center">
                  <label className="mr-1 col-auto">{t.translate("Search location")} : </label>
                  <div className='mt-3 col-12 col-sm-8'>
                    <PlacesAutocomplete
                      value={address}
                      onChange={setAddress}
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div className='form-group'>
                          {/* <p>Latitude: {coordinates.lat}</p>
                            <p>Longitude: {coordinates.lng}</p> */}

                          <input
                            {...getInputProps({
                              placeholder: 'Type address',
                              className: 'form-control',
                            })}
                          />

                          <div>
                            {loading ? <div>{t.translate("...loading")}</div> : null}

                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? '#41b6e6'
                                  : '#fff',
                              };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                    key: Math.random() * 1.2,
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
                <div className='col-md-12 align-items-center'>
                  <div className=''>
                    <Map
                      coordinates={coordinates}
                      getChangeLocation={getChangeLocation}
                      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg&v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBaD17OgMbI_0stUcRlvaeIvMuTet_rjdk`}
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `300px` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                    />
                  </div>
                </div>
                <div className='col-sm-12 align-items-center'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <label className='text-white font-weight-bold text-center'>
                        {t.translate("Direction")}
                      </label>
                      <select
                        className='form-control text-center'
                        aria-label='Direction'
                        onChange={(e) =>
                          updatePendingSelectedDirectionId(e.target.value)
                        }
                        value={selectedDirectionId}
                        required
                      >
                        <option value=''>{t.translate("Choose Direction")}</option>
                        <option value='both'>{t.translate("both Ways")}</option>
                        <option value='to the school'>{t.translate("to the school")}</option>
                        <option value='to home'>{t.translate("to home")}</option>
                      </select>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-group text-center'>
                        <label className='text-white font-weight-bold'>
                          {t.translate("Written address")}
                        </label>
                        <textarea
                          className='form-control'
                          id='exampleFormControlTextarea1'
                          required
                          onChange={(e) =>
                            updatePendingWrittenAddress(e.target.value)
                          }
                          value={writtenAddress}
                        // value = { writtenAddress }
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6 form-group'>
                      <label className='text-white font-weight-bold text-center'>
                        {t.translate("Area")}
                      </label>
                      <select
                        className='form-control text-center'
                        aria-label='Area'
                        onChange={(e) =>
                          updatePendingStudentSelectedAreaId(e.target.value)
                        }
                        value={selectedStudentAreaId}
                        required
                      >
                        <option value=''>{t.translate("Choose Area")}</option>
                        {areas.map((area: any) => {
                          return (
                            <option key={area.id} value={area.id}>
                              {area.areaname}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className='col-md-6 form-group'>
                      <label className='text-white font-weight-bold text-center'>
                        {t.translate("Trip")}
                      </label>
                      <select
                        className='form-control text-center'
                        aria-label='Trip'
                        onChange={(e) =>
                          updatePendingSelectedTripId(e.target.value)
                        }
                        value={selectedTripId}
                        required
                      >
                        <option value=''>{t.translate("Choose Trip")}</option>
                        {trips.map((trip: any) => {
                          return (
                            <option key={trip.id} value={trip.id}>
                              {trip.tripname} - {trip.direction}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='form-group justify-content-center text-center'>
                      <label className='text-white font-weight-bold'>
                        {t.translate("General Handing Instructions")}
                      </label>
                      <textarea
                        className='form-control'
                        id='exampleFormControlTextarea1'
                        required
                        onChange={(e) =>
                          updatePendingHandingInstructions(e.target.value)
                        }
                        value={handingInstructions}
                      ></textarea>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <label className='text-white font-weight-bold text-center'>
                        {t.translate("Collector Name")}
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        aria-label='Trip Name'
                        aria-describedby='Entering Trip Name'
                        onChange={(e) =>
                          updatePendingCollectorName(e.target.value)
                        }
                        value={collectorName}
                      />
                    </div>
                  </div>
                  <div className='row mt-1'>
                    <div className='col-md-12'>
                      <label className='text-white font-weight-bold text-center'>
                        {t.translate("Collector ID")}
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        aria-label='Trip Name'
                        aria-describedby='Entering Trip Name'
                        onChange={(e) =>
                          updatePendingCollectorID(e.target.value)
                        }
                        value={collectorID}
                      />
                      <button
                        // to='/PendingStudents'
                        // onClick={onConfirm}
                        className='btn btn-block btn-warning text-white font-weight-bold mt-3'
                      >
                        {t.translate("confirm")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div className='d-flex justify-content-center align-items-center mt-5'>
          <Loader type='ThreeDots' color='#00BFFF' height={100} width={100} />
        </div>
      )}
    </Fragment>
  );
});
export default withRouter(EditUnassignedStudent);
