import React, { Fragment, useEffect, Dispatch, memo, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions/index.actions';
import { IStateType } from '../../../store/models/root.interface';
import { Translate } from '../../../utils/Translate';

const GettingAddress: React.FC = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const areas = useSelector((state: IStateType) => state.assigntrip.areas);
  const loading = useSelector((state: IStateType) => state.assigntrip.loading);
  const area_id = useSelector((state: IStateType) => state.assigntrip.area_id);
  const students: any[] = useSelector(
    (state: IStateType) => state.assigntrip.students
  );
  const t = new Translate()
  const data: any = useSelector(
    (state: IStateType) => state.assigntrip
  );
  useEffect(() => {
    setAddress(data.address);

  }, [data])
  let [selectedAreaId, setSelectedAreaId] = useState<string>('');
  const [address, setAddress] = useState<string>('');

  useEffect(() => {
    dispatch(actions.fetchArea());
  }, [dispatch]);

  // console.log(selectedAreaId);
  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    if ((selectedAreaId !== '' || area_id !== '') && address) {
      dispatch(
        actions.getAddress(
          selectedAreaId === '' ? area_id : selectedAreaId,
          address
        )
      );
      props.history.push('/getlocation');
    }
  };
  return (
    <Fragment>
      <Link to='/' className='arrow-back'>
        <i className='fas fa-arrow-left'></i>
      </Link>
      <div className='container d-flex flex-column justify-content-center align-items-center'>
        <div
          className='align-items-center mt-1 w-100 rounded text-white text-center'
          style={{
            backgroundColor: 'rgb(44, 55, 94)',
            color: 'white',
            fontSize: '1.5 rem',
          }}
        >
          <h5>{t.translate("For")}:</h5>
          {students.map((std) => {
            return <h5 key={std.id}> {std.name}</h5>;
          })}
        </div>
      </div>
      <div className='container d-flex flex-column justify-content-center align-items-center'>
        <form onSubmit={(e) => onSubmitHandler(e)} className='col-12'>
          <div className='justify-content-center text-center'>
            <label className='text-white font-weight-bold text-center'>
              {t.translate("Select Area")}
            </label>
            <select
              className='form-control form-control-lg form-select'
              onChange={(e) => setSelectedAreaId(e.target.value)}
              required
              // value={area_id ? area_id : 'disableEle'}
              value={selectedAreaId ? selectedAreaId : area_id}
            >
              <option disabled value=''>
                {t.translate("Choose Area")}
              </option>
              {loading === false
                ? areas.map((area: any) => {
                  // console.log(area);
                  return (
                    <option key={area.id} value={area.id}>
                      {area.areaname}
                    </option>
                  );
                })
                : null}
            </select>
          </div>
          <div className='form-group justify-content-center text-center'>
            <label className='text-white font-weight-bold'>
              {t.translate("Enter Detailed Address")}
            </label>
            <textarea
              className='form-control'
              id='exampleFormControlTextarea1'
              onChange={(e) => setAddress(e.target.value)}
              required
              defaultValue={address}
            ></textarea>
          </div>
          <button
            // to='getlocation'
            className='btn btn-block btn-warning text-white font-weight-bold'
          >
            {t.translate("Proceed")}
          </button>
        </form>
      </div>
    </Fragment>
  );
};

export default memo(withRouter(GettingAddress));
