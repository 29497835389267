import React from 'react';
import classes from './Modal.module.css';

const modal = (props: any) => (
  <React.Fragment>
    <div
      className={classes.Modal}
      style={{
        transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
        opacity: props.show ? '1' : '0',
        maxWidth: `${props.width}px`,
      }}
    >
      {props.children}
    </div>
  </React.Fragment>
);

export default modal;
