import * as actionTypes from "./actionTypes";
import axios from "axios";

//draw path
export const drawPathStart = () => {
  return {
    type: actionTypes.DRAW_PATH_START,
  };
};
export const drawPathSuccess = (data: any) => {
  return {
    type: actionTypes.DRAW_PATH_SUCCESS,
    data,
  };
};
export const drawPathFail = (error: any) => {
  return {
    type: actionTypes.DRAW_PATH_FAIL,
    error,
  };
};

export const drawPath = (trip_id: any) => {
  return (dispatch: any) => {
    dispatch(drawPathStart());
    let data = { trip_id };
    console.log(data);
    axios
      .post(
        `admin/drawpath.php
        `,
        data
      )
      .then((res) => {
        console.log(res);
        dispatch(drawPathSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          console.log(err.response);
          dispatch(drawPathFail(err.response.data.msg));
        } else {
          dispatch(drawPathFail("Internet Connection Failed"));
        }
      });
  };
};
