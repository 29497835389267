import React, { Dispatch, useEffect } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentPath } from '../../../store/actions/root.actions';
import parent_avatar from '../../../assets/parent_avatar.png';
import ivy_logo from '../../../assets/ivy_logo_min.png';
import harmony_logo from '../../../assets/harmonylogo_min.png';
import { IStateType } from '../../../store/models/root.interface';

import * as actions from '../../../store/actions/index.actions';

import SelectChild from './SelectChild';
import GettingAddress from './GettingAddress';
import GettingLocation from './GettingLocation';
import SetHandingInstructions from './SetHandingInstructions';
import WaitConfirmation from './WaitConfirmation';
import TrackStudent from './TrackStudent';
import { isFirefox, isChrome, isEdge, isOpera } from 'react-device-detect'
import { Translate } from '../../../utils/Translate';
const Home: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  dispatch(updateCurrentPath('home', ''));

  const userId: string | null = useSelector(
    (state: IStateType) => state.auth.userId
  );
  const t = new Translate()
  useEffect(() => {

    if (isFirefox || isChrome || isOpera || isEdge) {



      if ('serviceWorker' in navigator) {
        const firebase = require('../../../firebase').default;

        const msg = firebase.messaging();
        msg
          .requestPermission()
          .then(() => {
            return msg.getToken();
          })
          .then((data: any) => {
            console.warn('token', data);
            // dispatch(actions.saveToken(userId, data));

          })
          .catch((er: any) => console.log(er));
      }
    }



  });
  return (
    <div style={{ position: 'relative' }}>
      <div className='text-center'>
        <h2
          className='text-white'
          style={{
            textShadow:
              '0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)',
            fontSize: '2rem',
          }}
        >
          {t.translate("IVY-IS Transportation")}
        </h2>
      </div>
      <div
        className='row justify-content-center align-content-between'
        style={{ height: '82vh' }}
      >
        <div className='col-12 row justify-content-center align-content-center'>
          <img
            src={parent_avatar}
            height='100px'
            className='rounded d-block '
            style={{ width: 'auto' }}
            alt='...'
          />
        </div>
        <Switch>
          <Route path='/trackstudenttrip'>
            <TrackStudent />
          </Route>
          <Route path='/waitconfirmation'>
            <WaitConfirmation />
          </Route>
          <Route path='/setinstrucrtions'>
            <SetHandingInstructions />
          </Route>
          <Route path='/getlocation'>
            <GettingLocation />
          </Route>
          <Route path='/getaddress'>
            <GettingAddress />
          </Route>
          <Route path='/'>
            <SelectChild />
          </Route>
        </Switch>

        <div className='container d-flex justify-content-center align-items-center m-3'>
          <div>
            <img
              src={ivy_logo}
              height='100px'
              className='rounded d-block '
              style={{ width: 'auto' }}
              alt='...'
            />
          </div>
          <div>
            <img
              src={harmony_logo}
              height='40px'
              className='rounded d-block'
              style={{ width: 'auto' }}
              alt='...'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Home);
