import React, {
  Fragment,
  Dispatch,
  useEffect,
  memo,
  useState,
  useRef,
} from "react";
import { Link, withRouter } from "react-router-dom";
// import parent_select_location_sample from '../../../assets/parent_select_location_sample.png';
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index.actions";
import { IStateType } from "../../../store/models/root.interface";
import Loader from "react-loader-spinner";
import Pagination from "react-js-pagination";
import classes from "../../Pagination.module.css";
import assigntrip from "../../../store/reducers/parent/assigntrip.reducer";
import SweetAlert from "react-bootstrap-sweetalert";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { Translate } from "../../../utils/Translate";

interface trip {
  id: string;

  direction: string;
  student_id: string;
  address: string;
  location_lat: string;
  location_lng: string;
  handing_instructions: string;
  collector_name: string;
  collector_id: string;
  parent_id: string;
  status: string;
  name: string;
  areaname: string;
  parentmobile: string;
  toschool?: number
  tohome?: number
  duration?: string
}

const PendingStudents: React.FC = memo((props: any) => {
  const count = useSelector((state: IStateType) => state.requestedtrips.count);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(3);

  const handlePageChange = (pageNumber: any) => {
    // console.log(`active page is ${pageNumber}`);
    dispatch(
      actions.fetchAllRequestedTrips(pageNumber, pageSize, enteredFilter)
    );
    setActivePage(pageNumber);
  };
  const dispatch: Dispatch<any> = useDispatch();
  const requestedtrips = useSelector(
    (state: IStateType) => state.requestedtrips.trips
  );
  const trips = useSelector((state: IStateType) => state.managetrips.trips);

  const loading = useSelector(
    (state: IStateType) => state.requestedtrips.loading
  );
  const tripsloading = useSelector(
    (state: IStateType) => state.managetrips.loading
  );
  const t = new Translate()
  useEffect(() => {
    $(document).ready(() => {

      const table: any = $('#datatable').DataTable();
    })
  })
  useEffect(() => {
    // dispatch(
    //   actions.fetchAllRequestedTrips(activePage, pageSize, enteredFilter)
    // );
    dispatch(actions.fetchTrips(1, 1000));
  }, [dispatch]);
  function editunassigned(tripid: string) {
    props.history.push("/EditUnassignedStudent?id=" + tripid);
  }

  const [selectedTripId, updatePendingSelectedTripId] = useState<string>("");

  function assigntrip(e: any, reqtripid: string) {
    e.preventDefault();

    const data = {
      requestedtrip_id: reqtripid,

      trip_id: selectedTripId,
    };
    dispatch(actions.fastAssTrip(data));
    // dispatch(actions.fetchAllRequestedTrips(activePage, pageSize));
  }

  const errorMsg = useSelector(
    (state: IStateType) => state.requestedtrips.error
  );
  const msg = useSelector((state: IStateType) => state.requestedtrips.msg);

  const onCancel = () => {
    dispatch(actions.clearMsgReqtrips());
  };

  const onSuccessCancel = () => {
    dispatch(actions.clearMsgReqtrips());
  };

  let forError = null;
  if (errorMsg) {
    forError = (
      <SweetAlert
        danger
        title="Warning"
        onConfirm={onCancel}
        onCancel={onCancel}
      >
        {errorMsg}
      </SweetAlert>
    );
  }

  let forSuccess = null;
  if (msg) {
    forSuccess = (
      <SweetAlert
        success
        title="Success"
        onConfirm={onSuccessCancel}
        onCancel={onSuccessCancel}
      >
        {msg}
      </SweetAlert>
    );
  }

  let arrowLeft = <i className="fas fa-angle-double-left"></i>;
  let arrowRight = <i className="fas fa-angle-double-right"></i>;

  const inputSearchRef: any = useRef();
  const [enteredFilter, setEnteredFilter] = useState<string>("");

  useEffect(() => {
    const timer = setTimeout(() => {
      if (enteredFilter === inputSearchRef.current.value) {
        console.log(enteredFilter);
        dispatch(
          actions.fetchAllRequestedTrips(activePage, pageSize, enteredFilter)
        );
      }
    }, 500);

    return () => {
      clearTimeout(timer);
      setActivePage(1);
    };
  }, [enteredFilter, inputSearchRef]);

  let [reqIdnput, setReqIdnput] = useState<string>("");
  let [rejectInput, setRejectInput] = useState<string>("");
  const addRejectHandler = (e: any) => {
    // console.log(reqIdnput + " " + rejectInput);
    e.preventDefault();
    dispatch(
      actions.rejectRequestedTrip({
        rejection_reason: rejectInput,
        requestedtrip_id: reqIdnput,
      })
    );
    setRejectInput("");
    let btn = document.getElementById("closedbtn")! as HTMLButtonElement;
    btn.click();
  };

  return (
    <Fragment>
      <div className="text-center">
        <h2
          className="text-white"
          style={{
            textShadow:
              "0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)",
            fontSize: "2rem",
          }}
        >
          {t.translate("Pending Students' Requests")}
        </h2>
      </div>
      <div className="input-group mt-3 mb-3 d-none">
        <input
          type="text"
          className="form-control"
          ref={inputSearchRef}
          onChange={(e) => setEnteredFilter(e.target.value)}
        />
        <div className="input-group-append">
          <button className="btn btn-primary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>

      {loading === false && tripsloading == false ? (
        <div className="justify-content-center align-items-center">
          {forError}
          {forSuccess}
          <div className="table-responsive bg-light">
            <table
              id="datatable"
              className="table-hover table-bordered text-center"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th scope="col">{t.translate("IVY ID")}</th>
                  <th scope="col">{t.translate("Student Name")}</th>
                  <th scope="col">{t.translate("Area")}</th>
                  <th scope="col">{t.translate("Location")}</th>
                  <th scope="col">{t.translate("To School")}</th>
                  <th scope="col">{t.translate("To Home")}</th>
                  <th scope="col">{t.translate("Duration")}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {requestedtrips.map((reqtrip: trip) => {
                  return (
                    <tr key={reqtrip.id}>
                      <td className="w-20">{reqtrip.student_id}</td>
                      <td className="w-20">{reqtrip.name}</td>
                      <td className="w-20">{reqtrip.areaname}</td>
                      <td className="w-20">
                        <p className="text-wrap text-break">
                          {reqtrip.address}
                        </p>
                      </td>
                      <td className="w-10">{reqtrip.toschool == 1 ? "YES" : "No"}</td>
                      <td className="w-10">{reqtrip.tohome == 1 ? "YES" : "No"}</td>
                      <td className="w-10">{reqtrip.duration}</td>
                      <td className="w-10">
                        <button
                          // to='/EditUnassignedStudent'
                          onClick={() => editunassigned(reqtrip.id)}
                          className="btn btn-warning text-white font-weight-bold m-1"
                        >
                          <i className="fas fa-sliders-h"></i>
                        </button>
                        <button
                          className="btn btn-success text-white font-weight-bold m-1"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={"#h" + reqtrip.id}
                          aria-expanded="false"
                          aria-controls="selectTrip"
                        >
                          <i className="fas fa-check"></i>
                        </button>
                        <button
                          // to='/EditUnassignedStudent'
                          onClick={() => setReqIdnput(reqtrip.id)}
                          className="btn btn-danger text-white font-weight-bold m-1"
                          data-bs-toggle="modal"
                          id="openbtn"
                          data-bs-target="#rejectModal"
                        >
                          {t.translate("Reject")}
                        </button>
                        <div
                          className="align-items-center rounded"
                          style={{
                            backgroundColor: "rgb(44, 55, 94)",
                            color: "white",
                          }}
                        >
                          <div className="collapse" id={"h" + reqtrip.id}>
                            <div className="form-group">
                              <label className="text-white font-weight-bold text-center">
                                {t.translate("Trip")}
                              </label>
                              <form onSubmit={(e) => assigntrip(e, reqtrip.id)}>
                                <select
                                  className="form-control text-center"
                                  aria-label="Default select example"
                                  required
                                  onChange={(e) =>
                                    updatePendingSelectedTripId(e.target.value)
                                  }
                                >
                                  <option value="">{t.translate("Choose Trip")}</option>
                                  {trips.map((trip: any) => {
                                    return (
                                      <option key={trip.id} value={trip.id}>
                                        {trip.tripname} - {trip.direction}
                                      </option>
                                    );
                                  })}
                                </select>
                                <button
                                  // onClick={() => assigntrip(reqtrip.id)}
                                  className="btn btn-success text-white font-weight-bold m-1"
                                >
                                  {t.translate("Assign")}
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div
                          className="modal fade"
                          id="rejectModal"
                          tabIndex={-1}
                          aria-labelledby="rejectModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content text-dark">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="rejectModalLabel"
                                >
                                  {t.translate("Reject Reason")}
                                </h5>
                              </div>
                              <div className="modal-body">
                                <form onSubmit={addRejectHandler}>
                                  <div className=" mb-3 row align-items-center justify-content-center">
                                    <label
                                      htmlFor="setReject"
                                      className="form-label text-dark"
                                    >
                                      {t.translate("Reason")}
                                    </label>
                                    <textarea
                                      name=""
                                      id="setReject"
                                      className="form-control"
                                      cols={30}
                                      rows={4}
                                      required
                                      onChange={(e) =>
                                        setRejectInput(e.target.value)
                                      }
                                      value={rejectInput}
                                    ></textarea>
                                    <div
                                      id="areaHelp"
                                      className="form-text ml-1"
                                    >
                                      {t.translate("Reason must be specific")}.
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      data-bs-dismiss="modal"
                                      id="closedbtn"
                                    >
                                      {t.translate("Close")}
                                    </button>
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      {t.translate("Save changes")}
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <div className="d-flex justify-content-center align-items-center my-3">
            {count !== 0 ? (
              <Pagination
                activePage={activePage}
                itemsCountPerPage={pageSize}
                totalItemsCount={+count}
                pageRangeDisplayed={5}
                onChange={(e) => handlePageChange(e)}
                firstPageText={"First"}
                lastPageText={"Last"}
                prevPageText={arrowLeft}
                nextPageText={arrowRight}
                activeClass={classes.active}
              />
            ) : null}
          </div> */}
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
        </div>
      )}
    </Fragment>
  );
});

export default withRouter(PendingStudents);
