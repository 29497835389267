import { IActionBase } from '../models/root.interface';
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
const DrawinitialState: any = {
  loading: null,
  error: null,
  msg: null,
  data: {},
};
///////////////
const drawPathStart = (state: any, action: IActionBase) => {
  return updateObject(state, { error: null, loading: true });
};

const drawPathSuccess = (state: any = DrawinitialState, action: IActionBase) => {
  return updateObject(state, {
    data: action.data,
    loading: false,
    error: null,
  });
};

const drawPathFail = (state: any, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const drawPath = (state: any = DrawinitialState, action: IActionBase) => {
  switch (action.type) {
    case actionTypes.DRAW_PATH_START:
      return drawPathStart(state, action);
    case actionTypes.DRAW_PATH_SUCCESS:
      return drawPathSuccess(state, action);
    case actionTypes.DRAW_PATH_FAIL:
      return drawPathFail(state, action);
    default:
      return state;
  }
};
export default drawPath;
