import { IActionBase, ITrips } from "../../models/root.interface";
import * as actionTypes from "../../actions/actionTypes";

import { updateObject } from "../../utility";

const initialState: ITrips = {
  trips: [],
  trakedTrips: [],
  trakedTripLoading: null,
  rejectedTrips: [],
  rejectedTripsLoading: null,
  rejectedTripsCount: 0,
  count: 0,
  loading: null,
  trip: null,
  updateLoading: null,
  loadingTrip: null,
  loadingUnAssignTrip: null,
  loadingSuspendTrip: null,
  error: null,
  msg: null,
};

const fetchTrashedTripsStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, loading: true });
};

const fetchTrashedTripsSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    trips: action.trips,
    count: action.count,
    loading: false,
    error: null,
  });
};

const fetchTrashedTripsFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const clearMsgTrashedtrips = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: null,
    updateLoading: null,
    msg: null,
  });
};

// for fastassign trip
const trashedfastAssTripStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, loading: true });
};

const trashedfastAssTripSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  let newArr = state.trips.filter((tr: any) => tr.id !== action.tripId);
  return updateObject(state, {
    trips: newArr,
    loading: false,
    error: null,
    msg: action.msg,
  });
};

const trashedfastAssTripFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const trashedtrips = (state: ITrips = initialState, action: IActionBase) => {
  switch (action.type) {
    case actionTypes.FETCH_TRASHEDTRIPS_START:
      return fetchTrashedTripsStart(state, action);
    case actionTypes.FETCH_TRASHEDTRIPS_SUCCESS:
      return fetchTrashedTripsSuccess(state, action);
    case actionTypes.FETCH_TRASHEDTRIPS_FAIL:
      return fetchTrashedTripsFail(state, action);
    case actionTypes.CLEAR_MSG_TRASHEDTRIPS:
      return clearMsgTrashedtrips(state, action);
    case actionTypes.TRASHED_FAST_ASSTRIP_START:
        return trashedfastAssTripStart(state, action);
    case actionTypes.TRASHED_FAST_ASSTRIP_SUCCESS:
        return trashedfastAssTripSuccess(state, action);
    case actionTypes.TRASHED_FAST_ASSTRIP_FAIL:
        return trashedfastAssTripFail(state, action);
    default:
      return state;
  }
};
export default trashedtrips;
