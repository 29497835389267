import * as actionTypes from "../actionTypes";
import axios from "axios";

export const clearMsgBus = () => {
  return {
    type: actionTypes.CLEAR_MSG_BUS,
  };
};

export const fetchAllBusesStart = () => {
  return {
    type: actionTypes.FETCH_BUSES_START,
  };
};
export const fetchAllBusesSuccess = (buses: any) => {
  return {
    type: actionTypes.FETCH_BUSES_SUCCESS,
    buses,
  };
};
export const fetchAllBusesFail = (error: any) => {
  return {
    type: actionTypes.FETCH_BUSES_FAIL,
    error,
  };
};

export const fetchAllBuses = () => {
  return (dispatch: any) => {
    dispatch(fetchAllBusesStart());
    axios
      .post(
        `admin/bus/getbuses.php
        `
      )
      .then((res) => {
        console.log(res);
        dispatch(fetchAllBusesSuccess(res.data.data ?? []));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fetchAllBusesFail(err.response.data.msg));
        } else {
          dispatch(fetchAllBusesFail("Internet Connection Failed"));
        }
      });
  };
};

// for create bus

export const createBusStart = () => {
  return {
    type: actionTypes.CREATE_BUS_START,
  };
};
export const createBusSuccess = (msg: any, newBus: any) => {
  return {
    type: actionTypes.CREATE_BUS_SUCCESS,
    msg,
    newBus,
  };
};
export const createBusFail = (error: any) => {
  return {
    type: actionTypes.CREATE_BUS_FAIL,
    error,
  };
};

export const createBus = (data: any) => {
  return (dispatch: any) => {
    dispatch(createBusStart());

    axios
      .post(
        `admin/bus/addbus.php
        `,
        data
      )
      .then((res) => {
        console.log(res);
        dispatch(createBusSuccess(res.data.msg, res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(createBusFail(err.response.data.msg));
        } else {
          dispatch(createBusFail("Internet Connection Failed"));
        }
      });
  };
};

// for create bus
export const editBusStart = () => {
  return {
    type: actionTypes.EDIT_BUS_START,
  };
};
export const editBusSuccess = (msg: any, newBus: any) => {
  return {
    type: actionTypes.EDIT_BUS_SUCCESS,
    msg,
    newBus,
  };
};
export const editBusFail = (error: any) => {
  return {
    type: actionTypes.EDIT_BUS_FAIL,
    error,
  };
};

export const editBus = (data: any) => {
  return (dispatch: any) => {
    // dispatch(editBusStart());

    axios
      .post(
        `admin/bus/editbus.php
        `,
        data
      )
      .then((res) => {
        console.log(res);
        dispatch(editBusSuccess("SUCCESS", res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(editBusFail(err.response.data.msg));
        } else {
          dispatch(editBusFail("Internet Connection Failed"));
        }
      });
  };
};
