import React, {
  Fragment,
  Dispatch,
  useEffect,
  memo,
  useState,
  useRef,
} from "react";
import { Link, withRouter } from "react-router-dom";
// import parent_select_location_sample from '../../../assets/parent_select_location_sample.png';
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index.actions";
import { IStateType } from "../../../store/models/root.interface";
import Loader from "react-loader-spinner";
import Pagination from "react-js-pagination";
import classes from "../../Pagination.module.css";
import assigntrip from "../../../store/reducers/parent/assigntrip.reducer";
import SweetAlert from "react-bootstrap-sweetalert";
import { Translate } from "../../../utils/Translate";

interface trip {
  id: string;
  direction: string;
  student_id: string;
  address: string;
  location_lat: string;
  location_lng: string;
  handing_instructions: string;
  collector_name: string;
  collector_id: string;
  parent_id: string;
  status: string;
  name: string;
  areaname: string;
  parentmobile: string;
  toschool?: number;
  tohome?: number;
}

const TrashedStudents: React.FC = memo((props: any) => {
  const count = useSelector((state: IStateType) => state.trashedtrips.count);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(3);

  const handlePageChange = (pageNumber: any) => {
    // console.log(`active page is ${pageNumber}`);
    dispatch(
      actions.fetchAllTrashedTrips(pageNumber, pageSize, enteredFilter)
    );
    setActivePage(pageNumber);
  };
  const dispatch: Dispatch<any> = useDispatch();
  const trashedtrips = useSelector(
    (state: IStateType) => state.trashedtrips.trips
  );
  const trips = useSelector((state: IStateType) => state.managetrips.trips);

  const loading = useSelector(
    (state: IStateType) => state.trashedtrips.loading
  );
  const tripsloading = useSelector(
    (state: IStateType) => state.trashedtrips.loading
  );

  const t = new Translate()
  useEffect(() => {
    // dispatch(
    //   actions.fetchAllRequestedTrips(activePage, pageSize, enteredFilter)
    // );
    dispatch(actions.fetchTrips(1, 1000));
  }, [dispatch]);
  const [selectedTripId, updateTrashedSelectedTripId] = useState<string>("");

  function assigntrip(e: any, trashedtripid: string) {
    e.preventDefault();

    const data = {
      trashedtrip_id: trashedtripid,

      trip_id: selectedTripId,
    };
    dispatch(actions.trashedfastAssTrip(data));
  }

  const errorMsg = useSelector(
    (state: IStateType) => state.trashedtrips.error
  );
  const msg = useSelector((state: IStateType) => state.trashedtrips.msg);

  const onCancel = () => {
    dispatch(actions.clearMsgTrashedtrips());
  };

  const onSuccessCancel = () => {
    dispatch(actions.clearMsgTrashedtrips());
  };

  let forError = null;
  if (errorMsg) {
    forError = (
      <SweetAlert
        danger
        title="Warning"
        onConfirm={onCancel}
        onCancel={onCancel}
      >
        {errorMsg}
      </SweetAlert>
    );
  }

  let forSuccess = null;
  if (msg) {
    forSuccess = (
      <SweetAlert
        success
        title="Success"
        onConfirm={onSuccessCancel}
        onCancel={onSuccessCancel}
      >
        {msg}
      </SweetAlert>
    );
  }

  let arrowLeft = <i className="fas fa-angle-double-left"></i>;
  let arrowRight = <i className="fas fa-angle-double-right"></i>;

  const inputSearchRef: any = useRef();
  const [enteredFilter, setEnteredFilter] = useState<string>("");

  useEffect(() => {
    const timer = setTimeout(() => {
      if (enteredFilter === inputSearchRef.current.value) {
        console.log(enteredFilter);
        dispatch(
          actions.fetchAllTrashedTrips(activePage, pageSize, enteredFilter)
        );
      }
    }, 500);

    return () => {
      clearTimeout(timer);
      setActivePage(1);
    };
  }, [enteredFilter, inputSearchRef]);

  return (
    <Fragment>
      <div className="text-center">
        <h2
          className="text-white"
          style={{
            textShadow:
              "0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)",
            fontSize: "2rem",
          }}
        >
          Trashed Students' Trips
        </h2>
      </div>
      <div className="input-group mt-3 mb-3">
        <input
          type="text"
          className="form-control"
          ref={inputSearchRef}
          onChange={(e) => setEnteredFilter(e.target.value)}
        />
        <div className="input-group-append">
          <button className="btn btn-primary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>

      {loading === false && tripsloading == false ? (
        <div className="justify-content-center align-items-center">
          {forError}
          {forSuccess}
          <div className="table-responsive bg-light">
            <table
              className="table-hover table-bordered text-center"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th scope="col">{t.translate("IVY ID")}</th>
                  <th scope="col">{t.translate("Student Name")}</th>
                  <th scope="col">{t.translate("Area")}</th>
                  <th scope="col">{t.translate("Location")}</th>
                  <th scope="col">{t.translate("To school")}</th>
                  <th scope="col">{t.translate("To Home")}</th>
                </tr>
              </thead>
              <tbody>
                {trashedtrips.map((trashedtrip: trip) => {
                  return (
                    <tr key={trashedtrip.id}>
                      <td className="w-20">{trashedtrip.student_id}</td>
                      <td className="w-20">{trashedtrip.name}</td>
                      <td className="w-20">{trashedtrip.areaname}</td>
                      <td className="w-20">
                        <p className="text-wrap text-break">
                          {trashedtrip.address}
                        </p>
                      </td>
                      <td className="w-10">{trashedtrip.toschool == 1 ? "Yes" : "No"}</td>
                      <td className="w-10">{trashedtrip.tohome == 1 ? "Yes" : "No"}</td>

                      <td className="w-10">
                        <button
                          className="btn btn-success text-white font-weight-bold m-1"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={"#h" + trashedtrip.id}
                          aria-expanded="false"
                          aria-controls="selectTrip"
                        >
                          <i className="fas fa-check"></i>
                        </button>
                        <div
                          className="align-items-center rounded"
                          style={{
                            backgroundColor: "rgb(44, 55, 94)",
                            color: "white",
                          }}
                        >
                          <div className="collapse" id={"h" + trashedtrip.id}>
                            <div className="form-group">
                              <label className="text-white font-weight-bold text-center">
                                {t.translate("Trip")}
                              </label>
                              <form onSubmit={(e) => assigntrip(e, trashedtrip.id)}>
                                <select
                                  className="form-control text-center"
                                  aria-label="Default select example"
                                  required
                                  onChange={(e) =>
                                    updateTrashedSelectedTripId(e.target.value)
                                  }
                                >
                                  <option value="">{t.translate("Choose Trip")}</option>
                                  {trips.map((trip: any) => {
                                    return (
                                      <option key={trip.id} value={trip.id}>
                                        {trip.tripname} - {trip.direction}
                                      </option>
                                    );
                                  })}
                                </select>
                                <button
                                  // onClick={() => assigntrip(trashedtrip.id)}
                                  className="btn btn-success text-white font-weight-bold m-1"
                                >
                                  {t.translate("Assign")}
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center align-items-center my-3">
            {count !== 0 ? (
              <Pagination
                activePage={activePage}
                itemsCountPerPage={pageSize}
                totalItemsCount={+count}
                pageRangeDisplayed={5}
                onChange={(e) => handlePageChange(e)}
                firstPageText={"First"}
                lastPageText={"Last"}
                prevPageText={arrowLeft}
                nextPageText={arrowRight}
                activeClass={classes.active}
              />
            ) : null}
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
        </div>
      )}
    </Fragment>
  );
});

export default withRouter(TrashedStudents);
