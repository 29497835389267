import React, { Fragment, useState, useEffect, Dispatch, memo } from "react";
import { Link, withRouter } from "react-router-dom";
// import parent_select_location_sample from '../../../assets/parent_select_location_sample.png';
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../store/actions/index.actions";
import { IStateType } from "../../../store/models/root.interface";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import Map from "../../Maps/Map";
import { Translate } from "../../../utils/Translate";

const GettingLocation: React.FC = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();

  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const students: any[] = useSelector(
    (state: IStateType) => state.assigntrip.students
  );
  const t = new Translate()
  const latRedux: any = useSelector(
    (state: IStateType) => state.assigntrip.location_lat
  );
  const lngRedux: any = useSelector(
    (state: IStateType) => state.assigntrip.location_lng
  );

  useEffect(() => {
    getLocation();
  }, []);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      // x.innerHTML = "Geolocation is not supported by this browser.";
      alert("Geolocation is not supported by this browser.");
    }
  };

  const showPosition = (position: any) => {
    console.log(position);

    if (latRedux && lngRedux) {
      console.log("here kemo", latRedux + " " + lngRedux);
      setCoordinates({
        lat: +latRedux,
        lng: +lngRedux,
      });
    } else {
      setCoordinates({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    }
  };

  const onSubmitHandler = () => {
    dispatch(actions.getCoords(coordinates.lat, coordinates.lng));
    props.history.push("/setinstrucrtions");
  };

  const [address, setAddress] = React.useState("");

  const handleSelect = async (value: any) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(latLng);
  };
  return (
    <Fragment>
      <Link to="/getaddress" className="arrow-back">
        <i className="fas fa-arrow-left"></i>
      </Link>
      <div className="container d-flex flex-column justify-content-center align-items-center">
        <div
          className="align-items-center mt-1 w-100 rounded text-white text-center"
          style={{
            backgroundColor: "rgb(44, 55, 94)",
            color: "white",
            fontSize: "1.5 rem",
          }}
        >
          <h5>For:</h5>
          {students.map((std) => {
            return <h5 key={std.id}> {std.name}</h5>;
          })}
        </div>
      </div>
      <div className="col-12 col-md-8 flex-column justify-content-center align-items-center">
        <div className="justify-content-center text-center">
          <label className="text-white font-weight-bold text-center">
            Mark Location Precisely
          </label>
        </div>

        <div className="mt-3 col-12 p-0 row justify-content-center align-items-center gap-2">
          <div className="col-2-lg row justify-content-center align-items-center">
            <label className="p-0 col-lg">{t.translate("Search location")}  : </label>
            <PlacesAutocomplete

              value={address}
              onChange={setAddress}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="form-group col-2-lg p-0">
                  {/* <p>Latitude: {coordinates.lat}</p>
                  <p>Longitude: {coordinates.lng}</p> */}

                  <input
                    {...getInputProps({
                      placeholder: "Type address",
                      className: "form-control",
                    })}
                  />

                  <div>
                    {loading ? <div>{t.translate("...loading")}</div> : null}

                    {suggestions.map((suggestion, index) => {
                      const style = {
                        backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                      };

                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, { style, key: index })}
                          key={index}
                        >
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            <button
              type="button"
              className="col-lg btn btn-success mb-3"
              onClick={() => getLocation()}
            >
              {t.translate("Or use current location")}
            </button>
          </div>
        </div>
        <div className="col-12 row justify-content-center align-items-center mb-2">
          <div className="" style={{ height: "300px" }}>
            {
              <Map
                coordinates={coordinates}
                getChangeLocation={setCoordinates}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg&v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBaD17OgMbI_0stUcRlvaeIvMuTet_rjdk`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `300px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            }
          </div>
        </div>

        <div className="col-12 form-group justify-content-center text-center mt-3">
          <button
            // to='setinstrucrtions'
            onClick={onSubmitHandler}
            className="btn btn-block btn-warning text-white font-weight-bold "
          >
            {t.translate("Proceed")}
          </button>
        </div>
        {/* <button onClick={getLocation}>get position</button> */}
      </div>
    </Fragment>
  );
};

export default memo(withRouter(GettingLocation));
