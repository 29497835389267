import React, { Fragment, useState, Dispatch, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions/index.actions';

import student_avatar from '../../../assets/student_avatar.png';
import { IStateType } from '../../../store/models/root.interface';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Translate } from '../../../utils/Translate';

const SelectChild: React.FC = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const students = useSelector(
    (state: IStateType) => state.assigntrip.students
  );
  const loading = useSelector((state: IStateType) => state.assigntrip.loading);
  let [children, setChildern] = useState([]);
  let [duration, setDuration] = useState('Two Semester');
  useEffect(() => {
    dispatch(actions.fetchStudents());
    dispatch(actions.fetchLastRequest());
  }, [dispatch]);
  const onChangeHandler = (e: any) => {
    // console.log(e.target.checked, e.target.id);
    if (e.target.checked) {
      let id = e.target.id;
      setChildern((prevState) => prevState.concat(id));
    } else {
      let curId = e.target.id;
      setChildern((prevState) => prevState.filter((id) => id !== curId));
    }
  };

  const t = new Translate()
  const onDurationChange = (value: any) => {
    setDuration(value);
  }

  const [showWarning, setShowWarning] = useState<boolean>(false);
  let warning = null;
  if (showWarning) {
    warning = (
      <SweetAlert
        warning
        confirmBtnText="OK"
        confirmBtnBsStyle='success'
        title='Select at least 1 Child.'
        onConfirm={() => setShowWarning(false)}
      >
      </SweetAlert>
    );
  }
  const submitChildren = (destination: string) => {
    if (children.length) {
      dispatch(actions.getChildren(children, destination, duration));
      props.history.push('/getaddress');
    }
    if (children = []) {
      setShowWarning(true);
    }
  };
  // console.log(props);
  return (
    <Fragment>
      <div className='container d-flex flex-column justify-content-center align-items-center'>
        {warning}
        <h3
          className='text-white text-center mt-1'
          style={{ fontSize: '2 rem' }}
        >
          {t.translate("No child selected")}
        </h3>

        {/*   */}
        {loading === false
          ? students.map((student: any) => {
            // console.log(student);
            return (
              <div
                className='align-items-center mt-1 w-100 rounded p-2'
                style={{ backgroundColor: 'rgb(44, 55, 94)', color: 'white' }}
                key={student.id}
              >
                <div className='row align-items-center'>
                  <div className='col-sm-12 col-md-2'>
                    <div className='row'>
                      <div
                        className='col-sm-12'
                        style={{ textAlign: 'center' }}
                      >
                        <input
                          type='checkbox'
                          id={student.id}
                          style={{ height: '25px', width: '25px' }}
                          onChange={onChangeHandler}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-12 col-md-2'>
                    <div className='row'>
                      <div className='col-sm-12 text-center'>
                        {student.name}
                      </div>
                    </div>
                  </div>

                  <div className='col-sm-12 col-md-2'>
                    <div className='row'>
                      <div className='col-sm-12'>
                        <div
                          className='rounded  mx-auto d-block'
                          style={{ width: '60px', height: '60px' }}
                        >
                          <img
                            className=' rounded'
                            src={
                              student.image ? student.image : student_avatar
                            }
                            alt='..'
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-sm-12 col-md my-4 m-md-0 border-right border-left'>
                    <div className='row'>
                      <div className='col-sm-12'>
                        <div className='text-center'>{t.translate("Requested")}:</div>
                        {student.requested.map((req: any) => {

                          if (req.toschool == 1 && req.tohome == 1) {
                            return (
                              <div
                                key={req.id}
                                className='d-flex justify-content-center'
                              >
                                <i className='fas fa-exchange-alt'></i>
                              </div>
                            );
                          } else if (req.toschool) {
                            return (
                              <div
                                key={req.id}
                                className='d-flex justify-content-center'
                              >
                                <i className='fas fa-long-arrow-alt-right'></i>
                              </div>
                            );
                          } else if (req.tohome === 1) {
                            return (
                              <div
                                key={req.id}
                                className='d-flex justify-content-center'
                              >
                                <i className='fas fa-long-arrow-alt-left'></i>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </div>
                    </div>
                  </div>

                  <div className='col-sm-12 col-lg'>
                    <div className='row'>
                      <div className='col-sm-12 text-center'>{t.translate("Assigned")}:</div>
                    </div>
                  </div>
                  {student.assigned.map((ass: any) => {
                    return (
                      <div key={ass.id} className='col-sm-12 col-md'>
                        <div className='row'>
                          <div className='col-sm-12'>
                            <div className='d-flex justify-content-center'>
                              {ass.toschool === 1 && ass.tohome === 1 ? "Both" : ass.toschool === 1 ? "To School" : "To Home"}
                            </div>
                            <div className='d-flex justify-content-center'>
                              <button
                                onClick={() =>
                                  props.history.push(
                                    '/trackstudenttrip?id=' + ass.trip_id
                                  )
                                }
                                disabled={
                                  ass.status === 'active' ? false : true
                                }
                                className='btn btn-warning btn-block text-white font-weight-bold m-1'
                              >
                                {t.translate("Track")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })
          : null}

        {/*   */}
        <button
          className='btn btn-warning btn-block text-white font-weight-bold m-1'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#collapseExample'
          aria-expanded='false'
          aria-controls='collapseExample'
        >
          {t.translate("Request Adding/Editing Trip")}
        </button>
        <div className='collapse' id='collapseExample'>
          <div
            className='card card-body'
            style={{ backgroundColor: 'rgb(44, 55, 94)', color: 'white' }}
          >
            <div>
              {/* Select semester */}
              <select
                className='form-control form-control-lg form-select'
                onChange={(e) => onDurationChange(e.target.value)}
                required
              // value={area_id ? area_id : 'disableEle'}

              >
                <option value='Two Semester'>
                  {t.translate("Two semesters")}
                </option>
                <option value='One Semester'>
                  {t.translate("One semester")}
                </option>

              </select>

            </div>
            <div className='d-flex justify-content-center align-items-center'>
              <button
                className='btn btn-warning text-white font-weight-bold m-1'
                onClick={() => submitChildren('to the school')}
              >
                {t.translate("To school")}
              </button>
              <button
                className='btn btn-warning text-white font-weight-bold m-1'
                onClick={() => submitChildren('to home')}
              >
                {t.translate("To Home")}
              </button>
              <button
                className='btn btn-warning text-white font-weight-bold m-1'
                onClick={() => submitChildren('both')}
              >
                {t.translate("Both Ways")}
              </button>
            </div>
          </div>
        </div>
        {/*<button className='btn btn-warning btn-block text-white font-weight-bold m-1'>
          Request Removing Trip
          </button>*/}
      </div>
    </Fragment>
  );
};

export default withRouter(SelectChild);
