import React, { Dispatch } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateCurrentPath } from "../../../store/actions/root.actions";
import parent_avatar from "../../../assets/parent_avatar.png";
import ivy_logo from "../../../assets/ivy_logo_min.png";
import harmony_logo from "../../../assets/harmonylogo_min.png";
import axios from "axios";
import Loader from "react-loader-spinner";
import SweetAlert from "react-bootstrap-sweetalert";

class DoPayment extends React.Component<any, any> {
  amount = 0;
  Checkout: any;
  order: string = "";
  session: string = "";
  constructor(props: any) {
    super(props);
    var w: any = window;
    w.paycancel = () => {
      this.paycancel();
    };
    w.payerror = (p: any) => {
      this.payerror(p);
    };
    w.paycomp = () => {
      this.paycomp();
    };
    w.paytimeout = () => {
      this.paytimeout();
    };
    this.state = {
      loading: false,
      errorMsg: null,
      successMsg: null,
      paymentSelect: "",
      selectedFile: null,
      image: null,
    };
  }
  updateamount = (e: any) => {
    console.log(e);
    this.amount = e.target.value;
    console.log(this.amount);
  };
  startPaymentProcess = (e: any) => {
    console.log(e);
    console.log(e.target);
    e.preventDefault();
    console.log("Start Payment!.");
    this.setState({ loading: true });
    axios
      .post(`parent/payment/createsession.php`, { amount: this.amount })
      .then((res: any) => {
        console.log(res);
        this.order = res.data.data.order;
        this.session = res.data.data.result.session.id;
        //this.order = this.dat
        console.log(this.order, this.session);
        this.configureCheckout();
        var w: any = window;
        w.Checkout.showLightbox();

        //dispatch(fetchAllBusesSuccess(res.data.data));
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          //dispatch(fetchAllBusesFail(err.response.data.msg));
        } else {
          //dispatch(fetchAllBusesFail('Internet Connection Failed'));
        }
      });
  };
  payerror = (error: any) => {
    console.log(JSON.stringify(error));
    this.setState({ errorMsg: " Error: " + error, loading: false });
  };
  paycancel = () => {
    console.log("Payment cancelled");
    this.setState({ errorMsg: "Payment cancelled", loading: false });
  };
  paycomp = () => {
    console.log("Payment Completed");
    // check from BE
    axios
      .post("include/payment/checkpayment.php", {
        order: this.order,
        amount: this.amount,
      })
      .then((data: any) => {
        if (data == "success") {
          console.log(data);
          if (data.search("success") !== -1) {
            console.log("Success !!.");
            this.setState({ successMsg: "Success !!.", loading: false });
          } else {
            console.log("ERRORR!!!");
            this.setState({ errorMsg: "Error !!.", loading: false });
          }
        } else {
          console.log("error");
          this.setState({ errorMsg: "Error !!.", loading: false });
        }
      });
  };
  paytimeout = () => {
    console.log("Payment timeout");
    this.setState({ loading: false });
    this.setState({ errorMsg: "Payment timeout" });
  };
  configureCheckout = () => {
    var w: any = window;
    w.Checkout.configure({
      merchant: "CIB701182",
      order: {
        amount: this.amount,
        currency: "EGP",
        description: "Transportation Fees",
        id: this.order,
      },
      session: {
        id: this.session,
      },
      interaction: {
        operation: "PURCHASE",
        merchant: {
          name: "IVY STEM International Schools",
        },
        locale: "en_US",
      },
    });
  };

  openAddFileInputHandler = () => {
    const fileInput = document.getElementById("addFile") as HTMLInputElement;
    fileInput.click();
  };

  addFileHandler = (e: any) => {
    const file: any = e.target.files[0];
    // console.log(file);

    if (file && file.size > 0) {
      var reader: any = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (reader.result.split(":")[1].split("/")[0] === "image") {
          console.log(reader.result); //base64encoded string
          this.setState({ image: reader.result, selectedFile: file });
        }
      };
    }
  };

  onUploadImage = () => {
    const formData = new FormData();
    formData.append(
      "file",
      this.state.selectedFile,
      this.state.selectedFile.name
    );

    // axios
    // .post(`api/v1/client/file/${folderId}`, formData)
    // .then((res) => {
    //   // console.log(res);
    //   // dispatch(uploadFileSuccess(res.data.file));
    // })
    // .catch((err) => {
    //   console.log(err);
    //   console.log(err.response);
    // });
  };

  onClearMsgHandler = () => {
    this.setState({ errorMsg: null, successMsg: null });
  };

  render() {
    // let paymentDiv = document.getElementById('payContainer') as HTMLDivElement;
    // if(paymentDiv){
    //   this.setState({loading:false})
    // }

    console.log(this.state);

    let forError = null;
    if (this.state.errorMsg) {
      forError = (
        <SweetAlert
          danger
          title="Warning"
          onConfirm={this.onClearMsgHandler}
          onCancel={this.onClearMsgHandler}
        >
          {this.state.errorMsg}
        </SweetAlert>
      );
    }

    let forSuccess = null;
    if (this.state.successMsg) {
      forSuccess = (
        <SweetAlert
          success
          title="Success"
          onConfirm={this.onClearMsgHandler}
          onCancel={this.onClearMsgHandler}
        >
          {this.state.successMsg}
        </SweetAlert>
      );
    }

    return this.state.loading === false ? (
      <div className="row justify-content-center">
        {forError}
        {forSuccess}
        <div className="row justify-content-center align-items-center text-center">
          <h2
            className="text-white"
            style={{
              textShadow:
                "0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)",
              fontSize: "2rem",
            }}
          >
            IVY-IS Transportation
          </h2>
          <img
            src={parent_avatar}
            height="100px"
            className="rounded d-block "
            style={{ width: "auto" }}
            alt="..."
          />
        </div>
        <div className="row">
          <div className="col-lg-4 mx-auto mt-1">
            <div className="card shadow" style={{ backgroundColor: 'rgb(44, 55, 94)' }}>
              <div className="card-body">
                <div className="text-center my-6">
                  <p className="d-block h5 mb-0 text-white">Required Fees: 60000</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-3 justify-content-center align-items-center">
          <select
            className="form-select col-sm-6"
            aria-label="Default select"
            onChange={(e) => this.setState({ paymentSelect: e.target.value })}
          >
            <option value="">Choose Method Payment</option>
            <option value="Cash at the school">Cash at the school</option>
            <option value="Online payment">Online payment</option>
            <option value="Bank transfer">Bank transfer</option>
          </select>
        </div>
        {this.state.paymentSelect === "Cash at the school" ? (
          <div className="row justify-content-center align-items-center">
            <div className="col-sm-12 text-center text-white rounded m-2 p-4" style={{ backgroundColor: 'rgb(44, 55, 94)' }}>
              <p
              style={{
                color: 'white',
                textAlign: 'center',
                textShadow:'0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)',
                fontSize: '1.2rem',
              }}>
                Please go to school and pass by room number: 133 and pay 50000LE.<br/>For any inquires, feel free to call the Ivy-Transportation Department on 0123456789 <br/> or send an email to : 
                <a className="text-white" href="mailto:transporation@ivyis.com"> transporation@ivyis.com</a>
              </p>
            </div>
          </div>
        ) : null}
        {this.state.paymentSelect === "Online payment" ? (
            <div className="row justify-content-center align-content-between" >
              <div className="col-12 row justify-content-center align-content-center mt-2">
              <form
                  onSubmit={this.startPaymentProcess}
                  className="row m-0 p-0" >
                  <div className="col-12 form-groupform-inline row m-0 p-0  justify-content-center">
                    <div className="col-md-6 m-0 p-0">
                      <label className="col-12">
                        {" "}
                        Amount to pay (EGP):
                      </label>
                      <input
                        className="form-control"
                        onChange={this.updateamount}
                        name="amount"
                        type="number"
                        min="1"
                     />
                    </div>
                  </div>
                  <div className="col-12  form-group form-inline row m-0 p-0 pb-2 justify-content-center">
                    <button className="btn col-md-6 btn-warning text-white font-weight-bold mt-4">
                      Start Payment Process!
                    </button>
                  </div>
                </form>
              </div>

              <div className="container d-flex justify-content-center align-items-center m-3">
                <div>
                  <img
                    src={ivy_logo}
                    height="100px"
                    className="rounded d-block "
                    style={{ width: "auto" }}
                    alt="..."
                  />
                </div>
                <div>
                  <img
                    src={harmony_logo}
                    height="40px"
                    className="rounded d-block"
                    style={{ width: "auto" }}
                    alt="..."
                  />
                </div>
              </div>
            </div>
        ) : null}
        {this.state.paymentSelect === "Bank transfer" ? (
          <div className="row justify-content-center align-items-center text-white">
            <input
              type="file"
              id="addFile"
              hidden={true}
              onChange={this.addFileHandler}
            />
            <div className="row justify-content-center ali align-items-center">
              <button
                type="button"
                disabled={this.props.loading}
                style={{ border: "none", background: "inherit",fontSize: '1.4rem'}}
                className={`row  justify-content-center align-items-center text-white ${
                  this.props.loading ? "text-secondary" : "text-white"
                }`}
                onClick={this.openAddFileInputHandler}
              >
                <i className="fas fa-plus-circle fa-2x"></i> Upload Image
              </button>
              {this.state.image ? (
                <img src={this.state.image} alt=""></img>
              ) : null}
              {this.state.image ? (
                <button className="btn btn-warning text-white font-weight-bold my-3">
                  Upload
                </button>
              ) : null}
            </div>
            <div className="text-center text-white rounded m-2 p-4" style={{ backgroundColor: 'rgb(44, 55, 94)' }}>
              <p
              style={{
                color: 'white',
                textAlign: 'center',
                textShadow:'0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)',
                fontSize: '1.2rem',
              }}>
                1-Go to your preferred bank.<br/>
                2-You Should transfer: 50000LE to IVY-Transportation Account: <strong>100045914296</strong> .<br/> 
                3-Take a clear image of the transfer receipt<br/> 
                4-then attach the transfer receipt via the above "Upload Image" 
              </p>
            </div>
          </div>
        ) : null}
         {this.state.paymentSelect === "" ? (
           <>
            <div className='justify-content-center align-items-center mt3'>
            <p className="text-start text-white " style={{fontSize: '1.2rem'}}>Payment History:</p>
            <div className='table-responsive bg-light'>
              <table
                className='table-hover table-bordered text-center'
                style={{ width: '100%' }}
              >
                <thead>
                  <tr>
                    <th scope='col'>#</th>
                    <th scope='col'>Date</th>
                    <th scope='col'>Payment amount</th>
                    <th scope='col'>Payment method</th>
                    <th scope='col'>Note</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>2021-05-16 10:09:10</td>
                    <td>300</td>
                    <td>Online payment</td>
                    <td>Outstanding payment</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='justify-content-center align-items-center mt3'>
          <p className="text-start text-white mt-1" style={{fontSize: '1.2rem'}}>Subscriptions:</p>
          <div className='table-responsive bg-light'>
            <table
              className='table-hover table-bordered text-center'
              style={{ width: '100%' }}
            >
              <thead>
                <tr>
                  <th scope='col'>#</th>
                  <th scope='col'>Amount</th>
                  <th scope='col'>For</th>
                  <th scope='col'>Notes</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>3000</td>
                  <td>Ramy Samir | Tagamo3-Trip 3-to school |</td>
                  <td>Not fully paid</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        </>
         ):null}
      </div>
    ) : (
      <div className="d-flex justify-content-center align-items-center mt-5">
        <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
      </div>
    );
  }
}

export default DoPayment;
