import React, { useState } from "react";
import { useParams } from "react-router";

import { Form } from "react-bootstrap";
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import unkown from "../../assets/unkown.jpg";
import "./student.css";
import { Translate } from "../../utils/Translate";

const StudentModal: React.FC<any> = (props: any) => {
  let t = new Translate();
  const { id } = useParams<{ id?: string }>();

  const [modal, setModal] = useState(false);
  const [Temp, setTemp] = useState("");

  const submitHandler = (e: any) => {
    e.preventDefault();
    props.setStudentModal(false);
    props.submitStudent(Temp);
    setTemp("");
  };
  const handleChange = (e: any) => {
    setTemp(e.target.value);
  };

  return (
    <div className="studentForm">
      <Modal isOpen={props.modal}>
        <ModalHeader>{t.translate("Add Attendance")}</ModalHeader>
        <ModalBody>
          <Form onSubmit={submitHandler}>
            <div className="upload-image mb-2">
              <img
                src={
                  props.student?.driveid == null
                    ? unkown
                    : `https://drive.google.com/uc?id=${props.student?.driveid}&export=download`
                }
              />
            </div>
            {props.student?.assigned == "no" ? (
              <div style={{ color: "red" }} className="mb-3">
                {t.translate("This student not assigned to this trip")}
              </div>
            ) : (
              ""
            )}

            <div style={{ fontWeight: "bold" }} className="mb-3">
              {" "}
              {t.translate("Student ID")} : {props.student?.id}
            </div>
            <div style={{ fontWeight: "bold" }} className="mb-3">
              {" "}
              {t.translate("Student Name")} : {props.student?.fullname}
            </div>
            <div style={{ fontWeight: "bold" }} className="mb-3">
              {" "}
              {t.translate("Grade")} : {props.student?.grade}
            </div>

            <label>{t.translate("Temperature")}</label>
            <Input
              type="number"
              placeholder="Temperature"
              value={37}
              name="entrytempreture"
              onChange={handleChange}
              required
            />
            {+Temp >= 38.5 ? (
              <div style={{ color: "red" }}>
                {t.translate(
                  "Temperature is very high and you have to leave school"
                )}
              </div>
            ) : (
              ""
            )}

            <Input
              type="submit"
              className="btn btn-primary mt-3"
              value={t.translate("Submit")}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-secondary"
            onClick={() => {
              props.setStudentModal(false);
              props.setQrCode(false);
            }}
          >
            {t.translate("Cancel")}
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default StudentModal;
