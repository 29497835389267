import React, { Fragment, memo, Dispatch, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions/index.actions';
import { IStateType } from '../../../store/models/root.interface';
import { Translate } from '../../../utils/Translate';

const SetHandingInstructions: React.FC = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const students: any[] = useSelector(
    (state: IStateType) => state.assigntrip.students
  );
  const t = new Translate()
  const [handing_instructions, setInstructions] = useState<string>('');
  const [collector_name, setCollectorName] = useState<string>('');
  const [collector_id, setCollectorId] = useState<string>('');

  const data: any = useSelector(
    (state: IStateType) => state.assigntrip
  );
  useEffect(() => {
    setInstructions(data.handing_instructions);
    setCollectorName(data.collector_name);
    setCollectorId(data.collector_id);

  }, [data])

  const {
    students_ids,
    direction,
    duration,
    area_id,
    address,
    location_lat,
    location_lng,

  } = useSelector((state: IStateType) => state.assigntrip);

  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    // console.log(handing_instructions, collector_name, collector_id);
    const tripData = {
      students_ids,
      direction,
      duration,
      area_id,
      address,
      location_lat,
      location_lng,
      handing_instructions,
      collector_name,
      collector_id,
    };
    console.log(tripData);

    dispatch(
      actions.getInstruction(
        handing_instructions,
        collector_name,
        collector_id
      )
    );
    dispatch(actions.assignTrip(tripData));
    props.history.push('/waitconfirmation');

  };
  return (
    <Fragment>
      <Link to='/getlocation' className='arrow-back'>
        <i className='fas fa-arrow-left'></i>
      </Link>
      <div className='container d-flex flex-column justify-content-center align-items-center'>
        <div
          className='align-items-center mt-1 w-100 rounded text-white text-center'
          style={{
            backgroundColor: 'rgb(44, 55, 94)',
            color: 'white',
            fontSize: '1.5 rem',
          }}
        >
          <h5>For:</h5>
          {students.map((std) => {
            return <h5 key={std.id}> {std.name}</h5>;
          })}
        </div>
      </div>
      <form
        onSubmit={(e) => onSubmitHandler(e)}
        className='col-12 row flex-column justify-content-center align-items-center m-2'
      >
        <div className='col-12 form-group justify-content-center text-center'>
          <label className='text-white font-weight-bold'>
            State Handing Instructions
          </label>
          <textarea
            className='form-control'
            value={handing_instructions}
            onChange={(e) => setInstructions(e.target.value)}
          ></textarea>

          <div className='mt-4'>
            <button
              className='btn btn-warning text-white font-weight-bold'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#CollectorData'
              aria-expanded='false'
              aria-controls='CollectorData'
            >
              {t.translate("Add collector Information")}
            </button>
          </div>

          <div className='collapse' id='CollectorData'>
            <label className='text-white font-weight-bold mt-1'>
              {t.translate("Collector Name")}
            </label>
            <input
              type='text'
              className='form-control'
              placeholder='Collector Name'
              aria-label='Collector Name'
              aria-describedby='adding collector name'
              onChange={(e) => setCollectorName(e.target.value)}
              defaultValue={collector_name}
            />
            <label className='text-white font-weight-bold mt-1'>
              {t.translate("Collector ID")}
            </label>
            <input
              type='text'
              className='form-control'
              placeholder='Collector ID'
              aria-label='Collector ID'
              aria-describedby='adding collector ID'
              onChange={(e) => setCollectorId(e.target.value)}
              defaultValue={collector_id}
            />
          </div>

          <button
            // to='waitconfirmation'
            onClick={onSubmitHandler}
            className='btn btn-block btn-warning text-white font-weight-bold mt-3'
          >
            {t.translate("Proceed")}
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default memo(withRouter(SetHandingInstructions));
