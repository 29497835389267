import { IActionBase } from '../models/root.interface';
import { Auth } from '../models/auth.interface';
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState: Auth = {
  token: null,
  userName: null,
  userId: null,
  driverId:null,
  role: null,
  loading: false,
  gpsnumber: null,
  error: null,
};

const authStart = (state: Auth, action: IActionBase) => {
  return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state: Auth, action: IActionBase) => {
  return updateObject(state, {
    token: action.token,
    userId: action.userId,
    driverId:action.driverId,
    userName: action.userName,
    role: action.role,
    gpsnumber: action.gpsnumber,
    error: null,
    loading: false,
  });
};

const authFail = (state: Auth, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const authLogout = (state: Auth, action: IActionBase) => {
  return updateObject(state, {
    token: null,
    userId: null,
    driverId:null,
    userName: null,
    role: null,
  });
};

const authClearError = (state: Auth, action: IActionBase) => {
  return updateObject(state, {
    error: null,
    loading: false,
  });
};

function accountReducer(state: Auth = initialState, action: IActionBase): Auth {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.AUTH_ERROR:
      return authClearError(state, action);
    default:
      return state;
  }
}

export default accountReducer;
