import React, { useState, FormEvent, Dispatch } from 'react';
import { OnChangeModel } from '../../common/types/Form.types';
import { useDispatch, useSelector } from 'react-redux';
// import { login } from '../../store/actions/account.actions';
import TextInput from '../../common/components/TextInput';
import ivy_logo from '../../assets/ivy_logo.png';
import harmony_logo from '../../assets/harmonylogo.svg';
import { withRouter } from 'react-router-dom';

import * as actions from '../../store/actions/index.actions';
import { IStateType } from '../../store/models/root.interface';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from 'react-loader-spinner';

const Login: React.FC = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();

  const loading = useSelector((state: IStateType) => state.auth.loading);

  const [formState, setFormState] = useState({
    email: { error: '', value: '' },
    password: { error: '', value: '' },
  });

  function hasFormValueChanged(model: OnChangeModel): void {
    setFormState({
      ...formState,
      [model.field]: { error: model.error, value: model.value },
    });
  }

  function submit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    if (isFormInvalid()) {
      return;
    }
    // console.log(formState.email.value, formState.password.value);
    dispatch(actions.auth(formState.email.value, formState.password.value));
  }

  const onCancel = () => {
    dispatch(actions.authClearError());
  };
  const errorMsg = useSelector((state: IStateType) => state.auth.error);
  let forError = null;
  if (errorMsg) {
    forError = (
      <SweetAlert
        danger
        title='Warning'
        onConfirm={onCancel}
        onCancel={onCancel}
      >
        {errorMsg}
      </SweetAlert>
    );
  }
  function isFormInvalid() {
    return (
      formState.email.error ||
      formState.password.error ||
      !formState.email.value ||
      !formState.password.value
    );
  }

  function getDisabledClass(): string {
    let isError: boolean = isFormInvalid() as boolean;
    return isError ? 'disabled' : '';
  }

  return (
    <div className='container '>
      {forError}
      <div
        className='row align-items-center justify-content-center'
        style={{ height: '100vh' }}
      >
        {loading === false ? (
          <div className='col-xl-10 col-lg-12 col-md-9'>
            <div className='card o-hidden border-0 shadow-lg '>
              <div className='card-body p-0'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className='p-5 bg-dark'>
                      <div className='col-12 row justify-content-center align-items-center align-content-center '>
                        <div className='col-12 row justify-content-center align-items-center mt-3'>
                          <img
                            src={ivy_logo}
                            height='150px'
                            width='150px'
                            className='rounded d-block '
                            style={{ width: 'auto' }}
                            alt='...'
                          />
                        </div>
                        <div className='col-12 row justify-content-center align-items-center mt-3'>
                          <img
                            src={harmony_logo}
                            height='40px'
                            className='rounded d-block'
                            style={{ width: 'auto' }}
                            alt='...'
                          />
                        </div>
                        <div className='col-12 mt-4  text-center'>
                          <h2
                            className='text-white'
                            style={{
                              textShadow:
                                '0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)',
                              fontSize: '1.2rem',
                            }}
                          >
                            IVY-IS Transportation
                          </h2>
                        </div>
                      </div>
                      <form className='col-12' onSubmit={submit}>
                        <div className='form-group row  justify-content-center mt-2'>
                          <div className='col-md-6 col-xl-5'>
                            <TextInput
                              id='input_email'
                              field='email'
                              value={formState.email.value}
                              onChange={hasFormValueChanged}
                              required={true}
                              maxLength={100}
                              label='Email'
                              style={{ backgroundColor: '#7991fc' }}
                              className='form-control form-control-lg border-0 text-white'
                              placeholder='Enter Email'
                            />
                          </div>
                        </div>
                        <div className='form-group row  justify-content-center my-2'>
                          <div className='col-md-6 col-xl-5'>
                            <TextInput
                              id='input_password'
                              field='password'
                              value={formState.password.value}
                              onChange={hasFormValueChanged}
                              required={true}
                              maxLength={100}
                              type='password'
                              label='Password'
                              placeholder='Enter Password'
                              className='form-control form-control-lg border-0 text-white'
                            />
                          </div>
                        </div>
                        <div className='form-group row  justify-content-center mt-5'>
                          <div className='col-md-6 col-xl-5'>
                            <button
                              type='submit'
                              className={`btn btn-secondary  btn-block btn-warning text-white ${getDisabledClass()}`}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='d-flex justify-content-center align-items-center mt-5'>
            <Loader type='ThreeDots' color='#00BFFF' height={100} width={100} />
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(Login);
