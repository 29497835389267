import React, { useEffect, useState } from "react";
import axios from "axios";
import MapBuses from "../../Maps/MapAllBuses";
import { Input } from "reactstrap";
import { useHistory } from "react-router";

import $ from "jquery";
import Swal from "sweetalert2";
import { Translate } from "../../../utils/Translate";
const BusTracking: React.FC = () => {
  let history = useHistory();
  const today = new Date();
  const t = new Translate()
  const date1 = today.toISOString().substr(0, 16);
  const [date, setDate] = useState<string>(date1);
  const [selectedBusId, setSelectedBusId] = useState();
  const gpsNumber = localStorage.gpsnumber;
  const [toggle, setToggle] = useState(false);
  const [trips, setTrips] = useState<any>([]);
  const [busRoutes, setBuseRoutes] = useState([]);
  useEffect(() => {
    Swal.showLoading();
    axios
      .get("reports/tripreports.php")
      .then((res) => {
        setTrips(res.data.data ?? []);
        $("#datatable").DataTable();
      })
      .finally(() => {
        Swal.close();
      });
  }, []);
  function changeRowColor(id: any, state: any) {
    let index = trips?.findIndex((trip: any) => trip.id === id);
    trips[index].color = state;
    setTrips([...trips]);
  }

  return (
    <div>
      <div className=" row col-12 ">
        <div className="text-center">
          <h2
            className="text-white"
            style={{
              textShadow:
                "0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)",
              fontSize: "2rem",
            }}
          >
            {t.translate("Trip Reports")}
          </h2>
        </div>

        <div
          className="table-responsive bg-light my-5"
          style={{ padding: "1%" }}
        >
          <table
            id="datatable"
            className="table-hover table-bordered text-center "
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>#</th>
                <th>{t.translate("Trip Name")}</th>
                <th>{t.translate("Trip Dir")}</th>
                <th>{t.translate("Date")}</th>
                <th>{t.translate("Start Time")}</th>
                <th>{t.translate("End Time")}</th>
                <th>{t.translate("Trip Status")}</th>
                <th>{t.translate("Attended Students")}</th>
                <th>{t.translate("Max Bus Speed")}</th>
              </tr>
            </thead>
            <tbody>
              {trips.length > 0
                ? trips.map((trip: any, index: any) => (
                  <tr
                    style={{
                      color: trip.color === "1" ? "blue" : "black",
                      cursor: "pointer",
                    }}
                    key={trip.id}
                    onMouseOver={() => changeRowColor(trip.id, "1")}
                    onMouseLeave={() => changeRowColor(trip.id, "0")}
                    onClick={() =>
                      history.push(
                        `/bustracking?from=${trip?.time?.split(" ")[1]}&to=${trip?.endtime?.split(" ")[1]
                        }&date=${trip?.time?.split(" ")[0]}&bus_id=${trip?.bus_id
                        }`
                      )
                    }
                  >
                    <td>{index + 1}</td>
                    <td>{trip?.tripname ?? "NA"}</td>
                    <td>{trip.direction ?? "NA"}</td>
                    <td>{trip?.time?.split(" ")[0] ?? "NA"}</td>
                    <td>{trip?.time?.split(" ")[1] ?? "NA"}</td>
                    <td>{trip?.endtime?.split(" ")[1] ?? "NA"}</td>
                    <td>{trip?.status}</td>
                    <td>{trip?.students}</td>
                    <td>{trip?.speed ?? "NA"}</td>
                  </tr>
                ))
                : null}
            </tbody>
          </table>
        </div>

        <div className="col-6"></div>
      </div>
    </div>
  );
};

export default BusTracking;
