import React, { useState} from "react";
import { Col } from "reactstrap";

import axios from 'axios';


import QrReader from 'react-qr-reader'


interface student {
    id :string ;
    fullname:string ;
}

interface studentOption {
    label :string ;
    value:string ;
}

const StudentQrCode: React.FC<any> = (props) => {


 const[delay ,setDelay] = useState(100);
 const[result ,setResult] = useState();


   
   

    const previewStyle = {
        height: 240,
        width: 320,
        margin: "auto",
        display:"inline-block"
      }

    const  handleScan =async(data :any)=>{
     setResult(data);
     if(data != null) {
       
       const URL = process.env.REACT_APP_BASEURL+"/include/decrypt.php";
       
       const res = await axios.post(URL ,{qrcode :data});
       console.log(res)
         
        props.handleScan(res);
        }
      }
    const  handleError =(err:any) =>{
        console.error(err)
      }
    return (    
            <div style={{ textAlign:"center"}}>
                {result != null ?" " : ( <QrReader
          delay={delay}
          style={previewStyle}
          onError={handleError}
          onScan={handleScan}
          facingMode="environment"
          /> )}
           
            </div>
    );
};

export default StudentQrCode;