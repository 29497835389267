import * as actionTypes from '../actionTypes';
import axios from 'axios';

//Fetch AREAS
export const sendDriverTripDetails = () => {
  return {
    type: actionTypes.SEND_DRIVER_START_TRIP_DETAILS,
  };
};
export const sendDriverTripDetailsSuccess = (msg: any, data: any) => {
  return {
    type: actionTypes.SEND_DRIVERTRIP_TRIP_DETAILS_SUCCESS,
    msg,
    data,
  };
};
export const sendDriverTripDetailsFail = (error: any) => {
  return {
    type: actionTypes.SEND_DRIVERTRIP_TRIP_DETAILS_FAIL,
    error,
  };
};
export const clearMsgDriverTripDetails = () => {
  return {
    type: actionTypes.CLEAR_MSG_DRIVERTRIPDETAILS,
  };
};

export const drivertripdetails = (data: any) => {
  return (dispatch: any) => {
    dispatch(sendDriverTripDetails());
    axios
      .post(`driver/tripdetails.php`, data)
      .then((res) => {
        console.log(res);

        dispatch(sendDriverTripDetailsSuccess(res.data.msg, res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(sendDriverTripDetailsFail(err.response.data.msg));
        } else {
          dispatch(sendDriverTripDetailsFail('Internet Connection Failed'));
        }
      });
  };
};

// change yes
export const changeYesToNo = () => {
  return {
    type: actionTypes.CHANGE_YES_TO_NO,
  };
};

// skip child
export const skipChildStart = () => {
  return {
    type: actionTypes.SKIP_CHILD_START,
  };
};
export const skipChildSuccess = () => {
  return {
    type: actionTypes.SKIP_CHILD_SUCCESS,
  };
};
export const skipChildFail = (error: any) => {
  return {
    type: actionTypes.SKIP_CHILD_FAIL,
    error,
  };
};

export const skipChild = (data: any) => {
  return (dispatch: any) => {
    dispatch(skipChildStart());
    axios
      .post(`driver/skipchild.php`, data)
      .then((res) => {
        console.log(res);

        dispatch(skipChildSuccess());
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(skipChildFail(err.response.data.msg));
        } else {
          dispatch(skipChildFail('Internet Connection Failed'));
        }
      });
  };
};

// picked child
export const pickedStart = () => {
  return {
    type: actionTypes.PICKED_CHILD_START,
  };
};
export const pickedSuccess = () => {
  return {
    type: actionTypes.PICKED_CHILD_SUCCESS,
  };
};
export const pickedFail = (error: any) => {
  return {
    type: actionTypes.PICKED_CHILD_FAIL,
    error,
  };
};

export const pickedChild = (data: any) => {
  return (dispatch: any) => {
    dispatch(pickedStart());
    axios
      .post(`driver/pickup.php`, data)
      .then((res) => {
        console.log(res);

        dispatch(pickedSuccess());
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(pickedFail(err.response.data.msg));
        } else {
          dispatch(pickedFail('Internet Connection Failed'));
        }
      });
  };
};

// end trip emergancy
export const endTripEmergancyStart = () => {
  return {
    type: actionTypes.END_TRIP_EMERGANCY_START,
  };
};
export const endTripEmergancySuccess = () => {
  return {
    type: actionTypes.END_TRIP_EMERGANCY_SUCCESS,
  };
};
export const endTripEmergancyFail = (error: any) => {
  return {
    type: actionTypes.END_TRIP_EMERGANCY_FAIL,
    error,
  };
};
export const clearTripEmergancy = () => {
  return {
    type: actionTypes.CLEAR_END_TRIP_EMERGANCY,
  };
};

export const endTripEmergancy = (data: any) => {
  return (dispatch: any) => {
    dispatch(endTripEmergancyStart());
    axios
      .post(`driver/finishtrip.php`, data)
      .then((res) => {
        console.log(res);

        dispatch(endTripEmergancySuccess());
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(endTripEmergancyFail(err.response.data.msg));
        } else {
          dispatch(endTripEmergancyFail('Internet Connection Failed'));
        }
      });
  };
};
