import { IActionBase, DriverEndTrip } from '../../models/root.interface';
import * as actionTypes from '../../actions/actionTypes';

import { updateObject } from '../../utility';

const initialState: DriverEndTrip = {
  trip: {},
  loading: null,
  error: null,
  msg: null,
  data: {},
};

// for API Calling
const sendDriverEndTrip = (state: DriverEndTrip, action: IActionBase) => {
  return updateObject(state, { error: null, loading: true });
};

const sendDriverEndTripSuccess = (
  state: DriverEndTrip = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    msg: action.msg,
    loading: false,
    error: null,
    data: action.data,
  });
};

const sendDriverEndTripFail = (
  state: DriverEndTrip,
  action: IActionBase
) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};
const clearMsgDriverEndTrip = (
  state: DriverEndTrip,
  action: IActionBase
) => {
  return updateObject(state, {
    error: null,
    msg: null,
    loading: null,
    data:{}
  });
};

const driverendtrip = (
  state: DriverEndTrip = initialState,
  action: IActionBase
) => {
  switch (action.type) {
    case actionTypes.SEND_DRIVER_END_TRIP:
      return sendDriverEndTrip(state, action);
    case actionTypes.SEND_DRIVER_ENDTRIP_SUCCESS:
      return sendDriverEndTripSuccess(state, action);
    case actionTypes.FETCH_DRIVER_ENDTRIP_FAIL:
      return sendDriverEndTripFail(state, action);
    case actionTypes.CLEAR_MSG_DRIVERENDTRIP:
      return clearMsgDriverEndTrip(state, action);
    default:
      return state;
  }
};
export default driverendtrip;
