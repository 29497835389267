import { IActionBase, ITrips } from "../../models/root.interface";
import * as actionTypes from "../../actions/actionTypes";

import { updateObject } from "../../utility";

const initialState: ITrips = {
  trips: [],
  rejectedTrips: [],
  rejectedTripsLoading: null,
  rejectedTripsCount: 0,
  trakedTrips: [],
  trakedTripLoading: null,
  count: 0,
  loading: null,
  trip: null,
  updateLoading: null,
  loadingTrip: null,
  loadingUnAssignTrip: null,
  loadingSuspendTrip: null,
  error: null,
  msg: null,
};

const fetchTripsStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, loading: true });
};

const fetchTripsSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    trips: action.trips,
    count: action.count,
    loading: false,
    error: null,
  });
};

const fetchTripsFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const fetchTripStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, loadingTrip: true });
};

const fetchTripSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    trip: action.trip,
    loadingTrip: false,
    error: null,
  });
};

const fetchTripFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingTrip: false,
  });
};

// for confirm trip
const confirmTripStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, loadingTrip: true });
};

const confirmTripSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    loadingTrip: false,
    error: null,
    msg: action.msg,
  });
};

const confirmTripFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingTrip: false,
  });
};

// for fastassign trip
const fastAssTripStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, loading: true });
};

const fastAssTripSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  let newArr = state.trips.filter((tr: any) => tr.id !== action.tripId);
  return updateObject(state, {
    trips: newArr,
    loading: false,
    error: null,
    msg: action.msg,
  });
};

const fastAssTripFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

// for rejectreq trip
const rejectReqTripStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, loading: true });
};

const rejectReqTripSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  let newArr = state.trips.filter((tr: any) => tr.id !== action.tripId);
  return updateObject(state, {
    // trips: newArr,
    loading: false,
    error: null,
    // msg: action.msg,
  });
};

const rejectReqTripFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

// fetch rejected
const fetchRejectedTripsStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, rejectedTripsLoading: true });
};

const fetchRejectedTripsSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    rejectedTrips: action.trips,
    rejectedTripsCount: action.count,
    rejectedTripsLoading: false,
    error: null,
  });
};

const fetchRejectedTripsFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    rejectedTripsLoading: false,
  });
};

const clearMsgReqtrips = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: null,
    updateLoading: null,
    msg: null,
  });
};

const requestedtrips = (state: ITrips = initialState, action: IActionBase) => {
  switch (action.type) {
    case actionTypes.FETCH_REQTRIPS_START:
      return fetchTripsStart(state, action);
    case actionTypes.FETCH_REQTRIPS_SUCCESS:
      return fetchTripsSuccess(state, action);
    case actionTypes.FETCH_REQTRIPS_FAIL:
      return fetchTripsFail(state, action);

    case actionTypes.FETCH_REQUESTEDTRIP_START:
      return fetchTripStart(state, action);
    case actionTypes.FETCH_REQUESTEDTRIP_SUCCESS:
      return fetchTripSuccess(state, action);
    case actionTypes.FETCH_REQUESTEDTRIP_FAIL:
      return fetchTripFail(state, action);

    case actionTypes.CONFIRM_TRIP_START:
      return confirmTripStart(state, action);
    case actionTypes.CONFIRM_TRIP_SUCCESS:
      return confirmTripSuccess(state, action);
    case actionTypes.CONFIRM_TRIP_FAIL:
      return confirmTripFail(state, action);

    case actionTypes.FAST_ASSTRIP_START:
      return fastAssTripStart(state, action);
    case actionTypes.FAST_ASSTRIP_SUCCESS:
      return fastAssTripSuccess(state, action);
    case actionTypes.FAST_ASSTRIP_FAIL:
      return fastAssTripFail(state, action);

    case actionTypes.REJECT_REQ_TRIP_START:
      return rejectReqTripStart(state, action);
    case actionTypes.REJECT_REQ_TRIP_SUCCESS:
      return rejectReqTripSuccess(state, action);
    case actionTypes.REJECT_REQ_TRIP_FAIL:
      return rejectReqTripFail(state, action);

    case actionTypes.FETCH_REJECTED_REQ_TRIPS_START:
      return fetchRejectedTripsStart(state, action);
    case actionTypes.FETCH_REJECTED_REQ_TRIPS_SUCCESS:
      return fetchRejectedTripsSuccess(state, action);
    case actionTypes.FETCH_REJECTED_REQ_TRIPS_FAIL:
      return fetchRejectedTripsFail(state, action);

    case actionTypes.CLEAR_MSG_REQTRIPS:
      return clearMsgReqtrips(state, action);

    default:
      return state;
  }
};
export default requestedtrips;
