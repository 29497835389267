import React, { Fragment, useEffect, Dispatch, memo } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../store/actions/index.actions';
import { IStateType } from '../../../store/models/root.interface';

import MapForTrackStudent from '../../Maps/MapForTrackStudent';
import { Translate } from '../../../utils/Translate';

const TrackStudent: React.FC = memo((props: any) => {
  let trip_id = '0';
  trip_id = JSON.parse(
    new URLSearchParams(props.location.search).get('id') || '0'
  );
  const t = new Translate()
  const dispatch: Dispatch<any> = useDispatch();
  const trackedData = useSelector(
    (state: IStateType) => state.trackTrip.trackedData
  );

  const loadingTrack = useSelector(
    (state: IStateType) => state.trackTrip.loadingTrack
  );

  useEffect(() => {
    dispatch(actions.trackTrip(trip_id));
    console.log(trip_id);
  }, []);

  const refreshMapHandler = () => {
    dispatch(actions.trackTrip(trip_id));
  };

  return (
    <Fragment>
      <Link to='/' className='arrow-back'>
        <i className='fas fa-arrow-left'></i>
      </Link>

      <div className='row mt-2 form-group justify-content-center text-center'>
        <label className='text-white font-weight-bold text-center'>
          {t.translate("Trip Stops")}
        </label>
        <div className='col-md-12'>
          <MapForTrackStudent
            loading={loadingTrack === false}
            coordinates={{
              lat: loadingTrack === false ? +trackedData.lat : 29.8352334,
              lng: loadingTrack === false ? +trackedData.lng : 31.38,
            }}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg&v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBaD17OgMbI_0stUcRlvaeIvMuTet_rjdk`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `500px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
        <div className='col-md-12 row justify-content-center mt-3'>
          <button
            onClick={refreshMapHandler}
            className='btn btn-warning col-sm-3'
          >
            {t.translate("Get live location")}
          </button>
        </div>
      </div>
    </Fragment>
  );
});

export default withRouter(TrackStudent);
