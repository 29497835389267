import { IActionBase, AddTrip } from '../../models/root.interface';
import * as actionTypes from '../../actions/actionTypes';

import { updateObject } from '../../utility';

const initialState: AddTrip = {
  drivers: [],
  buses: [],
  requestedTrips: [],
  employees: [],
  loadingDriver: null,
  loadingBus: null,
  loadingReqTrips: null,
  loadingEmployees: null,
  loadingAddTrip: null,
  error: null,
  msg: null,
  empcount: 0,
  reqcount: 0,
  trip:null
};

const fetchDriverStart = (state: AddTrip, action: IActionBase) => {
  return updateObject(state, { error: null, loadingDriver: true });
};

const fetchDriverSuccess = (
  state: AddTrip = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    drivers: action.drivers,
    loadingDriver: false,
    error: null,
  });
};

const fetchDriverFail = (state: AddTrip, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingDriver: false,
  });
};
const fetchBusesStart = (state: AddTrip, action: IActionBase) => {
  return updateObject(state, { error: null, loadingBus: true });
};

const fetchBusesSuccess = (
  state: AddTrip = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    buses: action.buses,
    loadingBus: false,
    error: null,
  });
};

const fetchBusesFail = (state: AddTrip, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingBus: false,
  });
};

// for fetch req trips
const fetchReqTripsStart = (state: AddTrip, action: IActionBase) => {
  return updateObject(state, { error: null, loadingReqTrips: true });
};

const fetchReqTripsSuccess = (
  state: AddTrip = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    requestedTrips: action.requestedTrips,
    reqcount: action.reqcount,
    loadingReqTrips: false,
    loadingAddTrip: false,
    error: null,
  });
};

const fetchReqTripsFail = (state: AddTrip, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingReqTrips: false,
  });
};
// for fetch req trips
const fetchEmployeesStart = (state: AddTrip, action: IActionBase) => {
  return updateObject(state, { error: null, loadingEmployees: true });
};

const fetchEmployeesSuccess = (
  state: AddTrip = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    employees: action.employees,
    empcount: action.count,
    loadingEmployees: false,
    error: null,
  });
};

const fetchEmployeesFail = (state: AddTrip, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingEmployees: false,
  });
};

// for add trip
const addTripStart = (state: AddTrip, action: IActionBase) => {
  return updateObject(state, { error: null, loadingAddTrip: true });
};

const addTripSuccess = (state: AddTrip = initialState, action: IActionBase) => {
  return updateObject(state, {
    loadingAddTrip: false,
    error: null,
    msg: action.msg,
    trip:null
  });
};

const addTripFail = (state: AddTrip, action: IActionBase) => {
  
  return updateObject(state, {
    error: action.error,
    loadingAddTrip: false,
    trip:action.trip
  });
};

const clearMsgAddTrip = (state: AddTrip, action: IActionBase) => {
  return updateObject(state, {
    error: null,
    loadingAddTrip: false,
    msg: null,
  });
};

const managetrips = (state: AddTrip = initialState, action: IActionBase) => {
  switch (action.type) {
    case actionTypes.FETCH_DRIVER_START:
      return fetchDriverStart(state, action);
    case actionTypes.FETCH_DRIVER_SUCCESS:
      return fetchDriverSuccess(state, action);
    case actionTypes.FETCH_DRIVER_FAIL:
      return fetchDriverFail(state, action);

    case actionTypes.FETCH_BUS_START:
      return fetchBusesStart(state, action);
    case actionTypes.FETCH_BUS_SUCCESS:
      return fetchBusesSuccess(state, action);
    case actionTypes.FETCH_BUS_FAIL:
      return fetchBusesFail(state, action);

    case actionTypes.FETCH_REQ_TRIPS_START:
      return fetchReqTripsStart(state, action);
    case actionTypes.FETCH_REQ_TRIPS_SUCCESS:
      return fetchReqTripsSuccess(state, action);
    case actionTypes.FETCH_REQ_TRIPS_FAIL:
      return fetchReqTripsFail(state, action);

    case actionTypes.FETCH_EMPLOYEES_START:
      return fetchEmployeesStart(state, action);
    case actionTypes.FETCH_EMPLOYEES_SUCCESS:
      return fetchEmployeesSuccess(state, action);
    case actionTypes.FETCH_EMPLOYEES_FAIL:
      return fetchEmployeesFail(state, action);

    case actionTypes.ADD_TRIP_START:
      return addTripStart(state, action);
    case actionTypes.ADD_TRIP_SUCCESS:
      return addTripSuccess(state, action);
    case actionTypes.ADD_TRIP_FAIL:
      return addTripFail(state, action);
    case actionTypes.CLEAR_MSG_ADDTRIP:
      return clearMsgAddTrip(state, action);

    default:
      return state;
  }
};
export default managetrips;
