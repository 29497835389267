import { IActionBase } from '../models/root.interface';
import * as actionTypes from '../actions/actionTypes';

import { updateObject } from '../utility';

const initialState: any = {
  trackedData: null,
  loadingTrack: null,
  error: null,
};

const trackTripStart = (state: any, action: IActionBase) => {
  return updateObject(state, { error: null, loadingTrack: true });
};

const trackTripSuccess = (state: any = initialState, action: IActionBase) => {
  return updateObject(state, {
    trackedData: action.data,
    loadingTrack: false,
    error: null,
  });
};

const trackTripFail = (state: any, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingTrack: false,
  });
};

const trackTrip = (state: any = initialState, action: IActionBase) => {
  switch (action.type) {
    case actionTypes.TRACK_STUDENTS_START:
      return trackTripStart(state, action);
    case actionTypes.TRACK_STUDENTS_SUCCESS:
      return trackTripSuccess(state, action);
    case actionTypes.TRACK_STUDENTS_FAIL:
      return trackTripFail(state, action);

    default:
      return state;
  }
};
export default trackTrip;
