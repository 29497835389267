import React, { Fragment, useState, useEffect, Dispatch, memo } from "react";
// import { useEffect, Dispatch } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
// import trips_tracker_sample from '../../../assets/trips_tracker_sample.png';
import { IStateType } from "../../../store/models/root.interface";
import * as actions from "../../../store/actions/index.actions";
import MapForAdminSortTrip from "../../Maps/MapForAdminSortTrip";
// import MapForAdminSortTrip from '../../Maps/MapForAdminSortTrip';
import Loader from "react-loader-spinner";
import SweetAlert from "react-bootstrap-sweetalert";
import EditTripForm from "./edittrip/form";
// import Pagination from 'react-js-pagination';
// import classes from '../../Pagination.module.css';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Translate } from "../../../utils/Translate";
const EditTrip: React.FC = memo((props: any) => {
  let t = new Translate();
  let id = "0";
  id = JSON.parse(new URLSearchParams(props.location.search).get("id") || "0");
  const dispatch: Dispatch<any> = useDispatch();

  const loading = false; //useSelector((state: IStateType) => state.managetrips.loading);

  const trip: any = useSelector((state: IStateType) => state.managetrips.trip);
  const trips = useSelector((state: IStateType) => state.managetrips.trips);

  useEffect(() => {
    // dispatch(
    //   actions.fetchAllRequestedTrips(activePage, pageSize, enteredFilter)
    // );
    dispatch(actions.fetchTrips(1, 1000));
  }, [dispatch]);
  const loadingTrip = useSelector(
    (state: IStateType) => state.managetrips.loadingTrip
  );

  /* const drivers = useSelector((state: IStateType) => state.addTrip.drivers);
  const loadingDriver = useSelector(
    (state: IStateType) => state.addTrip.loadingDriver
  );
  const buses = useSelector((state: IStateType) => state.addTrip.buses);
  const loadingBus = useSelector(
    (state: IStateType) => state.addTrip.loadingBus
  ); */
  //for student
  const requestedTrips = useSelector(
    (state: IStateType) => state.addTrip.requestedTrips
  );
  const [arrReqTrips, setArrRqTrips] = useState<any[]>([...requestedTrips]);
  const [assignReqArr, setAssignReqArr] = useState<any[]>([]);
  let [newArrPostions, setNewArrPostions] = useState<any>([]);
  let [MapDirection, SetMapDirection] = useState<any>("toschool");

  const updateLoading = useSelector(
    (state: IStateType) => state.managetrips.updateLoading
  );
  const [selectedTripId, updatePendingSelectedTripId] = useState<string>("");

  const removeAssignReqHandler = (data: any) => {
    // e.target.disabled = true;
    // document.querySelector('button')
    setAssignReqArr((prevState) => prevState.filter((st) => st.id !== data.id));
    setArrRqTrips((prevState) => [...prevState, data]);
  };

  // for employee
  // const employees = useSelector((state: IStateType) => state.addTrip.employees);
  // const loadingEmployees = useSelector(
  //   (state: IStateType) => state.addTrip.loadingEmployees
  // );
  // const [arrEmpTrips, setArrEmpTrips] = useState<any[]>([...employees]);
  const [assignEmpTrips, setAssignEmpTrips] = useState<any[]>([]);

  // const addAssignEmpReqHandler = (data: any) => {
  //   // e.target.disabled = true;
  //   // document.querySelector('button')
  //   // setArrEmpTrips((prevState) => prevState.filter((st) => st.id !== data.id));
  //   setAssignEmpTrips((prevState) => [...prevState, data]);
  // };

  const removeAssignEmpReqHandler = (data: any) => {
    // e.target.disabled = true;
    // document.querySelector('button')
    setAssignEmpTrips((prevState) =>
      prevState.filter((st) => st.id !== data.id)
    );
    // setArrEmpTrips((prevState) => [...prevState, data]);
  };

  const [assignedtrips, updateAssignedtrips] = useState<any[]>([]);
  const [employeetrips, updateEmployeetrips] = useState<any[]>([]);

  useEffect(() => {
    dispatch(actions.fetchTrip(id));
  }, []);

  useEffect(() => {
    if (loading === false && loadingTrip === false) {
      updateAssignedtrips(trip.assignedtrips);

      updateEmployeetrips(trip.employeetrips);
      let assignedtripsLatLng = null;
      if (loadingTrip === false && loading === false) {
        assignedtripsLatLng = trip.assignedtrips.map((tsp: any) => {
          if (
            (MapDirection == "toschool" && tsp.toschool === "1") ||
            (MapDirection == "tohome" && tsp.tohome === "1")
          )
            return {
              coords: { lat: +tsp.location_lat, lng: +tsp.location_lng },
              student_id: tsp.student_id,
              name: tsp.name,
              postion:
                MapDirection == "toschool" ? tsp.postion : tsp.tohomeposition,
            };
        });
        console.log(trip.assignedtrips, assignedtripsLatLng);
        setNewArrPostions(
          assignedtripsLatLng.filter((x: any) => x !== undefined)
        );
      }
      setTimeout(() => {
        $("#datatable").DataTable();
      }, 1500);
    }
  }, [loading, loadingTrip]);

  useEffect(() => {
    let assignedtripsLatLng = null;
    if (loadingTrip === false && loading === false) {
      assignedtripsLatLng = trip.assignedtrips.map((tsp: any) => {
        if (
          (MapDirection == "toschool" && tsp.toschool === "1") ||
          (MapDirection == "tohome" && tsp.tohome === "1")
        )
          return {
            coords: { lat: +tsp.location_lat, lng: +tsp.location_lng },
            student_id: tsp.student_id,
            name: tsp.name,
            postion:
              MapDirection == "toschool" ? tsp.postion : tsp.tohomeposition,
          };
      });
      let temp = assignedtripsLatLng.filter((x: any) => x !== undefined);
      console.log(temp);
      setNewArrPostions(temp);
    }
  }, [MapDirection]);
  // here return arr of object by {lat and lng}

  let [newEmpArrPostions, setNewEmpArrPostions] = useState<any>([]);
  let assignedEmptripsLatLng = null;
  if (loadingTrip === false) {
    assignedEmptripsLatLng = trip.employeetrips.map((tsp: any) => {
      return {
        coords: { lat: +tsp.location_lat, lng: +tsp.location_lng },
        employee_id: tsp.employee_id,
        name: tsp.name,
        postion: tsp.postion,
      };
    });
    newEmpArrPostions = assignedEmptripsLatLng;
  }

  const onCancel = () => {
    dispatch(actions.clearMsgAddTrip());
    dispatch(actions.clearMsgAssginTrip());
  };
  const onSuccessCancel = (x: any = null) => {
    dispatch(actions.clearMsgUpdateTrip());
    dispatch(actions.clearMsgAssginTrip());
  };

  const onDeleteConfirem = () => {
    dispatch(actions.clearMsgUpdateTrip());
    dispatch(actions.fetchTrip(id));
  };
  const onSavePointsMsg = () => {
    dispatch(actions.clearMsgUpdateTrip());
  };

  let newPoints: any = [];
  let newEmpPoints: any = [];
  const submitSavePoints = (data: any) => {
    newPoints = data;
  };

  function editassigned(tripid: string) {
    props.history.push("/EditAssignedStudent?id=" + tripid);
  }

  function fastUpdate(e: any, reqtripid: string) {
    e.preventDefault();

    const data = {
      assignedtrip_id: reqtripid,

      trip_id: selectedTripId,
    };
    dispatch(actions.fastUpdateTrip(data));
    // dispatch(actions.fetchAllRequestedTrips(activePage, pageSize));
  }

  const msg = useSelector((state: IStateType) => state.assignedtrips.msg);
  const loadingSuspendTrip = useSelector(
    (state: IStateType) => state.assignedtrips.loadingSuspendTrip
  );
  let forSuccess = null;
  if (msg) {
    forSuccess = (
      <SweetAlert
        success
        title="Success"
        onConfirm={onSuccessCancel}
        onCancel={onSuccessCancel}
      >
        {msg}
      </SweetAlert>
    );
  }
  const suspendHandler = (id: any) => {
    dispatch(actions.suspendTrip(id));
  };
  const unSuspendHandler = (id: any) => {
    dispatch(actions.unSuspendTrip(id));
  };
  const deleteAssignTripHandler = (id: any) => {
    // console.log(id);
    dispatch(actions.deleteAssignTrip(id));
  };
  const submitEmployeeSavePoints = (data: any) => {
    newEmpPoints = data;
  };
  const onClickSubmitSavePoints = () => {
    let data = {
      trip_id: id,
      postions: [...newPoints],
      emppositions: [...newEmpPoints],
      direction: MapDirection,
    };
    console.log(data);
    if (newPoints.length !== 0 || newEmpPoints.length !== 0) {
      dispatch(actions.setPoints(data));
    }
  };

  return (
    <Fragment>
      {forSuccess}
      <Link
        to="/TripsTable"
        className="btn btn-danger text-white font-weight-bold"
      >
        {t.translate("Back")}
      </Link>
      {loading === false && updateLoading === false && !loadingSuspendTrip ? (
        <div>
          <EditTripForm
            mytripId={id}
            mytripLoading={loadingTrip}
            gotoH={onSuccessCancel}
          />
          <div className="row mt-2 form-group justify-content-center text-center">
            <div className="col-12 my-4"></div>
            <div className="col-12 my-2">
              <button
                className="btn btn-warning m-3"
                onClick={() => {
                  SetMapDirection("toschool");
                }}
              >
                {" "}
                {t.translate("Set Stop Points Order To School!")}{" "}
              </button>
              <button
                className="btn btn-warning m-3"
                onClick={() => {
                  SetMapDirection("tohome");
                }}
              >
                {" "}
                {t.translate("SET Stop Points Order To Home!")}{" "}
              </button>
            </div>
            <label className="text-white font-weight-bold text-center">
              {MapDirection === "toschool"
                ? t.translate(`Trip Stops Points,
                please sort them to School`)
                : t.translate(`Trip Stops Points,
                please sort them to Home`)}
            </label>
            <div className="col-md-12">
              {/* <img alt='' src={trips_tracker_sample} /> */}
              {loadingTrip === false ? (
                <MapForAdminSortTrip
                  arrMarkers={newArrPostions}
                  emparrMarkers={newEmpArrPostions}
                  getPoints={submitSavePoints}
                  getEmpPoints={submitEmployeeSavePoints}
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg&v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBaD17OgMbI_0stUcRlvaeIvMuTet_rjdk`}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `500px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                />
              ) : null}
            </div>
            <div className="btn btn-warning" onClick={onClickSubmitSavePoints}>
              {t.translate("Save Points")}
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-12">
              <label className="text-white font-weight-bold">
                {t.translate("Assigned Students")}
              </label>
              <div className="table-responsive bg-light">
                <table
                  id="datatable"
                  className="table table-striped table-bordered text-center"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th scope="col">IvyID</th>
                      <th scope="col">{t.translate("Student Name")}</th>
                      <th scope="col">{t.translate("to School?")}</th>
                      <th scope="col">{t.translate("to home?")}</th>
                      <th scope="col">{t.translate("Is suspended?")}</th>
                      <th scope="col"></th>
                      {/* <th scope="col"></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {assignedtrips.map((assigntrip: any) => {
                      return (
                        <tr key={assigntrip.id}>
                          <td>{assigntrip.student_id}</td>
                          <td>{assigntrip.name}</td>
                          <td>{assigntrip.toschool == 1 ? "Yes" : "No"}</td>
                          <td>{assigntrip.tohome == 1 ? "Yes" : "No"}</td>
                          <td>
                            {assigntrip.status === "suspend" ? "Yes" : "No"}
                          </td>

                          <td>
                            <button
                              className="btn btn-success text-white font-weight-bold m-1"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={"#h" + assigntrip.id}
                              aria-expanded="false"
                              aria-controls="selectTrip"
                            >
                              <i className="fas fa-check"></i>
                            </button>
                            <button
                              onClick={() => suspendHandler(assigntrip.id)}
                              disabled={
                                assigntrip.status === "suspend" ? true : false
                              }
                              className="btn btn-success text-white font-weight-bold m-1"
                            >
                              {t.translate("Suspend")}
                            </button>
                            <button
                              onClick={() => unSuspendHandler(assigntrip.id)}
                              disabled={
                                assigntrip.status === "unsuspend" ? true : false
                              }
                              className="btn btn-success text-white font-weight-bold m-1"
                            >
                              {t.translate("Un-suspend")}
                            </button>

                            <button
                              onClick={() =>
                                deleteAssignTripHandler(assigntrip.id)
                              }
                              className="btn btn-danger text-white font-weight-bold m-1"
                            >
                              {t.translate("Trash")}
                            </button>

                            <div
                              className="align-items-center rounded"
                              style={{
                                backgroundColor: "rgb(44, 55, 94)",
                                color: "white",
                              }}
                            >
                              <div
                                className="collapse"
                                id={"h" + assigntrip.id}
                              >
                                <div className="form-group">
                                  <label className="text-white font-weight-bold text-center">
                                    {t.translate("Trip")}
                                  </label>
                                  <form
                                    onSubmit={(e) =>
                                      fastUpdate(e, assigntrip.id)
                                    }
                                  >
                                    <select
                                      className="form-control text-center"
                                      aria-label="Default select example"
                                      required
                                      onChange={(e) =>
                                        updatePendingSelectedTripId(
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">
                                        {t.translate("Choose Trip")}
                                      </option>
                                      {trips.map((trip: any) => {
                                        return (
                                          <option key={trip.id} value={trip.id}>
                                            {trip.tripname} - {trip.direction}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <button
                                      // onClick={() => assigntrip(reqtrip.id)}
                                      className="btn btn-success text-white font-weight-bold m-1"
                                    >
                                      {t.translate("Assign")}
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </td>
                          {/* <td>
                                <button
                                  // to='/adminstration/EditTrip'
                                  onClick={(e) =>
                                    unAssignedTrip(
                                      e,
                                      assigntrip.student_id,
                                      assigntrip.id
                                    )
                                  }
                                  type="button"
                                  className="btn btn-danger text-white font-weight-bold m-1"
                                >
                                  <i className="fas fa-times"></i>
                                </button>
                              </td> */}
                        </tr>
                      );
                    })}

                    {assignReqArr.map((arr) => (
                      <tr key={arr.id}>
                        <td className="w-10">{arr.student_id}</td>
                        <td className="w-20">{arr.name}</td>
                        {/* <td className="w-10 ">
                          <button
                            type="button"
                            onClick={(e) => removeAssignReqHandler(arr)}
                          >
                            <i className="fas fa-minus"></i>
                          </button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-12">
              <label className="text-white font-weight-bold">
                {t.translate("Assigned Employees")}
              </label>
              <div className="table-responsive bg-light">
                <table
                  className="table-hover table-bordered text-center"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th scope="col">IvyID</th>
                      <th scope="col">{t.translate("Employee Name")}</th>
                      <th scope="col">{t.translate("to School?")}</th>
                      <th scope="col">{t.translate("to home?")}</th>
                      <th scope="col">{t.translate("Is suspended?")}</th>
                      {/* <th scope="col"></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {employeetrips.map((emptrip: any) => {
                      return (
                        <tr key={emptrip.id}>
                          <td>{emptrip.employee_id}</td>
                          <td>{emptrip.name}</td>
                          <td>{emptrip.toschool == 1 ? "Yes" : "No"}</td>
                          <td>{emptrip.tohome == 1 ? "Yes" : "No"}</td>
                          <td>{emptrip.suspended == 1 ? "Yes" : "No"}</td>
                          {/* <td>
                            <Link
                              to="/adminstration/EditTrip"
                              className="btn btn-danger text-white font-weight-bold m-1"
                            >
                              <i className="fas fa-times"></i>
                            </Link>
                          </td> */}
                        </tr>
                      );
                    })}

                    {assignEmpTrips.map((arr) => (
                      <tr key={arr.id}>
                        <td className="w-10">{arr.id}</td>
                        <td className="w-20">{arr.fullname}</td>
                        {/* <td className="w-10 ">
                          <div onClick={(e) => removeAssignEmpReqHandler(arr)}>
                            <i className="fas fa-minus"></i>
                          </div>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
        </div>
      )}
    </Fragment>
  );
});
export default withRouter(EditTrip);
