import React, { useEffect, useState } from "react";
// import ivy from '../../assets/ivy_logo_min.png';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  Polyline,
} from "react-google-maps";
function OpenInfo(routes: any, id: any) {
  let index = routes?.findIndex((item: any) => item.id === id);
  routes[index].open = routes[index].open === false ? true : false;
  return [...routes];
}


const Map =(props:any)=>{

  const [state , setState] = useState({
    isOpen: false,
    isLastPointOpen: false,
    directions: undefined,
    routes: [],
  })
  useEffect(()=>{
    fetch(
      `https://roads.googleapis.com/v1/snapToRoads?path=${props.alldriver_path
        ?.map((r: any) => r.lat + "," + r.lng)
        ?.join(
          "|"
        )}&interpolate=true&key=AIzaSyBaD17OgMbI_0stUcRlvaeIvMuTet_rjdk`
    )
      .then((response) => {
        response.json().then((data) => {
          setState((prevstate: any) => ({
            ...prevstate,
              directions: data.snappedPoints?.map((m: any) => {
              return { lat: m.location.latitude, lng: m.location.longitude };
            }),
          }))
        
        });
      })
      .catch((error) => {
        console.log(error);
      });
    

    setState((prevState:any) =>({
      ...prevState,
      routes: props.driverPath
    }))
  },[props.updateMap]);

      let [Last_driver_location] =
      props.alldriver_path?.length > 0
        ? props.alldriver_path?.slice(-1)
        : [];
  return(
    <div>
            <div className="my-4">
       <GoogleMap
          defaultZoom={16}
          defaultCenter={
          
            Last_driver_location ? Last_driver_location : { lat: 29.98, lng: 31.32 }
          }
          center={
            Last_driver_location ? Last_driver_location : { lat: 29.98, lng: 31.32 }
          }
        >
          {Last_driver_location && (
            <Marker
              // marker shaped as car for the last driver location point only
              key={Math.random() * 1.2}
              position={Last_driver_location}
              onClick={() => setState((prevState:any)=> ({ ...prevState , isLastPointOpen: !state.isLastPointOpen }))}
            

              icon={{
                url: "https://img.icons8.com/material-outlined/2x/gps-device.png",
                scaledSize: new window.google.maps.Size(60, 32),
              }}
            >
              {state.isLastPointOpen ? (
                <InfoWindow options={{ disableAutoPan: true }}>
                  {/* <span>{mark.stdPostion}</span> */}
                <>
                  <div>
                    {" "}
                    <p>{Last_driver_location?.time?.split(" ")[1]}</p>
                    Last dirver location
                  </div>
                </>
                </InfoWindow>
              ) : null}
            </Marker>
          )}
          <Marker
            // marker for the first driver location point only
            key={Math.random() * 1.2}
            position={props?.alldriver_path[0]}
            onClick={() => setState((prevState:any)=>({ ...prevState , isOpen: !state.isOpen }))}
            icon={
              "https://mt.google.com/vt/icon/name=icons/spotlight/directions_destination_measle_drag.png&scale=3"
            }
          >
            {state.isOpen ? (
              <InfoWindow options={{ disableAutoPan: true }}>
                {/* <span>{mark.stdPostion}</span> */}
                <>
                  <div>
                    {" "}
                    <p>{props?.alldriver_path[0].time?.split(" ")[1]}</p>
                    First dirver location
                  </div>
                </>
              </InfoWindow>
            ) : null}
          </Marker>

          {props.driver_path?.length > 0 &&
            props.driver_path?.slice(1, -1)?.map((loc_mark: any) => (
              <Marker
                key={Math.random() * 1.2}
                position={loc_mark}
                onClick={() =>
                  setState((prevState:any)=>({
                    ...prevState,
                    routes: OpenInfo(
                      props.driver_path?.slice(1, -1),
                      loc_mark.id
                    ),
                  }))
                }
                icon={{
                  url: "https://img.icons8.com/carbon-copy/2x/google-maps-new.png",
                  scaledSize: new window.google.maps.Size(60, 32),
                }}

                // icon={{ url: ivy, scaledSize: { width: 30, height: 30 } }}
              >
                {loc_mark.open === true ? (
                  <InfoWindow options={{ disableAutoPan: true }}>
                    {/* <span>{mark.stdPostion}</span> */}
                    <>
                      <div>
                        {" "}
                        <p>{"Time: " + loc_mark?.time?.split(" ")[1]}</p>
                        <p>{"Student Id : " + loc_mark?.student_id}</p>
                        <p>{"Full Name: " + loc_mark?.fullname}</p>
                      </div>
                    </>
                  </InfoWindow>
                ) : null}
              </Marker>
            ))}
          {state.directions !== undefined && (
            <Polyline path={state.directions} />
          )}
        </GoogleMap>
      </div>

    </div>
  )
}
// class Map extends React.Component<any, any> {
//   state = {
//     isOpen: false,
//     isLastPointOpen: false,
//     directions: undefined,
//     routes: [],
//   };

//   componentDidMount() {
//     fetch(
//       `https://roads.googleapis.com/v1/snapToRoads?path=${this.props.alldriver_path
//         ?.map((r: any) => r.lat + "," + r.lng)
//         ?.join(
//           "|"
//         )}&interpolate=true&key=AIzaSyBaD17OgMbI_0stUcRlvaeIvMuTet_rjdk`
//     )
//       .then((response) => {
//         response.json().then((data) => {
//           this.setState({
//             directions: data.snappedPoints?.map((m: any) => {
//               return { lat: m.location.latitude, lng: m.location.longitude };
//             }),
//           });
//         });
//       })
//       .catch((error) => {
//         console.log(error);
//       });

//     this.setState({
//       routes: this.props.driverPath,
//     });
//   }

//   render() {
//     let [Last_driver_location] =
//       this.props.alldriver_path?.length > 0
//         ? this.props.alldriver_path?.slice(-1)
//         : [];

//     console.log(this.props.alldriver_path);
//     return (
//       <div className="my-4">
//         <GoogleMap
//           defaultZoom={16}
//           defaultCenter={
          
//             Last_driver_location ? Last_driver_location : { lat: 29.98, lng: 31.32 }
//           }
//           center={
//             Last_driver_location ? Last_driver_location : { lat: 29.98, lng: 31.32 }
//           }
//         >
//           {Last_driver_location && (
//             <Marker
//               // marker shaped as car for the last driver location point only
//               key={Math.random() * 1.2}
//               position={Last_driver_location}
//               onClick={() => this.setState({ isLastPointOpen: !this.state.isLastPointOpen })}
            

//               icon={{
//                 url: "https://img.icons8.com/material-outlined/2x/gps-device.png",
//                 scaledSize: new window.google.maps.Size(60, 32),
//               }}
//             >
//               {this.state.isLastPointOpen ? (
//                 <InfoWindow options={{ disableAutoPan: true }}>
//                   {/* <span>{mark.stdPostion}</span> */}
//                 <>
//                   <div>
//                     {" "}
//                     <p>{Last_driver_location?.time?.split(" ")[1]}</p>
//                     Last dirver location
//                   </div>
//                 </>
//                 </InfoWindow>
//               ) : null}
//             </Marker>
//           )}
//           <Marker
//             // marker for the first driver location point only
//             key={Math.random() * 1.2}
//             position={this.props?.alldriver_path[0]}
//             onClick={() => this.setState({ isOpen: !this.state.isOpen })}
//             icon={
//               "https://mt.google.com/vt/icon/name=icons/spotlight/directions_destination_measle_drag.png&scale=3"
//             }
//           >
//             {this.state.isOpen ? (
//               <InfoWindow options={{ disableAutoPan: true }}>
//                 {/* <span>{mark.stdPostion}</span> */}
//                 <>
//                   <div>
//                     {" "}
//                     <p>{this.props?.alldriver_path[0].time?.split(" ")[1]}</p>
//                     First dirver location
//                   </div>
//                 </>
//               </InfoWindow>
//             ) : null}
//           </Marker>

//           {this.props.driver_path?.length > 0 &&
//             this.props.driver_path?.slice(1, -1)?.map((loc_mark: any) => (
//               <Marker
//                 key={Math.random() * 1.2}
//                 position={loc_mark}
//                 onClick={() =>
//                   this.setState({
//                     routes: OpenInfo(
//                       this.props.driver_path?.slice(1, -1),
//                       loc_mark.id
//                     ),
//                   })
//                 }
//                 icon={{
//                   url: "https://img.icons8.com/carbon-copy/2x/google-maps-new.png",
//                   scaledSize: new window.google.maps.Size(60, 32),
//                 }}

//                 // icon={{ url: ivy, scaledSize: { width: 30, height: 30 } }}
//               >
//                 {loc_mark.open === true ? (
//                   <InfoWindow options={{ disableAutoPan: true }}>
//                     {/* <span>{mark.stdPostion}</span> */}
//                     <>
//                       <div>
//                         {" "}
//                         <p>{"Time: " + loc_mark?.time?.split(" ")[1]}</p>
//                         <p>{"Student Id : " + loc_mark?.student_id}</p>
//                         <p>{"Full Name: " + loc_mark?.fullname}</p>
//                       </div>
//                     </>
//                   </InfoWindow>
//                 ) : null}
//               </Marker>
//             ))}
//           {this.state.directions !== undefined && (
//             <Polyline path={this.state.directions} />
//           )}
//         </GoogleMap>
//       </div>
//     );
//   }
// }

export default withScriptjs(withGoogleMap(Map));
