import { IActionBase, ITrips } from '../../models/root.interface';
import * as actionTypes from '../../actions/actionTypes';

import { updateObject } from '../../utility';

const initialState: any = {
  buses: null,
  loading: null,
  error: null,
  msg: null,
};

const fetchBusesStart = (state: any, action: IActionBase) => {
  return updateObject(state, { error: null, loading: true });
};

const fetchBusesSuccess = (state: any = initialState, action: IActionBase) => {
  return updateObject(state, {
    buses: action.buses,
    loading: false,
    error: null,
  });
};

const fetchBusesFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};
// CREATE BUS
const createBusStart = (state: any, action: IActionBase) => {
  return updateObject(state, { error: null, loading: true });
};

const createBusSuccess = (state: any = initialState, action: IActionBase) => {
  return updateObject(state, {
    buses: state.buses.concat(action.newBus),
    loading: false,
    error: null,
  });
};

const createBusFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};
// edit BUS
const editBusStart = (state: any, action: IActionBase) => {
  return updateObject(state, { error: null, loading: true });
};

const editBusSuccess = (state: any = initialState, action: IActionBase) => {
  let newArr = state.buses.map((bus: any) => {
    if (bus.id === action.newBus.id) {
      bus = action.newBus;
    }
    return bus;
  });

  return updateObject(state, {
    buses: newArr,
    loading: false,
    error: null,
  });
};

const editBusFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const clearMsgBus = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: null,
    loading: false,
    msg: null,
  });
};

const forBus = (state: ITrips = initialState, action: IActionBase) => {
  switch (action.type) {
    case actionTypes.FETCH_BUSES_START:
      return fetchBusesStart(state, action);
    case actionTypes.FETCH_BUSES_SUCCESS:
      return fetchBusesSuccess(state, action);
    case actionTypes.FETCH_BUSES_FAIL:
      return fetchBusesFail(state, action);

    case actionTypes.CREATE_BUS_START:
      return createBusStart(state, action);
    case actionTypes.CREATE_BUS_SUCCESS:
      return createBusSuccess(state, action);
    case actionTypes.CREATE_BUS_FAIL:
      return createBusFail(state, action);

    case actionTypes.EDIT_BUS_START:
      return editBusStart(state, action);
    case actionTypes.EDIT_BUS_SUCCESS:
      return editBusSuccess(state, action);
    case actionTypes.EDIT_BUS_FAIL:
      return editBusFail(state, action);

    case actionTypes.CLEAR_MSG_BUS:
      return clearMsgBus(state, action);

    default:
      return state;
  }
};
export default forBus;
