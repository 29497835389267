import React, { useEffect, Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import "./styles/sb-admin-2.min.css";
import { Switch, Redirect, Route, withRouter } from "react-router-dom";
import Login from "./components/Account/Login";
import Client from "./components/Client/Client";
import Driver from "./components/Driver/Driver";
import Admin from "./components/Admin/Admin";
import { PrivateRoute } from "./common/components/PrivateRoute";
import * as actions from "./store/actions/index.actions";
import { IStateType } from "./store/models/root.interface";
import setAuthToken from "./utils/setAuthToken";
import axios from "axios";
// axios.defaults.baseURL = 'https://myivyis.net/transportation-backend';
axios.defaults.baseURL = process.env.REACT_APP_BASEURL; //'https://transportation.ivyis.org/backend';
//axios.defaults.baseURL = 'http://192.168.1.100/backend';

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker
//     .register('./firebase-messaging-sw.js')
//     .then(function (registration) {
//       console.log('Registration successful, scope is:', registration.scope);
//     })
//     .catch(function (err) {
//       console.log('Service worker registration failed, error:', err);
//     });
// }

function parseJwt(token: any) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

const App: React.FC = (props: any) => {
  let hash: any = null;
  hash = props.location.search.split("hash=")[1];
  // console.log(hash);
  // console.log(props);
  if (hash) {
    const data = parseJwt(hash);
    const expDate: any = new Date(new Date(data.exp * 1000));
    localStorage.setItem("token", hash);
    localStorage.setItem("expDate", expDate);
    try {
      console.log(data);
      localStorage.setItem("gpsnumber", data.data.user.gpsnumber);
    } catch (err) {
      console.log(err);
    }
  }

  const dispatch: Dispatch<any> = useDispatch();
  const isAuthenticated: string | null = useSelector(
    (state: IStateType) => state.auth.role
  );
  const userId: string | null = useSelector(
    (state: IStateType) => state.auth.userId
  );
  const gpsNumber: string | null = useSelector(
    (state: IStateType) => state.auth.gpsnumber
  );
  if (gpsNumber) localStorage.setItem("gpsnumber", gpsNumber);

  useEffect(() => {
    dispatch(actions.authCheckState());
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
  }, [dispatch]);

  let verified = null;
  if (!localStorage.token) {
    verified = (
      <Switch>
        <Route path="/login" component={Login} />
        <Redirect to="/login" />
      </Switch>
    );
  }

  if (isAuthenticated === "parent") {
    verified = (
      <Switch>
        <PrivateRoute path="/">
          <Client />
        </PrivateRoute>
      </Switch>
    );
  }
  if (isAuthenticated === "driver") {
    verified = (
      <Switch>
        <PrivateRoute path="/">
          <Driver />
        </PrivateRoute>
      </Switch>
    );
  }
  if (isAuthenticated === "admin") {
    verified = (
      <Switch>
        <PrivateRoute path="/">
          <Admin />
        </PrivateRoute>
      </Switch>
    );
  }
  return (
    <div className="App " id="wrapper">
      {verified}
    </div>
  );
};

export default withRouter(App);
