import React, { Fragment, useEffect, useState } from 'react';
import { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import parent_select_location_sample from '../../../assets/parent_select_location_sample.png';
import { IStateType } from '../../../store/models/root.interface';
import * as actions from '../../../store/actions/index.actions';
import Map from '../../Maps/Map';
import Loader from 'react-loader-spinner';
import SweetAlert from 'react-bootstrap-sweetalert';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Translate } from '../../../utils/Translate';

const EditAssignedStudent: React.FC = (props: any) => {
  let id = '0';
  id = JSON.parse(new URLSearchParams(props.location.search).get('id') || '0');
  const dispatch: Dispatch<any> = useDispatch();
  const trip: any = useSelector(
    (state: IStateType) => state.assignedtrips.trip
  );
  const areas = useSelector((state: IStateType) => state.assigntrip.areas);
  const trips = useSelector((state: IStateType) => state.managetrips.trips);
  const t = new Translate()
  const loadingTrip = useSelector(
    (state: IStateType) => state.assignedtrips.loadingTrip
  );

  useEffect(() => {
    dispatch(actions.fetchArea());
    dispatch(actions.fetchTrips(1, 1000));

    dispatch(actions.fetchAssignedTrip(id));
  }, [dispatch]);

  const [selectedTripId, updateSelectedTripId] = useState<string>('');
  const [selectedStudentAreaId, updateStudentSelectedAreaId] = useState<string>('');
  const [selectedDirectionId, updateSelectedDirectionId] = useState<string>('');
  const [writtenAddress, updateWrittenAddress] = useState<string>('');
  const [handingInstructions, updateHandingInstructions] = useState<string>('');
  const [collectorName, updateCollectorName] = useState<string>('');
  const [collectorID, updateCollectorID] = useState<string>('');
  const [coordinates, setCoordinates] = useState<any>();

  const errorMsg = useSelector(
    (state: IStateType) => state.assignedtrips.error
  );
  const msg = useSelector((state: IStateType) => state.assignedtrips.msg);

  useEffect(() => {
    if (loadingTrip === false) {
      updateSelectedTripId(trip?.trip_id);
      updateStudentSelectedAreaId(trip.area_id);
      if (trip.toschool == 1 && trip.tohome == 1) {
        updateSelectedDirectionId("both");
      } else if (trip.toschool == 1) {
        updateSelectedDirectionId("to the school");

      } else {
        updateSelectedDirectionId("to home");

      }
      updateWrittenAddress(trip.address);
      updateHandingInstructions(trip.handing_instructions);
      updateCollectorID(trip.collector_id);
      updateCollectorName(trip.collector_name);
      setCoordinates({ lat: +trip.location_lat, lng: +trip.location_lng });
    }
  }, [loadingTrip]);

  const onConfirm = function (e: any) {
    e.preventDefault();
    const data = {
      assignedtrip_id: id,
      parent_id: trip.parent_id,
      student_id: trip.student_id,
      location_lat: coordinates.lat,
      location_lng: coordinates.lng,
      address: writtenAddress,
      area_id: selectedStudentAreaId,
      trip_id: selectedTripId,
      direction: selectedDirectionId,
      handing_instructions: handingInstructions,
      collector_name: collectorName,
      collector_id: collectorID,
    };
    dispatch(actions.updateAssTrip(data));
  };

  //   let coordinates = { lat: 30, lng: 30 };

  const getChangeLocation = (coords: any) => {
    // console.log(coords);
    setCoordinates(coords);
  };

  //   console.log(coordinates);

  const onCancel = () => {
    dispatch(actions.clearMsgAssginTrip());
  };

  const onSuccessCancel = () => {
    dispatch(actions.clearMsgAssginTrip());
    props.history.push('/AssignedStudents');
  };

  let forError = null;
  if (errorMsg) {
    forError = (
      <SweetAlert
        danger
        title='Warning'
        onConfirm={onCancel}
        onCancel={onCancel}
      >
        {errorMsg}
      </SweetAlert>
    );
  }
  let forSuccess = null;
  if (msg) {
    forSuccess = (
      <SweetAlert
        success
        title='Success'
        onConfirm={onSuccessCancel}
        onCancel={onSuccessCancel}
      >
        {msg}
      </SweetAlert>
    );
  }



  const [address, setAddress] = React.useState('');

  const handleSelect = async (value: any) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    // console.log(latLng);
    setCoordinates(latLng);
  };

  return (
    <Fragment>
      <Link
        to='/AssignedStudents'
        className='btn btn-danger text-white font-weight-bold'
      >
        {t.translate("Back")}
      </Link>

      {loadingTrip === false ? (
        <form onSubmit={(e) => onConfirm(e)}>
          {forError}
          {forSuccess}
          <div className='row'>
            <div className='col-md-12'>
              <div className='row'>
                <div className='col-md-3 form-group justify-content-center text-center'>
                  <label className='text-white font-weight-bold text-center'>
                    {t.translate("Student Name")}
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder={trip ? trip.name : ' '}
                    aria-label='Trip Name'
                    aria-describedby='Entering Trip Name'
                    disabled
                  />
                </div>
                <div className='col-md-3 form-group justify-content-center text-center'>
                  <label className='text-white font-weight-bold text-center'>
                    {t.translate("Ivy-ID")}
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder={trip ? trip.student_id : ' '}
                    aria-label='Trip Name'
                    aria-describedby='Entering Trip Name'
                    disabled
                  />
                </div>
                <div className='col-md-3 form-group justify-content-center text-center'>
                  <label className='text-white font-weight-bold text-center'>
                    {t.translate("Parent")}
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder={trip ? trip.displayname : ' '}
                    aria-label='Trip Name'
                    aria-describedby='Entering Trip Name'
                    disabled
                  />
                </div>
                <div className='col-md-3 form-group justify-content-center text-center'>
                  <label className='text-white font-weight-bold text-center'>
                    {t.translate("Parent Mobile")}
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder={trip ? trip.mobile : ' '}
                    aria-label='Trip Name'
                    aria-describedby='Entering Trip Name'
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className='d-flex mt-2 form-group justify-content-center text-center'>
              <div className='col-12'>
                <div className='col-12 row justify-content-center align-items-center'>
                  <div className="row justify-content-center align-items-center">
                    <label className="mr-1 col-auto">{t.translate("Search location")} : </label>
                    <div className='mt-3 col-12 col-sm-8'>
                      <PlacesAutocomplete
                        value={address}
                        onChange={setAddress}
                        onSelect={handleSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className='form-group'>
                            {/* <p>Latitude: {coordinates.lat}</p>
                            <p>Longitude: {coordinates.lng}</p> */}

                            <input
                              {...getInputProps({
                                placeholder: 'Type address',
                                className: 'form-control',
                              })}
                            />

                            <div>
                              {loading ? <div>{t.translate("...loading")}</div> : null}

                              {suggestions.map((suggestion, index) => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? '#41b6e6'
                                    : '#fff',
                                };
                                return (
                                  <React.Fragment key={index}>
                                    <div

                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                    >
                                      {suggestion.description}
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  </div>
                  <div className='col-md-12 align-items-center'>
                    <div className=''>
                      <Map
                        coordinates={coordinates}
                        getChangeLocation={getChangeLocation}
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg&v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBaD17OgMbI_0stUcRlvaeIvMuTet_rjdk`}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `300px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                      />
                    </div>
                  </div>
                  <div className='col-sm-12 align-items-center'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <label className='text-white font-weight-bold text-center'>
                          {t.translate("Direction")}
                        </label>
                        <select
                          className='form-control text-center'
                          aria-label='Direction'
                          onChange={(e) =>
                            updateSelectedDirectionId(e.target.value)
                          }
                          value={selectedDirectionId}
                          required
                        >
                          <option value=''>{t.translate("Choose Direction")}</option>
                          <option value='both'>{t.translate("both Ways")}</option>
                          <option value='to the school'>{t.translate("to the school")}</option>
                          <option value='to home'>{t.translate("to home")}</option>
                        </select>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='form-group text-center'>
                          <label className='text-white font-weight-bold'>
                            {t.translate("Written address")}
                          </label>
                          <textarea
                            className='form-control'
                            id='exampleFormControlTextaaddress1'
                            onChange={(e) =>
                              updateWrittenAddress(e.target.value)
                            }
                            value={writtenAddress}
                            required
                          >
                            {' '}
                          </textarea>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-6 form-group'>
                        <label className='text-white font-weight-bold text-center'>
                          {t.translate("Area")}
                        </label>
                        <select
                          className='form-control text-center'
                          aria-label='Area'
                          onChange={(e) =>
                            updateStudentSelectedAreaId(e.target.value)
                          }
                          value={selectedStudentAreaId}
                          required
                        >
                          <option value=''>{t.translate("Choose Area")}</option>
                          {loadingTrip === false
                            ? areas.map((area: any) => {
                              return (
                                <option key={area.id} value={area.id}>
                                  {area.areaname}
                                </option>
                              );
                            })
                            : null}
                        </select>
                      </div>
                      <div className='col-md-6 form-group'>
                        <label className='text-white font-weight-bold text-center'>
                          {t.translate("Trip")}
                        </label>
                        <select
                          className='form-control text-center'
                          aria-label='Trip'
                          onChange={(e) => updateSelectedTripId(e.target.value)}
                          value={selectedTripId}
                          required
                        >
                          <option value=''>{t.translate("Choose Trip")}</option>
                          {loadingTrip === false
                            ? trips.map((trip: any) => {
                              return (
                                <option key={trip.id} value={trip.id}>
                                  {trip.tripname} - {trip.direction}
                                </option>
                              );
                            })
                            : null}
                        </select>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='form-group justify-content-center text-center'>
                        <label className='text-white font-weight-bold'>
                          {t.translate("Handing instruction")}
                        </label>
                        <textarea
                          className='form-control'
                          id='exampleFormControlTextarea1'
                          onChange={(e) =>
                            updateHandingInstructions(e.target.value)
                          }
                          value={handingInstructions}
                          required
                        ></textarea>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-12'>
                        <label className='text-white font-weight-bold text-center'>
                          {t.translate("Collector Name")}
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          aria-label='Trip Name'
                          aria-describedby='Entering Trip Name'
                          onChange={(e) => updateCollectorName(e.target.value)}
                          value={collectorName}
                        />
                      </div>
                    </div>
                    <div className='row mt-1'>
                      <div className='col-md-12'>
                        <label className='text-white font-weight-bold text-center'>
                          {t.translate("Collector ID")}
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          value={collectorID}
                          aria-label='Trip Name'
                          aria-describedby='Entering Trip Name'
                          onChange={(e) => updateCollectorID(e.target.value)}
                        />
                        <button
                          // to='/AssignedStudents'
                          // onSubmit={ (e)=>onConfirm(e)}
                          className='btn btn-block btn-warning text-white font-weight-bold mt-3'
                        >
                          {t.translate("Update")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div className='d-flex justify-content-center align-items-center mt-5'>
          <Loader type='ThreeDots' color='#00BFFF' height={100} width={100} />
        </div>
      )}
    </Fragment>
  );
};
export default withRouter(EditAssignedStudent);
