import React, { Dispatch, Fragment, useEffect, useState, memo } from "react";
import Loader from "react-loader-spinner";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IStateType } from "../../../store/models/root.interface";
import * as actions from "../../../store/actions/index.actions";
import { usePosition } from "../../Maps/use-position";
import SweetAlert from "react-bootstrap-sweetalert";
const StartTrip: React.FC = memo((props: any) => {
  const watch = true;
  const { latitude, longitude, timestamp, error } = usePosition(watch);
  const [coords, setCoords] = useState<any>({});
  useEffect(() => {
    setCoords({ lat: latitude, lng: longitude });
  }, [latitude, longitude]);

  const dispatch: Dispatch<any> = useDispatch();

  const driver_id: any = useSelector(
    (state: IStateType) => state.auth.driverId
  );
  const trip: any = useSelector(
    (state: IStateType) => state.getdrivertrip.trip
  );
  const loading = useSelector(
    (state: IStateType) => state.getdrivertrip.loading
  );
  const buses: any = useSelector((state: IStateType) => state.addTrip.buses);
  const busloading = useSelector(
    (state: IStateType) => state.addTrip.loadingBus
  );

  useEffect(() => {
    /* dispatch(actions.fetchDriverTrip({ driver_id }));
    dispatch(actions.fetchBus());
    dispatch(actions.clearTripEmergancy()); */
  }, [dispatch]);

  useEffect(() => {
    if (
      loading === false &&
      Object.keys(trip).length > 0 &&
      trip.status == "active"
    ) {
      props.history.push("/childbychild");
    }
  }, [dispatch, trip]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(actions.fetchDriverTrip({ driver_id }));
      if (
        loading === false &&
        Object.keys(trip).length > 0 &&
        trip.status == "active"
      ) {
        props.history.push("/childbychild");
      }
    }, 20000);
    return () => clearInterval(intervalId);
  }, [dispatch, trip]);

  const onConfirm = function (e: any) {
    e.preventDefault();
    const data = {
      driver_id: trip.driver_id,
      trip_id: trip.id,
      lat: coords.lat,
      lng: coords.lng,
    };
    dispatch(actions.driverstarttrip(data));
  };
  const errorMsg = useSelector(
    (state: IStateType) => state.driverstarttrip.error
  );
  const errorNoTrip = useSelector(
    (state: IStateType) => state.getdrivertrip.error
  );
  const msg = useSelector((state: IStateType) => state.driverstarttrip.msg);
  const retreived_data = useSelector(
    (state: IStateType) => state.driverstarttrip.data
  );
  const onCancel = () => {
    dispatch(actions.clearMsgDriverStartTrip());
  };
  const onSuccessCancel = () => {
    dispatch(actions.clearMsgDriverStartTrip());
    props.history.push("/childbychild");
  };

  let forError = null;
  if (errorMsg) {
    forError = (
      <SweetAlert
        danger
        title="Warning"
        onConfirm={onCancel}
        onCancel={onCancel}
      >
        {errorMsg}
      </SweetAlert>
    );
  }
  let forSuccess = null;
  if (msg) {
    forSuccess = (
      <SweetAlert
        success
        title="Success"
        onConfirm={onSuccessCancel}
        onCancel={onSuccessCancel}
      >
        {msg}
      </SweetAlert>
    );
  }

  const [showPassenger, setShowPassenger] = useState<boolean>(false);

  return (
    <Fragment>
      <div className="container d-flex flex-column justify-content-center align-items-center">
        {!errorNoTrip ? (
          <div className="col-12">
            <div className="justify-content-center text-center table-responsive">
              {loading === false &&
              busloading === false &&
              Object.keys(trip)?.length > 0 ? (
                <>
                  <table id="table" className="table table-dark table-hover ">
                    <thead>
                      <tr>
                        <th scope="col">Trip Name</th>
                        <th scope="col">{trip.tripname}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Trip Start</td>
                        <td>{trip.time}</td>
                      </tr>
                      <tr>
                        <td>Direction</td>
                        <td>{trip.direction}</td>
                      </tr>
                      <tr>
                        <td>Bus Number</td>
                        <td>
                          {
                            buses?.filter(
                              (bus: any) => bus?.id == trip?.bus_id
                            )[0]?.busnumber
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>Number Of Passengers</td>
                        <td>
                          {trip.assignedtrips.length +
                            trip.employeetrips.length}
                          <button
                            className="btn btn-warning ml-2"
                            onClick={() => setShowPassenger((state) => !state)}
                          >
                            show
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {showPassenger === true ? (
                    <table
                      id="table_2"
                      className="table table-dark table-hover"
                    >
                      <tbody>
                        {trip.assignedtrips.length > 0
                          ? trip.assignedtrips.map((child: any) => {
                              return (
                                <tr key={child.id}>
                                  <td scope="col">Name:</td>
                                  <td scope="col">{child.name}</td>
                                </tr>
                              );
                            })
                          : null}

                        {trip.employeetrips.length > 0
                          ? trip.employeetrips.map((emp: any) => {
                              return (
                                <tr key={emp.id}>
                                  <td scope="col">Employee name:</td>
                                  <td scope="col">{emp.name}</td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  ) : null}
                </>
              ) : (
                <div className="d-flex justify-content-center align-items-center mt-5">
                  <Loader
                    type="ThreeDots"
                    color="#00BFFF"
                    height={100}
                    width={100}
                  />
                </div>
              )}
            </div>
            {latitude && Object.keys(trip).length > 0 ? (
              <form onSubmit={(e) => onConfirm(e)}>
                {forError}
                {forSuccess}
                {loading === false &&
                trip.assignedtrips.length + trip.employeetrips.length > 0 ? (
                  <button className="btn btn-block btn-warning text-white font-weight-bold p-2">
                    Start Trip
                  </button>
                ) : null}
              </form>
            ) : null}
          </div>
        ) : (
          <div className="col-12">
            <div
              className="d-flex justify-content-center align-items-center rounded m-2 p-4"
              style={{ backgroundColor: "rgb(44, 55, 94)" }}
            >
              <p
                style={{
                  color: "white",
                  textAlign: "center",
                  textShadow:
                    "0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)",
                  fontSize: "1.9rem",
                }}
              >
                There is no trip!
              </p>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
});

export default withRouter(StartTrip);
