import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  DirectionsRenderer,
} from 'react-google-maps';

class Map extends React.Component<any, any> {
  state = {
    directions: undefined,
  };

  componentDidMount() {
    const directionsService = new google.maps.DirectionsService();
    const origin = this.props.current_loc;
    const destination = this.props.arrMarkers;

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result,
          });
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  }
  // componentDidUpdate() {
  // }
  render() {
    return (
      <GoogleMap
        defaultZoom={11}
        zoom={10}
        center={
          this.props.arrMarkers ? this.props.arrMarkers : { lat: 30, lng: 28 }
        }
        defaultCenter={
          this.props.arrMarkers ? this.props.arrMarkers : { lat: 30, lng: 28 }
        }
      >
        <Marker position={this.props.arrMarkers} icon={
            'http://mt.google.com/vt/icon?psize=27&font=fonts/Roboto-Bold.ttf&color=ff135C13&name=icons/spotlight/spotlight-waypoint-a.png&ax=43&ay=50&text=•&scale=1'
        }/>
        <Marker position={this.props.current_loc} />
        {/* {this.props.direction && (
          <DirectionsRenderer directions={this.state.directions} />
        )} */}
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(Map));
