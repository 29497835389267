import React, { useEffect, useState } from "react";
import axios from "axios";
import MapBuses from "../../Maps/MapAllBuses";
import { Input } from "reactstrap";
import $ from "jquery";
import Swal from "sweetalert2";
import { Translate } from "../../../utils/Translate";
const BusTracking: React.FC = () => {
  const today = new Date();

  const date1 = today.toISOString().substr(0, 16);
  const [date, setDate] = useState<string>(date1);
  const [selectedBusId, setSelectedBusId] = useState();
  const gpsNumber = localStorage.gpsnumber;
  const t = new Translate()
  const [buses, setBuses] = useState([]);
  const [busRoutes, setBuseRoutes] = useState([]);
  useEffect(() => {
    Swal.showLoading();
    axios
      .get("reports/busmovementreport.php")
      .then((res) => {
        $("#datatable").DataTable().destroy();
        setBuses(res.data.data ?? []);
        $("#datatable").DataTable();
      })
      .finally(() => {
        Swal.close();
      });
  }, []);
  const loading = () => {
    Swal.fire({
      html: '<div style="color:blue;"> Loading....... </div>',
      showConfirmButton: false,
    });
  };
  // if (trips.length === 0) {
  //   loading();
  // }
  return (
    <div>
      <div className=" row col-12 ">
        <div className="text-center">
          <h2
            className="text-white"
            style={{
              textShadow:
                "0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)",
              fontSize: "2rem",
            }}
          >
            {t.translate("Buses Movement Report Without Starting The Trip")}
          </h2>
        </div>

        <div
          className="table-responsive bg-light my-5"
          style={{ padding: "1%" }}
        >
          <table
            id="datatable"
            className="table-hover table-bordered text-center "
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>#</th>
                <th>{t.translate("Bus Name")}</th>
                <th>{t.translate("Driver Name")}</th>
                <th>{t.translate("Date")}</th>
                <th>{t.translate("Time")}</th>
                <th>{t.translate("Max Speed")}</th>
              </tr>
            </thead>
            <tbody>
              {buses.length > 0
                ? buses?.map((trip: any, index: any) => (
                  <tr key={trip.id}>
                    <td>{index + 1}</td>
                    <td>{trip?.busnumber ?? "NA"}</td>
                    <td>{trip.displayname ?? "NA"}</td>
                    <td>{trip?.date ?? "NA"}</td>
                    <td>{trip?.time?.split(" ")[1] ?? "NA"}</td>
                    <td>{trip?.speed ?? "NA"}</td>
                  </tr>
                ))
                : null}
            </tbody>
          </table>
        </div>

        <div className="col-6"></div>
      </div>
    </div>
  );
};

export default BusTracking;
