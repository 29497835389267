import { IActionBase, ITrips } from '../../models/root.interface';
import * as actionTypes from '../../actions/actionTypes';

import { updateObject } from '../../utility';

const initialState: any = {
  areas: null,
  count: 0,
  loadingAreas: null,
  error: null,
  msg: null,
};

const fetchAreasStart = (state: any, action: IActionBase) => {
  return updateObject(state, { error: null, loadingAreas: true });
};

const fetchAreasSuccess = (
  state: any = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    areas: action.areas,
    count: action.count,
    loadingAreas: false,
    error: null,
  });
};

const fetchAreasFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingAreas: false,
  });
};

// add area
const addAreaStart = (state: any, action: IActionBase) => {
    return updateObject(state, { error: null, loadingAreas: true });
  };
  
  const addAreaSuccess = (state: any = initialState, action: IActionBase) => {
    return updateObject(state, {
      areas: state.areas.concat(action.data),
      count: state.count + 1,
      loadingAreas: false,
      error: null,
    });
  };
  
  const addAreaFail = (state: ITrips, action: IActionBase) => {
    return updateObject(state, {
      error: action.error,
      loadingAreas: false,
    });
  };
// remove area
const removeAreaStart = (state: any, action: IActionBase) => {
    return updateObject(state, { error: null, loadingAreas: true });
  };
  
  const removeAreaSuccess = (state: any = initialState, action: IActionBase) => {
      const newArr = state.areas.filter((arr:any)=> arr.id !== action.oldId)
    return updateObject(state, {
      areas: newArr,
      loadingAreas: false,
      error: null,
    });
  };
  
  const removeAreaFail = (state: ITrips, action: IActionBase) => {
    return updateObject(state, {
      error: action.error,
      loadingAreas: false,
    });
  };
// edit area
const editAreaStart = (state: any, action: IActionBase) => {
    return updateObject(state, { error: null, loadingAreas: true });
  };
  
  const editAreaSuccess = (state: any = initialState, action: IActionBase) => {
      const newArr = state.areas.map((arr:any)=> {
          if(arr.id === action.data.id){
            arr = action.data;
          }
          return arr;
      })
    return updateObject(state, {
      areas: newArr,
      loadingAreas: false,
      error: null,
    });
  };
  
  const editAreaFail = (state: ITrips, action: IActionBase) => {
    return updateObject(state, {
      error: action.error,
      loadingAreas: false,
    });
  };



const locations = (state: ITrips = initialState, action: IActionBase) => {
  switch (action.type) {
    case actionTypes.FETCH_ALL_LOCATIONS_START:
      return fetchAreasStart(state, action);
    case actionTypes.FETCH_ALL_LOCATIONS_SUCCESS:
      return fetchAreasSuccess(state, action);
    case actionTypes.FETCH_ALL_LOCATIONS_FAIL:
      return fetchAreasFail(state, action);


    case actionTypes.ADD_LOCATION_START:
      return addAreaStart(state, action);
    case actionTypes.ADD_LOCATION_SUCCESS:
      return addAreaSuccess(state, action);
    case actionTypes.ADD_LOCATION_FAIL:
      return addAreaFail(state, action);


    case actionTypes.DELETE_LOCATION_START:
      return removeAreaStart(state, action);
    case actionTypes.DELETE_LOCATION_SUCCESS:
      return removeAreaSuccess(state, action);
    case actionTypes.DELETE_LOCATION_FAIL:
      return removeAreaFail(state, action);

    case actionTypes.EDIT_LOCATION_START:
      return editAreaStart(state, action);
    case actionTypes.EDIT_LOCATION_SUCCESS:
      return editAreaSuccess(state, action);
    case actionTypes.EDIT_LOCATION_FAIL:
      return editAreaFail(state, action);


    default:
      return state;
  }
};
export default locations;
