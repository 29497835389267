import { Route, RouteProps, Redirect } from 'react-router';
import React from 'react';
import { useSelector } from 'react-redux';
import { IStateType } from '../../store/models/root.interface';
import { IAccount } from '../../store/models/account.interface';
import Login from '../../components/Account/Login';

export function PrivateRoute({ children, ...rest }: RouteProps): JSX.Element {
  const isAuthenticated: string | null = useSelector(
    (state: IStateType) => state.auth.role
  );

  return (
    <React.Fragment>
      {isAuthenticated ? (
        <React.Fragment>
          <Route {...rest} render={() => children} />
          <Redirect to='/' />
        </React.Fragment>
      ) : (
        <Route path='/login' render={() => <Login />} />
      )}
    </React.Fragment>
  );
}
