import React, { Fragment, Dispatch, useEffect, memo, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index.actions";
import { IStateType } from "../../../store/models/root.interface";
import Loader from "react-loader-spinner";
import Pagination from "react-js-pagination";
import classes from "../../Pagination.module.css";
// require("bootstrap/less/bootstrap.less");
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Translate } from "../../../utils/Translate";

interface trip {
  id: string;

  onewayfee: string;
  twowaysfee: string;
  toschooltime: string;
  toschoolcount: string;
  tohometime: string;
  tohomecount: string;
  status: string;
  tripname: string;
  displayname: string;
  mobile: string;
  bus: string;
  capacity: string;
  area: string;
}
const TripsTable: React.FC = memo((props: any) => {
  let t = new Translate();
  const dispatch: Dispatch<any> = useDispatch();
  const trips = useSelector((state: IStateType) => state.managetrips.trips);
  const loading = useSelector((state: IStateType) => state.managetrips.loading);
  const count = useSelector((state: IStateType) => state.managetrips.count);

  function edittrip(tripid: string) {
    props.history.push("/EditTrip?id=" + tripid);
  }
  function trackTrip(tripid: string) {
    props.history.push("/TrackTrip?id=" + tripid);
  }

  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const handlePageChange = (pageNumber: any) => {
    console.log(`active page is ${pageNumber}`);
    dispatch(actions.fetchTrips(pageNumber, pageSize));
    setActivePage(pageNumber);
  };

  useEffect(() => {
    $(document).ready(() => {
      //const table: any = $('#datatable').DataTable();
    });
  });

  useEffect(() => {
    dispatch(actions.fetchTrips(activePage, pageSize));
    setTimeout(() => {
      $("#datatable").DataTable();
    }, 1500);
  }, [dispatch]);
  let arrowLeft = <i className="fas fa-angle-double-left"></i>;
  let arrowRight = <i className="fas fa-angle-double-right"></i>;
  return (
    <Fragment>
      <div className="text-center">
        <h2
          className="text-white"
          style={{
            textShadow:
              "0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)",
            fontSize: "2rem",
          }}
        >
          {t.translate("Managing Trips")}
        </h2>
      </div>
      {loading === false ? (
        <div className="row">
          <div className="col-12">
            <Link
              to="/AddTrip"
              className="btn btn-success my-1 text-white font-weight-bold"
            >
              {t.translate("Add Trip")}
            </Link>
          </div>
          <div className="col-md-12">
            <div className="table-responsive bg-light">
              <table
                id="datatable"
                className="table table-striped table-bordered text-center"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th> {t.translate("Name")}</th>
                    <th>{t.translate("Area")}</th>
                    <th>{t.translate("To school Time")}</th>
                    <th>{t.translate("To_S_Count")}</th>
                    {t.translate("1")}
                    <th>{t.translate("To home time")}</th>
                    <th>{t.translate("To_H_Count")}</th>
                    <th>{t.translate("Driver")}</th>
                    <th>{t.translate("Bus Number")}</th>
                    <th>{t.translate("Active?")}</th>
                    <th>{t.translate("Options")}</th>
                  </tr>
                </thead>
                <tbody>
                  {trips.map((trip: trip) => {
                    return (
                      <tr key={trip.id}>
                        <td className="w-10">{trip.tripname}</td>
                        <td className="w-10">{trip.area}</td>
                        <td className="w-10">{trip.toschooltime}</td>
                        <td className="w-10">{trip.toschoolcount}</td>
                        <td className="w-10">{trip.tohometime}</td>
                        <td className="w-10">{trip.tohomecount}</td>
                        <td className="w-10">
                          {trip.displayname} - {trip.mobile}
                        </td>
                        <td className="w-10">{trip.bus}</td>
                        <td className="w-5">
                          {trip.status === "active" ? "Yes" : "No"}
                        </td>
                        <td className="w-5">
                          <button
                            // to='/EditTrip'
                            onClick={() => edittrip(trip.id)}
                            className="btn btn-warning text-white font-weight-bold m-1"
                          >
                            <i className="fas fa-cog"></i>
                          </button>
                          <button
                            // to='/TrackTrip'
                            disabled={trip.status === "active" ? false : true}
                            onClick={() => trackTrip(trip.id)}
                            className="btn btn-danger text-white font-weight-bold m-1"
                          >
                            <i className="fas fa-eye"></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {/* <div className='d-flex justify-content-center align-items-center my-3'>
              {count !== 0 ? (
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={pageSize}
                  totalItemsCount={+count}
                  pageRangeDisplayed={5}
                  onChange={(e) => handlePageChange(e)}
                  firstPageText={'First'}
                  lastPageText={'Last'}
                  prevPageText={arrowLeft}
                  nextPageText={arrowRight}
                  activeClass={classes.active}
                />
              ) : null}
            </div> */}
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
        </div>
      )}
    </Fragment>
  );
});

export default withRouter(TripsTable);
