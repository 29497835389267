import * as actionTypes from '../actionTypes';
import axios from 'axios';

export const sendDriverRequestGate = () => {
  return {
    type: actionTypes.SEND_DRIVER_REQUEST_GATE,
  };
};
export const sendDriverRequestGateSuccess = (data: any) => {
  return {
    type: actionTypes.SEND_DRIVER_REQUEST_GATE_SUCCESS,
    data,
  };
};
export const sendDriverRequestGateFail = (error: any) => {
  return {
    type: actionTypes.FETCH_DRIVER_REQUEST_GATE_FAIL,
    error,
  };
};
export const clearMsgDriverRequestGate = () => {
  return {
    type: actionTypes.CLEAR_MSG_DRIVER_REQUEST_GATE,
  };
};

export const driverrequestgate = (data: any) => {
  return (dispatch: any) => {
    dispatch(sendDriverRequestGate());

    axios
      .post(`driver/requestgate.php`, data)
      .then((res) => {
        console.log(res.data.data);
        dispatch(sendDriverRequestGateSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(sendDriverRequestGateFail(err.response.data.msg));
        } else {
          dispatch(sendDriverRequestGateFail('Internet Connection Failed'));
        }
      });
  };
};
