import axios from "axios";
import * as actionTypes from "./actionTypes";
import setAuthToken from "../../utils/setAuthToken";

function parseJwt(token: any) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (
  token: any,
  userId: any,
  driverId: any,
  userName: any,
  role: any,
  gpsnumber: any
) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token,
    userId,
    driverId,
    userName,
    role,
    gpsnumber,
  };
};

export const authFail = (error: any) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error,
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("expDate");
  localStorage.removeItem("gpsnumber");
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expS: any) => {
  return (dispatch: any) => {
    setTimeout(() => {
      dispatch(logout());
    }, expS * 1000);
  };
};

export const auth = (userName: any, password: any) => {
  return (dispatch: any) => {
    dispatch(authStart());

    let authDate = {
      userName,
      password,
    };
    // var params = new URLSearchParams();
    // params.append('userName', userName);
    // params.append('password', password);
    // console.log(authData);
    axios
      .post(
        `auth/login.php
      `,
        authDate
      )
      .then((res) => {
        // console.log(res);
        const data = parseJwt(res.data.data.token);
        const expDate: any = new Date(new Date(data.exp * 1000));
       
        localStorage.setItem("token", res.data.data.token);
        localStorage.setItem("expDate", expDate);
        localStorage.setItem("gpsnumber", data.data.user.gpsnumber);
        localStorage.setItem("preferred_lang", "en");
        localStorage.setItem(
          "ivy_dict",
          JSON.stringify(res.data.data.dictionary.data)
        );
        setAuthToken(res.data.data.token);
        dispatch(
          authSuccess(
            res.data.data.token,
            data.data.userId,
            data.data.driverId,
            data.data.userName,
            data.data.role,
            data.data.user.gpsnumber
          )
        );
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(authFail(err.response.data.msg));
        } else {
          dispatch(authFail("Internet Connection Failed"));
        }
      });
  };
};

// for clear errors
export const authClearError = () => {
  return {
    type: actionTypes.AUTH_ERROR,
  };
};

// check token in local storge in every reload
export const authCheckState = () => {
  return (dispatch: any) => {
    const token: any = localStorage.getItem("token");
    if (!token) {
      dispatch(logout());
    } else {
      // i put new Date cuz local storage retuen string
      let getDatefromlocal: any = localStorage.getItem("expDate");
      const expDate: any = new Date(getDatefromlocal);
      if (expDate <= new Date()) {
        dispatch(logout());
      } else {
        const data = parseJwt(token);
        dispatch(
          authSuccess(
            token,
            data.data.userId,
            data.data.driverId,
            data.data.userName,
            data.data.role,
            data.data.gpsnumber
          )
        );
        dispatch(
          checkAuthTimeout((expDate.getTime() - new Date().getTime()) / 1000)
        );
      }
    }
  };
};
