import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';

class Map extends React.Component<any, any> {
  render() {
    return (
      <GoogleMap
        defaultZoom={11}
        defaultCenter={
          this.props.loading ? this.props.coordinates : { lat: 29, lng: 30 }
        }
        center={
          this.props.loading ? this.props.coordinates : { lat: 29, lng: 30 }
        }
      >
        {this.props.loading && <Marker position={this.props.coordinates} />}
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(Map));
