import * as actionTypes from "../actionTypes";
import axios from "axios";

export const fetchDriverStart = () => {
  return {
    type: actionTypes.FETCH_DRIVER_START,
  };
};
export const fetchDriverSuccess = (drivers: any) => {
  return {
    type: actionTypes.FETCH_DRIVER_SUCCESS,
    drivers,
  };
};
export const fetchDriverFail = (error: any) => {
  return {
    type: actionTypes.FETCH_DRIVER_FAIL,
    error,
  };
};

export const fetchDriver = () => {
  return (dispatch: any) => {
    dispatch(fetchDriverStart());

    axios
      .get(`admin/getdrivers.php`)
      .then((res) => {
        console.log(res);
        dispatch(fetchDriverSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fetchDriverFail(err.response.data.msg));
        } else {
          dispatch(fetchDriverFail("Internet Connection Failed"));
        }
      });
  };
};

export const fetchBusStart = () => {
  return {
    type: actionTypes.FETCH_BUS_START,
  };
};
export const fetchBusSuccess = (buses: any) => {
  return {
    type: actionTypes.FETCH_BUS_SUCCESS,
    buses,
  };
};
export const fetchBusFail = (error: any) => {
  return {
    type: actionTypes.FETCH_BUS_FAIL,
    error,
  };
};

export const fetchBus = () => {
  return (dispatch: any) => {
    dispatch(fetchBusStart());

    axios
      .get(`admin/getbuses.php`)
      .then((res) => {
        // console.log(res);
        dispatch(fetchBusSuccess(res.data.data ?? []));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fetchBusFail(err.response.data.msg));
        } else {
          dispatch(fetchBusFail("Internet Connection Failed"));
        }
      });
  };
};

// get requested trips
export const fetchRequestedTripsStart = () => {
  return {
    type: actionTypes.FETCH_REQ_TRIPS_START,
  };
};
export const fetchRequestedTripsSuccess = (data: any) => {
  return {
    type: actionTypes.FETCH_REQ_TRIPS_SUCCESS,
    requestedTrips: data.trips,
    reqcount: data.count,
  };
};
export const fetchRequestedTripsFail = (error: any) => {
  return {
    type: actionTypes.FETCH_REQ_TRIPS_FAIL,
    error,
  };
};

export const fetchRequestedTrips = (pageN: any, pageSize: any, search = "") => {
  return (dispatch: any) => {
    dispatch(fetchRequestedTripsStart());
    let data = { pagenumber: pageN, pagesize: pageSize, search };
    console.log(data);
    axios
      .post(`admin/getrequestedtrips.php`, data)
      .then((res) => {
        console.log(res);
        dispatch(fetchRequestedTripsSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fetchRequestedTripsFail(err.response.data.msg));
        } else {
          dispatch(fetchRequestedTripsFail("Internet Connection Failed"));
        }
      });
  };
};

// get employees
export const fetchEmployeesStart = () => {
  return {
    type: actionTypes.FETCH_EMPLOYEES_START,
  };
};
export const fetchEmployeesSuccess = (data: any) => {
  return {
    type: actionTypes.FETCH_EMPLOYEES_SUCCESS,
    employees: data.employees,
    count: data.count,
  };
};
export const fetchEmployeesFail = (error: any) => {
  return {
    type: actionTypes.FETCH_EMPLOYEES_FAIL,
    error,
  };
};

export const fetchEmployees = (pageN: any, pageSize: any) => {
  return (dispatch: any) => {
    dispatch(fetchEmployeesStart());
    let data = { pagenumber: pageN, pagesize: pageSize };

    axios
      .post(`admin/getemployees.php`, data)
      .then((res) => {
        // console.log(res);
        dispatch(fetchEmployeesSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fetchEmployeesFail(err.response.data.msg));
        } else {
          dispatch(fetchEmployeesFail("Internet Connection Failed"));
        }
      });
  };
};

// for add trip finaly

export const addTripStart = () => {
  return {
    type: actionTypes.ADD_TRIP_START,
  };
};
export const addTripSuccess = (msg: string) => {
  return {
    type: actionTypes.ADD_TRIP_SUCCESS,
    msg,
    trip: null,
  };
};
export const addTripFail = (error: any, data: any) => {
  return {
    type: actionTypes.ADD_TRIP_FAIL,
    error,
    trip: data,
  };
};

export const clearMsgAddTrip = () => {
  return {
    type: actionTypes.CLEAR_MSG_ADDTRIP,
  };
};

export const addTrip = (data: any) => {
  return (dispatch: any) => {
    dispatch(addTripStart());
    console.log(data);
    axios
      .post(`admin/addtrip.php`, data)
      .then((res) => {
        dispatch(addTripSuccess(res.data.msg));
      })
      .catch((err) => {
        console.log("Err!", err);
        // console.log(res);
        if (err.response) {
          dispatch(addTripFail(err.response.data.msg, data));
        } else {
          dispatch(addTripFail("Internet Connection Failed", data));
        }
      });
  };
};
