import React, { Fragment } from 'react';
import LeftMenu from '../LeftMenu/LeftMenu';
import TopMenu from '../TopMenu/TopMenu';
import { Switch, Route, withRouter } from 'react-router';
import Home from './Home/Home';
import Notifications from '../../common/components/Notification';
import DoPayment from './Payment/DoPayment';

const Admin: React.FC = (props: any) => {
  // console.log(props);
  return (
    <Fragment>
      <Notifications />
      <LeftMenu />
      <div id='content-wrapper' className='d-flex flex-column'>
        <div id='content'>
          <TopMenu />
          <div className='container-fluid'>
            <Switch>
              <Route path='/payments'>
                <DoPayment />
              </Route>
              <Route path='/'>
                <Home />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(Admin);
