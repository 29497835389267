import { IActionBase, DriverRequestGate } from '../../models/root.interface';
import * as actionTypes from '../../actions/actionTypes';

import { updateObject } from '../../utility';
const initialState: DriverRequestGate = {
  trip: {},
  loading: null,
  error: null,
  msg: null,
  data: {},
};

// for API Calling
const sendDriverRequestGate = (state: DriverRequestGate, action: IActionBase) => {
  return updateObject(state, { error: null, loading: true });
};

const sendDriverRequestGateSuccess = (
  state: DriverRequestGate = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    msg: action.msg,
    loading: false,
    error: null,
    data: action.data,
  });
};

const sendDriverRequestGateFail = (
  state: DriverRequestGate,
  action: IActionBase
) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};
const clearMsgDriverRequestGate = (
  state: DriverRequestGate,
  action: IActionBase
) => {
  return updateObject(state, {
    error: null,
    msg: null,
    loading: null,
    data:{}
  });
};

const driverrequestgate = (
  state: DriverRequestGate = initialState,
  action: IActionBase
) => {
  switch (action.type) {
    case actionTypes.SEND_DRIVER_REQUEST_GATE:
      return sendDriverRequestGate(state, action);
    case actionTypes.SEND_DRIVER_REQUEST_GATE_SUCCESS:
      return sendDriverRequestGateSuccess(state, action);
    case actionTypes.FETCH_DRIVER_REQUEST_GATE_FAIL:
      return sendDriverRequestGateFail(state, action);
    case actionTypes.CLEAR_MSG_DRIVER_REQUEST_GATE:
      return clearMsgDriverRequestGate(state, action);
    default:
      return state;
  }
};
export default driverrequestgate;
