import * as actionTypes from '../actionTypes';
import axios from 'axios';

export const clearMsgAssginTrip = () => {
  return {
    type: actionTypes.CLEAR_MSG_AssginTrip,
  };
};

export const fetchTripStart = () => {
  return {
    type: actionTypes.FETCH_ASSIGNTRIP_START,
  };
};
export const fetchTripSuccess = (data: any) => {
  return {
    type: actionTypes.FETCH_ASSIGNTRIP_SUCCESS,
    trip: data,
  };
};
export const fetchTripFail = (error: any) => {
  return {
    type: actionTypes.FETCH_ASSIGNTRIP_FAIL,
    error,
  };
};

export const fetchAssignedTrip = (tripid: string) => {
  return (dispatch: any) => {
    dispatch(fetchTripStart());

    axios
      .post(
        `admin/getassignedtrip.php
        `,
        { id: tripid }
      )
      .then((res) => {
        dispatch(fetchTripSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fetchTripsFail(err.response.data.msg));
        } else {
          dispatch(fetchTripFail('Internet Connection Failed'));
        }
      });
  };
};
export const fetchAssignedTripforSTD = (tripid: string) => {
  return (dispatch: any) => {
    dispatch(fetchTripStart());

    axios
      .post(
        `admin/getassignedtripforstd.php
        `,
        { id: tripid }
      )
      .then((res) => {
        dispatch(fetchTripSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          //dispatch(fetchTripsFail(err.response.data.msg));
        } else {
          dispatch(fetchTripFail('Internet Connection Failed'));
        }
      });
  };
};

// UDATE trip   
export const updateAssTripStart = () => {
  return {
    type: actionTypes.UPDATEASS_TRIP_START,
  };
};
export const updateAssTripSuccess = (msg: string) => {
  return {
    type: actionTypes.UPDATEASS_TRIP_SUCCESS,
    msg,
  };
};
export const updateAssTripFail = (error: any) => {
  return {
    type: actionTypes.UPDATEASS_TRIP_FAIL,
    error,
  };
};

export const updateAssTrip = (data: any) => {
  return (dispatch: any) => {
    dispatch(updateAssTripStart());

    axios
      .post(`admin/updateassignedtrip.php`, data)
      .then((res) => {
        console.log(res);
        dispatch(updateAssTripSuccess(res.data.msg));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(updateAssTripFail(err.response.data.msg));
        } else {
          dispatch(updateAssTripFail('Internet Connection Failed'));
        }
      });
  };
};

// fetch assign trips

export const fetchTripsStart = () => {
  return {
    type: actionTypes.FETCH_ASSIGNTRIPS_START,
  };
};
export const fetchTripsSuccess = (data: any) => {
  return {
    type: actionTypes.FETCH_ASSIGNTRIPS_SUCCESS,
    trips: data.trips,
    count: data.count,
  };
};
export const fetchTripsFail = (error: any) => {
  return {
    type: actionTypes.FETCH_ASSIGNTRIPS_FAIL,
    error,
  };
};

export const fetchAssignedTrips = (pageN: any, pageSize: any, search = '') => {
  return (dispatch: any) => {
    dispatch(fetchTripsStart());
    let data = { pagenumber: pageN, pagesize: pageSize, search };
    console.log(data);
    axios
      .post(
        `admin/getassignedtrips.php
        `,
        data
      )
      .then((res) => {
        console.log(res);
        dispatch(fetchTripsSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fetchTripsFail(err.response.data.msg));
        } else {
          dispatch(fetchTripsFail('Internet Connection Failed'));
        }
      });
  };
};

// suspend  trip

export const suspendTripStart = () => {
  return {
    type: actionTypes.SUSPEND_TRIP_START,
  };
};
export const suspendTripSuccess = (msg: any, data: any) => {
  return {
    type: actionTypes.SUSPEND_TRIP_SUCCESS,
    msg,
    data,
  };
};
export const suspendTripFail = (error: any) => {
  return {
    type: actionTypes.SUSPEND_TRIP_FAIL,
    error,
  };
};

export const suspendTrip = (assignedtrip_id: any) => {
  return (dispatch: any) => {
    dispatch(suspendTripStart());
    let data = { assignedtrip_id };

    axios
      .post(
        `admin/suspendtrip.php
        `,
        data
      )
      .then((res) => {
        console.log(res);
        dispatch(suspendTripSuccess(res.data.msg, res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(suspendTripFail(err.response.data.msg));
        } else {
          dispatch(suspendTripFail('Internet Connection Failed'));
        }
      });
  };
};

// un-suspend  trip

export const unSuspendTripStart = () => {
  return {
    type: actionTypes.UNSUSPEND_TRIP_START,
  };
};
export const unSuspendTripSuccess = (msg: any, data: any) => {
  return {
    type: actionTypes.UNSUSPEND_TRIP_SUCCESS,
    msg,
    data,
  };
};
export const unSuspendTripFail = (error: any) => {
  return {
    type: actionTypes.UNSUSPEND_TRIP_FAIL,
    error,
  };
};

export const unSuspendTrip = (assignedtrip_id: any) => {
  return (dispatch: any) => {
    dispatch(unSuspendTripStart());
    let data = { assignedtrip_id };

    axios
      .post(
        `admin/unsuspendtrip.php
        `,
        data
      )
      .then((res) => {
        console.log(res);
        dispatch(unSuspendTripSuccess(res.data.msg, res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(unSuspendTripFail(err.response.data.msg));
        } else {
          dispatch(unSuspendTripFail('Internet Connection Failed'));
        }
      });
  };
};
// un-suspend  trip

export const deleteAssignTripStart = () => {
  return {
    type: actionTypes.DELETE_ASSIGNTRIP_START,
  };
};
export const deleteAssignTripSuccess = (msg: any, id: any) => {
  return {
    type: actionTypes.DELETE_ASSIGNTRIP_SUCCESS,
    msg,
    id,
  };
};
export const deleteAssignTripFail = (error: any) => {
  return {
    type: actionTypes.DELETE_ASSIGNTRIP_FAIL,
    error,
  };
};

export const deleteAssignTrip = (assignedtrip_id: any) => {
  return (dispatch: any) => {
    dispatch(deleteAssignTripStart());
    let data = { assignedtrip_id };

    axios
      .post(`admin/deleteassignedtrip.php`, data)
      .then((res) => {
        console.log(res);
        dispatch(deleteAssignTripSuccess(res.data.msg, assignedtrip_id));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(deleteAssignTripFail(err.response.data.msg));
        } else {
          dispatch(deleteAssignTripFail('Internet Connection Failed'));
        }
      });
  };
};
