import React from "react";
import axios from "axios";
// import ivy from '../../assets/ivy_logo_min.png';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  Polyline,
} from "react-google-maps";

class Map extends React.Component<any, any> {
  state = {
    isOpen: false,
    directions: undefined,
    routes: [],
  };

  componentDidMount() {
    fetch(
      `https://roads.googleapis.com/v1/snapToRoads?path=${this.props.driver_path
        ?.map((r: any) => r.lat + "," + r.lng)
        ?.join(
          "|"
        )}&interpolate=true&key=AIzaSyBaD17OgMbI_0stUcRlvaeIvMuTet_rjdk`
    )
      .then((response) => {
        response.json().then((data) => {
          this.setState({
            directions: data.snappedPoints?.map((m: any) => {
              return { lat: m.location.latitude, lng: m.location.longitude };
            }),
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    let [Last_driver_location] = this.props.driver_path.slice(-1);
    console.log(Last_driver_location);
    return (
      <GoogleMap
        defaultZoom={11}
        defaultCenter={
          Last_driver_location ? Last_driver_location : { lat: 30, lng: 28 }
        }
        center={
          Last_driver_location ? Last_driver_location : { lat: 30, lng: 28 }
        }
      >
        {this.props.arrivedStudents &&
          this.props.arrivedStudents?.map((mark: any) => (
            <Marker
              key={Math.random() * 1.2}
              position={mark}
              icon={
                "http://mt.google.com/vt/icon?psize=27&font=fonts/Roboto-Bold.ttf&color=ff135C13&name=icons/spotlight/spotlight-waypoint-a.png&ax=43&ay=50&text=•&scale=1"
              }
            >
              <InfoWindow options={{ disableAutoPan: true }}>
                <h5>{mark.name}</h5>
              </InfoWindow>
            </Marker>
          ))}
        {this.props.notArrivedStudents &&
          this.props.notArrivedStudents?.map((mark: any) => (
            <Marker key={Math.random() * 1.2} position={mark}>
              <InfoWindow options={{ disableAutoPan: true }}>
                <h5>{mark.name}</h5>
              </InfoWindow>
            </Marker>
          ))}
        <Marker
          // marker shaped as car for the last driver location point only
          key={Math.random() * 1.2}
          position={Last_driver_location}
          icon={{
            url: "https://img.icons8.com/material-outlined/2x/gps-device.png",
            scaledSize: new window.google.maps.Size(60, 32),
          }}
        />

        <Marker
          // marker for the first driver location point only
          key={Math.random() * 1.2}
          position={this.props.driver_path[0]}
          onClick={() => this.setState({ isOpen: !this.state.isOpen })}
          icon={
            "https://mt.google.com/vt/icon/name=icons/spotlight/directions_destination_measle_drag.png&scale=3"
          }
        >
          {this.state.isOpen ? (
            <InfoWindow options={{ disableAutoPan: true }}>
              <>
                <div>
                  {" "}
                  <p>{this.props.driver_path[0].time.split(" ")[1]}</p>
                </div>
              </>
            </InfoWindow>
          ) : null}
        </Marker>

        {this.props.driver_path.map((loc_mark: any) => (
          <Marker
            key={Math.random() * 1.2}
            position={loc_mark}
            icon={{
              url: "",
              scaledSize: new window.google.maps.Size(60, 32),
            }}
          />
        ))}
        {console.log(this.state.directions, "directions")}
        {this.state.directions !== undefined && (
          <Polyline path={this.state.directions} />
        )}
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(Map));
