import React, {
  Fragment,
  Dispatch,
  useEffect,
  memo,
  useState,
  useRef,
} from "react";
import { Link, withRouter } from "react-router-dom";
// import parent_select_location_sample from '../../../assets/parent_select_location_sample.png';
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index.actions";
import { IStateType } from "../../../store/models/root.interface";
import Loader from "react-loader-spinner";
import Pagination from "react-js-pagination";
import classes from "../../Pagination.module.css";
import assigntrip from "../../../store/reducers/parent/assigntrip.reducer";
import SweetAlert from "react-bootstrap-sweetalert";
import { Translate } from "../../../utils/Translate";

interface trip {
  id: string;

  direction: string;
  student_id: string;
  address: string;
  location_lat: string;
  location_lng: string;
  handing_instructions: string;
  collector_name: string;
  collector_id: string;
  parent_id: string;
  status: string;
  name: string;
  areaname: string;
  parentmobile: string;
  rejection_reason: string;
}

const RejectionRequests: React.FC = memo((props: any) => {
  const count = useSelector(
    (state: IStateType) => state.requestedtrips.rejectedTripsCount
  );
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(3);

  const handlePageChange = (pageNumber: any) => {
    // console.log(`active page is ${pageNumber}`);
    dispatch(actions.fetchRejectReqTrips(pageNumber, pageSize, enteredFilter));
    setActivePage(pageNumber);
  };
  const dispatch: Dispatch<any> = useDispatch();
  const requestedtrips = useSelector(
    (state: IStateType) => state.requestedtrips.rejectedTrips
  );

  const loading = useSelector(
    (state: IStateType) => state.requestedtrips.rejectedTripsLoading
  );

  const errorMsg = useSelector(
    (state: IStateType) => state.requestedtrips.error
  );
  const msg = useSelector((state: IStateType) => state.requestedtrips.msg);

  const onCancel = () => {
    dispatch(actions.clearMsgReqtrips());
  };

  const onSuccessCancel = () => {
    dispatch(actions.clearMsgReqtrips());
  };

  let forError = null;
  if (errorMsg) {
    forError = (
      <SweetAlert
        danger
        title="Warning"
        onConfirm={onCancel}
        onCancel={onCancel}
      >
        {errorMsg}
      </SweetAlert>
    );
  }

  let forSuccess = null;
  if (msg) {
    forSuccess = (
      <SweetAlert
        success
        title="Success"
        onConfirm={onSuccessCancel}
        onCancel={onSuccessCancel}
      >
        {msg}
      </SweetAlert>
    );
  }

  let arrowLeft = <i className="fas fa-angle-double-left"></i>;
  let arrowRight = <i className="fas fa-angle-double-right"></i>;

  const inputSearchRef: any = useRef();
  const [enteredFilter, setEnteredFilter] = useState<string>("");
  const t = new Translate()
  useEffect(() => {
    const timer = setTimeout(() => {
      if (enteredFilter === inputSearchRef.current.value) {
        console.log(enteredFilter);
        dispatch(
          actions.fetchRejectReqTrips(activePage, pageSize, enteredFilter)
        );
      }
    }, 500);

    return () => {
      clearTimeout(timer);
      setActivePage(1);
    };
  }, [enteredFilter, inputSearchRef]);

  return (
    <Fragment>
      <div className="text-center">
        <h2
          className="text-white"
          style={{
            textShadow:
              "0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)",
            fontSize: "2rem",
          }}
        >
          {t.translate("Pending Students' Requests")}
        </h2>
      </div>
      <div className="input-group mt-3 mb-3">
        <input
          type="text"
          className="form-control"
          ref={inputSearchRef}
          onChange={(e) => setEnteredFilter(e.target.value)}
        />
        <div className="input-group-append">
          <button className="btn btn-primary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>

      {loading === false ? (
        <div className="justify-content-center align-items-center">
          {forError}
          {forSuccess}
          <div className="table-responsive bg-light">
            <table
              className="table-hover table-bordered text-center"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th scope="col">{t.translate("IVY ID")}</th>
                  <th scope="col">{t.translate("Student Name")}</th>
                  <th scope="col">{t.translate("Area")}</th>
                  <th scope="col">{t.translate("Location")}</th>
                  <th scope="col">{t.translate("Direction")}</th>
                  <th scope="col">{t.translate("Payment Type")}</th>
                  <th scope="col">{t.translate("Rejection Reason")}</th>
                </tr>
              </thead>
              <tbody>
                {requestedtrips.map((reqtrip: trip) => {
                  return (
                    <tr key={reqtrip.id}>
                      <td className="w-20">{reqtrip.student_id}</td>
                      <td className="w-20">{reqtrip.name}</td>
                      <td className="w-20">{reqtrip.areaname}</td>
                      <td className="w-20">
                        <p className="text-wrap text-break">
                          {reqtrip.address}
                        </p>
                      </td>
                      <td className="w-10">{reqtrip.direction}</td>
                      <td className="w-10">{t.translate("Credit Card")}</td>
                      <td className="w-10">{reqtrip.rejection_reason}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center align-items-center my-3">
            {count !== 0 ? (
              <Pagination
                activePage={activePage}
                itemsCountPerPage={pageSize}
                totalItemsCount={+count}
                pageRangeDisplayed={5}
                onChange={(e) => handlePageChange(e)}
                firstPageText={"First"}
                lastPageText={"Last"}
                prevPageText={arrowLeft}
                nextPageText={arrowRight}
                activeClass={classes.active}
              />
            ) : null}
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
        </div>
      )}
    </Fragment>
  );
});

export default withRouter(RejectionRequests);
