import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';

class Map extends React.Component<any, any> {
  state={
    defaultCen: {lat:31,lng:30},
  }
  render() {
    this.state.defaultCen = this.props.coordinates;
    return (
      this.state.defaultCen?<GoogleMap defaultZoom={15} defaultCenter={this.state.defaultCen} center={this.state.defaultCen}>
        <Marker
          draggable={true}
          onDragEnd={(e:any) => {
            let newLat: any = e?.latLng?.lat(),
              newLng: any = e?.latLng?.lng();
            // console.log(newLat, newLng);
            let coords = {
              lat: newLat,
              lng: newLng, 
            };
            this.props.getChangeLocation(coords);
            this.setState({defaultCen:coords})
          }}
          position={this.props.coordinates}
        />
      </GoogleMap>:null
    );
  }
}

export default withScriptjs(withGoogleMap(Map));
