import React, { Dispatch, Fragment, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { IStateType } from '../../../store/models/root.interface';
import * as actions from '../../../store/actions/index.actions';
import SweetAlert from 'react-bootstrap-sweetalert';
const TripFinished: React.FC = memo((props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const trip: any = useSelector(
    (state: IStateType) => state.getdrivertrip.trip
  );
  let IsSuccess = useSelector(
    (state: IStateType) => state.driverendtrip.data.status
  );
  const driver_id: any = useSelector(
    (state: IStateType) => state.auth.driverId
  );
  useEffect(() => {
    dispatch(actions.fetchDriverTrip({ driver_id }));
  }, [dispatch]);
  // const onConfirm = function (e: any) {
  //   e.preventDefault();
  //   const data = {
  //     trip_id: trip.id,
  //   };
  //   dispatch(actions.driverendtrip(data));
  // };
  useEffect(() => {
    if (IsSuccess == 'inactive') {
      IsSuccess = '';
      props.history.push('/');
    }
  }, [IsSuccess]);
  const errorMsg = useSelector(
    (state: IStateType) => state.driverendtrip.error
  );
  const onCancel = () => {
    dispatch(actions.clearMsgDriverEndTrip());
  };

  let forError = null;
  if (errorMsg) {
    forError = (
      <SweetAlert
        danger
        title='Warning'
        onConfirm={onCancel}
        onCancel={onCancel}
      >
        {errorMsg}
      </SweetAlert>
    );
  }

  const [showWarning, setShowWarning] = useState<boolean>(false);
  let warning = null;
  if (showWarning) {
    warning = (
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, i'm sure"
        confirmBtnBsStyle='success'
        title='Are you sure?'
        onConfirm={() => onConfirmHandler()}
        onCancel={() => setShowWarning(false)}
        focusCancelBtn
      >
        You will not be back to this trip
      </SweetAlert>
    );
  }
  const onConfirmHandler = () => {
    setShowWarning(false);
    const data = {
      trip_id: trip.id,
    };
    dispatch(actions.driverendtrip(data));
  };
  return (
    <Fragment>
      <div className='container d-flex flex-column justify-content-center align-items-center'>
        {warning}
        <div className='col-12'>
          <h3 className='text-white font-weight-bold bg-dark bg-gradient p-4 rounded text-center'>
            You Delievered All Passengers! Trip Finished.Go Back To School.
          </h3>
          <form>
            {forError}
            <div
              onClick={() => setShowWarning(true)}
              className='btn btn-block btn-warning text-white font-weight-bold p-3'
            >
              Confirm
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
});

export default withRouter(TripFinished);
