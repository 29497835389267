import { IActionBase, ITrips } from '../../models/root.interface';
import * as actionTypes from '../../actions/actionTypes';

import { updateObject } from '../../utility';

const initialState: any = {
  data:null,
  students: null,
  count: 0,
  loadingStudents: null,
  clusterLoading:null,
  error: null,
  msg: null,
};

const fetchStudentsStart = (state: any, action: IActionBase) => {
  return updateObject(state, { error: null, loadingStudents: true });
};

const fetchStudentsSuccess = (
  state: any = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    students: action.students,
    count: action.count,
    loadingStudents: false,
    error: null,
  });
};

const fetchStudentsFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingStudents: false,
  });
};


// map all students
const fetchMapAllStudentsStart = (state: any, action: IActionBase) => {
  return updateObject(state, { error: null, clusterLoading: true });
};

const fetchMapAllStudentsSuccess = (
  state: any = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    data:action.data,
    clusterLoading: false,
    error: null,
  });
};

const fetchMapAllStudentsFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    clusterLoading: false,
  });
};
/////////////////////////////////////////
//  for admin assign trip to student
const assignTripStudentStart = (state: any, action: IActionBase) => {
  return updateObject(state, { error: null, loadingStudents: true });
};

const assignTripStudentSuccess = (
  state: any = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    msg: action.msg,
    loadingStudents: false,
    error: null,
  });
};

const assignTripStudentFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingStudents: false,
  });
};

const clearMsgAllStudents = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: null,
    loadingStudents: false,
    msg: null,
  });
};

const assignedtrips = (state: ITrips = initialState, action: IActionBase) => {
  switch (action.type) {
    case actionTypes.FETCH_ALL_STUDENTS_START:
      return fetchStudentsStart(state, action);
    case actionTypes.FETCH_ALL_STUDENTS_SUCCESS:
      return fetchStudentsSuccess(state, action);
    case actionTypes.FETCH_ALL_STUDENTS_FAIL:
      return fetchStudentsFail(state, action);

    case actionTypes.ASSIGN_TRIP_STUDENT_START:
      return assignTripStudentStart(state, action);
    case actionTypes.ASSIGN_TRIP_STUDENT_SUCCESS:
      return assignTripStudentSuccess(state, action);
    case actionTypes.ASSIGN_TRIP_STUDENT_FAIL:
      return assignTripStudentFail(state, action);

    case actionTypes.CLEAR_MSG_ALL_STUDENTS:
      return clearMsgAllStudents(state, action);

      case actionTypes.FETCH_MAP_ALL_STUDENTS_START:
        return fetchMapAllStudentsStart(state, action);
      case actionTypes.FETCH_MAP_ALL_STUDENTS_SUCCESS:
        return fetchMapAllStudentsSuccess(state, action);
      case actionTypes.FETCH_MAP_ALL_STUDENTS_FAIL:
        return fetchMapAllStudentsFail(state, action);

    default:
      return state;
  }
};
export default assignedtrips;
