import * as actionTypes from '../actionTypes';
import axios from 'axios';

//Fetch AREAS
export const fetchTripStart = () => {
  return {
    type: actionTypes.FETCH_DRIVERTRIP_START,
  };
};
export const fetchTripSuccess = (trip: any) => {
  return {
    type: actionTypes.FETCH_DRIVERTRIP_SUCCESS,
    trip,
  };
};
export const fetchTripFail = (error: any) => {
  return {
    type: actionTypes.FETCH_DRIVERTRIP_FAIL,
    error,
  };
};
export const clearMsgDriverGetTrip = () => {
  return {
    type: actionTypes.CLEAR_MSG_DRIVERGETTRIP,
  };
};
export const fetchDriverTrip = (data: any) => {
  return (dispatch: any) => {
    dispatch(fetchTripStart());

    axios
      .post(`driver/gettrip.php`, data)
      .then((res) => {
        console.log(res);
        // if(res.data.data.trip)
        dispatch(fetchTripSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fetchTripFail(err.response.data.msg));
        } else {
          dispatch(fetchTripFail('Internet Connection Failed'));
        }
      });
  };
};
