// auth and login
export const AUTH_START: string = "AUTH_START";
export const AUTH_SUCCESS: string = "AUTH_SUCCESS";
export const AUTH_FAIL: string = "AUTH_FAIL";
export const AUTH_LOGOUT: string = "AUTH_LOGOUT";
export const AUTH_ERROR: string = "AUTH_ERROR";
export const FETCH_LastRequest_SUCCESS: string = "FETCH_LastRequest_SUCCESS";
// change Language
export const CHANGE_LANGUAGE: string = "CHANGE_LANGUAGE";

/////////////// for parent
// for assign trip
export const ASSIGN_TRIP: string = "ASSIGN_TRIP";
export const GET_CHILDREN: string = "GET_CHILDREN";
export const GET_ADDRESS: string = "GET_ADDRESS";
export const GET_COORDS: string = "GET_COORDS";
export const GET_INSTRUCTION: string = "GET_INSTRUCTION";
export const FETCH_AREA_START: string = "FETCH_AREA_START";
export const FETCH_AREA_SUCCESS: string = "FETCH_AREA_SUCCESS";
export const FETCH_AREA_FAIL: string = "FETCH_AREA_FAIL";

export const ASSIGN_TRIP_START: string = "ASSIGN_TRIP_START";
export const ASSIGN_TRIP_SUCCESS: string = "ASSIGN_TRIP_SUCCESS";
export const ASSIGN_TRIP_FAIL: string = "ASSIGN_TRIP_FAIL";

// fetch student
export const FETCH_STUDENTS_START: string = "FETCH_STUDENTS_START";
export const FETCH_STUDENTS_SUCCESS: string = "FETCH_STUDENTS_SUCCESS";
export const FETCH_STUDENTS_FAIL: string = "FETCH_STUDENTS_FAIL";
// SAVE Token
export const SAVE_TOKEN_START: string = "SAVE_TOKEN_START";
export const SAVE_TOKEN_SUCCESS: string = "SAVE_TOKEN_SUCCESS";
export const SAVE_TOKEN_FAIL: string = "SAVE_TOKEN_FAIL";

// for parent trackStuent trip
export const TRACK_STUDENTS_START: string = "TRACK_STUDENTS_START";
export const TRACK_STUDENTS_SUCCESS: string = "TRACK_STUDENTS_SUCCESS";
export const TRACK_STUDENTS_FAIL: string = "TRACK_STUDENTS_FAIL";

//admin

// admin add bus
export const FETCH_BUSES_START: string = "FETCH_BUSES_START";
export const FETCH_BUSES_SUCCESS: string = "FETCH_BUSES_SUCCESS";
export const FETCH_BUSES_FAIL: string = "FETCH_BUSES_FAIL";

export const CREATE_BUS_START: string = "CREATE_BUS_START";
export const CREATE_BUS_SUCCESS: string = "CREATE_BUS_SUCCESS";
export const CREATE_BUS_FAIL: string = "CREATE_BUS_FAIL";

export const EDIT_BUS_START: string = "EDIT_BUS_START";
export const EDIT_BUS_SUCCESS: string = "EDIT_BUS_SUCCESS";
export const EDIT_BUS_FAIL: string = "EDIT_BUS_FAIL";

export const CLEAR_MSG_BUS: string = "CLEAR_MSG_BUS";

// #### for Locations ####
export const CLEAR_MSG_ALL_LOCATIONS: string = "CLEAR_MSG_ALL_LOCATIONS";
export const FETCH_ALL_LOCATIONS_START: string = "FETCH_ALL_LOCATIONS_START";
export const FETCH_ALL_LOCATIONS_SUCCESS: string =
  "FETCH_ALL_LOCATIONS_SUCCESS";
export const FETCH_ALL_LOCATIONS_FAIL: string = "FETCH_ALL_LOCATIONS_FAIL";

export const ADD_LOCATION_START: string = "ADD_LOCATION_START";
export const ADD_LOCATION_SUCCESS: string = "ADD_LOCATION_SUCCESS";
export const ADD_LOCATION_FAIL: string = "ADD_LOCATION_FAIL";

export const DELETE_LOCATION_START: string = "DELETE_LOCATION_START";
export const DELETE_LOCATION_SUCCESS: string = "DELETE_LOCATION_SUCCESS";
export const DELETE_LOCATION_FAIL: string = "DELETE_LOCATION_FAIL";

export const EDIT_LOCATION_START: string = "EDIT_LOCATION_START";
export const EDIT_LOCATION_SUCCESS: string = "EDIT_LOCATION_SUCCESS";
export const EDIT_LOCATION_FAIL: string = "EDIT_LOCATION_FAIL";

//admin manage trip
export const FETCH_TRIPS_START: string = "FETCH_TRIPS_START";
export const FETCH_TRIPS_SUCCESS: string = "FETCH_TRIPS_SUCCESS";
export const FETCH_TRIPS_FAIL: string = "FETCH_TRIPS_FAIL";
//admin manage track trip
export const FETCH_TRAKED_TRIP_START: string = "FETCH_TRAKED_TRIP_START";
export const FETCH_TRAKED_TRIP_SUCCESS: string = "FETCH_TRAKED_TRIP_SUCCESS";
export const FETCH_TRAKED_TRIP_FAIL: string = "FETCH_TRAKED_TRIP_FAIL";

// un assigned trip
export const UNASSIGNED_TRIP_START: string = "UNASSIGNED_TRIP_START";
export const UNASSIGNED_TRIP_SUCCESS: string = "UNASSIGNED_TRIP_SUCCESS";
export const UNASSIGNED_TRIP_FAIL: string = "UNASSIGNED_TRIP_FAIL";

//admin req trip
export const FETCH_REQTRIPS_START: string = "FETCH_REQTRIPS_START";
export const FETCH_REQTRIPS_SUCCESS: string = "FETCH_REQTRIPS_SUCCESS";
export const FETCH_REQTRIPS_FAIL: string = "FETCH_REQTRIPS_FAIL";

//admin req trip
export const FETCH_TRASHEDTRIPS_START: string = "FETCH_TRASHEDTRIPS_START";
export const FETCH_TRASHEDTRIPS_SUCCESS: string = "FETCH_TRASHEDTRIPS_SUCCESS";
export const FETCH_TRASHEDTRIPS_FAIL: string = "FETCH_TRASHEDTRIPS_FAIL";
export const CLEAR_MSG_TRASHEDTRIPS: string = "CLEAR_MSG_TRASHEDTRIPS";

//admin assgind trips
export const FETCH_ASSIGNTRIPS_START: string = "FETCH_ASSIGNTRIPS_START";
export const FETCH_ASSIGNTRIPS_SUCCESS: string = "FETCH_ASSIGNTRIPS_SUCCESS";
export const FETCH_ASSIGNTRIPS_FAIL: string = "FETCH_ASSIGNTRIPS_FAIL";
//admin assgined trip
export const FETCH_ASSIGNTRIP_START: string = "FETCH_ASSIGNTRIP_START";
export const FETCH_ASSIGNTRIP_SUCCESS: string = "FETCH_ASSIGNTRIP_SUCCESS";
export const FETCH_ASSIGNTRIP_FAIL: string = "FETCH_ASSIGNTRIP_FAIL";
//admin delete assgined trip
export const DELETE_ASSIGNTRIP_START: string = "DELETE_ASSIGNTRIP_START";
export const DELETE_ASSIGNTRIP_SUCCESS: string = "DELETE_ASSIGNTRIP_SUCCESS";
export const DELETE_ASSIGNTRIP_FAIL: string = "DELETE_ASSIGNTRIP_FAIL";

//admin suspend trip
export const SUSPEND_TRIP_START: string = "SUSPEND_TRIP_START";
export const SUSPEND_TRIP_SUCCESS: string = "SUSPEND_TRIP_SUCCESS";
export const SUSPEND_TRIP_FAIL: string = "SUSPEND_TRIP_FAIL";

//admin unsuspend trip
export const UNSUSPEND_TRIP_START: string = "UNSUSPEND_TRIP_START";
export const UNSUSPEND_TRIP_SUCCESS: string = "UNSUSPEND_TRIP_SUCCESS";
export const UNSUSPEND_TRIP_FAIL: string = "UNSUSPEND_TRIP_FAIL";

//admin requested trip
export const FETCH_REQUESTEDTRIP_START: string = "FETCH_REQUESTEDTRIP_START";
export const FETCH_REQUESTEDTRIP_SUCCESS: string =
  "FETCH_REQUESTEDTRIP_SUCCESS";
export const FETCH_REQUESTEDTRIP_FAIL: string = "FETCH_REQUESTEDTRIP_FAIL";
export const CLEAR_MSG_AssginTrip: string = "CLEAR_MSG_AssginTrip";
//admin CONFIRM trip
export const CONFIRM_TRIP_START: string = "CONFIRM_TRIP_START";
export const CONFIRM_TRIP_SUCCESS: string = "CONFIRM_TRIP_SUCCESS";
export const CONFIRM_TRIP_FAIL: string = "CONFIRM_TRIP_FAIL";
export const CLEAR_MSG_REQTRIPS: string = "CLEAR_MSG_REQTRIPS";
//admin FAST trip
export const FAST_ASSTRIP_START: string = "FAST_ASSTRIP_START";
export const FAST_ASSTRIP_SUCCESS: string = "FAST_ASSTRIP_SUCCESS";
export const FAST_ASSTRIP_FAIL: string = "FAST_ASSTRIP_FAIL";

export const TRASHED_FAST_ASSTRIP_START: string = "TRASHED_FAST_ASSTRIP_START";
export const TRASHED_FAST_ASSTRIP_SUCCESS: string =
  "TRASHED_FAST_ASSTRIP_SUCCESS";
export const TRASHED_FAST_ASSTRIP_FAIL: string = "TRASHED_FAST_ASSTRIP_FAIL";
//admin Reject requested trip
export const REJECT_REQ_TRIP_START: string = " REJECT_REQ_TRIP_START";
export const REJECT_REQ_TRIP_SUCCESS: string = " REJECT_REQ_TRIP_SUCCESS";
export const REJECT_REQ_TRIP_FAIL: string = "  REJECT_REQ_TRIP_FAIL";

//admin Fetch Reject requested trips
export const FETCH_REJECTED_REQ_TRIPS_START: string =
  " FETCH_REJECTED_REQ_TRIPS_START";
export const FETCH_REJECTED_REQ_TRIPS_SUCCESS: string =
  " FETCH_REJECTED_REQ_TRIPS_SUCCESS";
export const FETCH_REJECTED_REQ_TRIPS_FAIL: string =
  "  FETCH_REJECTED_REQ_TRIPS_FAIL";

//admin update assign trip
export const UPDATEASS_TRIP_START: string = "UPDATEASS_TRIP_START";
export const UPDATEASS_TRIP_SUCCESS: string = "UPDATEASS_TRIP_SUCCESS";
export const UPDATEASS_TRIP_FAIL: string = "UPDATEASS_TRIP_FAIL";

//admin get trip
export const FETCH_TRIP_START: string = "FETCH_TRIP_START";
export const FETCH_TRIP_SUCCESS: string = "FETCH_TRIP_SUCCESS";
export const FETCH_TRIP_FAIL: string = "FETCH_TRIP_FAIL";

//admin set points
export const SET_POITNS_START: string = "SET_POITNS_START";
export const SET_POITNS_SUCCESS: string = "SET_POITNS_SUCCESS";
export const SET_POITNS_FAIL: string = "SET_POITNS_FAIL";

//admin Update trip
export const UPDATE_TRIP_START: string = "UPDATE_TRIP_START";
export const UPDATE_TRIP_SUCCESS: string = "UPDATE_TRIP_SUCCESS";
export const UPDATE_TRIP_FAIL: string = "UPDATE_TRIP_FAIL";
export const CLEAR_MSG_UPDATETRIP: string = "CLEAR_MSG_UPDATETRIP";

// admin all students
export const FETCH_ALL_STUDENTS_START: string = "FETCH_ALL_STUDENTS_START";
export const FETCH_ALL_STUDENTS_SUCCESS: string = "FETCH_ALL_STUDENTS_SUCCESS";
export const FETCH_ALL_STUDENTS_FAIL: string = "FETCH_ALL_STUDENTS_FAIL";
export const CLEAR_MSG_ALL_STUDENTS: string = "CLEAR_MSG_ALL_STUDENTS";
// admin all students -> assign trip to student
export const ASSIGN_TRIP_STUDENT_START: string = "ASSIGN_TRIP_STUDENT_START";
export const ASSIGN_TRIP_STUDENT_SUCCESS: string =
  "ASSIGN_TRIP_STUDENT_SUCCESS";
export const ASSIGN_TRIP_STUDENT_FAIL: string = "ASSIGN_TRIP_STUDENT_FAIL";

// admin all employees
export const FETCH_ALL_EMPLOYEES_START: string = "FETCH_ALL_EMPLOYEES_START";
export const FETCH_ALL_EMPLOYEES_SUCCESS: string =
  "FETCH_ALL_EMPLOYEES_SUCCESS";
export const FETCH_ALL_EMPLOYEES_FAIL: string = "FETCH_ALL_EMPLOYEES_FAIL";
export const CLEAR_MSG_ALL_EMPLOYEES: string = "CLEAR_MSG_ALL_EMPLOYEES";
// admin all REQUESTS
export const FETCH_ALL_REQUESTS_START: string = "FETCH_ALL_REQUESTS_START";
export const FETCH_ALL_REQUESTS_SUCCESS: string = "FETCH_ALL_REQUESTS_SUCCESS";
export const FETCH_ALL_REQUESTS_FAIL: string = "FETCH_ALL_REQUESTS_FAIL";
export const CLEAR_MSG_ALL_REQUESTS: string = "CLEAR_MSG_ALL_REQUESTS";

export const ALLOW_ENTRY_START: string = "ALLOW_ENTRY_START";
export const ALLOW_ENTRY_SUCCESS: string = "ALLOW_ENTRY_SUCCESS";
export const ALLOW_ENTRY_FAIL: string = "ALLOW_ENTRY_FAIL";
export const CLEAR_MSG_ALLOW_ENTRY: string = "CLEAR_MSG_ALL_REQUESTS";

// admin all employees -> assign trip to employee
export const ASSIGN_TRIP_EMPLOYEE_START: string = "ASSIGN_TRIP_EMPLOYEE_START";
export const ASSIGN_TRIP_EMPLOYEE_SUCCESS: string =
  "ASSIGN_TRIP_EMPLOYEE_SUCCESS";
export const ASSIGN_TRIP_EMPLOYEE_FAIL: string = "ASSIGN_TRIP_EMPLOYEE_FAIL";
///////admin suspend,edit,view,delte employee's trip///////////////////////
export const CLEAR_MSG_AssginEmployeeTrip: string =
  "CLEAR_MSG_AssginEmployeeTrip";
export const FETCH_ASSIGNEMPLOYEETRIP_START: string =
  "FETCH_ASSIGNEMPLOYEETRIP_START";
export const FETCH_ASSIGNEMPLOYEETRIP_SUCCESS: string =
  "FETCH_ASSIGNEMPLOYEETRIP_SUCCESS";
export const FETCH_ASSIGNEMPLOYEETRIP_FAIL: string =
  "FETCH_ASSIGNEMPLOYEETRIP_FAIL";
export const UPDATEASS_EMPLOYEE_TRIP_START: string =
  "UPDATEASS_EMPLOYEE_TRIP_START";
export const UPDATEASS_EMPLOYEE_TRIP_SUCCESS: string =
  "UPDATEASS_EMPLOYEE_TRIP_SUCCESS";
export const UPDATEASS_EMPLOYEE_TRIP_FAIL: string =
  "UPDATEASS_EMPLOYEE_TRIP_FAIL";
export const FETCH_EMPLOYEEASSIGNTRIPS_START: string =
  "FETCH_EMPLOYEEASSIGNTRIPS_START";
export const FETCH_EMPLOYEEASSIGNTRIPS_SUCCESS: string =
  "FETCH_EMPLOYEEASSIGNTRIPS_SUCCESS";
export const FETCH_EMPLOYEEASSIGNTRIPS_FAIL: string =
  "FETCH_EMPLOYEEASSIGNTRIPS_FAIL";
export const SUSPEND_EMPLOYEE_TRIP_START: string =
  "SUSPEND_EMPLOYEE_TRIP_START";
export const SUSPEND_EMPLOYEE_TRIP_SUCCESS: string =
  "SUSPEND_EMPLOYEE_TRIP_SUCCESS";
export const SUSPEND_EMPLOYEE_TRIP_FAIL: string = "SUSPEND_EMPLOYEE_TRIP_FAIL";
export const UNSUSPEND_EMPLOYEE_TRIP_START: string =
  "UNSUSPEND_EMPLOYEE_TRIP_START";
export const UNSUSPEND_EMPLOYEE_TRIP_SUCCESS: string =
  "UNSUSPEND_EMPLOYEE_TRIP_SUCCESS";
export const UNSUSPEND_EMPLOYEE_TRIP_FAIL: string =
  "UNSUSPEND_EMPLOYEE_TRIP_FAIL";
export const DELETE_EMPLOYEEASSIGNTRIP_START: string =
  "DELETE_EMPLOYEEASSIGNTRIP_START";
export const DELETE_EMPLOYEEASSIGNTRIP_SUCCESS: string =
  "DELETE_EMPLOYEEASSIGNTRIP_SUCCESS";
export const DELETE_EMPLOYEEASSIGNTRIP_FAIL: string =
  "DELETE_EMPLOYEEASSIGNTRIP_FAIL";
///////////////////////////

//admin add trip
export const FETCH_DRIVER_START: string = "FETCH_DRIVER_START";
export const FETCH_DRIVER_SUCCESS: string = "FETCH_DRIVER_SUCCESS";
export const FETCH_DRIVER_FAIL: string = "FETCH_DRIVER_FAIL";

export const FETCH_BUS_START: string = "FETCH_BUS_START";
export const FETCH_BUS_SUCCESS: string = "FETCH_BUS_SUCCESS";
export const FETCH_BUS_FAIL: string = "FETCH_BUS_FAIL";

export const FETCH_REQ_TRIPS_START: string = "FETCH_REQ_TRIPS_START";
export const FETCH_REQ_TRIPS_SUCCESS: string = "FETCH_REQ_TRIPS_SUCCESS";
export const FETCH_REQ_TRIPS_FAIL: string = "FETCH_REQ_TRIPS_FAIL";

export const FETCH_EMPLOYEES_START: string = "FETCH_EMPLOYEES_START";
export const FETCH_EMPLOYEES_SUCCESS: string = "FETCH_EMPLOYEES_SUCCESS";
export const FETCH_EMPLOYEES_FAIL: string = "FETCH_EMPLOYEES_FAIL";

export const ADD_TRIP_START: string = "ADD_TRIP_START";
export const ADD_TRIP_SUCCESS: string = "ADD_TRIP_SUCCESS";
export const ADD_TRIP_FAIL: string = "ADD_TRIP_FAIL";
export const CLEAR_MSG_ADDTRIP: string = "CLEAR_MSG_ADDTRIP";

//Driver
export const FETCH_DRIVERTRIP_START: string = "FETCH_DRIVERTRIP_START";
export const FETCH_DRIVERTRIP_SUCCESS: string = "FETCH_DRIVERTRIP_SUCCESS";
export const FETCH_DRIVERTRIP_FAIL: string = "FETCH_DRIVERTRIP_FAIL";
export const CLEAR_MSG_DRIVERGETTRIP: string = "CLEAR_MSG_DRIVERGETTRIP";

export const FETCH_DRIVER_START_TRIP: string = "FETCH_DRIVER_START_TRIP";
export const FETCH_DRIVERTRIP_START_SUCCESS: string =
  "FETCH_DRIVERTRIP_START_SUCCESS";
export const FETCH_DRIVERTRIP_START_FAIL: string =
  "FETCH_DRIVERTRIP_START_FAIL";
export const CLEAR_MSG_DRIVERSTARTTRIP: string = "CLEAR_MSG_DRIVERSTARTTRIP";

export const SEND_DRIVER_START_TRIP_DETAILS: string = "SEND_DRIVER_START_TRIP";
export const SEND_DRIVERTRIP_TRIP_DETAILS_SUCCESS: string =
  "SEND_DRIVERTRIP_TRIP_DETAILS_SUCCESS";
export const SEND_DRIVERTRIP_TRIP_DETAILS_FAIL: string =
  "SEND_DRIVERTRIP_TRIP_DETAILS_FAIL";
export const CLEAR_MSG_DRIVERTRIPDETAILS: string =
  "CLEAR_MSG_DRIVERTRIPDETAILS";
export const CHANGE_YES_TO_NO: string = "CHANGE_YES_TO_NO";

export const SEND_DRIVER_END_TRIP: string = "SEND_DRIVER_END_TRIP";
export const SEND_DRIVER_ENDTRIP_SUCCESS: string =
  "SEND_DRIVER_ENDTRIP_SUCCESS";
export const FETCH_DRIVER_ENDTRIP_FAIL: string = "FETCH_DRIVER_ENDTRIP_FAIL";
export const CLEAR_MSG_DRIVERENDTRIP: string = "CLEAR_MSG_DRIVERENDTRIP";

export const SEND_DRIVER_REQUEST_GATE: string = "SEND_DRIVER_REQUEST_GATE";
export const SEND_DRIVER_REQUEST_GATE_SUCCESS: string =
  "SEND_DRIVER_REQUEST_GATE_SUCCESS";
export const FETCH_DRIVER_REQUEST_GATE_FAIL: string =
  "FETCH_DRIVER_REQUEST_GATE_FAIL";
export const CLEAR_MSG_DRIVER_REQUEST_GATE: string =
  "CLEAR_MSG_DRIVER_REQUEST_GATE";

export const SEND_DRIVER_GATE_ENTRY_STATUS: string =
  "SEND_DRIVER_GATE_ENTRY_STATUS";
export const SEND_DRIVER_GATE_ENTRY_STATUS_SUCCESS: string =
  "SEND_DRIVER_GATE_ENTRY_STATUS_SUCCESS";
export const SEND_DRIVER_GATE_ENTRY_STATUS_FAIL: string =
  "SEND_DRIVER_GATE_ENTRY_STATUS_FAIL";
export const CLEAR_MSG_DRIVERGATEENTRYSTATUS: string =
  "CLEAR_MSG_DRIVERGATEENTRYSTATUS";

export const SKIP_CHILD_START: string = "SKIP_CHILD_START";
export const SKIP_CHILD_SUCCESS: string = "SKIP_CHILD_SUCCESS";
export const SKIP_CHILD_FAIL: string = "SKIP_CHILD_FAIL";

export const PICKED_CHILD_START: string = "PICKED_CHILD_START";
export const PICKED_CHILD_SUCCESS: string = "PICKED_CHILD_SUCCESS";
export const PICKED_CHILD_FAIL: string = "PICKED_CHILD_FAIL";

export const END_TRIP_EMERGANCY_START: string = "END_TRIP_EMERGANCY_START";
export const END_TRIP_EMERGANCY_SUCCESS: string = "END_TRIP_EMERGANCY_SUCCESS";
export const END_TRIP_EMERGANCY_FAIL: string = "END_TRIP_EMERGANCY_FAIL";
export const CLEAR_END_TRIP_EMERGANCY: string = "CLEAR_END_TRIP_EMERGANCY";

//draw path:

export const DRAW_PATH_START: string = "DRAW_PATH_START";
export const DRAW_PATH_SUCCESS: string = "DRAW_PATH_SUCCESS";
export const DRAW_PATH_FAIL: string = "DRAW_PATH_FAIL";

// MAP ALL STUDENTS

export const FETCH_MAP_ALL_STUDENTS_START: string =
  "FETCH_MAP_ALL_STUDENTS_START";
export const FETCH_MAP_ALL_STUDENTS_SUCCESS: string =
  "FETCH_MAP_ALL_STUDENTS_SUCCESS";
export const FETCH_MAP_ALL_STUDENTS_FAIL: string =
  "FETCH_MAP_ALL_STUDENTS_FAIL";
