import { combineReducers, Reducer } from 'redux';
import { UPDATE_CURRENT_PATH } from '../actions/root.actions';
import {
  IRootStateType,
  IActionBase,
  IStateType,
} from '../models/root.interface';
import notificationReducer from './notification.reducer';
import authReducer from './auth.reducer';
import trackTripReducer from './trackTrip.reducer';
import drawPathReducer from './drawPath.reducer';
import assigntripReducer from './parent/assigntrip.reducer';
import managetripsReducer from './admin/managetrips.reducer';
import LocationsReducer from './admin/Locations.reducer';
import requestedtripsReducer from './admin/requestedtrips.reducer';
import trashedtripsReducer from './admin/trashedtrips.reducer';
import assignedtripsReducer from './admin/assignedtrips.reducer';
import forAddTripReducer from './admin/forAddTrip.reducer';
import forForBusReducer from './admin/forBus.reducer';
import AllStudentsReducer from './admin/AllStudents.reducer';
import getdrivertrip from './driver/getdriverTrip.reducer';
import AllEmployeesReducer from './admin/AllEmployees.reducer';
import assignedEmployeetripsReducer from './admin/assignedemployeetrips.reducer';
import driverstarttrip from './driver/driverstartrip.reducer';
import driverrequestgate from './driver/driverrequestgate.reducer';
import drivergateentry from './driver/drivergatentry.reducer';
import driverendtrip from './driver/driverendtrip.reducer';
import drivertripdetails from './driver/drivertripdetails.reducer';
import AllowEntry from './admin/AllowEntry.reducer';
const initialState: IRootStateType = {
  page: { area: 'home', subArea: '' },
};

function rootReducer(
  state: IRootStateType = initialState,
  action: IActionBase
): IRootStateType {
  switch (action.type) {
    case UPDATE_CURRENT_PATH:
      return { ...state, page: { area: action.area, subArea: action.subArea } };
    default:
      return state;
  }
}

const rootReducers: Reducer<IStateType> = combineReducers({
  root: rootReducer,
  trackTrip: trackTripReducer,
  drawPath: drawPathReducer,
  notifications: notificationReducer,
  auth: authReducer,
  assigntrip: assigntripReducer,
  managetrips: managetripsReducer,
  locations: LocationsReducer,
  requestedtrips: requestedtripsReducer,
  trashedtrips: trashedtripsReducer,
  assignedtrips: assignedtripsReducer,
  addTrip: forAddTripReducer,
  forBus: forForBusReducer,
  getdrivertrip: getdrivertrip,
  driverstarttrip: driverstarttrip,
  driverrequestgate: driverrequestgate,
  drivergateentry: drivergateentry,
  driverendtrip: driverendtrip,
  drivertripdetails: drivertripdetails,
  AllStudents: AllStudentsReducer,
  AllEmployees: AllEmployeesReducer,
  assignedemployeetrips: assignedEmployeetripsReducer,
  AllowEntry: AllowEntry,
});

export default rootReducers;
