import axios from 'axios';

const setAuthToken = (token) => {
  if (token) {
    // console.log('jwt');
    axios.defaults.headers.common['jwt'] = token;
  } else {
    delete axios.defaults.headers.common['jwt'];
  }
};

export default setAuthToken;
