import React, { Fragment, Dispatch, useEffect, useState, useRef } from "react";
// import parent_select_location_sample from '../../../assets/parent_select_location_sample.png';
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index.actions";
import { IStateType } from "../../../store/models/root.interface";
import { Link, withRouter } from "react-router-dom";
import classes from "../../Pagination.module.css";
import Loader from "react-loader-spinner";
import Pagination from "react-js-pagination";
import SweetAlert from "react-bootstrap-sweetalert";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { Translate } from "../../../utils/Translate";

interface trip {
  id: string;

  direction: string;
  student_id: string;
  address: string;
  tripname: string;
  location_lat: string;
  location_lng: string;
  handing_instructions: string;
  collector_name: string;
  collector_id: string;
  parent_id: string;
  status: string;
  name: string;
  areaname: string;
  parentmobile: string;
  toschool?: number
  tohome?: number
}

const AssignedStudents: React.FC = (props: any) => {
  const count = useSelector((state: IStateType) => state.assignedtrips.count);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const handlePageChange = (pageNumber: any) => {
    // console.log(`active page is ${pageNumber}`);
    dispatch(actions.fetchAssignedTrips(pageNumber, pageSize, enteredFilter));
    setActivePage(pageNumber);
  };
  const dispatch: Dispatch<any> = useDispatch();
  const trips = useSelector((state: IStateType) => state.assignedtrips.trips);
  const loading = useSelector(
    (state: IStateType) => state.assignedtrips.loading
  );
  // useEffect(() => {
  //   dispatch(actions.fetchAssignedTrips(activePage, pageSize, enteredFilter));
  // }, [dispatch]);

  function editassigned(tripid: string) {
    props.history.push("/EditAssignedStudent?id=" + tripid);
  }

  const suspendHandler = (id: any) => {
    dispatch(actions.suspendTrip(id));
  };
  const unSuspendHandler = (id: any) => {
    dispatch(actions.unSuspendTrip(id));
  };
  const deleteAssignTripHandler = (id: any) => {
    // console.log(id);
    dispatch(actions.deleteAssignTrip(id));
  };

  let arrowLeft = <i className="fas fa-angle-double-left"></i>;
  let arrowRight = <i className="fas fa-angle-double-right"></i>;

  const onSuccessCancel = () => {
    dispatch(actions.clearMsgAssginTrip());
  };
  const t = new Translate()
  const msg = useSelector((state: IStateType) => state.assignedtrips.msg);
  const loadingSuspendTrip = useSelector(
    (state: IStateType) => state.assignedtrips.loadingSuspendTrip
  );
  let forSuccess = null;
  if (msg) {
    forSuccess = (
      <SweetAlert
        success
        title="Success"
        onConfirm={onSuccessCancel}
        onCancel={onSuccessCancel}
      >
        {msg}
      </SweetAlert>
    );
  }

  const inputSearchRef: any = useRef();
  const [enteredFilter, setEnteredFilter] = useState<string>("");
  useEffect(() => {
    $(document).ready(() => {

      const table: any = $('#datatable').DataTable();
    })
  })
  useEffect(() => {
    const timer = setTimeout(() => {
      if (enteredFilter === inputSearchRef.current.value) {
        // console.log(enteredFilter);
        dispatch(
          actions.fetchAssignedTrips(activePage, pageSize, enteredFilter)
        );
      }
    }, 500);

    return () => {
      clearTimeout(timer);
      setActivePage(1);
    };
  }, [enteredFilter, inputSearchRef]);
  return (
    <Fragment>
      <div className="text-center">
        <h2
          className="text-white"
          style={{
            textShadow:
              "0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)",
            fontSize: "2rem",
          }}
        >
          {t.translate("Modify students's Trips")}
        </h2>
      </div>
      <div className="input-group mt-3 mb-3 d-none">
        <input
          type="text"
          className="form-control"
          ref={inputSearchRef}
          onChange={(e) => setEnteredFilter(e.target.value)}
        />
        <div className="input-group-append">
          <button className="btn btn-primary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>

      {loading === false && !loadingSuspendTrip ? (
        <div className="justify-content-center align-items-center">
          {forSuccess}
          <div className="table-responsive bg-light">
            <table
              id="datatable"
              className="table-hover table-bordered text-center"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th scope="col">{t.translate("IVY ID")}</th>
                  <th scope="col">{t.translate("Student Name")} </th>
                  <th scope="col">{t.translate("Area")}</th>
                  <th scope="col">{t.translate("Location")}</th>
                  <th scope="col">{t.translate("To School")}</th>
                  <th scope="col">{t.translate("To Home")}</th>
                  <th scope="col">{t.translate("Assigned Trip")}</th>
                  <th scope="col">{t.translate("Status")}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {trips.map((trip: trip) => {
                  return (
                    <tr key={trip.id}>
                      <td className="w-20">{trip.student_id}</td>
                      <td className="w-20">{trip.name}</td>
                      <td className="w-20">{trip.areaname}</td>
                      <td className="w-20">
                        <p className="text-wrap text-break">{trip.address}</p>
                      </td>
                      <td className="w-10">{trip.toschool == 1 ? "YES" : "NO"}</td>
                      <td className="w-10">{trip.tohome == 1 ? "YES" : "NO"}</td>
                      <td className="w-10">{trip.tripname}</td>
                      <td className="w-10"> {trip.status} </td>
                      <td className="w-10">
                        <button
                          // to='/EditAssignedStudent'
                          onClick={() => editassigned(trip.id)}
                          className="btn btn-warning text-white font-weight-bold m-1"
                        >
                          <i className="fas fa-sliders-h"></i>
                        </button>
                        <button
                          onClick={() => suspendHandler(trip.id)}
                          disabled={trip.status === "suspend" ? true : false}
                          className="btn btn-success text-white font-weight-bold m-1"
                        >
                          {t.translate("Suspend")}
                        </button>
                        <button
                          onClick={() => unSuspendHandler(trip.id)}
                          disabled={trip.status === "unsuspend" ? true : false}
                          className="btn btn-success text-white font-weight-bold m-1"
                        >
                          {t.translate("Un-suspend")}
                        </button>
                        <button
                          onClick={() => deleteAssignTripHandler(trip.id)}
                          className="btn btn-danger text-white font-weight-bold m-1"
                        >
                          {t.translate("Trash")}
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <div className="d-flex justify-content-center align-items-center my-3">
            {count !== 0 ? (
              <Pagination
                activePage={activePage}
                itemsCountPerPage={pageSize}
                totalItemsCount={+count}
                pageRangeDisplayed={5}
                onChange={(e) => handlePageChange(e)}
                firstPageText={"First"}
                lastPageText={"Last"}
                prevPageText={arrowLeft}
                nextPageText={arrowRight}
                activeClass={classes.active}
              />
            ) : null}
          </div> */}
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
        </div>
      )}
    </Fragment>
  );
};

export default withRouter(AssignedStudents);
