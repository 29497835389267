import React, {
  Fragment,
  Dispatch,
  useEffect,
  memo,
  useState,
  useRef,
} from "react";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index.actions";
import { IStateType } from "../../../store/models/root.interface";
import Loader from "react-loader-spinner";
import Pagination from "react-js-pagination";
import classes from "../../Pagination.module.css";
import { Translate } from "../../../utils/Translate";
// require("bootstrap/less/bootstrap.less");

interface trip {
  id: string;

  tripprice: string;
  time: string;
  direction: string;
  start_written_address: string;
  end_written_address: string;
  status: string;
  tripname: string;
  displayname: string;
  mobile: string;
  busnumber: string;
  capacity: string;
  areaname: string;
}
const TripsTable: React.FC = memo((props: any) => {
  let t = new Translate();
  const dispatch: Dispatch<any> = useDispatch();
  const areas = useSelector((state: IStateType) => state.locations.areas);
  const loading = useSelector(
    (state: IStateType) => state.locations.loadingAreas
  );
  const count = useSelector((state: IStateType) => state.locations.count);

  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const handlePageChange = (pageNumber: any) => {
    // console.log(`active page is ${pageNumber}`);
    dispatch(actions.fetchAllLocations(pageNumber, pageSize, enteredFilter));
    setActivePage(pageNumber);
  };

  let arrowLeft = <i className="fas fa-angle-double-left"></i>;
  let arrowRight = <i className="fas fa-angle-double-right"></i>;

  const inputSearchRef: any = useRef<HTMLInputElement>();
  const [enteredFilter, setEnteredFilter] = useState<string>("");
  useEffect(() => {
    const timer = setTimeout(() => {
      // console.log(enteredFilter);
      if (enteredFilter === inputSearchRef.current.value) {
        dispatch(
          actions.fetchAllLocations(activePage, pageSize, enteredFilter)
        );
      }
    }, 500);

    return () => {
      clearTimeout(timer);
      setActivePage(1);
    };
  }, [enteredFilter, inputSearchRef]);

  //   the new
  let [areaInput, setAreaInput] = useState<string>("");
  // console.log(areaInput)
  const addAreaHandler = (e: any) => {
    e.preventDefault();
    dispatch(actions.addArea({ areaname: areaInput }));
    setAreaInput("");
    let btn = document.getElementById("closedbtn")! as HTMLButtonElement;
    btn.click();
  };

  const deleteArea = (id: any) => {
    // console.log(id)
    dispatch(actions.deleteArea({ id }));
  };

  const openEditArea = (id: any) => {
    let btn = document.getElementById("openbtnEdit")! as HTMLButtonElement;
    btn.click();

    const area = areas.filter((area: any) => area.id === id);
    console.log(area[0].areaname);
    let input = document.getElementById("setArea2")! as HTMLInputElement;
    input.value = area[0].areaname;

    let inputId = document.getElementById("areaId")! as HTMLInputElement;
    inputId.value = id;
  };
  const editAreaHandler = (e: any) => {
    e.preventDefault();
    let input = document.getElementById("areaId")! as HTMLInputElement;
    const id = input.value;
    // console.log(id)
    dispatch(actions.editArea({ id: id, areaname: areaInput }));
    let btn = document.getElementById("closedbtnEdit")! as HTMLButtonElement;
    btn.click();
  };
  return (
    <Fragment>
      <div className="text-center">
        <h2
          className="text-white"
          style={{
            textShadow:
              "0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)",
            fontSize: "2rem",
          }}
        >
          {t.translate("Locations")}
        </h2>
      </div>
      <div className="row">
        <div className="col-sm-3 ">
          <button
            type="button"
            className="btn btn-warning"
            data-bs-toggle="modal"
            id="openbtn"
            data-bs-target="#areaModal"
          >
            {t.translate("Add Area")}
          </button>
          <button
            type="button"
            className="btn btn-warning "
            hidden
            data-bs-toggle="modal"
            id="openbtnEdit"
            data-bs-target="#areaEditModal"
          >
            {t.translate("Edit Area")}
          </button>
        </div>
        <div
          className="modal fade"
          id="areaModal"
          tabIndex={-1}
          aria-labelledby="areaModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content text-dark">
              <div className="modal-header">
                <h5 className="modal-title" id="areaModalLabel">
                  {t.translate("Set Area")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form className="row" onSubmit={addAreaHandler}>
                  <div className="col-sm-6 mb-3">
                    <label htmlFor="setArea" className="form-label text-dark">
                      {t.translate("Area")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="setArea"
                      aria-describedby="areaHelp"
                      onChange={(e) => setAreaInput(e.target.value)}
                    />
                    <div id="areaHelp" className="form-text ml-1">
                      {t.translate("Area must be specific.")}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      id="closedbtn"
                    >
                      {t.translate("Close")}
                    </button>
                    <button type="submit" className="btn btn-primary">
                      {t.translate("Save changes")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="areaEditModal"
          tabIndex={-1}
          aria-labelledby="areaEditModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content text-dark">
              <div className="modal-header">
                <h5 className="modal-title" id="areaEditModalLabel">
                  {t.translate("Set Area")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form className="row" onSubmit={editAreaHandler}>
                  <div className="col-sm-6 mb-3">
                    <label htmlFor="setArea2" className="form-label text-dark">
                      {t.translate("Area")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="setArea2"
                      aria-describedby="areaHelp"
                      onChange={(e) => setAreaInput(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      hidden
                      required
                      id="areaId"
                      aria-describedby="areaHelp"
                    />
                    <div id="areaHelp" className="form-text ml-1">
                      {t.translate("Area must be specific.")}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      id="closedbtnEdit"
                    >
                      {t.translate("Close")}
                    </button>
                    <button type="submit" className="btn btn-primary">
                      {t.translate("Save changes")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="input-group mt-3 mb-3">
        <input
          type="text"
          className="form-control"
          ref={inputSearchRef}
          onChange={(e) => setEnteredFilter(e.target.value)}
        />
        <div className="input-group-append">
          <button className="btn btn-primary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>

      {loading === false ? (
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive bg-light">
              <table
                className="table-hover table-bordered text-center"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>{t.translate("Area")}</th>
                    <th>{t.translate("Options")}</th>
                  </tr>
                </thead>
                <tbody>
                  {areas !== null && loading === false
                    ? areas.map((area: any) => (
                        <tr key={area.id}>
                          <td>{area.areaname}</td>
                          <td className="w-5">
                            <button
                              // to='/EditTrip'
                              onClick={() => openEditArea(area.id)}
                              className="btn btn-warning text-white font-weight-bold m-1"
                            >
                              <i className="fas fa-cog"></i>
                            </button>
                            <button
                              // to='/TrackTrip'
                              onClick={() => deleteArea(area.id)}
                              className="btn btn-danger text-white font-weight-bold m-1"
                            >
                              <i className="fas fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))
                    : areas}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center align-items-center my-3">
              {count !== 0 ? (
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={pageSize}
                  totalItemsCount={+count}
                  pageRangeDisplayed={5}
                  onChange={(e) => handlePageChange(e)}
                  firstPageText={"First"}
                  lastPageText={"Last"}
                  prevPageText={arrowLeft}
                  nextPageText={arrowRight}
                  activeClass={classes.active}
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
        </div>
      )}
    </Fragment>
  );
});

export default withRouter(TripsTable);
