import * as actionTypes from '../actionTypes';
import axios from 'axios';

export const fetchTripsStart = () => {
  return {
    type: actionTypes.FETCH_TRIPS_START,
  };
};
export const fetchTripsSuccess = (data: any) => {
  return {
    type: actionTypes.FETCH_TRIPS_SUCCESS,
    trips: data.trips,
    count: data.count,
  };
};
export const fetchTripsFail = (error: any) => {
  return {
    type: actionTypes.FETCH_TRIPS_FAIL,
    error,
  };
};

export const fetchTripStart = () => {
  return {
    type: actionTypes.FETCH_TRIP_START,
  };
};
export const fetchTripSuccess = (data: any) => {
  return {
    type: actionTypes.FETCH_TRIP_SUCCESS,
    trip: data,
  };
};
export const fetchTripFail = (error: any) => {
  return {
    type: actionTypes.FETCH_TRIP_FAIL,
    error,
  };
};
export const updateTripStart = () => {
  return {
    type: actionTypes.UPDATE_TRIP_START,
  };
};
export const updateTripSuccess = (msg: any) => {
  return {
    type: actionTypes.UPDATE_TRIP_SUCCESS,
    msg,
  };
};
export const updateTripFail = (error: any) => {
  return {
    type: actionTypes.UPDATE_TRIP_FAIL,
    error,
  };
};

export const fetchTrips = (pageN: any, pageSize: any) => {
  return (dispatch: any) => {
    dispatch(fetchTripsStart());

    let data = { pagenumber: pageN, pagesize: pageSize };
    axios
      .post(`admin/gettrips.php`, data)
      .then((res) => {
        dispatch(fetchTripsSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fetchTripsFail(err.response.data.msg));
        } else {
          dispatch(fetchTripsFail('Internet Connection Failed'));
        }
      });
  };
};

export const clearMsgUpdateTrip = () => {
  return {
    type: actionTypes.CLEAR_MSG_UPDATETRIP,
  };
};

export const fetchTrip = (tripid: string) => {
  return (dispatch: any) => {
    dispatch(fetchTripStart());

    axios
      .post(
        `admin/gettrip.php
        `,
        { id: tripid }
      )
      .then((res) => {
        console.log(res);
        dispatch(fetchTripSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fetchTripsFail(err.response.data.msg));
        } else {
          dispatch(fetchTripFail('Internet Connection Failed'));
        }
      });
  };
};

export const updateTrip = (data: any) => {
  return (dispatch: any) => {
    dispatch(updateTripStart());

    axios
      .post(`admin/updatetrip.php`, data)
      .then((res) => {
        console.log(res);
        dispatch(updateTripSuccess(res.data.msg));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(updateTripFail(err.response.data.msg));
        } else {
          dispatch(updateTripFail('Internet Connection Failed'));
        }
      });
  };
};
export const fastUpdateTrip = (data: any) => {
  return (dispatch: any) => {
    dispatch(updateTripStart());

    axios
      .post(`admin/fastupdatetrip.php`, data)
      .then((res) => {
        console.log(res);
        dispatch(updateTripSuccess(res.data.msg));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(updateTripFail(err.response.data.msg));
        } else {
          dispatch(updateTripFail('Internet Connection Failed'));
        }
      });
  };
};

// fot un assigned trips
export const unAssignedTripStart = () => {
  return {
    type: actionTypes.UNASSIGNED_TRIP_START,
  };
};
export const unAssignedTripSuccess = (msg: any) => {
  return {
    type: actionTypes.UNASSIGNED_TRIP_SUCCESS,
    msg,
  };
};
export const unAssignedTripFail = (error: any) => {
  return {
    type: actionTypes.UNASSIGNED_TRIP_FAIL,
    error,
  };
};

export const unAssignedTrip = (data: any) => {
  return (dispatch: any) => {
    dispatch(unAssignedTripStart());

    axios
      .post(`admin/unassigntrip.php`, data)
      .then((res) => {
        console.log(res);
        dispatch(unAssignedTripSuccess('Deleted'));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(unAssignedTripFail(err.response.data.data.msg));
        } else {
          dispatch(unAssignedTripFail('Internet Connection Failed'));
        }
      });
  };
};

// fot ADMIN SET POINTS IN EDIT TRIP
export const setPointsStart = () => {
  return {
    type: actionTypes.SET_POITNS_START,
  };
};
export const setPointsSuccess = (msg: any) => {
  return {
    type: actionTypes.SET_POITNS_SUCCESS,
    msg,
  };
};
export const setPointsFail = (error: any) => {
  return {
    type: actionTypes.SET_POITNS_FAIL,
    error,
  };
};

export const setPoints = (data: any) => {
  return (dispatch: any) => {
    dispatch(setPointsStart());

    axios
      .post(`admin/savepostions.php`, data)
      .then((res) => {
        console.log(res);
        // trip_id
        dispatch(fetchTrip(data.trip_id));
        dispatch(setPointsSuccess('successfully points saved'));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(setPointsFail(err.response.data.data.msg));
        } else {
          dispatch(setPointsFail('Internet Connection Failed'));
        }
      });
  };
};

// fot ADMIN GET ASSIGNED AND UN ASSIGN POINTS TRIP
export const fetchTrakedPointsStart = () => {
  return {
    type: actionTypes.FETCH_TRAKED_TRIP_START,
  };
};
export const fetchTrakedPointsSuccess = (data: any) => {
  return {
    type: actionTypes.FETCH_TRAKED_TRIP_SUCCESS,
    data,
  };
};
export const fetchTrakedPointsFail = (error: any) => {
  return {
    type: actionTypes.FETCH_TRAKED_TRIP_FAIL,
    error,
  };
};

export const fetchTrakedPoints = (data: any) => {
  return (dispatch: any) => {
    dispatch(fetchTrakedPointsStart());
    // {"trip_id" :41 }
    axios
      .post(`admin/tracktrip.php`, data)
      .then((res) => {
        console.log(res);
        // trip_id
        dispatch(fetchTrakedPointsSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fetchTrakedPointsFail(err.response.data.data.msg));
        } else {
          dispatch(fetchTrakedPointsFail('Internet Connection Failed'));
        }
      });
  };
};
