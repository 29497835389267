import * as actionTypes from '../actionTypes';
import axios from 'axios';

export const clearMsgTrashedtrips = () => {
  return {
    type: actionTypes.CLEAR_MSG_TRASHEDTRIPS,
  };
};

export const fetchTrashedTripsStart = () => {
  return {
    type: actionTypes.FETCH_TRASHEDTRIPS_START,
  };
};
export const fetchTrashedTripsSuccess = (data: any) => {
  return {
    type: actionTypes.FETCH_TRASHEDTRIPS_SUCCESS,
    trips: data.trips,
    count: data.count,
  };
};
export const fetchTrashedTripsFail = (error: any) => {
  return {
    type: actionTypes.FETCH_TRASHEDTRIPS_FAIL,
    error,
  };
};

export const fetchAllTrashedTrips = (
  pageN: any,
  pageSize: any,
  search = ''
) => {
  return (dispatch: any) => {
    let data = { pagenumber: pageN, pagesize: pageSize, search };
    console.log(data);
    dispatch(fetchTrashedTripsStart());

    axios
      .post(
        `admin/gettrashedtrips.php
        `,
        data
      )
      .then((res) => {
        console.log(res);
        dispatch(fetchTrashedTripsSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fetchTrashedTripsFail(err.response.data.msg));
        } else {
          dispatch(fetchTrashedTripsFail('Internet Connection Failed'));
        }
      });
  };
};

export const trashedfastAssTripStart = () => {
  return {
    type: actionTypes.TRASHED_FAST_ASSTRIP_START,
  };
};
export const trashedfastAssTripSuccess = (msg: string, tripId: any) => {
  return {
    type: actionTypes.TRASHED_FAST_ASSTRIP_SUCCESS,
    msg,
    tripId,
  };
};
export const trashedfastAssTripFail = (error: any) => {
  return {
    type: actionTypes.TRASHED_FAST_ASSTRIP_FAIL,
    error,
  };
};
export const trashedfastAssTrip = (data: any) => {
  return (dispatch: any) => {
    dispatch(trashedfastAssTripStart());
    const tripId = data.trashedtrip_id;
    console.log(data);
    axios
      .post(`admin/trashedfastassgintrip.php`, data)
      .then((res) => {
        console.log(res);
        dispatch(trashedfastAssTripSuccess(res.data.msg, tripId));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(trashedfastAssTripFail(err.response.data.msg));
        } else {
          dispatch(trashedfastAssTripFail("Internet Connection Failed"));
        }
      });
  };
};