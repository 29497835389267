import React from "react";
import ivy from "../../assets/ivy_logo_min.png";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import axios from "axios";
// let i = 1;
class Map extends React.Component<any, any> {
  state: any = {
    arr: [],
    emparr: [],
    isOpen: true,
  };
  componentDidMount() {

  }

  onChangeHandler = (e: any, stdId: any) => {
    console.log(e.target.value, " " + stdId);
    let newArr = this.props.arrMarkers.map((ele: any, index: any) => {
      if (ele.student_id === stdId) {
        ele.postion = e.target.value;
      }
      return ele;
    });
    // this.setState({ arr: newArr });
    // console.log(newArr);
    this.props.getPoints(newArr);
  };

  onEmpChangeHandler = (e: any, empId: any) => {
    let newEmpArr = this.state.emparr.map((eleemp: any, index: any) => {
      if (eleemp.employee_id === empId) {
        eleemp.postion = e.target.value;
      }
      return eleemp;
    });
    // this.setState({ arr: newArr });
    // console.log(newArr);
    this.props.getEmpPoints(newEmpArr);
  };
  shouldComponentUpdate = (nextProps: any, nextState: any, context: any) => {
    console.log("next Props", nextProps);
    return true;
  }

  onEmpEndDrag = (e: any, empId: any) => {
    let newLat: any = e?.latLng?.lat(),
      newLng: any = e?.latLng?.lng();


    try {
      axios.post('admin/employees/changeemppostion.php', { lat: newLat, lng: newLng, employee_id: empId }).then((res) => {
        console.log(res);


      })
    } catch (error) {

    }
    console.log(empId)
  }
  render() {
    // console.log(this.props);
    return (
      <GoogleMap
        defaultZoom={11}
        defaultCenter={
          {
            lat: 29.985147386999323,
            lng: 31.326269130685223,
          }
        }
      >

        {/* school marker  */}

        <Marker
          key={Math.random() * 1.2}
          onClick={() => this.setState({ showInfo: !this.state.showInfo })}
          position={{
            lat: 29.985147386999323,
            lng: 31.326269130685223,
          }}
          icon={{ url: ivy, scaledSize: new google.maps.Size(30, 30) }}
        >


        </Marker>
        {this.props.arrMarkers.map((mark: any) => (
          <Marker
            key={Math.random() * 1.2}
            position={mark.coords}
            onClick={() => this.setState({ isOpen: !this.state.isOpen })}
            // icon={{ url: ivy, scaledSize: new google.maps.Size(30, 30) }}
            icon={
              'https://mt.google.com/vt/icon?psize=30&font=fonts/arialuni_t.ttf&color=ff304C13&name=icons/spotlight/spotlight-waypoint-a.png&ax=43&ay=48&text=%E2%80%A2'
            }
          >
            {this.state.isOpen ? (
              <InfoWindow options={{ disableAutoPan: true }}>
                {/* <span>{mark.stdPostion}</span> */}
                <>
                  <h5>{mark.name}</h5>
                  <input
                    className="custom-input-map "
                    type="number"
                    // value={mark.postion}
                    placeholder={mark.postion}
                    min="1"
                    // minLength=''
                    onChange={(e) => this.onChangeHandler(e, mark.student_id)}
                  />
                </>
              </InfoWindow>
            ) : null}
          </Marker>
        ))}
        {this.props.emparrMarkers.map((emark: any) => (
          <Marker
            draggable={true}
            key={Math.random() * 1.2}
            position={emark.coords}
            onClick={() => this.setState({ isOpen: !this.state.isOpen })}
            onDragEnd={(e: any) => this.onEmpEndDrag(e, emark.employee_id)}
          >
            {this.state.isOpen ? (
              <InfoWindow options={{ disableAutoPan: true }}>
                {/* <span>{mark.stdPostion}</span> */}
                <>
                  <h5>{emark.name}</h5>
                  <input
                    className="custom-input-map "
                    type="number"
                    // value={mark.postion}
                    placeholder={emark.postion}
                    min="1"
                    // minLength=''
                    onChange={(e) =>
                      this.onEmpChangeHandler(e, emark.employee_id)
                    }
                  />
                </>
              </InfoWindow>
            ) : null}
          </Marker>
        ))}
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(Map));
