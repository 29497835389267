import React, { Dispatch, Fragment, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { IStateType } from '../../../store/models/root.interface';
import * as actions from '../../../store/actions/index.actions';
import SweetAlert from 'react-bootstrap-sweetalert';
const RequestGate: React.FC = memo((props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const trip: any = useSelector(
    (state: IStateType) => state.getdrivertrip.trip
  );
  const driver_id: any = useSelector(
    (state: IStateType) => state.auth.driverId
  );
  useEffect(() => {
    dispatch(actions.fetchDriverTrip({ driver_id }));
    dispatch(actions.fetchBus());
  }, [dispatch, driver_id]);
  let IsSuccess = useSelector(
    (state: IStateType) => state.driverrequestgate.data.id
  );
  useEffect(() => {
    if (IsSuccess) {
      IsSuccess = '';
      props.history.push('/DriverWaiting');
    }
  }, [IsSuccess]);
  const onConfirm = function (e: any) {
    e.preventDefault();
    const data = {
      trip_id: trip.id,
    };
    dispatch(actions.driverrequestgate(data));
  };
  const errorMsg = useSelector(
    (state: IStateType) => state.driverrequestgate.error
  );
  const onCancel = () => {
    dispatch(actions.clearMsgDriverGateEntryStatus());
    dispatch(actions.clearMsgDriverRequestGate());
    props.history.push('/');
  };
  let forError = null;
  if (errorMsg) {
    forError = (
      <SweetAlert
        danger
        title='Warning'
        onConfirm={onCancel}
        onCancel={onCancel}
      >
        {errorMsg}
      </SweetAlert>
    );
  }
  return (
    <Fragment>
      <div className='container d-flex flex-column justify-content-center align-items-center'>
        <div className='col-12'>
          {trip.id !== undefined?
          <>
          <h3 className='text-white font-weight-bold bg-dark p-4 rounded text-center'>
            Do You Need To Enter School ?
          </h3>
          <form onSubmit={(e) => onConfirm(e)} className='pb-3'>
            {forError}
            <button className='btn btn-block btn-warning text-white font-weight-bold p-3'>
              Yes
            </button>
          </form>
          <Link
            to='/'
            className='btn btn-block btn-warning text-white font-weight-bold p-3'
          >
            No
          </Link>
          </>
          :<div
          className='d-flex justify-content-center align-items-center rounded m-2 p-4'
          style={{ backgroundColor: 'rgb(44, 55, 94)' }}
          >
          <p
            style={{
              color: 'white',
              textAlign: 'center',
              textShadow:
                '0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)',
              fontSize: '1.9rem',
            }}
          >
            You can't request gate if you don't have a trip ! 
          </p>
        </div>}
        </div>
      </div>
    </Fragment>
  );
});
export default withRouter(RequestGate);
