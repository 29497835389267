import * as actionTypes from '../actionTypes';
import axios from 'axios';

export const clearMsgLocations = () => {
  return {
    type: actionTypes.CLEAR_MSG_ALL_LOCATIONS,
  };
};

export const fetchAllLocationsStart = () => {
  return {
    type: actionTypes.FETCH_ALL_LOCATIONS_START,
  };
};
export const fetchAllLocationsSuccess = (areas: any,count:any) => {
  return {
    type: actionTypes.FETCH_ALL_LOCATIONS_SUCCESS,
    areas,
    count,
  };
};
export const fetchAllLocationsFail = (error: any) => {
  return {
    type: actionTypes.FETCH_ALL_LOCATIONS_FAIL,
    error,
  };
};

export const fetchAllLocations = (pageN: any, pageSize: any, search = '') => {
  return (dispatch: any) => {
    dispatch(fetchAllLocationsStart());
    let data = { pagenumber: pageN, pagesize: pageSize, search };
    // console.log(data)
    axios
      .post(
        `admin/area/getareas.php`,
        data
      )
      .then((res) => {
        console.log(res);
        // dispatch(fetchAllLocationsSuccess(res.data.data, res.data.data.count));
        dispatch(fetchAllLocationsSuccess(res.data.data.areas,res.data.data.count));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fetchAllLocationsFail(err.response.data.msg));
        } else {
          dispatch(fetchAllLocationsFail('Internet Connection Failed'));
        }
      });
  };
};

// for assign trip from admin

export const addAreaStart = () => {
  return {
    type: actionTypes.ADD_LOCATION_START,
  };
};
export const addAreaSuccess = (data:any,msg: any) => {
  return {
    type: actionTypes.ADD_LOCATION_SUCCESS,
    data,
    msg,
  };
};
export const addAreaFail = (error: any) => {
  return {
    type: actionTypes.ADD_LOCATION_FAIL,
    error,
  };
};

export const addArea = (data: any) => {
  return (dispatch: any) => {
      console.log(data)
    dispatch(addAreaStart());

    axios
      .post(
        `admin/area/addarea.php`,
        data
      )
      .then((res) => {
        // console.log(res);
        dispatch(addAreaSuccess(res.data.data, 'SUCCESS'));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(addAreaFail(err.response.data.msg));
        } else {
          dispatch(addAreaFail('Internet Connection Failed'));
        }
      });
  };
};


export const deleteAreaStart = () => {
  return {
    type: actionTypes.DELETE_LOCATION_START,
  };
};
export const deleteAreaSuccess = (oldId:any,msg: any) => {
  return {
    type: actionTypes.DELETE_LOCATION_SUCCESS,
    oldId,
    msg,
  };
};
export const deleteAreaFail = (error: any) => {
  return {
    type: actionTypes.DELETE_LOCATION_FAIL,
    error,
  };
};

export const deleteArea = (data: any) => {
  return (dispatch: any) => {
      console.log(data)
    dispatch(deleteAreaStart());

    axios
      .post(
        `admin/area/delarea.php`,
        data
      )
      .then((res) => {
        // console.log(res);
        dispatch(deleteAreaSuccess(data.id, 'SUCCESS'));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(deleteAreaFail(err.response.data.msg));
        } else {
          dispatch(deleteAreaFail('Internet Connection Failed'));
        }
      });
  };
};

export const editAreaStart = () => {
  return {
    type: actionTypes.EDIT_LOCATION_START,
  };
};
export const editAreaSuccess = (data:any,msg: any) => {
  return {
    type: actionTypes.EDIT_LOCATION_SUCCESS,
    data,
    msg,
  };
};
export const editAreaFail = (error: any) => {
  return {
    type: actionTypes.EDIT_LOCATION_FAIL,
    error,
  };
};

export const editArea = (data: any) => {
  return (dispatch: any) => {
    //   console.log(data)
    dispatch(editAreaStart());

    axios
      .post(
        `admin/area/editarea.php`,
        data
      )
      .then((res) => {
        // console.log(res);
        dispatch(editAreaSuccess(data, 'SUCCESS'));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(editAreaFail(err.response.data.msg));
        } else {
          dispatch(editAreaFail('Internet Connection Failed'));
        }
      });
  };
};
