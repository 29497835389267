import React, { Fragment, useState, Dispatch, useEffect, memo } from "react";
import { Link, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../store/actions/index.actions";
import { IStateType } from "../../../store/models/root.interface";
import Loader from "react-loader-spinner";
import SweetAlert from "react-bootstrap-sweetalert";
import "./Addbus.css";
import { Translate } from "../../../utils/Translate";
const AddBus: React.FC = memo((props: any) => {
  let t = new Translate();
  const dispatch: Dispatch<any> = useDispatch();
  const buses = useSelector((state: IStateType) => state.forBus.buses);
  const loading = useSelector((state: IStateType) => state.forBus.loading);
  const gpsNumber = localStorage.gpsnumber;

  useEffect(() => {
    dispatch(actions.fetchAllBuses());
  }, []);

  const [busNumber, setBusNumber] = useState<string>("");
  const [capacity, setCapacity] = useState<string>("");
  const [gpsnumber, setGpsnumber] = useState<string>("");
  const [license, setLicense] = useState<string>("");
  const [editbusNumber, setEditBusNumber] = useState<string>("");
  const [editcapacity, setEditCapacity] = useState<string>("");
  const [editLicense, setEditLicense] = useState<string>("");
  const [editGpsNumber, setEditGpsNumber] = useState<string>("");
  const [busId, setbusId] = useState<string>("");

  const submitAddHandler = (e: any) => {
    e.preventDefault();
    const data = {
      busnumber: busNumber,
      license: license,
      capacity: capacity,
      gpsnumber: gpsnumber,
    };
    dispatch(actions.createBus(data));
  };

  const submitEditBusHandler = (e: any) => {
    e.preventDefault();
    const data = {
      busid: busId,
      busnumber: editbusNumber,
      license: editLicense,
      capacity: editcapacity,
      gpsnumber: editGpsNumber,
    };
    // console.log(data);
    dispatch(actions.editBus(data));
    // setShowModal(false);
  };

  const errorMsg = useSelector((state: IStateType) => state.addTrip.error);
  const msg = useSelector((state: IStateType) => state.addTrip.msg);

  const onCancel = () => {
    dispatch(actions.clearMsgAddTrip());
  };
  const onSuccessCancel = () => {
    dispatch(actions.clearMsgAddTrip());
    // props.history.push('/TripsTable');
  };

  let forError = null;
  if (errorMsg) {
    forError = (
      <SweetAlert
        danger
        title="Warning"
        onConfirm={onCancel}
        onCancel={onCancel}
      >
        {errorMsg}
      </SweetAlert>
    );
  }

  let forSuccess = null;
  if (msg) {
    forSuccess = (
      <SweetAlert
        success
        title="Success"
        onConfirm={onSuccessCancel}
        onCancel={onSuccessCancel}
      >
        {msg}
      </SweetAlert>
    );
  }
  // console.log(busId);
  return (
    <Fragment>
      {/*       <Link to='/' className='btn btn-danger text-white font-weight-bold'>
        Back
      </Link> */}
      {loading === false ? (
        <Fragment>
          <form onSubmit={submitAddHandler}>
            {forError}
            {forSuccess}
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4">
                <label className="text-white font-weight-bold text-left">
                  {t.translate("Bus Number")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Bus Number"
                  aria-label="Bus Number"
                  aria-describedby="Entering Bus Number"
                  onChange={(e) => setBusNumber(e.target.value)}
                  required
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4 form-group justify-content-center text-center">
                <label className="text-white font-weight-bold text-left">
                  {t.translate("Capacity")}
                </label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter Capacity"
                  required
                  aria-label="Capacity"
                  aria-describedby="Entering Capacity"
                  onChange={(e) => setCapacity(e.target.value)}
                />
              </div>
              {gpsNumber == "1" ? (
                <div className="col-12 col-md-6 col-lg-4 form-group justify-content-center text-center">
                  <label className="text-white font-weight-bold text-left">
                    {t.translate("GPS phone number")}
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Number"
                    required
                    aria-label="Number"
                    aria-describedby="Entering Number"
                    onChange={(e) => setGpsnumber(e.target.value)}
                  />
                </div>
              ) : null}

              <div className="col-12 col-md-6 col-lg-4 form-group justify-content-center text-center">
                <label className="text-white font-weight-bold text-left">
                  {t.translate("License plate number")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter License plate number"
                  required
                  aria-label="License plate number"
                  aria-describedby="Entering License plate number"
                  onChange={(e) => setLicense(e.target.value)}
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <button className="col-sm-3 btn btn-block btn-warning text-white font-weight-bold my-3">
                {t.translate("Add Bus")}
              </button>
            </div>
          </form>

          <div className="table-responsive bg-light my-5">
            <table
              className="table-hover table-bordered text-center"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th>{t.translate("bus Number")}</th>
                  <th>{t.translate("License Plate No.")}</th>
                  <th>{t.translate("capacity")}</th>
                  {gpsNumber == "1" ? (
                    <th>{t.translate("GPS phone number")}</th>
                  ) : null}

                  <th></th>
                </tr>
              </thead>
              <tbody>
                {buses.length > 0
                  ? buses.map((bus: any) => (
                      <tr key={bus.id}>
                        <td>{bus.busnumber}</td>
                        <td>{bus.license}</td>
                        <td>{bus.capacity}</td>
                        {gpsNumber == "1" ? <td>{bus.gpsnumber}</td> : null}

                        <td>
                          <button
                            // to='/EditTrip'
                            onClick={() => {
                              console.log(bus);
                              setEditBusNumber(bus.busnumber);
                              setEditCapacity(bus.capacity);
                              setEditLicense(bus.license || " ");
                              setEditGpsNumber(bus.gpsnumber || " ");
                              setbusId(bus.id);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            className="btn btn-warning text-white font-weight-bold m-1"
                          >
                            <i className="fas fa-cog"></i>
                          </button>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>

          <div
            className="modal fade"
            id="exampleModal"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <form className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {t.translate("Edit Bus")}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>

                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12 form-group  text-center">
                      <label className="text-black font-weight-bold text-left blackColor">
                        {t.translate("Bus Number")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Bus Number"
                        aria-label="Bus Number"
                        aria-describedby="Entering Bus Number"
                        value={editbusNumber}
                        onChange={(e) => setEditBusNumber(e.target.value)}
                        required
                      />
                    </div>

                    {gpsNumber == "1" ? (
                      <div className="col-12 col-md-12 col-lg-12 form-group justify-content-center text-center">
                        <label
                          className="text-black font-weight-bold text-left blackColor"
                          style={{ color: "black !important" }}
                        >
                          {t.translate("GPS phone number")}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Number"
                          required
                          value={editGpsNumber}
                          aria-label="Number"
                          aria-describedby="Entering Number"
                          onChange={(e) => setEditGpsNumber(e.target.value)}
                        />
                      </div>
                    ) : null}

                    <div className="col-md-12 form-group  text-center">
                      <label className=" font-weight-bold text-left blackColor">
                        {t.translate("License Plate No.")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter License Plate No."
                        aria-label="License Plate No."
                        aria-describedby="Entering License Plate No."
                        value={editLicense}
                        onChange={(e) => setEditLicense(e.target.value)}
                        required
                      />
                    </div>

                    <div className="col-12 form-group justify-content-center text-center">
                      <label className=" font-weight-bold text-left blackColor">
                        {t.translate("Capacity")}
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Capacity"
                        required
                        aria-label="Capacity"
                        aria-describedby="Entering Capacity"
                        value={editcapacity}
                        onChange={(e) => setEditCapacity(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    {t.translate("Close")}
                  </button>
                  <button
                    type="submit"
                    onClick={submitEditBusHandler}
                    data-bs-dismiss="modal"
                    className="btn btn-primary"
                  >
                    {t.translate("Save changes")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
        </div>
      )}
    </Fragment>
  );
});
export default withRouter(AddBus);
