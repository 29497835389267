import * as actionTypes from './actionTypes';
import axios from 'axios';

export const trackTripStart = () => {
  return {
    type: actionTypes.TRACK_STUDENTS_START,
  };
};
export const trackTripSuccess = (data: any) => {
  return {
    type: actionTypes.TRACK_STUDENTS_SUCCESS,
    data,
  };
};
export const trackTripFail = (error: any) => {
  return {
    type: actionTypes.TRACK_STUDENTS_FAIL,
    error,
  };
};

export const trackTrip = (trip_id: any) => {
  return (dispatch: any) => {
    dispatch(trackTripStart());
    let data = { trip_id };
    console.log(data);
    axios
      .post(
        `driver/track.php
        `,
        data
      )
      .then((res) => {
        console.log(res);
        dispatch(trackTripSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(trackTripFail(err.response.data.msg));
        } else {
          dispatch(trackTripFail('Internet Connection Failed'));
        }
      });
  };
};