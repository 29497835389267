import React, {
  Fragment,
  Dispatch,
  useEffect,
  memo,
  useState,
  useRef,
  createRef,
} from "react";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index.actions";
import { IStateType } from "../../../store/models/root.interface";
import Loader from "react-loader-spinner";
import Pagination from "react-js-pagination";
import classes from "../../Pagination.module.css";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { getOuterBindingIdentifiers } from "@babel/types";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Translate } from "../../../utils/Translate";
const MySwal = withReactContent(Swal);

function PrintElem(trip: any) {
  var mywindow: any = window.open("", "PRINT", "height=500,width=800");
  let data = trip.durationstxt.map((v: any, i: number) => {
    if (i === 0) {
      return `<tr>
                <td>#${trip.students_ids[i]}</td>
                <td>${trip.students_names[i]}</td>
                <td>${trip.grades[i]}</td>
                <td>${trip.mobiles[i] ?? ""}</td>
                <td>${trip.addresses[i]} </td>
                <td>${trip.distances[i]}</td>
                <td>${v}</td>
                <td>${trip.times[i]}</td>
              </tr>`;
    } else {
      return `<t>
                <td>#${trip.students_ids[i] ?? ""}</td>
                <td>${trip.students_names[i] ?? ""}</td>
                <td>${trip.grades[i] ?? ""}</td>
                <td>${trip.mobiles[i] ?? ""}</td>
                <td>${trip.addresses[i] ?? ""} </td>
                <td>${trip.distances[i]}</td>
                <td>${trip.durationstxt[i]}</td>
                <td>${trip.times[i]}</td>
              </tr>`;
    }
  });
  mywindow.document.write(
    "<html><head><title>" +
      document.title +
      " (" +
      trip.direction +
      ") </title>"
  );
  mywindow.document.write(`
      <style>
        table {
          font-family: Arial, Helvetica, sans-serif;
          border-collapse: collapse;
          width: 100%;
        }

        table td, table th {
          border: 1px solid #ddd;
          padding: 8px;
        }

        table tr:nth-child(even){background-color: #f2f2f2;}

        table tr:hover {background-color: #ddd;}

        table th {
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: left;
          background-color: #04AA6D;
          color: white;
        }
      </style>
    `);
  mywindow.document.write("</head><body >");
  mywindow.document.write('<div style="text-align:center" >');
  mywindow.document.write(
    "<h1>#" + trip.id + ":" + trip.tripname + " (" + trip.direction + ")</h1>"
  );
  mywindow.document.write("</div>");
  mywindow.document.write(`
              <table className='table-hover table-bordered text-center' style={{ width: '100%' }} >
                <thead>
                  <tr>
                    <th>#Students Sorted</th>
                    <th>Students Names</th>
                    <th>Students grade</th>
                    <th>Mobiles</th>
                    <th>Addresses</th>
                    <th>Distances</th>
                    <th>Estimated Duration</th>
                    <th>Estimated Time</th>
                  </tr>
                </thead>
                <tbody>
                  ${data}
                </tbody>
              </table>


    `);
  mywindow.document.write("</body></html>");

  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/
  mywindow.onafterprint = () => {
    console.log("After print");
    //mywindow.close();
  };
  mywindow.print();

  return true;
}

function PrintHTML(Element: any, title: string) {
  var mywindow: any = window.open("", "PRINT", "height=500,width=800");

  mywindow.document.write("<html><head><title>" + title + " </title>");
  mywindow.document.write(`
      <style>
        table {
          font-family: Arial, Helvetica, sans-serif;
          border-collapse: collapse;
          width: 100%;
        }

        table td, table th {
          border: 1px solid #ddd;
          padding: 8px;
        }

        table tr:nth-child(even){background-color: #f2f2f2;}

        table tr:hover {background-color: #ddd;}

        table th {
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: left;
          background-color: #04AA6D;
          color: white;
        }
      </style>
    `);
  mywindow.document.write("</head><body >");
  mywindow.document.write('<div style="text-align:center" >');
  mywindow.document.write("<h1>" + title + "</h1>");
  mywindow.document.write("</div>");
  mywindow.document.write(`${Element}`);
  mywindow.document.write("</body></html>");

  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/
  mywindow.onafterprint = () => {
    console.log("After print");
    //mywindow.close();
  };
  mywindow.print();

  return true;
}

const TripanalyticsM: React.FC = memo((props: any) => {
  let t = new Translate();
  const dispatch: Dispatch<any> = useDispatch();
  const [trips, SetTrips] = useState<any>(null);
  const [loading, SetLoading] = useState(true);
  const [departure_time, SetDTime] = useState("now");
  const [TripDirection, SetTripDirection] = useState<any>("To Home Trip");
  //const [Refs, SetRefs] = useState<any>([]);

  var TripsList: any = [];

  var Refs: any = [];
  function getTrips(toschool: any = 0) {
    SetLoading(true);
    axios
      .post("admin/gettripsinfoM.php", {
        departure_time: departure_time,
        toSchool: toschool,
      })
      .then((res) => {
        //console.log(res.data.data);
        var ts = Object.values(res.data.data);
        SetTrips(ts);
        SetLoading(false);
      });
  }

  function sortStdsNames(trip: any) {
    let stdIds = trip.assigned;
    let order = trip.route?.waypoint_order;
    if (
      order === null ||
      order === undefined ||
      stdIds === null ||
      stdIds.length === 0
    ) {
      return <div className="col-12"></div>;
    }
    return order.map((i: any) => {
      //console.log(i)
      return (
        <div key={i} className="col-12">
          {stdIds[i]?.fullname}{" "}
        </div>
      );
    });
  }
  function sortStds(trip: any) {
    let stdIds = trip.assigned;
    let order = trip.route?.waypoint_order;
    let students_ids: any = [];
    TripsList[TripsList.length - 1].students_ids = students_ids;
    if (
      order === null ||
      order === undefined ||
      stdIds === null ||
      stdIds.length === 0
    ) {
      return <div className="col-12"></div>;
    }
    return order.map((i: any) => {
      //console.log(i)
      students_ids.push(stdIds[i]?.student_id.toString());
      return (
        <div key={i} className="col-12">
          #{stdIds[i]?.student_id}{" "}
        </div>
      );
    });
  }
  function Addresses(trip: any) {
    let stdIds = trip.assigned;
    let order = trip.route?.waypoint_order;
    if (
      order === null ||
      order === undefined ||
      stdIds === null ||
      stdIds.length === 0
    ) {
      return <div className="col-12"></div>;
    }
    return order.map((i: any) => {
      //console.log(i)
      return (
        <div key={i} className="col-12">
          {stdIds[i]?.address}{" "}
        </div>
      );
    });
  }
  function Grades(trip: any) {
    let stdIds = trip.assigned;
    let order = trip.route?.waypoint_order;
    if (
      order === null ||
      order === undefined ||
      stdIds === null ||
      stdIds.length === 0
    ) {
      return <div className="col-12"></div>;
    }
    return order.map((i: any) => {
      //console.log(i)
      return (
        <div key={i} className="col-12">
          {stdIds[i]?.grade}{" "}
        </div>
      );
    });
  }
  function distances(trip: any) {
    let legs = trip?.route?.legs;
    if (legs === null || legs === undefined) {
      return <div className="col-12"></div>;
    }
    return legs.map((leg: any, index: number) => {
      //console.log(i)
      return (
        <div key={index} className="col-12">
          {leg.distance.text}{" "}
        </div>
      );
    });
  }
  function Ptimes(trip: any) {
    let legs = trip.route?.legs;
    if (legs === null || legs === undefined) {
      return <div className="col-12"></div>;
    }
    let durations: any = [];
    TripsList[TripsList.length - 1].durations = durations;
    return legs.map((leg: any, index: number) => {
      //console.log(i)
      durations.push(+leg.duration.value * 1000);
      return (
        <div key={index} className="col-12">
          {leg.duration.text}{" "}
        </div>
      );
    });
  }
  function Etimes(trip: any) {
    let legs = trip.route?.legs;
    if (legs === null || legs === undefined) {
      return <div className="col-12"></div>;
    }
    let duration = TripsList[TripsList.length - 1].durations;
    let times: any = [];
    TripsList[TripsList.length - 1].times = times;
    let time = new Date();
    return legs.map((leg: any, index: number) => {
      //console.log(i)
      time = new Date(time.getTime() + leg.duration.value * 1000);
      let strTime = time.toLocaleString();
      times.push(strTime.split(",")[1]);
      // times.push
      return (
        <div key={index} className="col-12">
          {strTime.split(",")[1]}{" "}
        </div>
      );
    });
  }

  //-----------------------------------------
  function FillTripsList(trip: any) {
    //console.log(trip);
    if (!trip.route) {
      trip.students_ids = [];
      trip.students_names = [];
      trip.durations = [];
      trip.distances = [];
      return;
    }

    FillStdsNames(trip);
    FillsortStds(trip);
    FillAddresses(trip);
    FillGrades(trip);
    FillDistances(trip);
    FillPtimes(trip);
    FillEtimes(trip);
  }
  function FillStdsNames(trip: any) {
    let stdIds = trip.assigned;
    let order = trip.route?.waypoint_order;
    let students_names: any = [];
    TripsList[TripsList.length - 1].students_names = students_names;
    let mobiles: any = [];
    TripsList[TripsList.length - 1].mobiles = mobiles;
    if (order === null || order === undefined) return;
    order.forEach((i: any) => {
      students_names.push(stdIds[i]?.fullname);
      mobiles.push(stdIds[i]?.mobile);
    });
  }
  function FillsortStds(trip: any) {
    let stdIds = trip.assigned;
    let order = trip.route?.waypoint_order;
    let students_ids: any = [];
    TripsList[TripsList.length - 1].students_ids = students_ids;
    if (
      order === null ||
      order === undefined ||
      stdIds === null ||
      stdIds.length === 0
    ) {
      return <div className="col-12"></div>;
    }
    order.forEach((i: any) => {
      students_ids.push(stdIds[i]?.student_id.toString());
    });
  }
  function FillAddresses(trip: any) {
    let stdIds = trip.assigned;
    let order = trip.route?.waypoint_order;
    let Addresses: any = [];
    TripsList[TripsList.length - 1].addresses = Addresses;
    if (
      order === null ||
      order === undefined ||
      stdIds === null ||
      stdIds.length === 0
    ) {
      return <div className="col-12"></div>;
    }
    order.forEach((i: any) => {
      Addresses.push(stdIds[i]?.address);
    });
  }
  function FillGrades(trip: any) {
    let stdIds = trip.assigned;
    let order = trip.route?.waypoint_order;
    let grades: any = [];
    TripsList[TripsList.length - 1].grades = grades;
    if (
      order === null ||
      order === undefined ||
      stdIds === null ||
      stdIds.length === 0
    ) {
      return <div className="col-12"></div>;
    }
    order.forEach((i: any) => {
      //console.log(i)
      grades.push(stdIds[i]?.grade);
    });
  }
  function FillDistances(trip: any) {
    let legs = trip?.route?.legs;
    let distances: any = [];
    TripsList[TripsList.length - 1].distances = distances;
    if (legs === null || legs === undefined) {
      return <div className="col-12"></div>;
    }
    legs.forEach((leg: any, index: number) => {
      //console.log(i)
      distances.push(leg.distance.text);
    });
  }
  function FillPtimes(trip: any) {
    let legs = trip.route?.legs;
    if (legs === null || legs === undefined) {
      return <div className="col-12"></div>;
    }
    let durations: any = [];
    TripsList[TripsList.length - 1].durations = durations;
    let durationstxt: any = [];
    TripsList[TripsList.length - 1].durationstxt = durationstxt;
    legs.forEach((leg: any, index: number) => {
      durations.push(+leg.duration.value * 1000);
      durationstxt.push(leg.duration.text);
    });
  }
  function FillEtimes(trip: any) {
    let legs = trip.route?.legs;
    if (legs === null || legs === undefined) {
      return <div className="col-12"></div>;
    }
    let times: any = [];
    TripsList[TripsList.length - 1].times = times;
    let time = new Date();
    legs.forEach((leg: any, index: number) => {
      //console.log(i)
      time = new Date(time.getTime() + leg.duration.value * 1000 + 120000);
      let strTime = time.toLocaleString();
      times.push(strTime.split(",")[1]);
    });
  }

  function ToSchool() {
    getTrips(1);
    SetTripDirection("To School Trip");
  }
  function ToHome() {
    getTrips(0);
    SetTripDirection("To Home Trip");
  }

  useEffect(() => {
    //-------------- get trips
    getTrips();
  }, []);
  const makeRef = (trip: any) => {
    let r = createRef();
    let o = { ref: r, name: trip.tripname, id: trip.id };
    Refs.push(o);
    return o;
  };
  const handlePrint = () => {
    Swal.fire({
      title: "Please Select Trip To Print",
      showCancelButton: true,
      confirmButtonText: "Print Now",
      html: `<div>
        <h4>
          Please Select a trip to Print
        </h4>
        <select className="form-control" id="STrip">
          ${Refs.map((r: any) => {
            return `<option value=${r.id}>${r.name} </option>`;
          })}
        </select>
      </div>`,
      //denyButtonText: `Don't save`,
    }).then((result) => {
      let res: any = $("#STrip").val();
      console.log(res);
      let tr = TripsList.find((t: any) => t.id === res);
      tr.direction = TripDirection;
      PrintElem(tr);
    });
    /* useReactToPrint({
      content: () => SelectedRef.current,
    }); */
  };

  const printAllPage = () => {
    let e = document.getElementById("DTContainer")?.innerHTML;
    PrintHTML(e, TripDirection + "s");
  };

  function SendMails() {
    console.log(TripsList);
    Swal.fire({
      title: "Are you sure you want to Send Email To Parents Now?",
      showCancelButton: true,
      confirmButtonText: "Yes, I'm",
      //denyButtonText: `Don't save`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        SetLoading(true);
        axios
          .post("include/email/sendarrivaltime.php", { trips: TripsList })
          .then((res) => {
            console.log(res.data.data);
            MySwal.fire({
              title: <p></p>,
              footer: "Copyright 2018",
              html: res.data.data,
              didOpen: () => {
                // `MySwal` is a subclass of `Swal`
                //   with all the same instance & static methods
                //MySwal.clickConfirm()
              },
              icon: res.data.status,
            }).then(() => {
              //Swal.fire("Sent Successfully!", '', 'success')
              SetLoading(false);
            });
          })
          .catch((res) => {
            MySwal.fire({
              title: <p></p>,
              footer: "Copyright 2018",
              html: res.data.data,
              didOpen: () => {
                // `MySwal` is a subclass of `Swal`
                //   with all the same instance & static methods
                //MySwal.clickConfirm()
              },
              icon: res.data.status,
            }).then(() => {
              //Swal.fire("Sent Successfully!", '', 'success')
              SetLoading(false);
            });
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
    /*  */
  }

  return (
    <Fragment>
      <div className="text-center">
        <h2
          className="text-white"
          style={{
            textShadow:
              "0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)",
            fontSize: "2rem",
          }}
        >
          {t.translate("Trips Analysis")}
        </h2>
      </div>
      {loading === false ? (
        <div className="row">
          <div className="col-12">
            <h2 className="text-white">
              {" "}
              {t.translate("Trip Direction")}:{TripDirection}
            </h2>
          </div>
          <div className="col-12 justify-content-around">
            <button className="btn btn-warning m-2" onClick={ToSchool}>
              {t.translate("To School")}
            </button>
            <button className="btn btn-warning m-2" onClick={ToHome}>
              {t.translate("To Home")}
            </button>
          </div>
          <div className="col-12">
            <button className="btn btn-success m-2" onClick={handlePrint}>
              {t.translate("Print single trip!")}
            </button>
            <button className="btn btn-success m-2" onClick={printAllPage}>
              {t.translate("Print all trips")}
            </button>
            <button className="btn btn-warning m-2" onClick={SendMails}>
              {t.translate("Send Email to Parents")}
            </button>
          </div>
          <div className="col-md-12">
            <div id="DTContainer" className="table-responsive bg-light">
              <table
                id="datatable"
                className="table-hover table-bordered text-center"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{t.translate("Name")}</th>
                    <th>{t.translate("Total Duration (Minutes)")}</th>
                    <th>{t.translate("#Students Sorted")}</th>
                    <th>{t.translate("Students Names")}</th>
                    <th>{t.translate("Students grade")}</th>
                    <th>{t.translate("Addresses")}</th>
                    <th>{t.translate("Distances")}</th>
                    <th>{t.translate("Estimated Duration")}</th>
                    <th>{t.translate("Estimated Time")}</th>
                  </tr>
                </thead>

                {trips.map((trip: any, index: number) => {
                  let t: any = {};
                  t.id = trip.id;
                  t.tripname = trip.tripname;
                  TripsList.push(t);
                  FillTripsList(trip);
                  let data: any = null;
                  if (t.durationstxt)
                    data = t.durationstxt.map((v: any, i: number) => {
                      if (i === 0) {
                        return (
                          <tr key={index * 1000 + i}>
                            <td rowSpan={t.durationstxt.length}>{trip.id}</td>
                            <td rowSpan={t.durationstxt.length}>
                              {trip.tripname}
                            </td>
                            <td rowSpan={t.durationstxt.length}>
                              {parseInt((+trip.route.duration / 60).toString())}
                            </td>
                            <td>#{t.students_ids[i]}</td>
                            <td>{t.students_names[i]}</td>
                            <td>{t.grades[i]}</td>
                            <td>{t.addresses[i]} </td>
                            <td>{t.distances[i]}</td>
                            <td>{v}</td>
                            <td>{t.times[i]}</td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr key={index * 1000 + i}>
                            <td>#{t.students_ids[i]}</td>
                            <td>{t.students_names[i]}</td>
                            <td>{t.grades[i]}</td>
                            <td>{t.addresses[i]} </td>
                            <td>{t.distances[i]}</td>
                            <td>{t.durationstxt[i]}</td>
                            <td>{t.times[i]}</td>
                          </tr>
                        );
                      }
                    });
                  if (data) {
                    let r: any = makeRef(trip);
                    return (
                      <tbody id={r.id} key={index * 1519} ref={r.ref}>
                        {data}
                      </tbody>
                    );
                  }

                  return (
                    <tbody key={index * 1519}>
                      <tr>
                        <td>{trip.id}</td>
                        <td>{trip.tripname}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td> </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
        </div>
      )}
    </Fragment>
  );
});

export default withRouter(TripanalyticsM);
