import * as actionTypes from '../actionTypes';
import axios from 'axios';

export const sendDriverGateEntryStatus = () => {
  return {
    type: actionTypes.SEND_DRIVER_GATE_ENTRY_STATUS,
  };
};
export const sendDriverGateEntryStatusSuccess = (data: any) => {
  return {
    type: actionTypes.SEND_DRIVER_GATE_ENTRY_STATUS_SUCCESS,
    data,
  };
};
export const sendDriverGateEntryStatusFail = (error: any) => {
  return {
    type: actionTypes.SEND_DRIVER_GATE_ENTRY_STATUS_FAIL,
    error,
  };
};
export const clearMsgDriverGateEntryStatus = () => {
  return {
    type: actionTypes.CLEAR_MSG_DRIVERGATEENTRYSTATUS,
  };
};

export const drivergateentry = (data: any) => {
  return (dispatch: any) => {
    dispatch(sendDriverGateEntryStatus());
    axios
      .post(`driver/gateentry.php`, data)
      .then((res) => {
        console.log(res.data.data);

        dispatch(sendDriverGateEntryStatusSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(sendDriverGateEntryStatusFail(err.response.data.msg));
        } else {
          dispatch(sendDriverGateEntryStatusFail('Internet Connection Failed'));
        }
      });
  };
};
