import React, { Dispatch, Fragment, useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter, Redirect } from "react-router-dom";
import { IStateType } from "../../../store/models/root.interface";
import * as actions from "../../../store/actions/index.actions";
import SweetAlert from "react-bootstrap-sweetalert";
import Map from "../../Maps/Map";
import Loader from "react-loader-spinner";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const AssignStudent: React.FC = memo((props: any) => {
  // console.log(props);

  if (props.location.state === undefined) {
    props.history.push("/Payments");
  }
  const { parentId } = props.location.state;

  return (
    <Fragment>
      <Link
        to="/Payments"
        className="btn btn-danger text-white font-weight-bold"
      >
        Back
      </Link>
      {true ? (
        <div className="justify-content-center align-items-center my-3">
          <div className="row">
            <div className="col-lg-4 mx-auto mt-1 mb-2">
              <div className="card shadow" style={{ backgroundColor: 'rgb(44, 55, 94)' }}>
                <div className="card-body">
                  <div className="text-center my-6">
                    <p className="d-block h5 mb-0 text-white">Fees to be paid : 5000</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive bg-light">
            <table
              className="table-hover table-bordered text-center"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Date</th>
                  <th scope="col">Depit</th>
                  <th scope="col">Credit</th>
                  <th scope="col">Details</th>
                  <th scope="col">Notes</th>
                  <th scope="col">User</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>2021-05-16 10:09:10</td>
                  <td>0</td>
                  <td>10000</td>
                  <td>Accepted Trip: Tagamo3-trip 3-to school For |Ziad Ahmed Salem|</td>
                  <td>didn't pay yet</td>
                  <td>ID:11311-Parent-Ahmed Salem</td>
                </tr>
                <tr>
                  <td className="text-danger">2</td>
                  <td className="text-danger">2021-05-18 12:06:50</td>
                  <td className="text-danger">5000</td>
                  <td className="text-danger">0</td>
                  <td className="text-danger">Settlement</td>
                  <td className="text-danger">5000 discount</td>
                  <td className="text-danger">ID:11511-Staff-Mostafa</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center align-items-center my-3">
            {true ? (
              // <Pagination
              //   activePage={activePage}
              //   itemsCountPerPage={pageSize}
              //   totalItemsCount={+count}
              //   pageRangeDisplayed={5}
              //   onChange={(e) => handlePageChange(e)}
              //   firstPageText={"First"}
              //   lastPageText={"Last"}
              //   prevPageText={arrowLeft}
              //   nextPageText={arrowRight}
              //   activeClass={classes.active}
              // />
              <div style={{display:'none'}}>pag</div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
        </div>
      )}
    </Fragment>
  );
});
export default withRouter(AssignStudent);
