import { IActionBase, IEntry } from '../../models/root.interface';
import * as actionTypes from '../../actions/actionTypes';

import { updateObject } from '../../utility';

const initialState: any = {
  gaterequests: null,
  count: 0,
  loadingRequests: null,
  error: null,
  msg: null,
  allowentry: {},
  allowEntryLoading: null,
};

const fetchRequestsStart = (state: any, action: IActionBase) => {
  return updateObject(state, { error: null, loadingRequests: true });
};

const fetchRequestsSuccess = (
  state: any = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    gaterequests: action.gaterequests,
    count: action.count,
    loadingRequests: false,
    error: null,
  });
};

const fetchRequestsFail = (state: IEntry, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingRequests: false,
  });
};

const clearMsgAllRequests = (state: IEntry, action: IActionBase) => {
  return updateObject(state, {
    error: null,
    loadingRquests: false,
    msg: null,
  });
};

//Allow Entry

const allowEntryStart = (state: any, action: IActionBase) => {
  return updateObject(state, { error: null, allowentryLoading: true });
};

const allowEntrySuccess = (state: any = initialState, action: IActionBase) => {
  return updateObject(state, {
    allowentry: action.data,
    allowentryLoading: false,
    error: null,
  });
};

const allowEntryFail = (state: IEntry, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    allowentryLoading: false,
  });
};

const clearAllowEntryMsg = (state: IEntry, action: IActionBase) => {
  return updateObject(state, {
    error: null,
    allowentryLoading: false,
    msg: null,
  });
};

const AllowEntry = (state: IEntry = initialState, action: IActionBase) => {
  switch (action.type) {
    case actionTypes.FETCH_ALL_REQUESTS_START:
      return fetchRequestsStart(state, action);
    case actionTypes.FETCH_ALL_REQUESTS_SUCCESS:
      return fetchRequestsSuccess(state, action);
    case actionTypes.FETCH_ALL_REQUESTS_FAIL:
      return fetchRequestsFail(state, action);
    case actionTypes.CLEAR_MSG_ALL_REQUESTS:
      return clearMsgAllRequests(state, action);

    case actionTypes.ALLOW_ENTRY_START:
      return allowEntryStart(state, action);
    case actionTypes.ALLOW_ENTRY_SUCCESS:
      return allowEntrySuccess(state, action);
    case actionTypes.ALLOW_ENTRY_FAIL:
      return allowEntryFail(state, action);
    case actionTypes.CLEAR_MSG_ALLOW_ENTRY:
      return clearAllowEntryMsg(state, action);

    default:
      return state;
  }
};
export default AllowEntry;
