import * as actionTypes from '../actionTypes';
import axios from 'axios';

export const clearMsgAssginEmployeeTrip = () => {
  return {
    type: actionTypes.CLEAR_MSG_AssginEmployeeTrip,
  };
};

export const fetchEmployeeTripStart = () => {
  return {
    type: actionTypes.FETCH_ASSIGNEMPLOYEETRIP_START,
  };
};
export const fetchEmployeeTripSuccess = (data: any) => {
  return {
    type: actionTypes.FETCH_ASSIGNEMPLOYEETRIP_SUCCESS,
    trip: data,
  };
};
export const fetchEmployeeTripFail = (error: any) => {
  return {
    type: actionTypes.FETCH_ASSIGNEMPLOYEETRIP_FAIL, 
    error,
  };
};

export const fetchAssignedEmployeeTrip = (tripid: string) => {
  return (dispatch: any) => {
    dispatch(fetchEmployeeTripStart());

    axios
      .post(
        `admin/employees/getemployeetrip.php
        `,
        { id: tripid }
      )
      .then((res) => {
        dispatch(fetchEmployeeTripSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fetchEmployeeTripsFail(err.response.data.msg));
        } else {
          dispatch(fetchEmployeeTripFail('Internet Connection Failed'));
        }
      });
  };
};

// UDATE trip
export const updateAssEmployeeTripStart = () => {
  return {
    type: actionTypes.UPDATEASS_EMPLOYEE_TRIP_START,
  };
};
export const updateAssEmployeeTripSuccess = (msg: string) => {
  return {
    type: actionTypes.UPDATEASS_EMPLOYEE_TRIP_SUCCESS,
    msg,
  };
};
export const updateAssEmployeeTripFail = (error: any) => {
  return {
    type: actionTypes.UPDATEASS_EMPLOYEE_TRIP_FAIL,
    error,
  };
};

export const updateAssEmployeeTrip = (data: any) => {
  return (dispatch: any) => {
    dispatch(updateAssEmployeeTripStart());

    axios
      .post(`admin/employees/updateemployeetrip.php`, data)
      .then((res) => {
        console.log(res);
        dispatch(updateAssEmployeeTripSuccess(res.data.msg));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(updateAssEmployeeTripFail(err.response.data.msg));
        } else {
          dispatch(updateAssEmployeeTripFail('Internet Connection Failed'));
        }
      });
  };
};

// fetch assign trips

export const fetchEmployeeTripsStart = () => {
  return {
    type: actionTypes.FETCH_EMPLOYEEASSIGNTRIPS_START,
  };
};
export const fetchEmployeeTripsSuccess = (data: any) => {
  return {
    type: actionTypes.FETCH_EMPLOYEEASSIGNTRIPS_SUCCESS,
    trips: data.trips,
    count: data.count,
  };
};
export const fetchEmployeeTripsFail = (error: any) => {
  return {
    type: actionTypes.FETCH_EMPLOYEEASSIGNTRIPS_FAIL,
    error,
  };
};

export const fetchEmployeeAssignedTrips = (pageN: any, pageSize: any, search = '') => {
  return (dispatch: any) => {
    dispatch(fetchEmployeeTripsStart());
    let data = { pagenumber: pageN, pagesize: pageSize, search };
    console.log(data);
    axios
      .post(
        `admin/employees/getemployeetrips.php
        `,
        data
      )
      .then((res) => {
        console.log(res);
        dispatch(fetchEmployeeTripsSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fetchEmployeeTripsFail(err.response.data.msg));
        } else {
          dispatch(fetchEmployeeTripsFail('Internet Connection Failed'));
        }
      });
  };
};

// suspend  trip

export const suspendEmployeeTripStart = () => {
  return {
    type: actionTypes.SUSPEND_EMPLOYEE_TRIP_START,
  };
};
export const suspendEmployeeTripSuccess = (msg: any, data: any) => {
  return {
    type: actionTypes.SUSPEND_EMPLOYEE_TRIP_SUCCESS,
    msg,
    data,
  };
};
export const suspendEmployeeTripFail = (error: any) => {
  return {
    type: actionTypes.SUSPEND_EMPLOYEE_TRIP_FAIL,
    error,
  };
};

export const suspendEmployeeTrip = (employeetrip_id: any) => {
  return (dispatch: any) => {
    dispatch(suspendEmployeeTripStart());
    let data = { employeetrip_id };

    axios
      .post(
        `admin/employees/suspendtrip.php
        `,
        data
      )
      .then((res) => {
        console.log(res);
        dispatch(suspendEmployeeTripSuccess(res.data.msg, res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(suspendEmployeeTripFail(err.response.data.msg));
        } else {
          dispatch(suspendEmployeeTripFail('Internet Connection Failed'));
        }
      });
  };
};

// un-suspend  trip

export const unSuspendEmployeeTripStart = () => {
  return {
    type: actionTypes.UNSUSPEND_EMPLOYEE_TRIP_START,
  };
};
export const unSuspendEmployeeTripSuccess = (msg: any, data: any) => {
  return {
    type: actionTypes.UNSUSPEND_EMPLOYEE_TRIP_SUCCESS,
    msg,
    data,
  };
};
export const unSuspendEmployeeTripFail = (error: any) => {
  return {
    type: actionTypes.UNSUSPEND_EMPLOYEE_TRIP_FAIL,
    error,
  };
};

export const unSuspendEmployeeTrip = (employeetrip_id: any) => {
  return (dispatch: any) => {
    dispatch(unSuspendEmployeeTripStart());
    let data = { employeetrip_id };

    axios
      .post(
        `admin/employees/unsuspendtrip.php
        `,
        data
      )
      .then((res) => {
        console.log(res);
        dispatch(unSuspendEmployeeTripSuccess(res.data.msg, res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(unSuspendEmployeeTripFail(err.response.data.msg));
        } else {
          dispatch(unSuspendEmployeeTripFail('Internet Connection Failed'));
        }
      });
  };
};
// delete trip

export const deleteAssignEmployeeTripStart = () => {
  return {
    type: actionTypes.DELETE_EMPLOYEEASSIGNTRIP_START,
  };
};
export const deleteAssignEmployeeTripSuccess = (msg: any, id: any) => {
  return {
    type: actionTypes.DELETE_EMPLOYEEASSIGNTRIP_SUCCESS,
    msg,
    id,
  };
};
export const deleteAssignEmployeeTripFail = (error: any) => {
  return {
    type: actionTypes.DELETE_EMPLOYEEASSIGNTRIP_FAIL,
    error,
  };
};

export const deleteEmployeeAssignTrip = (employeetrip_id: any) => {
  return (dispatch: any) => {
    dispatch(deleteAssignEmployeeTripStart());
    let data = { employeetrip_id };

    axios
      .post(`admin/employees/deletetrip.php`, data)
      .then((res) => {
        console.log(res);
        dispatch(deleteAssignEmployeeTripSuccess(res.data.msg, employeetrip_id));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(deleteAssignEmployeeTripFail(err.response.data.msg));
        } else {
          dispatch(deleteAssignEmployeeTripFail('Internet Connection Failed'));
        }
      });
  };
};
