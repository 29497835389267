import { createStore, applyMiddleware, Store, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducers from './reducers/root.reducer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers =
  (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;
const store: Store = createStore(
  rootReducers,
  composeEnhancers(applyMiddleware(thunk))
);

store.subscribe(() => {});
export default store;
