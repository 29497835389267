import React, { Fragment, useEffect, useState, memo, Dispatch } from "react";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IStateType } from "../../../store/models/root.interface";
import * as actions from "../../../store/actions/index.actions";
import SingleMarkerMap from "../../Maps/SingleMarkerMap";
// import { usePosition } from '../../Maps/use-position';
import { isPropertySignature } from "typescript";
import Loader from "react-loader-spinner";
import SweetAlert from "react-bootstrap-sweetalert";
import TripsTable from "../../Admin/ManageTrips/TripsTable";
import { Translate } from "../../../utils/Translate";
const ChildByChild: React.FC = memo((props: any) => {
  let t = new Translate();
  const dispatch: Dispatch<any> = useDispatch();
  const trip: any = useSelector(
    (state: IStateType) => state.getdrivertrip.trip
  );
  const driver_id: any = useSelector(
    (state: IStateType) => state.auth.driverId
  );

  if (Object.keys(trip).length == 0) {
    dispatch(actions.fetchDriverTrip({ driver_id }));
  }
  useEffect(() => {
    dispatch(actions.fetchDriverTrip({ driver_id }));
  }, [dispatch]);
  const loading: any = useSelector(
    (state: IStateType) => state.getdrivertrip.loading
  );

  const to_next_child: any = useSelector(
    (state: IStateType) => state.drivertripdetails.data
  );
  const emergancyDone: any = useSelector(
    (state: IStateType) => state.drivertripdetails.emergancyDone
  );

  if (emergancyDone) {
    props.history.push("/");
  }
  if (loading === false && trip.assignedtrips.length <= 0) {
    props.history.push("/TripFinished");
  }

  const watch = true;
  // const { latitude, longitude, timestamp, error } = usePosition(watch);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  useEffect(() => {
    getLocation();
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      getLocation();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [latitude, longitude]);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      // x.innerHTML = "Geolocation is not supported by this browser.";
      alert("Geolocation is not supported by this browser.");
    }
  };

  const showPosition = (position: any) => {
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);
    setCoords({ lat: latitude, lng: longitude });
    console.log(position.coords.latitude + "  " + position.coords.longitude);
  };

  const [coords, setCoords] = useState<any>({});
  let [child_location_index, set_child_location_index] = useState(0);
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     // setCoords({ lat: latitude, lng: longitude });
  //     if (Object.keys(trip).length > 0) {
  //       let tripLogid = trip.triplog_id;
  //       const data = {
  //         driver_id: trip.driver_id,
  //         trip_id: trip.id,
  //         triplog_id: tripLogid,
  //         lat: +latitude,
  //         lng: +longitude,
  //         student_id: trip.assignedtrips[child_location_index].student_id,
  //       };
  //       dispatch(actions.drivertripdetails(data));
  //     }
  //   }, 5000);
  //   return () => clearInterval(intervalId);
  // }, [coords, child_location_index, trip]);

  useEffect(() => {
    setCoords({ lat: latitude, lng: longitude });
    if (Object.keys(trip).length > 0 && trip.assignedtrips.length > 0) {
      let tripLogid = trip.triplog_id;
      const data = {
        driver_id: trip.driver_id,
        trip_id: trip.id,
        triplog_id: tripLogid,
        lat: +latitude,
        lng: +longitude,
        student_id: trip.assignedtrips[child_location_index].student_id,
      };
      dispatch(actions.drivertripdetails(data));
    }
  }, [trip]);

  //////
  const [next_child_status, set_next_child_status] = useState("");
  useEffect(() => {
    if (to_next_child === "yes") {
      set_next_child_status("yes");
      // redux no
      dispatch(actions.changeYesToNo());
    }
  }, [to_next_child]);
  //////
  const [current_child_lat, setcurrentchildlat] = useState(
    Object.keys(trip).length !== 0 && trip.assignedtrips.length > 0
      ? trip.assignedtrips[0].location_lat
      : +latitude
  );
  const [current_child_lng, setcurrentchildlng] = useState(
    Object.keys(trip).length !== 0 && trip.assignedtrips.length > 0
      ? trip.assignedtrips[0].location_lng
      : +longitude
  );
  function current_child_selection() {
    if (child_location_index < trip.assignedtrips.length - 1 && picked) {
      set_child_location_index((state) => state + 1);
      console.log(child_location_index + " & " + trip.assignedtrips.length);
      setcurrentchildlat(trip.assignedtrips[child_location_index].location_lat);
      setcurrentchildlng(trip.assignedtrips[child_location_index].location_lng);
      set_next_child_status("no");
      // child_location_index = child_location_index + 1;
      let data = {
        pickup: picked,
        student_id: trip.assignedtrips[child_location_index].student_id,
        trip_id: trip.id,
      };
      // console.log(data);
      dispatch(actions.pickedChild(data));
    }
    if (child_location_index >= trip.assignedtrips.length - 1 && picked) {
      let data = {
        pickup: picked,
        student_id: trip.assignedtrips[child_location_index].student_id,
        trip_id: trip.id,
      };
      // console.log(data);
      dispatch(actions.pickedChild(data));
      props.history.push("/TripFinished");
    }
    //console.log(child_location_index + ' & ' + trip.assignedtrips.length);
  }

  const skipChild = () => {
    if (child_location_index < trip.assignedtrips.length - 1) {
      set_child_location_index((state) => state + 1);
      console.log(child_location_index + " & " + trip.assignedtrips.length);
      setcurrentchildlat(trip.assignedtrips[child_location_index].location_lat);
      setcurrentchildlng(trip.assignedtrips[child_location_index].location_lng);
      set_next_child_status("no");
      // child_location_index = child_location_index + 1;
      let data = {
        student_id: trip.assignedtrips[child_location_index].student_id,
        trip_id: trip.id,
      };
      // console.log(data);
      dispatch(actions.skipChild(data));
    }
    if (child_location_index >= trip.assignedtrips.length - 1) {
      let data = {
        student_id: trip.assignedtrips[child_location_index].student_id,
        trip_id: trip.id,
      };
      // console.log(data);
      dispatch(actions.skipChild(data));
      props.history.push("/TripFinished");
    }
  };
  const [picked, setPicked] = useState<any>("");

  const [showWarning, setShowWarning] = useState<boolean>(false);
  let warning = null;
  if (showWarning) {
    warning = (
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, i'm sure"
        confirmBtnBsStyle="success"
        title="Are you sure?"
        onConfirm={() => onEndTripEmergancy()}
        onCancel={() => setShowWarning(false)}
        focusCancelBtn
      >
        {t.translate("You will not be back to this trip")}
      </SweetAlert>
    );
  }
  const onEndTripEmergancy = () => {
    setShowWarning(false);
    const data = {
      trip_id: trip.id,
    };
    dispatch(actions.endTripEmergancy(data));
  };
  const [showSkipWarning, setShowSkipWarning] = useState<boolean>(false);
  let skipWarning = null;
  if (showSkipWarning) {
    skipWarning = (
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, i'm sure"
        confirmBtnBsStyle="success"
        title="Are you sure?"
        onConfirm={() => onSkipHandler()}
        onCancel={() => setShowSkipWarning(false)}
        focusCancelBtn
      >
        {t.translate("You will not be back to this child")}
      </SweetAlert>
    );
  }
  const onSkipHandler = () => {
    setShowSkipWarning(false);
    skipChild();
    // const data = {
    //   trip_id: trip.id,
    // };
    // dispatch(actions.driverendtrip(data));
  };
  const openGoogleMapsHandler = () => {};
  return (
    <Fragment>
      {loading === false && trip.assignedtrips.length > 0 ? (
        <div className="container">
          {warning}
          {skipWarning}
          <div className="col-12">
            <div className="justify-content-center text-center table-responsive">
              {Object.keys(trip).length > 0 ? (
                <table id="table" className="table table-dark table-hover ">
                  <thead>
                    <tr>
                      <th scope="col">{t.translate("Trip Name")}</th>
                      <th scope="col">{trip.tripname}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t.translate("Started")}:</td>
                      <td>{trip.finalstart}</td>
                    </tr>
                    <tr>
                      <td>{t.translate("Direction")}</td>
                      <td>{trip.direction}</td>
                    </tr>
                    <tr>
                      <td>{t.translate("Number Of Passengers")}</td>
                      <td>
                        {trip.assignedtrips.length + trip.employeetrips.length}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : null}
            </div>
          </div>
          <div className="col-12 row justify-content-between my-4">
            <div className="col-xs-12 col-sm-12 col-md-4 mt-1">
              <button
                className=" btn btn-block btn-warning text-white font-weight-bold p-2 text-wrap"
                onClick={() => setShowSkipWarning(true)}
              >
                {t.translate("Skip passenger")}
              </button>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 mt-1">
              {trip.assignedtrips.length > 0 ? (
                <a
                  href={`google.navigation:q=${trip.assignedtrips[child_location_index].location_lat},${trip.assignedtrips[child_location_index].location_lng}`}
                  className="btn btn-block btn-warning text-white font-weight-bold p-2 text-wrap"
                >
                  {t.translate("Show Navigation In Google Maps")}
                </a>
              ) : null}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 mt-1">
              <button
                className="btn btn-block btn-danger text-white font-weight-bold p-2 text-wrap"
                onClick={() => setShowWarning(true)}
              >
                {t.translate("emergency finish")}
              </button>
            </div>
          </div>
          <div className="col-12">
            <div className="justify-content-center text-center table-responsive">
              <div className="">
                {coords.lat && coords.lng && trip.assignedtrips.length > 0 ? (
                  <SingleMarkerMap
                    // direction={true}
                    current_loc={coords}
                    arrMarkers={{
                      lat: +trip.assignedtrips[child_location_index]
                        .location_lat,
                      lng: +trip.assignedtrips[child_location_index]
                        .location_lng,
                    }}
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg&v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBaD17OgMbI_0stUcRlvaeIvMuTet_rjdk`}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `450px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                  />
                ) : (
                  <div className="d-flex justify-content-center align-items-center mt-5">
                    <Loader
                      type="ThreeDots"
                      color="#00BFFF"
                      height={100}
                      width={100}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="text-center table-responsive mt-5">
            <table id="table" className="table table-dark mb-0">
              <thead>
                <tr>
                  <th scope="col">{t.translate("Passenger")}</th>
                  <th scope="col">
                    {trip.assignedtrips[child_location_index].name}
                  </th>
                </tr>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">
                    {trip.assignedtrips[child_location_index].student_id}
                  </th>
                </tr>
              </thead>
            </table>
            {trip.assignedtrips[child_location_index].handing_instructions ? (
              <table id="table" className="table table-dark mb-0">
                <tbody>
                  <tr>
                    <td>{t.translate("Full Address")}</td>
                  </tr>
                  <tr>
                    <td>{trip.assignedtrips[child_location_index].address}</td>
                  </tr>
                  <tr>
                    <td>{t.translate("Generic Notes")}</td>
                  </tr>
                  <tr>
                    <td>
                      {
                        trip.assignedtrips[child_location_index]
                          .handing_instructions
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : null}

            {trip.assignedtrips[child_location_index].collector_name ? (
              <div>
                <table id="table" className="table table-dark mb-0">
                  <tbody>
                    <tr>
                      <td>{t.translate("Collector")}</td>
                    </tr>
                  </tbody>
                </table>
                <table id="table" className="table table-dark mb-0">
                  <tbody>
                    <tr>
                      <td>{t.translate("Name")}</td>
                      <td>
                        {
                          trip.assignedtrips[child_location_index]
                            .collector_name
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>ID</td>
                      <td>
                        {trip.assignedtrips[child_location_index].collector_id}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : null}
          </div>
          {
            /* next_child_status === 'yes' */ true ? (
              <div className="row">
                <div className="row justify-content-center align-items-center">
                  <div className="form-check col-sm row align-items-center ">
                    <input
                      className="form-check-input col"
                      style={{
                        height: "25px",
                        width: "25px",
                      }}
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      value="yes"
                      onChange={(e: any) => setPicked(e.target.value)}
                    />
                    <label
                      className="form-check-label col"
                      htmlFor="flexRadioDefault1"
                    >
                      {t.translate("Picked")}
                    </label>
                  </div>
                  <div className="form-check col-sm row align-items-center">
                    <input
                      className="form-check-input col"
                      style={{
                        height: "25px",
                        width: "25px",
                      }}
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      value="no"
                      onChange={(e: any) => setPicked(e.target.value)}
                    />
                    <label
                      className="form-check-label col"
                      htmlFor="flexRadioDefault2"
                    >
                      {t.translate("Not picked")}
                    </label>
                  </div>
                </div>
                <button
                  className="btn btn-block btn-warning text-white font-weight-bold p-2 mt-3"
                  onClick={() => current_child_selection()}
                >
                  {t.translate("next passenger")}
                </button>
              </div>
            ) : null
          }
        </div>
      ) : null}
    </Fragment>
  );
});

export default withRouter(ChildByChild);
