import React, { Dispatch, Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../../store/actions/index.actions';
const LeftMenu: React.FC = () => {
  let [leftMenuVisibility, setLeftMenuVisibility] = useState(false);

  function changeLeftMenuVisibility() {
    setLeftMenuVisibility(!leftMenuVisibility);
  }

  function getCollapseClass() {
    return leftMenuVisibility ? '' : 'collapsed';
  }
  const dispatch: Dispatch<any> = useDispatch();
  return (
    <Fragment>
      <div className='toggle-area'>
        <button
          className='btn btn-primary toggle-button'
          onClick={() => changeLeftMenuVisibility()}
        >
          <i className='fas fa-bus-alt'></i>
        </button>
      </div>

      <ul
        className={`navbar-nav bg-gradient-primary-green sidebar sidebar-dark accordion ${getCollapseClass()}`}
        id='collapseMenu'
      >
        <a
          className='sidebar-brand d-flex align-items-center justify-content-center'
          href='index.html'
        >
          <div className='sidebar-brand-icon icon-green rotate-n-15'>
            <i className='fas fa-bus-alt'></i>
          </div>
          <div className='sidebar-brand-text mx-3'>
            TRANSPORT <sup>IVY</sup>
          </div>
        </a>

        <hr className='sidebar-divider my-0' />

        {/*<li className='nav-item active'>
          <Link className='nav-link' to='Home'>
            <i className='far fa-fw fa-bell'></i>
            <span>Notification</span>
          </Link>
          </li>*/}

        <hr className='sidebar-divider' />
        <div className='sidebar-heading'>Popular</div>

        <li className='nav-item'>
          <Link className='nav-link py-2 text-left ml-1' to={`/`}>
            <i className='fas fa-user-graduate'></i>
            <span>Students</span>
          </Link>
        </li>
        {/* <li className='nav-item'>
          <Link className='nav-link py-2 text-left' to={`/payments`}>
            <i className='fas fa-fw fa-dollar-sign'></i>
            <span>Payments</span>
          </Link>
        </li> */}

        {/*<li className='nav-item'>
          <Link className='nav-link' to={`/`}>
            <i className='fas fa-fw fa-edit'></i>
            <span>Edit Handing Instructions</span>
          </Link>
        </li>*/}

        <hr className='sidebar-divider' />

        <div className='sidebar-heading'>User</div>

        {/* <li className='nav-item'>
          <Link className='nav-link' to={`/users`}>
            <i className='fas fa-fw fa-user'></i>
            <span>Users</span>
          </Link>
        </li> */}

        <li className='nav-item'>
          <Link className='nav-link' to='{Login' onClick={() => dispatch(actions.logout())}>
            <i className='fas fa-power-off'></i>
            <span>Logout</span>
          </Link>
        </li>

        <hr className='sidebar-divider d-none d-md-block' />
      </ul>
    </Fragment>
  );
};

export default LeftMenu;
