import React, { Dispatch, Fragment, useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { IStateType } from '../../../store/models/root.interface';
import * as actions from '../../../store/actions/index.actions';
import SweetAlert from 'react-bootstrap-sweetalert';
import Map from '../../Maps/Map';
import Loader from 'react-loader-spinner';
import axios from 'axios';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Translate } from '../../../utils/Translate';

const AssignStudent: React.FC = memo((props: any) => {
  // console.log(props);

  if (props.location.state === undefined) {
    props.history.push('/allstudents');
  }
  const { id, fullName } = props.location.state;
  const dispatch: Dispatch<any> = useDispatch();

  const areas = useSelector((state: IStateType) => state.assigntrip.areas);
  const trips = useSelector((state: IStateType) => state.managetrips.trips);
  const loadingTrip = useSelector(
    (state: IStateType) => state.assignedtrips.loadingTrip
  );
  const trip: any = useSelector(
    (state: IStateType) => state.assignedtrips.trip
  );
  const t = new Translate()
  useEffect(() => {
    dispatch(actions.fetchArea());
    dispatch(actions.fetchTrips(1, 1000));
    dispatch(actions.fetchAssignedTripforSTD(id));

  }, [dispatch]);

  const [selectedTripId, updateSelectedTripId] = useState<string>('');
  const [selectedStudentAreaId, updateStudentSelectedAreaId] = useState<string>('');
  const [writtenAddress, updateWrittenAddress] = useState<string>('');
  const [direction, updateDirection] = useState<string>('');
  const [handingInstructions, updateHandingInstructions] = useState<string>('');
  const [collectorName, updateCollectorName] = useState<string>('');
  const [collectorID, updateCollectorID] = useState<string>('');
  const [coordinates, setCoordinates] = useState<any>({
    lat: 29.98587619219674,
    lng: 31.181054687500005,
  });
  useEffect(() => {
    if (loadingTrip === false) {
      updateSelectedTripId(trip.trip_id);
      updateStudentSelectedAreaId(trip.area_id);
      if (trip.toschool == 1 && trip.tohome == 1) {
        updateDirection("both");
      } else if (trip.toschool == 1) {
        updateDirection("to home");
      } else {
        updateDirection("to the school");
      }
      updateWrittenAddress(trip.address);
      updateHandingInstructions(trip.handing_instructions);
      updateCollectorID(trip.collector_id);
      updateCollectorName(trip.collector_name);
      if (trip.location_lat) {
        setCoordinates({ lat: +trip.location_lat, lng: +trip.location_lng });
      }
    }
  }, [loadingTrip]);
  const loadingStudents = useSelector(
    (state: IStateType) => state.AllStudents.loadingStudents
  );
  const errorMsg = useSelector((state: IStateType) => state.AllStudents.error);
  const msg = useSelector((state: IStateType) => state.AllStudents.msg);

  const onConfirm = function (e: any) {
    e.preventDefault();
    const data = {
      student_id: id,
      direction: direction,
      trip_id: selectedTripId,
      area_id: selectedStudentAreaId,
      address: writtenAddress,
      location_lat: coordinates.lat,
      location_lng: coordinates.lng,
      handing_instructions: handingInstructions,
      collector_name: collectorName,
      collector_id: collectorID,
    };
    // console.log(data);
    dispatch(actions.assignTripToStudent(data));
  };

  //   let coordinates = { lat: 30, lng: 30 };

  const getChangeLocation = (coords: any) => {
    // console.log(coords);
    setCoordinates(coords);
  };

  //   console.log(coordinates);

  const onCancel = () => {
    dispatch(actions.clearMsgAllStudent());
  };

  const onSuccessCancel = () => {
    dispatch(actions.clearMsgAllStudent());
    props.history.push('/allstudents');
  };

  let forError = null;
  if (errorMsg) {
    forError = (
      <SweetAlert
        danger
        title='Warning'
        onConfirm={onCancel}
        onCancel={onCancel}
      >
        {errorMsg}
      </SweetAlert>
    );
  }
  let forSuccess = null;
  if (msg) {
    forSuccess = (
      <SweetAlert
        success
        title='Success'
        onConfirm={onSuccessCancel}
        onCancel={onSuccessCancel}
      >
        {msg}
      </SweetAlert>
    );
  }

  const [address, setAddress] = React.useState('');

  const handleSelect = async (value: any) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    // console.log(latLng);
    setCoordinates(latLng);
  };

  return (
    <Fragment>
      <Link
        to='/allstudents'
        className='btn btn-danger text-white font-weight-bold'
      >
        {t.translate("Back")}
      </Link>
      {loadingStudents === false ? (
        <form onSubmit={(e) => onConfirm(e)}>
          {forError}
          {forSuccess}
          <div className='row'>
            <div className='col-md-12'>
              <div className='row'>
                <div className='col-md-6 form-group justify-content-center text-center'>
                  <label className='text-white font-weight-bold text-center'>
                    {t.translate("Student Name")}
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    aria-label='Trip Name'
                    aria-describedby='Entering Trip Name'
                    disabled
                    value={fullName}
                  />
                </div>
                <div className='col-md-6 form-group justify-content-center text-center'>
                  <label className='text-white font-weight-bold text-center'>
                    {t.translate("Ivy-ID")}
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    aria-label='Trip Name'
                    aria-describedby='Entering Trip Name'
                    disabled
                    value={id}
                  />
                </div>
              </div>
            </div>
            <div className='d-flex mt-2 form-group justify-content-center text-center'>
              <div className='col-12'>
                <div className='col-12 row justify-content-center align-items-center'>
                  <div className="row justify-content-center align-items-center">
                    <label className="mr-1 col-auto">{t.translate("Search location")} : </label>
                    <div className='mt-3 col-12 col-sm-8'>
                      <PlacesAutocomplete
                        value={address}
                        onChange={setAddress}
                        onSelect={handleSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className='form-group'>
                            {/* <p>Latitude: {coordinates.lat}</p>
                            <p>Longitude: {coordinates.lng}</p> */}

                            <input
                              {...getInputProps({
                                placeholder: 'Type address',
                                className: 'form-control',
                              })}
                            />

                            <div>
                              {loading ? <div>{t.translate("...loading")}</div> : null}

                              {suggestions.map((suggestion) => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? '#41b6e6'
                                    : '#fff',
                                };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                      key: Math.random() * 1.2,
                                    })}
                                  >
                                    {suggestion.description}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  </div>
                  <div className='col-md-12 align-items-center'>
                    <div className=''>
                      <Map
                        coordinates={coordinates}
                        getChangeLocation={getChangeLocation}
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg&v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBaD17OgMbI_0stUcRlvaeIvMuTet_rjdk`}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `300px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                      />
                    </div>
                  </div>
                  <div className='col-sm-12 align-items-center'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <label className='text-white font-weight-bold text-center'>
                          {t.translate("Direction")}
                        </label>
                        <select
                          className='form-control text-center'
                          aria-label='Direction'
                          onChange={(e) =>
                            updateDirection(e.target.value)
                          }
                          value={direction}
                          required
                        >
                          <option value=''>{t.translate("Choose Direction")}</option>
                          <option value='both'>{t.translate("both Ways")}</option>
                          <option value='to the school'>{t.translate("to the school")}</option>
                          <option value='to home'>{t.translate("to home")}</option>
                        </select>
                      </div>
                    </div>





                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='form-group text-center'>
                          <label className='text-white font-weight-bold'>
                            {t.translate("Written address")}
                          </label>
                          <textarea
                            className='form-control'
                            id='exampleFormControlTextaaddress1'
                            onChange={(e) =>
                              updateWrittenAddress(e.target.value)
                            }
                            value={writtenAddress}
                            required
                          >
                            {' '}
                          </textarea>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-6 form-group'>
                        <label className='text-white font-weight-bold text-center'>
                          {t.translate("Area")}
                        </label>
                        <select
                          className='form-control text-center'
                          aria-label='Area'
                          onChange={(e) =>
                            updateStudentSelectedAreaId(e.target.value)
                          }
                          required
                          value={selectedStudentAreaId}
                        >
                          <option value=''>Choose Area</option>
                          {areas.map((area: any) => {
                            return (
                              <option key={area.id} value={area.id}>
                                {area.areaname}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className='col-md-6 form-group'>
                        <label className='text-white font-weight-bold text-center'>
                          {t.translate("Trip")}
                        </label>
                        <select
                          className='form-control text-center'
                          aria-label='Trip'
                          onChange={(e) => updateSelectedTripId(e.target.value)}
                          required
                          value={selectedTripId}
                        >
                          <option value=''>Choose Trip</option>
                          {trips.map((trip: any) => {
                            return (
                              <option key={trip.id} value={trip.id}>
                                {trip.tripname} - {trip.direction}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='form-group justify-content-center text-center'>
                        <label className='text-white font-weight-bold'>
                          {t.translate("Handing instruction")}
                        </label>
                        <textarea
                          className='form-control'
                          id='exampleFormControlTextarea1'
                          onChange={(e) =>
                            updateHandingInstructions(e.target.value)
                          }
                          value={handingInstructions}
                          required
                        ></textarea>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-12'>
                        <label className='text-white font-weight-bold text-center'>
                          {t.translate("Collector Name")}
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          aria-label='Trip Name'
                          aria-describedby='Entering Trip Name'
                          onChange={(e) => updateCollectorName(e.target.value)}
                          value={collectorName}
                        />
                      </div>
                    </div>
                    <div className='row mt-1'>
                      <div className='col-md-12'>
                        <label className='text-white font-weight-bold text-center'>
                          {t.translate("Collector ID")}
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          aria-label='Trip Name'
                          aria-describedby='Entering Trip Name'
                          onChange={(e) => updateCollectorID(e.target.value)}
                          value={collectorID}
                        />
                        <button className='btn btn-block btn-warning text-white font-weight-bold mt-3'>
                          {t.translate("Submit")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div className='d-flex justify-content-center align-items-center mt-5'>
          <Loader type='ThreeDots' color='#00BFFF' height={100} width={100} />
        </div>
      )}
    </Fragment>
  );
});
export default withRouter(AssignStudent);
