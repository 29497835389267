import * as actionTypes from "../actionTypes";
import axios from "axios";

export const clearMsgReqtrips = () => {
  return {
    type: actionTypes.CLEAR_MSG_REQTRIPS,
  };
};

export const fetchTripsStart = () => {
  return {
    type: actionTypes.FETCH_REQTRIPS_START,
  };
};
export const fetchTripsSuccess = (data: any) => {
  return {
    type: actionTypes.FETCH_REQTRIPS_SUCCESS,
    trips: data.trips,
    count: data.count,
  };
};
export const fetchTripsFail = (error: any) => {
  return {
    type: actionTypes.FETCH_REQTRIPS_FAIL,
    error,
  };
};

export const fetchAllRequestedTrips = (
  pageN: any,
  pageSize: any,
  search = ""
) => {
  return (dispatch: any) => {
    let data = { pagenumber: pageN, pagesize: pageSize, search };
    console.log(data);
    dispatch(fetchTripsStart());

    axios
      .post(
        `admin/getrequestedtrips.php
        `,
        data
      )
      .then((res) => {
        console.log(res);
        dispatch(fetchTripsSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fetchTripsFail(err.response.data.msg));
        } else {
          dispatch(fetchTripsFail("Internet Connection Failed"));
        }
      });
  };
};

export const fetchTripStart = () => {
  return {
    type: actionTypes.FETCH_REQUESTEDTRIP_START,
  };
};
export const fetchTripSuccess = (data: any) => {
  return {
    type: actionTypes.FETCH_REQUESTEDTRIP_SUCCESS,
    trip: data,
  };
};
export const fetchTripFail = (error: any) => {
  return {
    type: actionTypes.FETCH_REQUESTEDTRIP_FAIL,
    error,
  };
};

export const confirmTripStart = () => {
  return {
    type: actionTypes.CONFIRM_TRIP_START,
  };
};
export const confirmTripSuccess = (msg: string) => {
  return {
    type: actionTypes.CONFIRM_TRIP_SUCCESS,
    msg,
  };
};
export const confirmTripFail = (error: any) => {
  return {
    type: actionTypes.CONFIRM_TRIP_FAIL,
    error,
  };
};

export const fetchRequestedTrip = (tripid: string) => {
  return (dispatch: any) => {
    dispatch(fetchTripStart());

    axios
      .post(
        `admin/getrequestedtrip.php
        `,
        { id: tripid }
      )
      .then((res) => {
        dispatch(fetchTripSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fetchTripFail(err.response.data.msg));
        } else {
          dispatch(fetchTripFail("Internet Connection Failed"));
        }
      });
  };
};

export const confirmTrip = (data: any) => {
  return (dispatch: any) => {
    dispatch(confirmTripStart());

    axios
      .post(`admin/assigntrip.php`, data)
      .then((res) => {
        console.log(res);
        dispatch(confirmTripSuccess(res.data.msg));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(confirmTripFail(err.response.data.msg));
        } else {
          dispatch(confirmTripFail("Internet Connection Failed"));
        }
      });
  };
};

export const fastAssTripStart = () => {
  return {
    type: actionTypes.FAST_ASSTRIP_START,
  };
};
export const fastAssTripSuccess = (msg: string, tripId: any) => {
  return {
    type: actionTypes.FAST_ASSTRIP_SUCCESS,
    msg,
    tripId,
  };
};
export const fastAssTripFail = (error: any) => {
  return {
    type: actionTypes.FAST_ASSTRIP_FAIL,
    error,
  };
};
export const fastAssTrip = (data: any) => {
  return (dispatch: any) => {
    dispatch(fastAssTripStart());
    const tripId = data.requestedtrip_id;
    axios
      .post(`admin/fastassgintrip.php`, data)
      .then((res) => {
        console.log(res);
        dispatch(fastAssTripSuccess(res.data.msg, tripId));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fastAssTripFail(err.response.data.msg));
        } else {
          dispatch(fastAssTripFail("Internet Connection Failed"));
        }
      });
  };
};

export const rejectRequestedTripStart = () => {
  return {
    type: actionTypes.REJECT_REQ_TRIP_START,
  };
};
export const rejectRequestedTripSuccess = (msg: string, tripId: any) => {
  return {
    type: actionTypes.REJECT_REQ_TRIP_SUCCESS,
    msg,
    tripId,
  };
};
export const rejectRequestedTripFail = (error: any) => {
  return {
    type: actionTypes.REJECT_REQ_TRIP_FAIL,
    error,
  };
};
export const rejectRequestedTrip = (data: any) => {
  return (dispatch: any) => {
    dispatch(rejectRequestedTripStart());
    const tripId = data.requestedtrip_id;
    axios
      .post(`admin/rejectstudentrequest.php`, data)
      .then((res) => {
        console.log(res);
        dispatch(rejectRequestedTripSuccess(res.data.msg, tripId));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(rejectRequestedTripFail(err.response.data.msg));
        } else {
          dispatch(rejectRequestedTripFail("Internet Connection Failed"));
        }
      });
  };
};

export const fetchRejectReqTripsStart = () => {
  return {
    type: actionTypes.FETCH_REJECTED_REQ_TRIPS_START,
  };
};
export const fetchRejectReqTripsSuccess = (data: any) => {
  return {
    type: actionTypes.FETCH_REJECTED_REQ_TRIPS_SUCCESS,
    trips: data.trips,
    count: data.count,
  };
};
export const fetchRejectReqTripsFail = (error: any) => {
  return {
    type: actionTypes.FETCH_REJECTED_REQ_TRIPS_FAIL,
    error,
  };
};

export const fetchRejectReqTrips = (pageN: any, pageSize: any, search = "") => {
  return (dispatch: any) => {
    let data = { pagenumber: pageN, pagesize: pageSize, search };
    console.log(data);
    dispatch(fetchRejectReqTripsStart());

    axios
      .post(`admin/getrejectedtrips.php`, data)
      .then((res) => {
        console.log(res);
        dispatch(fetchRejectReqTripsSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fetchRejectReqTripsFail(err.response.data.msg));
        } else {
          dispatch(fetchRejectReqTripsFail("Internet Connection Failed"));
        }
      });
  };
};
