import { IActionBase, ITrips } from "../../models/root.interface";
import * as actionTypes from "../../actions/actionTypes";

import { updateObject } from "../../utility";

const initialState: ITrips = {
  trips: [],
  rejectedTrips: [],
  rejectedTripsLoading: null,
  rejectedTripsCount: 0,
  trakedTrips: {},
  trakedTripLoading: null,
  count: 0,
  loading: null,
  trip: null,
  updateLoading: false,
  loadingTrip: null,
  loadingUnAssignTrip: null,
  loadingSuspendTrip: null,
  error: null,
  msg: null,
};

const fetchTripsStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, loading: true });
};

const fetchTripsSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    trips: action.trips,
    count: action.count,
    loading: false,
    error: null,
  });
};

const fetchTripsFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loading: null,
  });
};

const fetchTripStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {loadingTrip: true });
};

const fetchTripSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    trip: action.trip,

    loadingTrip: false
    });
};

const fetchTripFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingTrip: null,
  });
};

const updateTripStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, updateLoading: true });
};

const updateTripSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    updateLoading: false,

    error: null,
    msg: action.msg,
  });
};

const updateTripFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    updateLoading: false,
  });
};
// unAssigned trip
const unAssignedTripStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, updateLoading: true });
};

const unAssignedTripSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    updateLoading: false,
    error: null,
    msg: action.msg,
  });
};

const unAssignedTripFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    updateLoading: false,
  });
};

// for set pointes for students
const setPointsStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, loading: true });
};

const setPointsSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    msg: action.msg,
    loading: false,
    error: null,
  });
};

const setPointsFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loading: null,
  });
};

// for tracked trip
const fetchTrakedPointsStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, trakedTripLoading: true });
};

const fetchTrakedPointsSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    trakedTrips: action.data,
    trakedTripLoading: false,
    error: null,
  });
};

const fetchTrakedPointsFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    trakedTripLoading: null,
  });
};

const clearMsgUpdateTrip = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: null,
    updateLoading: false,
    msg: null,
  });
};

const managetrips = (state: ITrips = initialState, action: IActionBase) => {
  switch (action.type) {
    case actionTypes.FETCH_TRIPS_START:
      return fetchTripsStart(state, action);
    case actionTypes.FETCH_TRIPS_SUCCESS:
      return fetchTripsSuccess(state, action);
    case actionTypes.FETCH_TRIPS_FAIL:
      return fetchTripsFail(state, action);

    case actionTypes.FETCH_TRIP_START:
      return fetchTripStart(state, action);
    case actionTypes.FETCH_TRIP_SUCCESS:
      return fetchTripSuccess(state, action);
    case actionTypes.FETCH_TRIP_FAIL:
      return fetchTripFail(state, action);

    case actionTypes.UPDATE_TRIP_START:
      return updateTripStart(state, action);
    case actionTypes.UPDATE_TRIP_SUCCESS:
      return updateTripSuccess(state, action);
    case actionTypes.UPDATE_TRIP_FAIL:
      return updateTripFail(state, action);

    case actionTypes.UNASSIGNED_TRIP_START:
      return unAssignedTripStart(state, action);
    case actionTypes.UNASSIGNED_TRIP_SUCCESS:
      return unAssignedTripSuccess(state, action);
    case actionTypes.UNASSIGNED_TRIP_FAIL:
      return unAssignedTripFail(state, action);

    case actionTypes.SET_POITNS_START:
      return setPointsStart(state, action);
    case actionTypes.SET_POITNS_SUCCESS:
      return setPointsSuccess(state, action);
    case actionTypes.SET_POITNS_FAIL:
      return setPointsFail(state, action);

    case actionTypes.FETCH_TRAKED_TRIP_START:
      return fetchTrakedPointsStart(state, action);
    case actionTypes.FETCH_TRAKED_TRIP_SUCCESS:
      return fetchTrakedPointsSuccess(state, action);
    case actionTypes.FETCH_TRAKED_TRIP_FAIL:
      return fetchTrakedPointsFail(state, action);

    case actionTypes.CLEAR_MSG_UPDATETRIP:
      return clearMsgUpdateTrip(state, action);

    default:
      return state;
  }
};
export default managetrips;
