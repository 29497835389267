import React, { Dispatch, Fragment, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IStateType } from "../../../store/models/root.interface";
import * as actions from "../../../store/actions/index.actions";
import { Translate } from "../../../utils/Translate";

const AllowEntry: React.FC = memo(() => {
  let t = new Translate();
  const dispatch: Dispatch<any> = useDispatch();
  const gaterequests = useSelector(
    (state: IStateType) => state.AllowEntry.gaterequests
  );
  const allowentry = useSelector(
    (state: IStateType) => state.AllowEntry.allowentry
  );
  const loadingRequests = useSelector(
    (state: IStateType) => state.AllowEntry.loadingRequests
  );
  const count = useSelector((state: IStateType) => state.AllowEntry.count);

  useEffect(() => {
    setTimeout(() => {
      dispatch(actions.fetchAllRequests(1, 100));
    }, 10000);
  }, [dispatch, gaterequests]);

  useEffect(() => {
    dispatch(actions.fetchAllRequests(1, 100));
  }, [dispatch, allowentry]);

  const allowEntry = (requestid: any, allow: any) => {
    dispatch(actions.allowEntry(requestid, allow));
    console.log(requestid);
  };
  return (
    <Fragment>
      <div className="justify-content-center align-items-center">
        <div className="table-responsive bg-light">
          {loadingRequests === false ? (
            <table
              className="table-hover table-bordered text-center"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th scope="col">{t.translate("Trip Name")}</th>
                  <th scope="col">{t.translate("Driver")}</th>
                  <th scope="col">{t.translate("Direction")}</th>
                  <th scope="col">{t.translate("Time")}</th>
                  <th scope="col">{t.translate("Bus Number")}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {gaterequests.map((request: any) => {
                  return (
                    <tr key={request.id}>
                      <td>{request.tripname}</td>
                      <td>
                        {request.displayname} - {request.mobile}
                      </td>
                      <td>{request.direction}</td>
                      <td>{request.time}</td>
                      <td>{request.busnumber}</td>
                      <td>
                        <button
                          // to='/AllowEntry'
                          onClick={() => allowEntry(request.id, "yes")}
                          className="btn btn-success text-white font-weight-bold m-1"
                        >
                          <i className="fas fa-check"></i>
                        </button>
                      </td>

                      <td>
                        <button
                          // to='/adminstration/EditTrip'
                          onClick={(e) => allowEntry(request.id, "no")}
                          className="btn btn-danger text-white font-weight-bold m-1"
                        >
                          <i className="fas fa-times"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
});

export default AllowEntry;
