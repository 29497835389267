import React, { Dispatch, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Modal from '../../../common/components/UI/Modal/Modal';
import { IStateType } from '../../../store/models/root.interface';
import * as actions from '../../../store/actions/index.actions';
const DriverWaiting: React.FC = (props:any) => {
  const dispatch: Dispatch<any> = useDispatch();
    const admin_response = useSelector((state: IStateType) => state.drivergateentry.data.allow);
    const requestgate_id = useSelector((state: IStateType) => state.driverrequestgate.data.id);
    useEffect(() => {
        const intervalId = setInterval(() => {
            dispatch(actions.drivergateentry({requestgate_id:requestgate_id}));
        }, 5000);
        return () => clearInterval(intervalId);
      }, [dispatch ,admin_response]);

    useEffect(() => {
      dispatch(actions.drivergateentry({requestgate_id:requestgate_id}));
      }, [dispatch]);
  function ConfirmHandler(){
    dispatch(actions.clearMsgDriverRequestGate());
    dispatch(actions.clearMsgDriverGateEntryStatus());
    props.history.push('/');
  }
  /*let modal = null;
  setTimeout(() => {
    modal = <Modal show={true} />;
  }, 15000);*/
  return (
    <Fragment>
      {admin_response==="yes"?<Modal show={true} width='300px'>
        <div className='row justify-content-center align-items-center'>
          <h5 className='text-white font-weight-bold  p-4 rounded'>
            It's fine now, please enter
          </h5>
          <button
            onClick={ConfirmHandler}
            className='btn btn-block btn-warning text-white font-weight-bold'
          >
            Confirm
          </button>
        </div>
      </Modal>:null}
      {admin_response==="no"?<Modal show={true} width='300px'>
        <div className='row justify-content-center align-items-center'>
          <h5 className='text-white font-weight-bold  p-4 rounded'>
            Sorry! Your request was declined. 
          </h5>
          <button
            onClick={ConfirmHandler}
            className='btn btn-block btn-warning text-white font-weight-bold'
          >
            Ok!
          </button>
        </div>
      </Modal>:null}
      <div className='container d-flex flex-column justify-content-center align-items-center'>
        <div className='col-12'>
          <h5 className='text-white font-weight-bold bg-dark p-4 rounded'>
            Wait for confirmation !
          </h5>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(DriverWaiting);
