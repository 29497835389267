import { IActionBase, ITrips } from "../../models/root.interface";
import * as actionTypes from "../../actions/actionTypes";

import { updateObject } from "../../utility";

const initialState: ITrips = {
  trips: [],
  rejectedTrips: [],
  rejectedTripsLoading: null,
  rejectedTripsCount: 0,
  trakedTrips: [],
  trakedTripLoading: null,
  count: 0,
  loading: null,
  trip: null,
  updateLoading: null,
  loadingTrip: null,
  loadingUnAssignTrip: null,
  loadingSuspendTrip: null,
  error: null,
  msg: null,
};

const fetchEmployeeTripsStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, loading: true });
};

const fetchEmployeeTripsSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    trips: action.trips,
    count: action.count,
    loading: false,
    error: null,
  });
};

const fetchEmployeeTripsFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const fetchEmployeeTripStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, loadingTrip: true });
};

const fetchEmployeeTripSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    trip: action.trip,
    loadingTrip: false,
    error: null,
  });
};

const fetchEmployeeTripFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingTrip: false,
  });
};

const updateAssEmployeeTripStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, loadingUnAssignTrip: true });
};

const updateAssEmployeeTripSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    loadingUnAssignTrip: false,
    error: null,
    msg: action.msg,
  });
};

const updateAssEmployeeTripFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingUnAssignTrip: false,
  });
};

// for suspend
const suspendEmployeeTripStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, loadingSuspendTrip: true });
};

const suspendEmployeeTripSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  let newArr = state.trips.map((trip: any) =>
    trip.id === action.data.id ? action.data : trip
  );
  console.log(newArr);
  return updateObject(state, {
    loadingSuspendTrip: false,
    error: null,
    msg: action.msg,
    trips: newArr,
  });
};

const suspendEmployeeTripFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingSuspendTrip: false,
  });
};

// for unSuspend
const unSuspendEmployeeTripStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, loadingSuspendTrip: true });
};

const unSuspendEmployeeTripSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  let newArr = state.trips.map((trip: any) =>
    trip.id === action.data.id ? action.data : trip
  );
  console.log(newArr);
  return updateObject(state, {
    loadingSuspendTrip: false,
    error: null,
    msg: action.msg,
    trips: newArr,
  });
};

const unSuspendEmployeeTripFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingSuspendTrip: false,
  });
};
// for Delete
const deleteAssignEmployeeTripStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, loadingSuspendTrip: true });
};

const deleteAssignEmployeeTripSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  let newArr = state.trips.filter((trip: any) => trip.id !== action.id);
  console.log(newArr);
  return updateObject(state, {
    loadingSuspendTrip: false,
    error: null,
    msg: action.msg,
    trips: newArr,
  });
};

const deleteAssignEmployeeTripFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingSuspendTrip: false,
  });
};

const clearMsgAssginEmployeeTrip = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: null,
    updateLoading: null,
    msg: null,
  });
};

const assignedemployeetrips = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  switch (action.type) {
    case actionTypes.FETCH_EMPLOYEEASSIGNTRIPS_START:
      return fetchEmployeeTripsStart(state, action);

    case actionTypes.FETCH_EMPLOYEEASSIGNTRIPS_SUCCESS:
      return fetchEmployeeTripsSuccess(state, action);

    case actionTypes.FETCH_EMPLOYEEASSIGNTRIPS_FAIL:
      return fetchEmployeeTripsFail(state, action);

    case actionTypes.FETCH_ASSIGNEMPLOYEETRIP_START:
      return fetchEmployeeTripStart(state, action);

    case actionTypes.FETCH_ASSIGNEMPLOYEETRIP_SUCCESS:
      return fetchEmployeeTripSuccess(state, action);

    case actionTypes.FETCH_ASSIGNEMPLOYEETRIP_FAIL:
      return fetchEmployeeTripFail(state, action);

    case actionTypes.UPDATEASS_EMPLOYEE_TRIP_START:
      return updateAssEmployeeTripStart(state, action);

    case actionTypes.UPDATEASS_EMPLOYEE_TRIP_SUCCESS:
      return updateAssEmployeeTripSuccess(state, action);

    case actionTypes.UPDATEASS_EMPLOYEE_TRIP_FAIL:
      return updateAssEmployeeTripFail(state, action);

    case actionTypes.SUSPEND_EMPLOYEE_TRIP_START:
      return suspendEmployeeTripStart(state, action);

    case actionTypes.SUSPEND_EMPLOYEE_TRIP_SUCCESS:
      return suspendEmployeeTripSuccess(state, action);

    case actionTypes.SUSPEND_EMPLOYEE_TRIP_FAIL:
      return suspendEmployeeTripFail(state, action);

    case actionTypes.UNSUSPEND_EMPLOYEE_TRIP_START:
      return unSuspendEmployeeTripStart(state, action);

    case actionTypes.UNSUSPEND_EMPLOYEE_TRIP_SUCCESS:
      return unSuspendEmployeeTripSuccess(state, action);

    case actionTypes.UNSUSPEND_EMPLOYEE_TRIP_FAIL:
      return unSuspendEmployeeTripFail(state, action);

    case actionTypes.DELETE_EMPLOYEEASSIGNTRIP_START:
      return deleteAssignEmployeeTripStart(state, action);

    case actionTypes.DELETE_EMPLOYEEASSIGNTRIP_SUCCESS:
      return deleteAssignEmployeeTripSuccess(state, action);

    case actionTypes.DELETE_EMPLOYEEASSIGNTRIP_FAIL:
      return deleteAssignEmployeeTripFail(state, action);

    case actionTypes.CLEAR_MSG_AssginEmployeeTrip:
      return clearMsgAssginEmployeeTrip(state, action);

    default:
      return state;
  }
};
export default assignedemployeetrips;
