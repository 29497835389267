import { IActionBase, Ichildren } from '../../models/root.interface';
import * as actionTypes from '../../actions/actionTypes';

import { updateObject } from '../../utility';

const initialState: Ichildren = {
  students_ids: [],
  direction: null,
  duration : null ,
  areas: [],
  loading: null,
  error: null,
  students: [],
  area_id: '',
  address: null,
  location_lat: null,
  location_lng: null,
  handing_instructions: null,
  collector_name: null,
  collector_id: null,
  finalAssignedStd: null,
  loadingAssignTrip: null,
};

// for get data form
const getChildren = (state: Ichildren = initialState, action: IActionBase) => {
  return updateObject(state, {
    students_ids: action.students_ids,
    direction: action.direction,
    duration :action.duration
  });
};
const getAddress = (state: Ichildren = initialState, action: IActionBase) => {
  return updateObject(state, {
    area_id: action.area_id,
    address: action.address,
  });
};
const getCoords = (state: Ichildren = initialState, action: IActionBase) => {
  return updateObject(state, {
    location_lat: action.lat,
    location_lng: action.lng,
  });
};

const getInstruction = (
  state: Ichildren = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    handing_instructions: action.handing_instructions,
    collector_name: action.collector_name,
    collector_id: action.collector_id,
  });
};

// for API Calling
const fetchAreasStart = (state: Ichildren, action: IActionBase) => {
  return updateObject(state, { error: null, loading: true });
};

const fetchAreasSuccess = (
  state: Ichildren = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    areas: action.areas,
    loading: false,
    error: null,
  });
};

const fetchAreasFail = (state: Ichildren, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};


const fetchLastRequestSuccess = (
  state: Ichildren = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    address: action.address,
    area_id: action.area_id,
    handing_instructions: action.handing_instructions,
    collector_name: action.collector_name,
    collector_id: action.collector_id,
    location_lng: action.location_lng,
    location_lat: action.location_lat,
    loading: false,
    error: null,
  });
};

const fetchStudentsStart = (state: Ichildren, action: IActionBase) => {
  return updateObject(state, { error: null, loading: true });
};

const fetchStudentsSuccess = (
  state: Ichildren = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    students: action.students,
    loading: false,
    error: null,
  });
};

const fetchStudentsFail = (state: Ichildren, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

// assign trip
const assignTripStart = (state: Ichildren, action: IActionBase) => {
  return updateObject(state, { error: null, loadingAssignTrip: true });
};

const assignTripSuccess = (
  state: Ichildren = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    finalAssignedStd: action.data,
    loadingAssignTrip: false,
    error: null,
  });
};

const assignTripFail = (state: Ichildren, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingAssignTrip: null,
  });
};

const assigntrip = (state: Ichildren = initialState, action: IActionBase) => {
  switch (action.type) {
    case actionTypes.GET_CHILDREN:
      return getChildren(state, action);
    case actionTypes.GET_ADDRESS:
      return getAddress(state, action);
    case actionTypes.GET_COORDS:
      return getCoords(state, action);
    case actionTypes.GET_INSTRUCTION:
      return getInstruction(state, action);

    case actionTypes.FETCH_AREA_START:
      return fetchAreasStart(state, action);
    case actionTypes.FETCH_AREA_SUCCESS:
      return fetchAreasSuccess(state, action);
    case actionTypes.FETCH_AREA_FAIL:
      return fetchAreasFail(state, action);

    case actionTypes.FETCH_STUDENTS_START:
      return fetchStudentsStart(state, action);
    case actionTypes.FETCH_STUDENTS_SUCCESS:
      return fetchStudentsSuccess(state, action);
    case actionTypes.FETCH_STUDENTS_FAIL:
      return fetchStudentsFail(state, action);

    case actionTypes.ASSIGN_TRIP_START:
      return assignTripStart(state, action);
    case actionTypes.ASSIGN_TRIP_SUCCESS:
      return assignTripSuccess(state, action);
    case actionTypes.ASSIGN_TRIP_FAIL:
      return assignTripFail(state, action);
    case actionTypes.FETCH_LastRequest_SUCCESS:
      return fetchLastRequestSuccess(state, action);

    default:
      return state;
  }
};
export default assigntrip;
