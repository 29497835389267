import React, { Dispatch, useEffect, useState } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPath } from "../../../store/actions/root.actions";
import parent_avatar from "../../../assets/parent_avatar.png";
import ivy_logo from "../../../assets/ivy_logo_min.png";
import harmony_logo from "../../../assets/harmonylogo_min.png";
import RequestGate from "./RequestGate";
import DriverWaiting from "./DriverWaiting";
import StartTrip from "./StartTrip";
import TripFinished from "./TripFinished";
import ChildByChild from "./ChildByChild";
import { clearMsgDriverEndTrip } from "../../../store/actions/driver/driverendtrip.actions";
import { clearMsgDriverGetTrip } from "../../../store/actions/driver/getdrivertrip.actions";
import { IStateType } from "../../../store/models/root.interface";
import * as actions from "../../../store/actions/index.actions";
import Loader from "react-loader-spinner";
import StudentQrCode from "../../StudentQrCode/StudentQrCode";
import StudentModal from "../../StudentQrCode/StudentModal";
import Select from "react-select";
import { Translate } from "../../../utils/Translate";
import axios from "axios";
import Swal from "sweetalert2";
const Home: React.FC = (props: any) => {
  let t = new Translate();
  const dispatch: Dispatch<any> = useDispatch();
  const driver_id: any = useSelector(
    (state: IStateType) => state.auth.driverId
  );
  // const trip: any = useSelector(
  //   (state: IStateType) => state.getdrivertrip.trip
  // );
  const [IsLoading, SetIsLoading] = useState<boolean>(true);
  const [qrCode, setQrCode] = useState(false);
  const [studentModal, setStudentModal] = useState(false);
  const [assigned, setAssigned] = useState(true);
  const [student, setStudent] = useState<any>();
  const [checkedStudents, SetCheckedStudents] = useState<any>([]);
  const [decodedQrCode, setDecodedQr] = useState("");
  const [selStudent, setSelStudent] = useState<any>(null);
  const [picked, setPicked] = useState("");

  const trip: any = useSelector(
    (state: IStateType) => state.getdrivertrip.trip
  );
  const errorNoTrip = useSelector(
    (state: IStateType) => state.getdrivertrip.error
  );

  const submitStudent = async (temp: any) => {
    const text = decodedQrCode;

    const student_id = text.split(":")[0];
    if (student_id) {
      const data = {
        student_id: student_id,
        entrytempreture: "",
        temperature: temp,
        trip_id: trip.id,
        triplog: trip.triplog_id,
        direction: trip.direction,
        assigned: student.assigned,
      };
      axios
        .post(
          `${process.env.REACT_APP_BASEURL}/student/qrcodeattendance.php`,
          data
        )
        .then((res) => {
          console.log(res);
          if (res.data.data) {
            SetCheckedStudents((prev: []) => {
              let i = prev.findIndex((s: any) => s.id == res.data.data.id);
              if (i === -1) {
                return [...prev, res.data.data];
              } else {
                return prev;
              }
            });
          } else {
          }
          setQrCode(false);
        })
        .catch((e) => {
          let ee = e as any;
          Swal.fire(ee?.response?.data.msg || "Failed to login", "", "error");
        });
    }
  };

  const handleScan = async (result: any) => {
    setDecodedQr(result.data.data);
    const student_id = result.data.data.split(":")[0];
    const res = await axios.post(
      `${process.env.REACT_APP_BASEURL}/student/getstudent.php`,
      { student_id: student_id, driver_id: driver_id }
    );
    console.log(res);
    if (res.data.data) {
      setStudent(res.data.data);
      setStudentModal(true);
      setSelStudent(null);
    } else {
    }
    setQrCode(false);
  };

  //dispatch(clearMsgDriverRequestGate());
  useEffect(() => {
    dispatch(updateCurrentPath("home", ""));
    dispatch(clearMsgDriverEndTrip());
    dispatch(clearMsgDriverGetTrip());
    dispatch(actions.fetchDriverTrip({ driver_id }));
    dispatch(actions.fetchBus());
    dispatch(actions.clearTripEmergancy());
    setTimeout(() => {
      SetIsLoading(false);
    }, 2000);
  }, [dispatch]);

  const options = trip?.all_students?.map((student: any) => {
    return { value: student.id, label: `${student.id}:${student.fullname}` };
  });
  if (options?.length !== 0 && IsLoading === false && assigned) {
    trip?.assignedstudents?.map((std: any) => {
      SetCheckedStudents((prev: []) => {
        let i = prev.findIndex((s: any) => s.id == std.id);
        if (i === -1) {
          return [...prev, std];
        } else {
          return prev;
        }
      });
    });
    setAssigned(false);
  }

  if (IsLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center mt-5">
        <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
      </div>
    );
  } else
    return (
      <div style={{ position: "relative" }}>
        <div className="text-center">
          <h2
            className="text-white"
            style={{
              textShadow:
                "0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)",
              fontSize: "2rem",
            }}
          >
            {t.translate("IVY-IS Transportation")}
          </h2>
        </div>
        <div
          className="row justify-content-center align-content-between"
          style={{ height: "82vh" }}
        >
          <div className="col-12 row justify-content-center align-content-center text-center text-white">
            <img
              src={parent_avatar}
              height="100px"
              className="rounded d-block "
              style={{ width: "auto" }}
              alt="..."
            />
            <div className="mb-5 row col-12 justify-content-center">
              {!errorNoTrip ? (
                <div className=" blackColor col-10">
                  {qrCode ? <StudentQrCode handleScan={handleScan} /> : ""}
                  {!qrCode && (
                    <button
                      className="btn btn-success col-6 btn-lg mt-2 mb-5 "
                      onClick={() => {
                        setQrCode(true);
                      }}
                    >
                      {t.translate("Qr code")}
                    </button>
                  )}

                  <StudentModal
                    modal={studentModal}
                    student={student}
                    submitStudent={submitStudent}
                    setStudentModal={setStudentModal}
                    setQrCode={setQrCode}
                  />
                  <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                      <Select
                        options={options}
                        onChange={(val) => {
                          console.log(val);
                          setSelStudent(val);
                        }}
                        value={selStudent}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <button
                        className="btn btn-success"
                        onClick={() => {
                          if (selStudent == null) {
                            Swal.fire({
                              title: "Error!",
                              text: "Please, Select a Student first!",
                              icon: "error",
                            });
                          } else {
                            handleScan({ data: { data: selStudent.value } });
                          }
                        }}
                      >
                        {t.translate("Confirm Selected Student")}
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {!errorNoTrip ? (
            <div className="col-12">
              <div className="justify-content-center text-center table-responsive">
                <table id="table" className="table table-dark table-hover ">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">{t.translate("Student ID")}</th>
                      <th scope="col">{t.translate("Student Name")}</th>
                      <th scope="col">{t.translate("Grade Level")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkedStudents.map((s: any, i: number) => {
                      let c = "text-success";
                      if (s.assigned == "no") {
                        c = " text-danger";
                      }
                      return (
                        <tr key={i + 1} className={c}>
                          <td>{i + 1}</td>
                          <td>{s.id}</td>
                          <td>{s.fullname}</td>
                          <td>{s.grade}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}
          <Switch>
            <Route path="/childbychild">
              <ChildByChild />
            </Route>
            <Route path="/requestgate">
              <RequestGate />
            </Route>
            <Route path="/driverwaiting">
              <DriverWaiting />
            </Route>
            <Route path="/tripfinished">
              <TripFinished />
            </Route>
            <Route path="/">
              <StartTrip />
            </Route>
          </Switch>

          <div className="container d-flex justify-content-center align-items-center m-3">
            <div>
              <img
                src={ivy_logo}
                height="100px"
                className="rounded d-block "
                style={{ width: "auto" }}
                alt="..."
              />
            </div>
            <div>
              <img
                src={harmony_logo}
                height="40px"
                className="rounded d-block"
                style={{ width: "auto" }}
                alt="..."
              />
            </div>
          </div>
        </div>
      </div>
    );
};

export default withRouter(Home);
