import { IActionBase, ITrips } from '../../models/root.interface';
import * as actionTypes from '../../actions/actionTypes';

import { updateObject } from '../../utility';

const initialState: any = {
  employees: null,
  count: 0,
  loadingEmployees: null,
  error: null,
  msg: null,
};

const fetchEmployeesStart = (state: any, action: IActionBase) => {
  return updateObject(state, { error: null, loadingEmployees: true });
};

const fetchEmployeesSuccess = (
  state: any = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    employees: action.employees,
    count: action.count,
    loadingEmployees: false,
    error: null,
  });
};

const fetchEmployeesFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingEmployees: false,
  });
};

const clearMsgAllEmployees = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: null,
    loadingEmployees: false,
    msg: null,
  });
};

//  for admin assign trip to employee
const assignTripEmployeeStart = (state: any, action: IActionBase) => {
  return updateObject(state, { error: null, loadingEmployees: true });
};

const assignTripEmployeeSuccess = (
  state: any = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    msg: action.msg,
    loadingEmployees: false,
    error: null,
  });
};

const assignTripEmployeeFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingEmployees: false,
  });
};

const assignedtrips = (state: ITrips = initialState, action: IActionBase) => {
  switch (action.type) {
    case actionTypes.FETCH_ALL_EMPLOYEES_START:
      return fetchEmployeesStart(state, action);
    case actionTypes.FETCH_ALL_EMPLOYEES_SUCCESS:
      return fetchEmployeesSuccess(state, action);
    case actionTypes.FETCH_ALL_EMPLOYEES_FAIL:
      return fetchEmployeesFail(state, action);
    case actionTypes.CLEAR_MSG_ALL_EMPLOYEES:
      return clearMsgAllEmployees(state, action);
    case actionTypes.ASSIGN_TRIP_EMPLOYEE_START:
      return assignTripEmployeeStart(state, action);
    case actionTypes.ASSIGN_TRIP_EMPLOYEE_SUCCESS:
      return assignTripEmployeeSuccess(state, action);
    case actionTypes.ASSIGN_TRIP_EMPLOYEE_FAIL:
      return assignTripEmployeeFail(state, action);
    default:
      return state;
  }
};
export default assignedtrips;
