import React, { Fragment, useEffect, Dispatch, memo } from "react";
import { Link, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import trips_tracker_sample from '../../../assets/trips_tracker_sample.png';
import { IStateType } from "../../../store/models/root.interface";
import * as actions from "../../../store/actions/index.actions";
// import MapForTrackStudent from '../../Maps/MapForTrackStudent';
import MultiMarkerMap from "../../Maps/MultiMarkerMap";
import { Translate } from "../../../utils/Translate";
import { Input } from "reactstrap";
const TrackTrip: React.FC = memo((props: any) => {
  let t = new Translate();
  let trip_id = "0";
  trip_id = JSON.parse(
    new URLSearchParams(props.location.search).get("id") || "0"
  );

  const dispatch: Dispatch<any> = useDispatch();

  const trip: any = useSelector((state: IStateType) => state.managetrips.trip);
  const loadingTrip = useSelector(
    (state: IStateType) => state.managetrips.loadingTrip
  );

  const trakedTrips: any = useSelector(
    (state: IStateType) => state.managetrips.trakedTrips
  );
  const trakedTripLoading = useSelector(
    (state: IStateType) => state.managetrips.trakedTripLoading
  );

  useEffect(() => {
    dispatch(actions.fetchTrip(trip_id));
  }, [dispatch]);

  let assignedtripsLatLng = null;
  if (loadingTrip === false && trip.assignedtrips.length > 0) {
    assignedtripsLatLng = trip.assignedtrips?.map((tsp: any) => {
      return {
        name: tsp.name,
        lat: +tsp.location_lat,
        lng: +tsp.location_lng,
      };
    });
    // console.log(assignedtripsLatLng);
  }
  let arrivedStudents = null;
  if (trakedTripLoading === false && trakedTrips.arrivedstudents.length > 0) {
    arrivedStudents = trakedTrips.arrivedstudents?.map((tsp: any) => {
      return {
        name: tsp.name,
        lat: +tsp.location_lat,
        lng: +tsp.location_lng,
      };
    });
    // console.log(arrivedStudents);
  }
  let notArrivedStudents = null;
  if (
    trakedTripLoading === false &&
    trakedTrips.notarrivedstudents.length > 0
  ) {
    notArrivedStudents = trakedTrips.notarrivedstudents?.map((tsp: any) => {
      return {
        name: tsp.name,
        lat: +tsp.location_lat,
        lng: +tsp.location_lng,
      };
    });
    console.log(notArrivedStudents);
  }

  // const trackedData = useSelector(
  //   (state: IStateType) => state.trackTrip.trackedData
  // );

  // const loadingTrack = useSelector(
  //   (state: IStateType) => state.trackTrip.loadingTrack
  // );

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     dispatch(actions.trackTrip(trip_id));
  //   }, 5000);
  //   return () => clearInterval(intervalId);
  // }, [dispatch]);
  // useEffect(() => {
  //   dispatch(actions.trackTrip(trip_id));
  // }, []);

  const path = useSelector((state: IStateType) => state.drawPath.data);

  const loadingPath = useSelector(
    (state: IStateType) => state.drawPath.loading
  );
  useEffect(() => {
    dispatch(actions.drawPath(trip_id));
    dispatch(actions.fetchTrakedPoints({ trip_id }));
  }, []);
  const refreshMapHandler = () => {
    dispatch(actions.fetchTrip(trip_id));
    dispatch(actions.fetchTrakedPoints({ trip_id }));
    dispatch(actions.drawPath(trip_id));
  };

  let driver_full_path = null;
  console.log(notArrivedStudents, "path");
  if (loadingPath === false && path.length > 0 && path != null) {
    driver_full_path = path?.map((p: any) => {
      return {
        lat: +p.lat,
        lng: +p.lng,
      };
    });
    // console.log(assignedtripsLatLng);
  }
  return (
    <Fragment>
      <Link
        to="/TripsTable"
        className="btn btn-danger text-white font-weight-bold"
      >
        {t.translate("Back")}
      </Link>

      <div className="col-12">
        <div className="col-md-12">
          {loadingTrip === false &&
          loadingPath === false &&
          trakedTripLoading === false &&
          driver_full_path != null ? (
            <MultiMarkerMap
              arrivedStudents={arrivedStudents}
              notArrivedStudents={notArrivedStudents}
              driver_path={driver_full_path}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg&v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBaD17OgMbI_0stUcRlvaeIvMuTet_rjdk`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `500px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          ) : null}
        </div>
        {loadingTrip === false ? (
          <div className="col-md-12 mt-1">
            <div className="row text-white">
              <div className="col-md-4">
                <h3>
                  {t.translate("Trip Name")}: {trip.tripname}
                </h3>
              </div>
              <div className="col-md-4">
                <h3>
                  {t.translate("Status")}: {trip.status}
                </h3>
              </div>
              <div className="col-md-4">
                <h3>
                  {t.translate("Driver")}: {trip.drivername} -{" "}
                  {trip.drivernumber}
                </h3>
              </div>
            </div>
          </div>
        ) : null}
        <div className="col-md-12 row justify-content-center mt-3">
          <button
            onClick={refreshMapHandler}
            className="btn btn-warning col-sm-3"
          >
            {t.translate("Get live location")}
          </button>
        </div>
      </div>
    </Fragment>
  );
});
export default withRouter(TrackTrip);
