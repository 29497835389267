import React, { Dispatch, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import ivy_logo from "../../assets/ivy_logo_min.png";
import harmony_logo from "../../assets/harmonylogo_min.png";
import { useDispatch } from "react-redux";
import * as actions from "../../store/actions/index.actions";
import { Translate } from "../../utils/Translate";

const AdminLeftMenu: React.FC = () => {
  let t = new Translate();
  let [leftMenuVisibility, setLeftMenuVisibility] = useState(false);

  function changeLeftMenuVisibility() {
    setLeftMenuVisibility(!leftMenuVisibility);
  }

  function getCollapseClass() {
    return leftMenuVisibility ? "" : "collapsed";
  }
  const dispatch: Dispatch<any> = useDispatch();
  return (
    <Fragment>
      <div className="toggle-area">
        <button
          className="btn btn-primary toggle-button"
          onClick={() => changeLeftMenuVisibility()}
        >
          <i className="fas fa-bus-alt"></i>
        </button>
      </div>

      <ul
        className={`navbar-nav bg-gradient-primary-green sidebar sidebar-dark accordion ${getCollapseClass()}`}
        id="collapseMenu"
      >
        <a
          className="sidebar-brand d-flex align-items-center justify-content-center"
          href="index.html"
        >
          <div className="sidebar-brand-icon icon-green rotate-n-15">
            <i className="fas fa-bus-alt"></i>
          </div>
          <div className="sidebar-brand-text mx-3">
            {t.translate("TRANSPORT")} <sup>IVY</sup>
          </div>
        </a>

        <hr className="sidebar-divider my-0" />
        <div className="sidebar-heading text-center">
          {t.translate("Trips")}
        </div>

        <hr className="sidebar-divider my-1" />

        <li className="nav-item">
          <Link className="nav-link" to={`/TripsTable`}>
            <i className="fas fa-cog"></i>
            <span>{t.translate("Manage Trips")}</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={`/Tripanalytics`}>
            <i className="fas fa-chart-bar"></i>
            <span>{t.translate("Trip Analytics(Google Routes)")}</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={`/TripanalyticsM`}>
            <i className="fas fa-chart-bar"></i>
            <span>{t.translate("Trip Analytics(Manual Routes)")}</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={`/Locations`}>
            <i className="fas fa-plus-square"></i>
            <span>{t.translate("Locations")}</span>
          </Link>
        </li>

        {/*         <li className="nav-item">
          <Link className="nav-link" to={`/AddTrip`}>
            <i className="fas fa-plus-square"></i>
            <span>Add Trip</span>
          </Link>
        </li> */}

        <li className="nav-item">
          <Link className="nav-link" to={`/AddBus`}>
            <i className="fas fa-bus-alt"></i>
            <span>{t.translate("School Buses")}</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to={`/AllowEntry`}>
            <i className="fas fa-check-circle"></i>
            <span>{t.translate("Allow Entry")}</span>
          </Link>
        </li>
        <hr className="sidebar-divider my-1" />

        <div className="sidebar-heading text-center">
          {t.translate("Students Managment")}
        </div>

        <hr className="sidebar-divider my-1" />
        <li className="nav-item">
          <Link className="nav-link" to={"/ClusterOverview"}>
            <i className="fas fa-eye"></i>
            <span> {t.translate("Clusters Overview")}</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={`/AssignedStudents`}>
            <i className="fas fa-user-edit"></i>
            <span> {t.translate("Modify Student's Trip")}</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to={`/PendingStudents`}>
            <i className="fas fa-vote-yea"></i>

            <span>{t.translate("Confirm Student's Trip")}</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={`/RejectedRequests`}>
            <i className="fas fa-times-circle"></i>

            <span>{t.translate("Rejected Requests")}</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={`/TrashedStudents`}>
            <i className="fas fa-solid fa-trash"></i>

            <span>{t.translate("Trashed Students' Trips")}</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={`/AllStudents`}>
            <i className="fas fa-user-check"></i>
            <span>{t.translate("All Students")}</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={`/bustracking`}>
            <i className="fas fa-solid fa-bus"></i>
            <span>{t.translate("Bus tracking")}</span>
          </Link>
        </li>

        <div className="sidebar-heading text-center mt-5">
          {t.translate("Employees Managment")}
        </div>

        <li className="nav-item">
          <Link className="nav-link" to={`/AllEmployees`}>
            <i className="fas fa-user-check"></i>
            <span>{t.translate("All Employees")}</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to={`/AssignedEmployees`}>
            <i className="fas fa-user-edit"></i>
            <span>{t.translate("Modify Employee's Trip")}</span>
          </Link>
        </li>

        <hr className="sidebar-divider" />

        <div className="sidebar-heading text-center mt-5">
          {t.translate("Reports")}
        </div>

        <li className="nav-item">
          <Link className="nav-link" to={`/Tripreport`}>
            <i className="fas fa-chart-bar"></i>
            <span>{t.translate("Trip Report")}</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to={`/Busreport`}>
            <i className="fas fa-bus-alt"></i>
            <span>{t.translate("Bus Report")}</span>{" "}
          </Link>
        </li>

        <hr className="sidebar-divider" />

        <div className="sidebar-heading text-center">{t.translate("User")}</div>

        {/* <li className='nav-item'>
          <Link className='nav-link' to={`/users`}>
            <i className='fas fa-fw fa-user'></i>
            <span>Users</span>
          </Link>
        </li> */}

        <li className="nav-item">
          <Link
            className="nav-link"
            to="{Login"
            onClick={() => dispatch(actions.logout())}
          >
            <i className="fas fa-power-off"></i>
            <span>{t.translate("Logout")}</span>
          </Link>
        </li>

        <div className="row justify-content-center align-items-center">
          <div className="col-12 row justify-content-center align-items-center mt-3">
            <img
              src={ivy_logo}
              height="80px"
              className="rounded d-block "
              style={{ width: "auto" }}
              alt="..."
            />
          </div>
          <div className="col-12 row justify-content-center align-items-center mt-3">
            <img
              src={harmony_logo}
              height="30px"
              className="rounded d-block"
              style={{ width: "auto" }}
              alt="..."
            />
          </div>
        </div>
        <hr className="sidebar-divider d-none d-md-block" />
      </ul>
    </Fragment>
  );
};

export default AdminLeftMenu;
