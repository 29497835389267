import React, { Dispatch, Fragment, useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter, Redirect } from "react-router-dom";
import { IStateType } from "../../../store/models/root.interface";
import * as actions from "../../../store/actions/index.actions";
import SweetAlert from "react-bootstrap-sweetalert";
import Map from "../../Maps/Map";
import Loader from "react-loader-spinner";
import { Translate } from "../../../utils/Translate";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const AssignEmployee: React.FC = memo((props: any) => {
  let t = new Translate();
  if (props.location.state === undefined) {
    props.history.push("/allemployees");
  }
  const { id, fullName } = props.location.state;
  const dispatch: Dispatch<any> = useDispatch();

  const areas = useSelector((state: IStateType) => state.assigntrip.areas);
  const trips = useSelector((state: IStateType) => state.managetrips.trips);

  useEffect(() => {
    dispatch(actions.fetchArea());
    dispatch(actions.fetchTrips(1, 1000));
  }, [dispatch]);

  const [selectedTripId, updateSelectedTripId] = useState<string>("");
  const [selectedDirectionId, updateSelectedDirectionId] = useState<string>("");
  const [selectedEmployeeAreaId, updateEmployeeSelectedAreaId] =
    useState<string>("");
  const [writtenAddress, updateWrittenAddress] = useState<string>("");
  const [coordinates, setCoordinates] = useState<any>({
    lat: 29.98587619219674,
    lng: 31.181054687500005,
  });

  const loadingEmployees = useSelector(
    (state: IStateType) => state.AllEmployees.loadingEmployees
  );
  const errorMsg = useSelector((state: IStateType) => state.AllEmployees.error);
  const msg = useSelector((state: IStateType) => state.AllEmployees.msg);

  const onConfirm = function (e: any) {
    e.preventDefault();
    const data = {
      employee_id: id,
      direction: selectedDirectionId,
      trip_id: selectedTripId,
      area_id: selectedEmployeeAreaId,
      address: writtenAddress,
      location_lat: coordinates.lat,
      location_lng: coordinates.lng,
    };
    dispatch(actions.assignTripToEmployee(data));
  };
  const getChangeLocation = (coords: any) => {
    setCoordinates(coords);
  };

  const onCancel = () => {
    dispatch(actions.clearMsgAllEmployee());
  };

  const onSuccessCancel = () => {
    dispatch(actions.clearMsgAllEmployee());
    props.history.push("/allemployees");
  };

  let forError = null;
  if (errorMsg) {
    forError = (
      <SweetAlert
        danger
        title="Warning"
        onConfirm={onCancel}
        onCancel={onCancel}
      >
        {errorMsg}
      </SweetAlert>
    );
  }
  let forSuccess = null;
  if (msg) {
    forSuccess = (
      <SweetAlert
        success
        title="Success"
        onConfirm={onSuccessCancel}
        onCancel={onSuccessCancel}
      >
        {msg}
      </SweetAlert>
    );
  }

  const [address, setAddress] = React.useState("");

  const handleSelect = async (value: any) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    // console.log(latLng);
    setCoordinates(latLng);
  };

  return (
    <Fragment>
      <Link
        to="/allemployees"
        className="btn btn-danger text-white font-weight-bold"
      >
        {t.translate("Back")}
      </Link>
      {loadingEmployees === false ? (
        <form onSubmit={(e) => onConfirm(e)}>
          {forError}
          {forSuccess}
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6 form-group justify-content-center text-center">
                  <label className="text-white font-weight-bold text-center">
                    {t.translate("Employee Name")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Trip Name"
                    aria-describedby="Entering Trip Name"
                    disabled
                    value={fullName}
                  />
                </div>
                <div className="col-md-6 form-group justify-content-center text-center">
                  <label className="text-white font-weight-bold text-center">
                    Ivy-ID
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Trip Name"
                    aria-describedby="Entering Trip Name"
                    disabled
                    value={id}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex mt-2 form-group justify-content-center text-center">
              <div className="col-12">
                <div className="col-12 row justify-content-center align-items-center">
                  <div className="row justify-content-center align-items-center">
                    <label className="mr-1 col-auto">
                      {t.translate("Search location")} :{" "}
                    </label>
                    <div className="mt-3 col-12 col-sm-8">
                      <PlacesAutocomplete
                        value={address}
                        onChange={setAddress}
                        onSelect={handleSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className="form-group">
                            {/* <p>Latitude: {coordinates.lat}</p>
                            <p>Longitude: {coordinates.lng}</p> */}

                            <input
                              {...getInputProps({
                                placeholder: "Type address",
                                className: "form-control",
                              })}
                            />

                            <div>
                              {loading ? <div>...loading</div> : null}

                              {suggestions.map((suggestion) => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? "#41b6e6"
                                    : "#fff",
                                };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                      key: Math.random() * 1.2,
                                    })}
                                  >
                                    {suggestion.description}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  </div>
                  <div className="col-md-12 align-items-center">
                    <div className="">
                      <Map
                        coordinates={coordinates}
                        getChangeLocation={getChangeLocation}
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyBaD17OgMbI_0stUcRlvaeIvMuTet_rjdk&libraries=geometry,drawing,places`}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `300px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 align-items-center">
                    <div className="row">
                      <div className="col-md-12">
                        <label className="text-white font-weight-bold text-center">
                          {t.translate("Direction")}
                        </label>
                        <select
                          className="form-control text-center"
                          aria-label="Direction"
                          onChange={(e) =>
                            updateSelectedDirectionId(e.target.value)
                          }
                          value={selectedDirectionId}
                          required
                        >
                          <option value="">
                            {t.translate("Choose Direction")}
                          </option>
                          <option value="both">{t.translate("Both")}</option>
                          <option value="to the school">
                            {t.translate("to the school")}
                          </option>
                          <option value="to home">
                            {t.translate("to home")}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group text-center">
                          <label className="text-white font-weight-bold">
                            {t.translate("Written address")}
                          </label>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextaaddress1"
                            onChange={(e) =>
                              updateWrittenAddress(e.target.value)
                            }
                            value={writtenAddress}
                            required
                          >
                            {" "}
                          </textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <label className="text-white font-weight-bold text-center">
                          {t.translate("Area")}
                        </label>
                        <select
                          className="form-control text-center"
                          aria-label="Area"
                          onChange={(e) =>
                            updateEmployeeSelectedAreaId(e.target.value)
                          }
                          required
                          value={selectedEmployeeAreaId}
                        >
                          <option value="">{t.translate("Choose Area")}</option>
                          {areas.map((area: any) => {
                            return (
                              <option key={area.id} value={area.id}>
                                {area.areaname}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label className="text-white font-weight-bold text-center">
                          {t.translate("Trip")}
                        </label>
                        <select
                          className="form-control text-center"
                          aria-label="Trip"
                          onChange={(e) => updateSelectedTripId(e.target.value)}
                          required
                          value={selectedTripId}
                        >
                          <option value="">{t.translate("Choose Trip")}</option>
                          {trips.map((trip: any) => {
                            return (
                              <option key={trip.id} value={trip.id}>
                                {trip.tripname} - {trip.direction}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-md-12">
                        <button className="btn btn-block btn-warning text-white font-weight-bold mt-3">
                          {t.translate("Submit")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
        </div>
      )}
    </Fragment>
  );
});
export default withRouter(AssignEmployee);
