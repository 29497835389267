import firebase from 'firebase';
var firebaseConfig = {
  apiKey: 'AIzaSyAVNvbqKcRm5lsFXJcNYAxCU89QKgy8HYk',
  authDomain: 'transportation-503cb.firebaseapp.com',
  projectId: 'transportation-503cb',
  storageBucket: 'transportation-503cb.appspot.com',
  messagingSenderId: '111543490868',
  appId: '1:111543490868:web:7c850a459020fe828a6d61',
  measurementId: 'G-5WLEJ3ZFD6',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase;
