import React from 'react';
import Modal from 'react-modal';
import axios from 'axios';
// import ivy from '../../assets/ivy_logo_min.png';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  DirectionsRenderer
} from 'react-google-maps';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';

import ivy from "../../assets/ivy_logo_min.png";
console.log(require("@deck.gl/google-maps").GoogleMapsOverlay);
const { GoogleMapsOverlay } = require("@deck.gl/google-maps");
const { TripsLayer } = require("deck.gl");
// const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");
// import MarkerClusterer from '@google/markerclusterer';
const MySwal = withReactContent(Swal)

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

function arrayUnique(array: any) {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j])
        a.splice(j--, 1);
    }
  }

  return a;
}
const VENDOR_COLORS = [
  [255, 0, 0], // vendor #0
  [0, 0, 255], // vendor #1
];
//Modal.setAppElement("#root");
class Map extends React.Component<any, any> {
  state = {
    showInfo: false,
    showWindow: false,
    modalIsOpen: false,
    directions: undefined,
    progress: []
  }

  body: any = {}
  fastAssign: any = []
  selectedTrip: string = ""
  onInfoWindowClose = () => {
    this.setState({ showInfo: !this.state.showInfo })
  }
  req: any = window;
  handleMarkerClick = (x: any) => {
    this.fastAssign = [{ requestedtrip_id: x.r_id, id: x.id, trip_id: "" }];
    this.body = <div className="col-12 row">
      <div className="col-12 text-dark">
        <h4> Please Select a Trip: </h4>
        <select
          className='form-control text-center'
          aria-label='Trip'
          onChange={(e) => {
            this.selectedTrip = e.target.value;
          }}
          required
        //value={this.state.selectedTrip}
        >
          <option value=''>Choose Trip</option>
          {this.props.trips.map((trip: any) => {
            return (
              <option key={trip.id} value={trip.id}>
                {trip.tripname} - {trip.direction}
              </option>
            );
          })}
        </select>

      </div>

      <div className="col-12">
        <hr />
        <h2>For The following Student: </h2><br />
        <h4>#:{x.id} | {x.name}</h4>
      </div>
      <button className="col -12 btn btn-primary btn-lg" onClick={this.submit}>Save!</button>
    </div>
    this.setState({ modalIsOpen: true });
  }
  handleClusterClick = (x: any) => {
    let w: any = window;
    w.c = x;
    let markers = x.getMarkerClusterer().markers_;
    let inBound: any = [];
    for (let i = 0; i < markers.length; i++) {
      if (x.isMarkerInClusterBounds(markers[i])) {
        inBound.push(markers[i]);
      }
    }
    let stds: any = [];
    let compined = this.props.assginedTrips.concat(this.props.requestedTrips);
    for (let i = 0; i < inBound.length; i++) {
      let arr = compined.filter((std: any) => std !== null && std.lat == inBound[i].position.lat() && std.lng == inBound[i].position.lng());
      stds = arrayUnique(stds.concat(arr));
    }
    console.log("stds " , stds);
    //----------------------------------
    this.fastAssign = stds;
    this.body = <div className="col-12 row">
      <div className="col-12 text-dark">
        <h4> Please Select a Trip: </h4>
        <select
          className='form-control text-center'
          aria-label='Trip'
          onChange={(e) => {
            this.selectedTrip = e.target.value;
          }}
          required
        //value={this.state.selectedTrip}
        >
          <option value=''>Choose Trip</option>
          {this.props.trips.map((trip: any) => {
            return (
              <option key={trip.id} value={trip.id}>
                {trip.tripname} - {trip.direction}
              </option>
            );
          })}
        </select>

      </div>

      <div className="col-12">
        <hr />
        <h2>For The following Student: </h2><br />
        {stds.map((std: any, key: number) => {
          return (<h4 key={key}>#:{std.id} | {std.name}</h4>)

        })}
      </div>
      <button className="col -12 btn btn-primary btn-lg" onClick={this.submit}>Save!</button>
    </div>
    this.setState({ modalIsOpen: true });
  }

  submit = () => {
    axios
      .post(
        `admin/assigncluster.php`,
        { stds: this.fastAssign, trip_id: this.selectedTrip }
      )
      .then((res) => {

        Swal.fire({
          title: "Result",
          html: res.data.data,
          icon: res.data.status
        }).then(() => {
          this.closeModal();
        })
      })
      .catch((err) => {
        Swal.fire({
          title: "Result",
          text: "Error",
          icon: "error"
        })

      });
  }
  openModal = () => {
    this.setState({ modalIsOpen: true })
  }
  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  componentDidMount() {
  
    //-------------------
    const waypoints = this.props.path?.path.map((p: any) => ({
      location: { lat: p[0], lng: p[1] },
      stopover: true
    }))
    if (!waypoints) return;
    const origin = waypoints.shift().location;
    const destination = waypoints.pop().location;



    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
        waypoints: waypoints
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result
          });
        } else {
          this.setState({ error: result });
        }
      }
    );
    //-------------------
  }


  RequestedTrips: any = null
  AssignedTrips: any = null
  render() {
    //--------------------
    console.log("path " , this.props.path?.path)
    const waypoints = this.props.path?.path.map((p: any) => ({
      location: { lat: p[0], lng: p[1] },
      stopover: true
    }));
    console.log("waypoints " , waypoints)
    const origin = waypoints?.shift().location;
    const destination = waypoints?.pop().location;
    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
        waypoints: waypoints
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          console.log( "result " , result)
          let s: any = this.state.directions;
          if (result?.routes[0].overview_polyline != s?.routes[0].overview_polyline)
            this.setState({
              directions: result
            });
        } else {
          this.setState({ error: result });
        }
      }
    );

    //----------------


    this.req.stds = { assigned: this.props.assginedTrips, req: this.props.requestedTrips }
    this.AssignedTrips = this.props.assginedTrips?.map((amark: any) => (
      <Marker
        key={Math.random() * 1.2}
        onRightClick={() => this.setState({ showInfo: !this.state.showInfo })}
        onClick={() => { this.handleMarkerClick(amark); }}
        position={amark}
        icon={
          'https://mt.google.com/vt/icon?psize=30&font=fonts/arialuni_t.ttf&color=ff304C13&name=icons/spotlight/spotlight-waypoint-a.png&ax=43&ay=48&text=%E2%80%A2'
        }
      >

        {this.state.showInfo ? <InfoWindow options={{ disableAutoPan: true }} >
          <h5>assigned:{amark.name}</h5>
        </InfoWindow> : null}
      </Marker>
    ))
    this.RequestedTrips = this.props.requestedTrips?.map((mark: any) => (
      <Marker key={Math.random() * 1.2} position={mark} onRightClick={() => this.setState({ showInfo: !this.state.showInfo })} onClick={() => this.handleMarkerClick(mark)} >

        {this.state.showInfo ? <InfoWindow options={{ disableAutoPan: true }}>
          <div className="align-items-center">
            <h5>not assigned:{mark.name}</h5>
            {/* <div className='form-group'>
        <select
          className='form-control text-center'
          aria-label='Default select example'
          required
        >
        <option value=''>Choose Trip</option>
        {this.props.trips.map((trip: any) => {
        return (
            <option key={trip.id} value={trip.id}>
            {trip.tripname} - {trip.direction}
            </option>
          );
        })}
        </select>
        <button
          className='btn btn-success text-white font-weight-bold m-1'
        >
          Assign
        </button>
        </div> */}
          </div>
        </InfoWindow> : null}
      </Marker>
    ))
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={() => { }}
          onRequestClose={() => { }}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div className="top-0 border-bottom d-flex m-0 p-0" style={{ width: "100%" }}>
            <h2 style={{ marginRight: "auto", fontSize: "18px", display: "flex", paddingRight: "100px" }}>Assign Trip To Stodents</h2>
            <span className="btn" style={{ cursor: "pointer", marginLeft: "auto", fontSize: "20px", display: "flex", color: "red", marginTop: "-10px" }} onClick={() => this.closeModal()} >X</span>
          </div>
          <div className="row mt-3">

            {this.body}

          </div>
          <div className=" bottom-0 border-top d-flex m-0 p-0 mt-3 pt-1" style={{ width: "100%", marginBottom: "-10px !important" }}>
            <button className="btn btn-danger" style={{ marginLeft: "auto" }} onClick={() => { this.closeModal(); }} >Cancel</button>
          </div>
        </Modal>

        <GoogleMap

          defaultZoom={11}
          defaultCenter={
            { lat: 29.9843482, lng: 31.3239946 }
          }
        >
          {/* school marker  */}

          <Marker
            key={Math.random() * 1.2}
            onClick={() => this.setState({ showInfo: !this.state.showInfo })}
            position={{
              lat: 29.985147386999323,
              lng: 31.326269130685223,
            }}
            icon={{ url: ivy, scaledSize: new google.maps.Size(30, 30) }}
          >
          </Marker>

          <MarkerClusterer
            averageCenter
            enableRetinaIcons
            // minimumClusterSize={1}
            onClick={(e: any) => { this.handleClusterClick(e); }}
            gridSize={40}
            zoomOnClick={false}
          >
            {this.AssignedTrips}
            {this.RequestedTrips}
          </MarkerClusterer>

          {/*           <MarkerClusterer
            averageCenter
            enableRetinaIcons
            // minimumClusterSize={1}
            gridSize={60}
          >


          </MarkerClusterer> */}
          <DirectionsRenderer
            directions={this.state.directions}
          />
        </GoogleMap>

      </React.Fragment>
    );
  }
}

export default withScriptjs(withGoogleMap(Map));
