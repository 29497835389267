import { IActionBase, DriverGateEntry} from '../../models/root.interface';
import * as actionTypes from '../../actions/actionTypes';

import { updateObject } from '../../utility';
const initialState: DriverGateEntry = {
  loading: null,
  error: null,
  msg: null,
  data: {},
};

// for API Calling
const sendDriverGateEntryStatus = (state: DriverGateEntry, action: IActionBase) => {
  return updateObject(state, { error: null, loading: true });
};

const sendDriverGateEntryStatusSuccess = (
  state: DriverGateEntry = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    msg: action.msg,
    loading: false,
    error: null,
    data: action.data,
  });
};

const sendDriverGateEntryStatusFail = (
  state: DriverGateEntry,
  action: IActionBase
) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};
const clearMsgDriverGateEntryStatus = (
  state: DriverGateEntry,
  action: IActionBase
) => {
  return updateObject(state, {
    error: null,
    msg: null,
    loading: null,
    data:{}
  });
};

const drivergateentry = (
  state: DriverGateEntry = initialState,
  action: IActionBase
) => {
  switch (action.type) {
    case actionTypes.SEND_DRIVER_GATE_ENTRY_STATUS:
      return sendDriverGateEntryStatus(state, action);
    case actionTypes.SEND_DRIVER_GATE_ENTRY_STATUS_SUCCESS:
      return sendDriverGateEntryStatusSuccess(state, action);
    case actionTypes.SEND_DRIVER_GATE_ENTRY_STATUS_FAIL:
      return sendDriverGateEntryStatusFail(state, action);
    case actionTypes.CLEAR_MSG_DRIVERGATEENTRYSTATUS:
      return clearMsgDriverGateEntryStatus(state, action);
    default:
      return state;
  }
};
export default drivergateentry;
