import * as actionTypes from '../actionTypes';
import axios from 'axios';

// for form data
export const getChildren = (students_ids: any, direction: string ,duration :any) => {
  return {
    type: actionTypes.GET_CHILDREN,
    students_ids,
    direction,
    duration
  };
};
export const getAddress = (area_id: string, address: string) => {
  return {
    type: actionTypes.GET_ADDRESS,
    area_id,
    address,
  };
};
export const getCoords = (lat: Number, lng: Number) => {
  return {
    type: actionTypes.GET_COORDS,
    lat,
    lng,
  };
};

export const getInstruction = (
  handing_instructions: string,
  collector_name: string,
  collector_id: string
) => {
  return {
    type: actionTypes.GET_INSTRUCTION,
    handing_instructions,
    collector_name,
    collector_id,
  };
};

//Fetch AREAS
export const fetchAreaStart = () => {
  return {
    type: actionTypes.FETCH_AREA_START,
  };
};
export const fetchAreaSuccess = (areas: any) => {
  return {
    type: actionTypes.FETCH_AREA_SUCCESS,
    areas,
  };
};
export const fetchAreaFail = (error: any) => {
  return {
    type: actionTypes.FETCH_AREA_FAIL,
    error,
  };
};

// fetch last request 

export const fetchLastRequestStart = () => {
  return {
    type: actionTypes.FETCH_AREA_START,
  };
};
export const fetchLastRequestSuccess = (data: any) => {
  console.log(data.address)
  return {
    type: actionTypes.FETCH_LastRequest_SUCCESS,
    address: data.address,
    area_id: data.area_id,
    handing_instructions: data.handing_instructions,
    collector_name: data.collector_name,
    collector_id: data.collector_id,
    location_lat: data.location_lat,
    location_lng: data.location_lng,
   
  };
};
export const fetchLastRequestFail = (error: any) => {
  return {
    type: actionTypes.FETCH_AREA_FAIL,
    error,
  };
};


export const fetchArea = () => {
  return (dispatch: any) => {
    dispatch(fetchAreaStart());

    axios
      .get(`parent/getareas.php`)
      .then((res) => {
        

        dispatch(fetchAreaSuccess(res.data.data));
      })
      .catch((err) => {
       
        if (err.response) {
         
          dispatch(fetchAreaFail(err.response.data.msg));
        } else {
          dispatch(fetchAreaFail('Internet Connection Failed'));
        }
      });
  };
};

// Fetch Last Request

export const fetchLastRequest = () => {
  return (dispatch: any) => {
    dispatch(fetchLastRequestStart());

    axios
      .get(`parent/getlastrequest.php`)
      .then((res) => {
       

        dispatch(fetchLastRequestSuccess(res.data.data));
      })
      .catch((err) => {
    
        if (err.response) {
         
          dispatch(fetchLastRequestFail(err.response.data.msg));
        } else {
          dispatch(fetchLastRequestFail('Internet Connection Failed'));
        }
      });
  };
};

//GET STUDENTS
export const fetchStudentsStart = () => {
  return {
    type: actionTypes.FETCH_STUDENTS_START,
  };
};
export const fetchStudentsSuccess = (students: any) => {
  return {
    type: actionTypes.FETCH_STUDENTS_SUCCESS,
    students,
  };
};
export const fetchStudentsFail = (error: any) => {
  return {
    type: actionTypes.FETCH_STUDENTS_FAIL,
    error,
  };
};

export const fetchStudents = () => {
  return (dispatch: any) => {
    dispatch(fetchStudentsStart());

    axios
      .get(
        `parent/getstudents.php`
      )
      .then((res) => {
        dispatch(fetchStudentsSuccess(res.data.data));
      })
      .catch((err) => {
       
        if (err.response) {
          
          dispatch(fetchStudentsFail(err.response.data.msg));
        } else {
          dispatch(fetchStudentsFail('Internet Connection Failed'));
        }
      });
  };
};

//parent/addrequest.php ,data

// FOR FINAL ASSIGN TRIP

export const assignTripStart = () => {
  return {
    type: actionTypes.ASSIGN_TRIP_START,
  };
};
export const assignTripSuccess = (data: any) => {
  return {
    type: actionTypes.ASSIGN_TRIP_SUCCESS,
    data,
  };
};
export const assignTripFail = (error: any) => {
  return {
    type: actionTypes.ASSIGN_TRIP_FAIL,
    error,
  };
};

export const assignTrip = (tripData: any) => {
  return (dispatch: any) => {
    dispatch(assignTripStart());

    axios
      .post(`parent/addrequest.php`, tripData)
      .then((res) => {
       
        dispatch(assignTripSuccess(res.data.data));
      })
      .catch((err) => {
        
       
        if (err.response) {
          
          dispatch(assignTripFail(err.response.data.msg));
        } else {
          dispatch(assignTripFail('Internet Connection Failed'));
        }
      });
  };
};
// FOR Save token

export const saveTokenStart = () => {
  return {
    type: actionTypes.SAVE_TOKEN_START,
  };
};
export const saveTokenSuccess = (data: any) => {
  return {
    type: actionTypes.SAVE_TOKEN_SUCCESS,
    data,
  };
};
export const saveTokenFail = (error: any) => {
  return {
    type: actionTypes.SAVE_TOKEN_FAIL,
    error,
  };
};

export const saveToken = (parent_id: any,token:any) => {
  return (dispatch: any) => {
    dispatch(saveTokenStart());

    axios
      .post(`parent/savetoken.php`, {parent_id,token})
      .then((res) => {
        
        dispatch(saveTokenSuccess(res.data.data));
      })
      .catch((err) => {
       
        if (err.response) {
         
          dispatch(saveTokenFail(err.response.data.msg));
        } else {
          dispatch(saveTokenFail('Internet Connection Failed'));
        }
      });
  };
};
