import React, { Fragment } from "react";
import AdminLeftMenu from "../LeftMenu/AdminLeftMenu";
import TopMenu from "../TopMenu/TopMenu";
import { Switch, Route } from "react-router";
import AdminHome from "./Home/AdminHome";
import Notifications from "../../common/components/Notification";
import TripsTable from "./ManageTrips/TripsTable";
import TripAnalytics from "./ManageTrips/tripanalytics";
import TripAnalyticsM from "./ManageTrips/tripanalyticsM";
import EditTrip from "./ManageTrips/EditTrip";
import TrackTrip from "./ManageTrips/TrackTrip";
import Locations from "./ManageTrips/Locations";
import AddTrip from "./ManageTrips/AddTrip";
import AddBus from "./ManageTrips/AddBus";
import AllowEntry from "./ManageTrips/AllowEntry";
import ClusterOverview from "./Managment/ClusterOverview";
import AssignedStudents from "./Managment/AssignedStudents";
import EditAssignedStudent from "./Managment/EditAssignedStudent";
import PendingStudents from "./Managment/PendingStudents";
import EditUnassignedStudent from "./Managment/EditUnassignedStudent";
import AllStudents from "./Managment/AllStudents";
import AssignStudent from "./Managment/AssignStudent";
import AllEmployees from "./Managment/AllEmployees";
import PaymentDetail from "./Accounting/PaymentDetail";
import AssignEmployee from "./Managment/AssignEmployee";
import AssignedEmployees from "./Managment/AssignedEmployees";
import EditAssignedEmployee from "./Managment/EditAssignedEmployee";
import TrashedStudents from "./Managment/TrashedStudents";
import RejectionRequests from "./Managment/RejectionRequests";
import BusTracking from "./BusTracking/BusTracking";
import BusReport from "./Reports/BusMovemoentsReport";
import TripReport from "./Reports/TripLogReport";
const Administration: React.FC = () => {
  return (
    <Fragment>
      <Notifications />
      <AdminLeftMenu />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopMenu />
          <div className="container-fluid">
            <Switch>
              <Route path="/EditAssignedEmployee">
                <EditAssignedEmployee />
              </Route>
              <Route path="/AssignedEmployees">
                <AssignedEmployees />
              </Route>
              <Route path="/AssignStudent">
                <AssignStudent />
              </Route>
              <Route path="/AssignEmployee">
                <AssignEmployee />
              </Route>
              <Route path="/TripsTable">
                <TripsTable />
              </Route>
              <Route path="/TripAnalytics">
                <TripAnalytics />
              </Route>
              <Route path="/TripAnalyticsM">
                <TripAnalyticsM />
              </Route>
              <Route path="/EditTrip">
                <EditTrip />
              </Route>
              <Route path="/Locations">
                <Locations />
              </Route>
              <Route path="/AddTrip">
                <AddTrip />
              </Route>
              <Route path="/AddBus">
                <AddBus />
              </Route>
              <Route path="/AllowEntry">
                <AllowEntry />
              </Route>
              <Route path="/TrackTrip">
                <TrackTrip />
              </Route>
              <Route path="/ClusterOverview">
                <ClusterOverview />
              </Route>
              <Route path="/bustracking">
                <BusTracking />
              </Route>
              <Route path="/AssignedStudents">
                <AssignedStudents />
              </Route>
              <Route path="/EditAssignedStudent">
                <EditAssignedStudent />
              </Route>
              <Route path="/PendingStudents">
                <PendingStudents />
              </Route>
              <Route path="/RejectedRequests">
                <RejectionRequests />
              </Route>
              <Route path="/TrashedStudents">
                <TrashedStudents />
              </Route>
              <Route path="/EditUnassignedStudent">
                <EditUnassignedStudent />
              </Route>
              <Route path="/AllStudents">
                <AllStudents />
              </Route>
              <Route path="/AllEmployees">
                <AllEmployees />
              </Route>

              <Route path="/PaymentDetail">
                <PaymentDetail />
              </Route>
              <Route path="/Busreport">
                <BusReport />
              </Route>
              <Route path="/Tripreport">
                <TripReport />
              </Route>
              <Route path="/">
                <AdminHome />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Administration;
