import * as actionTypes from '../actionTypes';
import axios from 'axios';

export const clearMsgAllEmployee = () => {
  return {
    type: actionTypes.CLEAR_MSG_ALL_EMPLOYEES,
  };
};

export const fetchAllStart = () => {
  return {
    type: actionTypes.FETCH_ALL_EMPLOYEES_START,
  };
};
export const fetchAllSuccess = (employees: any, count: any) => {
  return {
    type: actionTypes.FETCH_ALL_EMPLOYEES_SUCCESS,
    employees,
    count,
  };
};
export const fetchAllFail = (error: any) => {
  return {
    type: actionTypes.FETCH_ALL_EMPLOYEES_FAIL,
    error,
  };
};

export const fetchAllEmployees = (pageN: any, pageSize: any, search = '') => {
  return (dispatch: any) => {
    dispatch(fetchAllStart());
    let data = { pagenumber: pageN, pagesize: pageSize, search };
    axios
      .post(
        `admin/employees/getemployees.php
        `,
        data
      )
      .then((res) => {
        console.log(res);
        dispatch(fetchAllSuccess(res.data.data.employees, res.data.data.count));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fetchAllFail(err.response.data.msg));
        } else {
          dispatch(fetchAllFail('Internet Connection Failed'));
        }
      });
  };
};

// for assign trip to employee from admin

export const assignTripToEmployeeStart = () => {
  return {
    type: actionTypes.ASSIGN_TRIP_EMPLOYEE_START,
  };
};
export const assignTripToEmployeeSuccess = (msg: any) => {
  return {
    type: actionTypes.ASSIGN_TRIP_EMPLOYEE_SUCCESS,
    msg,
  };
};
export const assignTripToEmployeeFail = (error: any) => {
  return {
    type: actionTypes.ASSIGN_TRIP_EMPLOYEE_FAIL,
    error,
  };
};

export const assignTripToEmployee = (data: any) => {
  return (dispatch: any) => {
    dispatch(assignTripToEmployeeStart());

    axios
      .post(
        `admin/employees/adminassignemployee.php
        `,
        data
      )
      .then((res) => {
        console.log(res);
        dispatch(assignTripToEmployeeSuccess(res.data.msg));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(assignTripToEmployeeFail(err.response.data.msg));
        } else {
          dispatch(assignTripToEmployeeFail('Internet Connection Failed'));
        }
      });
  };
};