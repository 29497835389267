import axios from "axios";
import React, { Fragment, Dispatch, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { updateCurrentPath } from "../../../store/actions/root.actions";
import SweetAlert from "react-bootstrap-sweetalert";
import { Translate } from "../../../utils/Translate";
const AdminHome: React.FC = () => {
  let t = new Translate();
  const dispatch: Dispatch<any> = useDispatch();
  // dispatch(updateCurrentPath('home', ''));

  const [msg, setMsg] = useState<any>();

  const reportHandler = async (type: string) => {
    try {
      let res;
      if (type == "Assigned") {
        res = await axios.get(`assignedTripsReport.php`);
        if ((res.data.data = "success")) {
          const msg = (
            <SweetAlert
              success
              title="success"
              onConfirm={() => {
                window.location.href =
                  process.env.REACT_APP_BASEURL + "/Assigned trips report.csv";
                setMsg("");
              }}
              confirmBtnText="Download your file"
            ></SweetAlert>
          );
          setMsg(msg);
        }
      } else if (type == "Requested") {
        res = await axios.get(`requestedTripsReport.php`);
        if ((res.data.data = "success")) {
          const msg = (
            <SweetAlert
              success
              title="success"
              onConfirm={() => {
                window.location.href =
                  process.env.REACT_APP_BASEURL + "/Requested trips report.csv";
                setMsg("");
              }}
              confirmBtnText="Download your file"
            ></SweetAlert>
          );
          setMsg(msg);
        }
      }
    } catch (e) {}
  };
  return (
    <Fragment>
      {msg}
      <div className="text-center">
        <h2
          className="text-white"
          style={{
            textShadow:
              "0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)",
            fontSize: "2rem",
          }}
        >
          {t.translate("IVY-IS Transportation Administration")}
        </h2>

        <div className="row mt-5">
          <div className="col-6">
            <button
              onClick={() => {
                reportHandler("Assigned");
              }}
              className="btn btn-success my-1 text-white font-weight-bold"
            >
              {t.translate("Assigend trips report")}
            </button>
          </div>
          <div className="col-6">
            <button
              onClick={() => {
                reportHandler("Requested");
              }}
              className="btn btn-success my-1 text-white font-weight-bold"
            >
              {t.translate("Requested trips report")}
            </button>
          </div>
        </div>
      </div>
      <div className="row justify-content-center align-content-between"></div>
    </Fragment>
  );
};

export default AdminHome;
