import React, {
  Fragment,
  Dispatch,
  useEffect,
  useState,
  useRef,
  memo,
} from "react";
import { withRouter } from "react-router-dom";
import classes from "./AllEmployees.module.css";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index.actions";
import { IStateType } from "../../../store/models/root.interface";
import Loader from "react-loader-spinner";
import Pagination from "react-js-pagination";
import SweetAlert from "react-bootstrap-sweetalert";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Translate } from "../../../utils/Translate";
const AllEmployees: React.FC = (props: any) => {
  let t = new Translate();
  const dispatch: Dispatch<any> = useDispatch();

  const count = useSelector((state: IStateType) => state.AllEmployees.count);

  const employees = useSelector(
    (state: IStateType) => state.AllEmployees.employees
  );
  const loadingEmployees = useSelector(
    (state: IStateType) => state.AllEmployees.loadingEmployees
  );

  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const handlePageChange = (pageNumber: any) => {
    dispatch(actions.fetchAllEmployees(pageNumber, pageSize, enteredFilter));
    setActivePage(pageNumber);
  };

  let arrowLeft = <i className="fas fa-angle-double-left"></i>;
  let arrowRight = <i className="fas fa-angle-double-right"></i>;

  const inputSearchRef: any = useRef<HTMLInputElement>();
  const [enteredFilter, setEnteredFilter] = useState<string>("");

  useEffect(() => {
    $(document).ready(() => {
      const table: any = $("#datatable").DataTable();
    });
  });
  useEffect(() => {
    const timer = setTimeout(() => {
      if (enteredFilter === inputSearchRef.current.value) {
        dispatch(
          actions.fetchAllEmployees(activePage, pageSize, enteredFilter)
        );
      }
    }, 500);

    return () => {
      clearTimeout(timer);
      setActivePage(1);
    };
  }, [enteredFilter, inputSearchRef]);
  const onNewAssignHandler = (id: any, fullName: any) => {
    props.history.push({
      pathname: "/AssignEmployee",
      state: {
        id,
        fullName,
      },
    });
  };

  return (
    <Fragment>
      <div>
        <div className="text-center">
          <h2
            className="text-white"
            style={{
              textShadow:
                "0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)",
              fontSize: "2rem",
            }}
          >
            {t.translate("All Employees")}
          </h2>
        </div>
        <div className="input-group mt-3 mb-3 d-none">
          <input
            type="text"
            className="form-control"
            ref={inputSearchRef}
            onChange={(e) => setEnteredFilter(e.target.value)}
          />
          <div className="input-group-append">
            <button className="btn btn-primary">
              <i className="fas fa-search"></i>
            </button>
          </div>
        </div>
        {loadingEmployees === false ? (
          <div className="justify-content-center align-items-center">
            <div className="table-responsive bg-light">
              <table
                id="datatable"
                className="table-hover table-bordered text-center"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th scope="col">IVY-ID</th>
                    <th scope="col">{t.translate("Name")}</th>
                    <th scope="col">{t.translate("Req")}</th>
                    <th scope="col">{t.translate("Status")}</th>
                  </tr>
                </thead>
                <tbody>
                  {employees.map((emp: any) => (
                    <tr key={emp.id}>
                      <td>{emp.id}</td>
                      <td>{emp.fullname}</td>

                      <td>
                        <div className={`${classes.Emp_Req_Container}`}>
                          {emp.reqtohome ? (
                            <Fragment>
                              <div>&nbsp;</div>
                              <div className="badge bg-success text-white font-weight-bold p-1 m-1 font-2">
                                {t.translate("Req home")}
                              </div>
                            </Fragment>
                          ) : null}
                          {emp.reqtoschool ? (
                            <Fragment>
                              <div className="badge bg-success text-white font-weight-bold p-1 m-1 font-2">
                                {t.translate("Req school")}
                              </div>
                              <div>&nbsp;</div>
                            </Fragment>
                          ) : null}
                        </div>
                      </td>
                      <td>
                        <div className={`${classes.Emp_Status_Container}`}>
                          <div className={`${classes.Emp_Status}`}>
                            {emp.toschool == 1 || emp.tohome == 1 ? (
                              <span>{t.translate("suspend")}</span>
                            ) : (
                              <span>&nbsp;</span>
                            )}
                            <button
                              disabled={emp.tohome ? true : false}
                              className="btn btn-warning text-white font-weight-bold p-1 m-1 font-2"
                              onClick={() =>
                                onNewAssignHandler(emp.id, emp.fullname)
                              }
                            >
                              {t.translate("Asign trip")}
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* <div className='d-flex justify-content-center align-items-center my-3'>
              {count !== 0 ? (
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={pageSize}
                  totalItemsCount={+count}
                  pageRangeDisplayed={5}
                  onChange={(e) => handlePageChange(e)}
                  firstPageText={'First'}
                  lastPageText={'Last'}
                  prevPageText={arrowLeft}
                  nextPageText={arrowRight}
                  activeClass={classes.active}
                />
              ) : null}
            </div> */}
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center mt-5">
            <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
          </div>
        )}
      </div>
    </Fragment>
  );
};
export default withRouter(AllEmployees);
