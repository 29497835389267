import React, { Fragment, Dispatch, useEffect, useState, useRef } from "react";
// import parent_select_location_sample from '../../../assets/parent_select_location_sample.png';
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index.actions";
import { IStateType } from "../../../store/models/root.interface";
import { Link, withRouter } from "react-router-dom";
import classes from "../../Pagination.module.css";
import Loader from "react-loader-spinner";
import Pagination from "react-js-pagination";
import SweetAlert from "react-bootstrap-sweetalert";
import { Translate } from "../../../utils/Translate";
interface trip {
  id: string;
  trip_id: string;
  direction: string;
  employee_id: string;
  address: string;
  tripname: string;
  location_lat: string;
  location_lng: string;
  status: string;
  name: string;
  areaname: string;
  toschool?: number;
  tohome?: number;
}

const AssignedEmployees: React.FC = (props: any) => {
  let t = new Translate();
  const count = useSelector(
    (state: IStateType) => state.assignedemployeetrips.count
  );
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const handlePageChange = (pageNumber: any) => {
    // console.log(`active page is ${pageNumber}`);
    dispatch(
      actions.fetchEmployeeAssignedTrips(pageNumber, pageSize, enteredFilter)
    );
    setActivePage(pageNumber);
  };
  const dispatch: Dispatch<any> = useDispatch();
  const trips = useSelector(
    (state: IStateType) => state.assignedemployeetrips.trips
  );
  const loading = useSelector(
    (state: IStateType) => state.assignedemployeetrips.loading
  );
  // useEffect(() => {
  //   dispatch(actions.fetchAssignedTrips(activePage, pageSize, enteredFilter));
  // }, [dispatch]);

  function editassignedemployee(tripid: string) {
    props.history.push("/EditAssignedEmployee?id=" + tripid);
  }

  const suspendEmployeeHandler = (id: any) => {
    dispatch(actions.suspendEmployeeTrip(id));
  };
  const unSuspendEmployeeHandler = (id: any) => {
    dispatch(actions.unSuspendEmployeeTrip(id));
  };
  const deleteEmployeeAssignTripHandler = (id: any) => {
    // console.log(id);
    dispatch(actions.deleteEmployeeAssignTrip(id));
  };

  let arrowLeft = <i className="fas fa-angle-double-left"></i>;
  let arrowRight = <i className="fas fa-angle-double-right"></i>;

  const onSuccessCancel = () => {
    dispatch(actions.clearMsgAssginEmployeeTrip());
  };

  const msg = useSelector(
    (state: IStateType) => state.assignedemployeetrips.msg
  );
  const loadingSuspendTrip = useSelector(
    (state: IStateType) => state.assignedemployeetrips.loadingSuspendTrip
  );
  let forSuccess = null;
  if (msg) {
    forSuccess = (
      <SweetAlert
        success
        title="Success"
        onConfirm={onSuccessCancel}
        onCancel={onSuccessCancel}
      >
        {msg}
      </SweetAlert>
    );
  }

  const inputSearchRef: any = useRef();
  const [enteredFilter, setEnteredFilter] = useState<string>("");
  useEffect(() => {
    const timer = setTimeout(() => {
      if (enteredFilter === inputSearchRef.current.value) {
        // console.log(enteredFilter);
        dispatch(
          actions.fetchEmployeeAssignedTrips(
            activePage,
            pageSize,
            enteredFilter
          )
        );
      }
    }, 500);

    return () => {
      clearTimeout(timer);
      setActivePage(1);
    };
  }, [enteredFilter, inputSearchRef]);
  return (
    <Fragment>
      <div className="text-center">
        <h2
          className="text-white"
          style={{
            textShadow:
              "0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)",
            fontSize: "2rem",
          }}
        >
          {t.translate("Modify Employee's Trips")}
        </h2>
      </div>
      <div className="input-group mt-3 mb-3">
        <input
          type="text"
          className="form-control"
          ref={inputSearchRef}
          onChange={(e) => setEnteredFilter(e.target.value)}
        />
        <div className="input-group-append">
          <button className="btn btn-primary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>

      {loading === false && !loadingSuspendTrip ? (
        <div className="justify-content-center align-items-center">
          {forSuccess}
          <div className="table-responsive bg-light">
            <table
              className="table-hover table-bordered text-center"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th scope="col">IVY ID</th>
                  <th scope="col"> {t.translate("Employee Name")}</th>
                  <th scope="col"> {t.translate("Area")}</th>
                  <th scope="col"> {t.translate("Location")}</th>
                  <th scope="col"> {t.translate("To School")}</th>
                  <th scope="col"> {t.translate("To Home")}</th>
                  <th scope="col"> {t.translate("Assigned Trip")}</th>
                </tr>
              </thead>
              <tbody>
                {trips.map((trip: trip) => {
                  return (
                    <tr key={trip.id}>
                      <td className="w-20">{trip.employee_id}</td>
                      <td className="w-20">{trip.name}</td>
                      <td className="w-20">{trip.areaname}</td>
                      <td className="w-20">
                        <p className="text-wrap text-break">{trip.address}</p>
                      </td>
                      <td className="w-10">
                        {trip.toschool == 1 ? "Yes" : "No"}
                      </td>
                      <td className="w-10">
                        {trip.tohome == 1 ? "Yes" : "No"}
                      </td>
                      <td className="w-10">{trip.tripname}</td>
                      <td className="w-10">
                        <button
                          // to='/EditAssignedStudent'
                          onClick={() => editassignedemployee(trip.id)}
                          className="btn btn-warning text-white font-weight-bold m-1"
                        >
                          <i className="fas fa-sliders-h"></i>
                        </button>
                        <button
                          onClick={() => suspendEmployeeHandler(trip.id)}
                          disabled={trip.status === "suspend" ? true : false}
                          className="btn btn-success text-white font-weight-bold m-1"
                        >
                          {t.translate("Suspend")}
                        </button>
                        <button
                          onClick={() => unSuspendEmployeeHandler(trip.id)}
                          disabled={trip.status === "unsuspend" ? true : false}
                          className="btn btn-success text-white font-weight-bold m-1"
                        >
                          {t.translate("Un-suspend")}
                        </button>
                        <button
                          onClick={() =>
                            deleteEmployeeAssignTripHandler(trip.id)
                          }
                          className="btn btn-danger text-white font-weight-bold m-1"
                        >
                          <i className="fas fa-times"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center align-items-center my-3">
            {count !== 0 ? (
              <Pagination
                activePage={activePage}
                itemsCountPerPage={pageSize}
                totalItemsCount={+count}
                pageRangeDisplayed={5}
                onChange={(e) => handlePageChange(e)}
                firstPageText={"First"}
                lastPageText={"Last"}
                prevPageText={arrowLeft}
                nextPageText={arrowRight}
                activeClass={classes.active}
              />
            ) : null}
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
        </div>
      )}
    </Fragment>
  );
};

export default withRouter(AssignedEmployees);
