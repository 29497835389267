import { IActionBase, IDriver } from '../../models/root.interface';
import * as actionTypes from '../../actions/actionTypes';

import { updateObject } from '../../utility';

const initialState: IDriver = {
  trip : {} ,  
  loading: null,
  error: null,
  msg : null ,

};


// for API Calling
const fetchTripStart = (state: IDriver, action: IActionBase) => {
  return updateObject(state, { error: null, loading: true });
};

const fetchTripSuccess = (
  state: IDriver = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    trip: action.trip,
    loading: false,
    error: null,
  });
};

const fetchTripFail = (state: IDriver, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const clearMsgDriverTripDetails = (
  state: IDriver = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    trip : {},
    error: null,
    msg: null,
    loading: false,
  });
};
const getdrivertrip = (state: IDriver = initialState, action: IActionBase) => {
  switch (action.type) {
  

    case actionTypes.FETCH_DRIVERTRIP_START:
      return fetchTripStart(state, action);
    case actionTypes.FETCH_DRIVERTRIP_SUCCESS:
      return fetchTripSuccess(state, action);
    case actionTypes.FETCH_DRIVERTRIP_FAIL:
      return fetchTripFail(state, action);
    case actionTypes.CLEAR_MSG_DRIVERGETTRIP:
      return clearMsgDriverTripDetails(state, action);


    default:
      return state;
  }
};
export default getdrivertrip;
