import { IActionBase, ITrips } from "../../models/root.interface";
import * as actionTypes from "../../actions/actionTypes";

import { updateObject } from "../../utility";

const initialState: ITrips = {
  trips: [],
  rejectedTrips: [],
  rejectedTripsLoading: null,
  rejectedTripsCount: 0,
  trakedTrips: [],
  trakedTripLoading: null,
  count: 0,
  loading: null,
  trip: null,
  updateLoading: null,
  loadingTrip: null,
  loadingUnAssignTrip: null,
  loadingSuspendTrip: null,
  error: null,
  msg: null,
};

const fetchTripsStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, loading: true });
};

const fetchTripsSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    trips: action.trips,
    count: action.count,
    loading: false,
    error: null,
  });
};

const fetchTripsFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const fetchTripStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, loadingTrip: true });
};

const fetchTripSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    trip: action.trip,
    loadingTrip: false,
    error: null,
  });
};

const fetchTripFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingTrip: false,
  });
};

const updateAssTripStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, loadingUnAssignTrip: true });
};

const updateAssTripSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    loadingUnAssignTrip: false,
    error: null,
    msg: action.msg,
  });
};

const updateAssTripFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingUnAssignTrip: false,
  });
};

// for suspend
const suspendTripStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, loadingSuspendTrip: true });
};

const suspendTripSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  let newArr = state.trips.map((trip: any) =>
    trip.id === action.data.id ? action.data : trip
  );
  console.log(newArr);
  return updateObject(state, {
    loadingSuspendTrip: false,
    error: null,
    msg: action.msg,
    trips: newArr,
  });
};

const suspendTripFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingSuspendTrip: false,
  });
};

// for unSuspend
const unSuspendTripStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, loadingSuspendTrip: true });
};

const unSuspendTripSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  let newArr = state.trips.map((trip: any) =>
    trip.id === action.data.id ? action.data : trip
  );
  console.log(newArr);
  return updateObject(state, {
    loadingSuspendTrip: false,
    error: null,
    msg: action.msg,
    trips: newArr,
  });
};

const unSuspendTripFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingSuspendTrip: false,
  });
};
// for Delete
const deleteTripStart = (state: ITrips, action: IActionBase) => {
  return updateObject(state, { error: null, loadingSuspendTrip: true });
};

const deleteTripSuccess = (
  state: ITrips = initialState,
  action: IActionBase
) => {
  let newArr = state.trips.filter((trip: any) => trip.id !== action.id);
  console.log(newArr);
  return updateObject(state, {
    loadingSuspendTrip: false,
    error: null,
    msg: action.msg,
    trips: newArr,
  });
};

const deleteTripFail = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: action.error,
    loadingSuspendTrip: false,
  });
};

const clearMsgAssginTrip = (state: ITrips, action: IActionBase) => {
  return updateObject(state, {
    error: null,
    updateLoading: null,
    msg: null,
  });
};

const assignedtrips = (state: ITrips = initialState, action: IActionBase) => {
  switch (action.type) {
    case actionTypes.FETCH_ASSIGNTRIPS_START:
      return fetchTripsStart(state, action);
    case actionTypes.FETCH_ASSIGNTRIPS_SUCCESS:
      return fetchTripsSuccess(state, action);
    case actionTypes.FETCH_ASSIGNTRIPS_FAIL:
      return fetchTripsFail(state, action);
    case actionTypes.FETCH_ASSIGNTRIP_START:
      return fetchTripStart(state, action);
    case actionTypes.FETCH_ASSIGNTRIP_SUCCESS:
      return fetchTripSuccess(state, action);
    case actionTypes.FETCH_ASSIGNTRIP_FAIL:
      return fetchTripFail(state, action);

    case actionTypes.UPDATEASS_TRIP_START:
      return updateAssTripStart(state, action);
    case actionTypes.UPDATEASS_TRIP_SUCCESS:
      return updateAssTripSuccess(state, action);
    case actionTypes.UPDATEASS_TRIP_FAIL:
      return updateAssTripFail(state, action);

    case actionTypes.SUSPEND_TRIP_START:
      return suspendTripStart(state, action);
    case actionTypes.SUSPEND_TRIP_SUCCESS:
      return suspendTripSuccess(state, action);
    case actionTypes.SUSPEND_TRIP_FAIL:
      return suspendTripFail(state, action);

    case actionTypes.UNSUSPEND_TRIP_START:
      return unSuspendTripStart(state, action);
    case actionTypes.UNSUSPEND_TRIP_SUCCESS:
      return unSuspendTripSuccess(state, action);
    case actionTypes.UNSUSPEND_TRIP_FAIL:
      return unSuspendTripFail(state, action);

    case actionTypes.DELETE_ASSIGNTRIP_START:
      return deleteTripStart(state, action);
    case actionTypes.DELETE_ASSIGNTRIP_SUCCESS:
      return deleteTripSuccess(state, action);
    case actionTypes.DELETE_ASSIGNTRIP_FAIL:
      return deleteTripFail(state, action);

    case actionTypes.CLEAR_MSG_AssginTrip:
      return clearMsgAssginTrip(state, action);

    default:
      return state;
  }
};
export default assignedtrips;
