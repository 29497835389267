import React, { useState, Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions/index.actions";
import { IStateType } from "../../store/models/root.interface";
import { Link } from "react-router-dom";
import Select from "react-select";

function TopMenuAccount(): JSX.Element {
  let preferred_lang = localStorage.getItem("preferred_lang");
  let defauleLang = { value: "en", label: "EN" };
  if (!preferred_lang) {
    localStorage.setItem("preferred_lang", "en");
    defauleLang = { value: "en", label: "EN" };
  } else {
    if (preferred_lang === "en") {
      defauleLang = { value: "en", label: "EN" };
    } else if (preferred_lang === "ar") {
      defauleLang = { value: "ar", label: "AR عربي" };
    }
  }
  const changeLanguage = (e: any) => {
    // console.log(e);
    localStorage.setItem("preferred_lang", e.value);
    window.location.reload();
  };
  const dispatch: Dispatch<any> = useDispatch();
  const [isShow, setShow] = useState(false);
  const userName: any = useSelector((state: IStateType) => state.auth.userName);
  const options = [
    { value: "en", label: "EN" },
    { value: "ar", label: "AR عربي" },
  ];
  return (
    <li style={{ display: "flex" }} className="nav-item dropdown no-arrow">
      <span style={{ marginTop: "10%" }}>
        {" "}
        <Select
          defaultValue={defauleLang}
          onChange={(e: any) => changeLanguage(e)}
          options={options}
        />
      </span>
      <span
        className="nav-link dropdown-toggle"
        onClick={() => {
          setShow(!isShow);
        }}
        // to='/'
        id="userDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span className="mr-2 d-none d-lg-inline small">{userName}</span>
        <img
          className="img-profile rounded-circle"
          alt=""
          src="https://source.unsplash.com/QAB-WJcbgJk/60x60"
        />
      </span>

      <div
        style={{ color: "black" }}
        className={`dropdown-menu dropdown-menu-right shadow animated--grow-in ${
          isShow ? "show" : ""
        }`}
        aria-labelledby="userDropdown"
      >
        <Link
          className="dropdown-item text-dark"
          onClick={() => dispatch(actions.logout())}
          to="login"
          data-toggle="modal"
          data-target="#logoutModal"
        >
          <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-dark text-gray-400"></i>
          Logout
        </Link>
      </div>
    </li>
  );
}

export default TopMenuAccount;
