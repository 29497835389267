import { IActionBase, DriverTripDetails } from '../../models/root.interface';
import * as actionTypes from '../../actions/actionTypes';

import { updateObject } from '../../utility';

const initialState: DriverTripDetails = {
  trip: {},
  loading: null,
  error: null,
  msg: null,
  data: null,
  emergancyDone: false,
};

// for API Calling
const sendDriverTripDetails = (
  state: DriverTripDetails,
  action: IActionBase
) => {
  return updateObject(state, { error: null, loading: true });
};

const sendDriverTripDetailsSuccess = (
  state: DriverTripDetails = initialState,
  action: IActionBase
) => {
  return updateObject(state, {
    loading: false,
    error: null,
    data: action.data,
  });
};

const sendDriverTripDetailsFail = (
  state: DriverTripDetails,
  action: IActionBase
) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};
const clearMsgDriverTripDetails = (
  state: DriverTripDetails,
  action: IActionBase
) => {
  return updateObject(state, {
    error: null,
    msg: null,
    loading: false,
  });
};

const changeYesToNo = (state: DriverTripDetails, action: IActionBase) => {
  return updateObject(state, {
    data: 'no',
  });
};

// finish emergancy

const endTripEmergancySuccess = (
  state: DriverTripDetails,
  action: IActionBase
) => {
  return updateObject(state, {
    emergancyDone: true,
  });
};
const clearTripEmergancy = (state: DriverTripDetails, action: IActionBase) => {
  return updateObject(state, {
    emergancyDone: false,
  });
};

const drivertripdetails = (
  state: DriverTripDetails = initialState,
  action: IActionBase
) => {
  switch (action.type) {
    case actionTypes.SEND_DRIVER_START_TRIP_DETAILS:
      return sendDriverTripDetails(state, action);
    case actionTypes.SEND_DRIVERTRIP_TRIP_DETAILS_SUCCESS:
      return sendDriverTripDetailsSuccess(state, action);
    case actionTypes.SEND_DRIVERTRIP_TRIP_DETAILS_FAIL:
      return sendDriverTripDetailsFail(state, action);
    case actionTypes.CLEAR_MSG_DRIVERTRIPDETAILS:
      return clearMsgDriverTripDetails(state, action);
    case actionTypes.CHANGE_YES_TO_NO:
      return changeYesToNo(state, action);

    case actionTypes.END_TRIP_EMERGANCY_SUCCESS:
      return endTripEmergancySuccess(state, action);
    case actionTypes.CLEAR_END_TRIP_EMERGANCY:
      return clearTripEmergancy(state, action);
    default:
      return state;
  }
};
export default drivertripdetails;
