import React, {
  Fragment,
  Dispatch,
  useEffect,
  useState,
  useRef,
  memo,
} from 'react';
import { withRouter } from 'react-router-dom';
import classes from './AllStudents.module.css';

import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions/index.actions';
import { IStateType } from '../../../store/models/root.interface';
import Loader from 'react-loader-spinner';
import Pagination from 'react-js-pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { Translate } from '../../../utils/Translate';

const AllStudents: React.FC = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  // console.log(props);
  const count = useSelector((state: IStateType) => state.AllStudents.count);
  const students = useSelector(
    (state: IStateType) => state.AllStudents.students
  );
  const loadingStudents = useSelector(
    (state: IStateType) => state.AllStudents.loadingStudents
  );

  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const t = new Translate()
  const handlePageChange = (pageNumber: any) => {
    // console.log(`active page is ${pageNumber}`);
    dispatch(actions.fetchAllStudents(pageNumber, pageSize, enteredFilter));
    setActivePage(pageNumber);
  };

  let arrowLeft = <i className='fas fa-angle-double-left'></i>;
  let arrowRight = <i className='fas fa-angle-double-right'></i>;

  const inputSearchRef: any = useRef<HTMLInputElement>();
  const [enteredFilter, setEnteredFilter] = useState<string>('');
  // console.log(inputSearchRef.current.value === undefined);

  useEffect(() => {
    $(document).ready(() => {

      const table: any = $('#datatable').DataTable();
    })
  })
  useEffect(() => {
    const timer = setTimeout(() => {
      // console.log(enteredFilter);
      if (enteredFilter === inputSearchRef.current.value) {
        dispatch(actions.fetchAllStudents(activePage, pageSize, enteredFilter));
      }
    }, 500);

    return () => {
      clearTimeout(timer);
      setActivePage(1);
    };
  }, [enteredFilter, inputSearchRef]);
  // std.id,std.fullname,std.toschool,std.tohome
  const onNewAssignHandler = (id: any, fullName: any, direction: any) => {
    // let direction = null;
    // if (toHome) {
    //   direction = 'to home';
    // } else if (toSchool) {
    //   direction = 'to the school';
    // }

    console.log(id, fullName, direction);
    props.history.push({
      pathname: '/AssignStudent',
      state: {
        id,
        fullName,
        direction,
      },
    });

    // retrive by this.props.location.state
  };

  return (
    <Fragment>
      <div>
        <div className='text-center'>
          <h2
            className='text-white'
            style={{
              textShadow:
                '0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)',
              fontSize: '2rem',
            }}
          >
            {t.translate("All Students")}
          </h2>
        </div>
        <div className='input-group mt-3 mb-3 d-none'>
          <input
            type='text'
            className='form-control'
            ref={inputSearchRef}
            onChange={(e) => setEnteredFilter(e.target.value)}
          />
          <div className='input-group-append'>
            <button className='btn btn-primary'>
              <i className='fas fa-search'></i>
            </button>
          </div>
        </div>
        {loadingStudents === false ? (
          <div className='justify-content-center align-items-center'>
            <div className='table-responsive bg-light'>
              <table
                id="datatable"
                className='table table-striped table-bordered text-center'
                style={{ width: '100%' }}
              >
                <thead>
                  <tr>
                    <th scope='col'>{t.translate("Student ID")}</th>
                    <th scope='col'>{t.translate("Name")}</th>

                    <th scope='col'>{t.translate("Parent Req")}</th>
                    <th scope='col'>{t.translate("Status")}</th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((std: any) => (
                    <tr key={std.id}>
                      <td>{std.id}</td>
                      <td>{std.fullname}</td>

                      <td>
                        <div className={`${classes.Std_Parent_Req_Container}`}>
                          {std.reqtohome ? (
                            <Fragment>
                              <div>&nbsp;</div>
                              <div className='badge bg-success text-white font-weight-bold p-1 m-1 font-2'>
                                {t.translate("Req home")}
                              </div>
                            </Fragment>
                          ) : null}
                          {std.reqtoschool ? (
                            <Fragment>
                              <div className='badge bg-success text-white font-weight-bold p-1 m-1 font-2'>
                                {t.translate("Req school")}
                              </div>
                              <div>&nbsp;</div>
                            </Fragment>
                          ) : null}
                        </div>
                      </td>
                      <td>
                        {std.sustoschool ? (
                          <span>{t.translate("suspend")}</span>
                        ) : (
                          <span>&nbsp;</span>
                        )}
                        <button

                          className='btn btn-warning text-white font-weight-bold p-1 m-1 font-2'
                          onClick={() =>
                            onNewAssignHandler(
                              std.id,
                              std.fullname,
                              'to the school'
                            )
                          }
                        >
                          {t.translate("Assign trip")}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* <div className='d-flex justify-content-center align-items-center my-3'>
              {count !== 0 ? (
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={pageSize}
                  totalItemsCount={+count}
                  pageRangeDisplayed={5}
                  onChange={(e) => handlePageChange(e)}
                  firstPageText={'First'}
                  lastPageText={'Last'}
                  prevPageText={arrowLeft}
                  nextPageText={arrowRight}
                  activeClass={classes.active}
                />
              ) : null}
            </div> */}
          </div>
        ) : (
          <div className='d-flex justify-content-center align-items-center mt-5'>
            <Loader type='ThreeDots' color='#00BFFF' height={100} width={100} />
          </div>
        )}
      </div>
    </Fragment>
  );
};
export default withRouter(AllStudents);
