import React, { Fragment, useState, Dispatch, useEffect, memo } from "react";
import { Link, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../store/actions/index.actions";
import { IStateType } from "../../../store/models/root.interface";
import Loader from "react-loader-spinner";
import SweetAlert from "react-bootstrap-sweetalert";
import { Translate } from "../../../utils/Translate";

const EditTrip: React.FC = memo((props: any) => {
  let t = new Translate();
  const dispatch: Dispatch<any> = useDispatch();
  const areas = useSelector((state: IStateType) => state.assigntrip.areas);
  const loading = useSelector(
    (state: IStateType) => state.addTrip.loadingAddTrip
  );

  const drivers = useSelector((state: IStateType) => state.addTrip.drivers);
  const loadingDriver = useSelector(
    (state: IStateType) => state.addTrip.loadingDriver
  );
  const buses = useSelector((state: IStateType) => state.addTrip.buses);
  const loadingBus = useSelector(
    (state: IStateType) => state.addTrip.loadingBus
  );
  const requestedTrips = useSelector(
    (state: IStateType) => state.addTrip.requestedTrips
  );
  const loadingReqTrips = useSelector(
    (state: IStateType) => state.addTrip.loadingReqTrips
  );
  // const employees = useSelector((state: IStateType) => state.addTrip.employees);
  // const loadingEmployees = useSelector(
  //   (state: IStateType) => state.addTrip.loadingEmployees
  // );

  const empcount = useSelector((state: IStateType) => state.addTrip.empcount);
  const reqcount = useSelector((state: IStateType) => state.addTrip.reqcount);

  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  // const handlePageChange = (pageNumber: any) => {
  //   dispatch(actions.fetchEmployees(pageNumber, pageSize));
  //   setActivePage(pageNumber);
  // };

  const [activePageReq, setActivePageReq] = useState(1);
  const [pageSizeReq, setPageSizeReq] = useState(10);
  const handlePageChangeReq = (pageNumber: any) => {
    dispatch(actions.fetchRequestedTrips(pageNumber, pageSizeReq));
    setActivePageReq(pageNumber);
  };

  useEffect(() => {
    dispatch(actions.fetchArea());
    dispatch(actions.fetchDriver());
    dispatch(actions.fetchBus());
    dispatch(actions.fetchRequestedTrips(activePageReq, pageSizeReq));
    // dispatch(actions.fetchEmployees(activePage, pageSize));
  }, [dispatch]);
  var triperr = useSelector((state: IStateType) => state.addTrip.trip);

  if (!triperr) {
    triperr = {
      tripname: "",
      bothWayPrice: "",
      oneWayPrice: "",
      goingToHomeTime: "",
      goingToSchoolTime: "",
      driver_id: "",
      area_id: "",
      bus_id: "",
    };
  }
  console.log("Trip", triperr);
  const [tripName, setTripName] = useState<string>(triperr.tripname);
  const [selectedAreaId, setSelectedAreaId] = useState<string>(triperr.area_id);
  const [selectedDriverId, setSelectedDriverId] = useState<string>(
    triperr.driver_id
  );
  const [selectedBusId, setSelectedBusId] = useState<string>(triperr.bus_id);
  const [bothWayPrice, setBothWayPrice] = useState<string>(
    triperr.bothWayPrice
  );
  const [oneWayPrice, setOneWAYPrice] = useState<string>(triperr.oneWayPrice);
  const [goingToHomeTime, setGoingToHomeTime] = useState<string>(
    triperr.goingToHomeTime
  );
  const [goingToSchoolTime, setGoingToSchoolTime] = useState<string>(
    triperr.goingToSchoolTime
  );
  // for student assign
  const [arrReqTrips, setArrRqTrips] = useState<any[]>([...requestedTrips]);

  const [assignReqArr, setAssignReqArr] = useState<any[]>([]);
  const addAssignReqHandler = (data: any) => {
    // e.target.disabled = true;
    // document.querySelector('button')
    setArrRqTrips((prevState) => prevState.filter((st) => st.id !== data.id));
    setAssignReqArr((prevState) => [...prevState, data]);
  };

  const removeAssignReqHandler = (data: any) => {
    // e.target.disabled = true;
    // document.querySelector('button')
    setAssignReqArr((prevState) => prevState.filter((st) => st.id !== data.id));
    setArrRqTrips((prevState) => [...prevState, data]);
  };
  // for employee
  // const [arrEmpTrips, setArrEmpTrips] = useState<any[]>([...employees]);

  const [assignEmpTrips, setAssignEmpTrips] = useState<any[]>([]);

  const addAssignEmpReqHandler = (data: any) => {
    // e.target.disabled = true;
    // document.querySelector('button')
    // setArrEmpTrips((prevState) => prevState.filter((st) => st.id !== data.id));
    setAssignEmpTrips((prevState) => [...prevState, data]);
  };

  const removeAssignEmpReqHandler = (data: any) => {
    // e.target.disabled = true;
    // document.querySelector('button')
    setAssignEmpTrips((prevState) =>
      prevState.filter((st) => st.id !== data.id)
    );
    // setArrEmpTrips((prevState) => [...prevState, data]);
  };

  const submitNewTripHandler = (e: any) => {
    e.preventDefault();
    const finalAssignStdTrips = assignReqArr.map((reqtrip) => reqtrip.id);
    const finalAssignEmpTrips = assignEmpTrips.map((emp) => emp.id);
    const data = {
      tripname: tripName,
      area_id: selectedAreaId,
      driver_id: selectedDriverId,
      bus_id: selectedBusId,
      oneWayPrice: oneWayPrice,
      bothWayPrice: bothWayPrice,
      goingToSchoolTime: goingToSchoolTime,
      goingToHomeTime: goingToHomeTime,
      status: false,
      requestedtrips: finalAssignStdTrips,
      employeestrips: finalAssignEmpTrips,
    };
    dispatch(actions.addTrip(data));
    // console.log(JSON.stringify(data));
  };
  const errorMsg = useSelector((state: IStateType) => state.addTrip.error);
  const msg = useSelector((state: IStateType) => state.addTrip.msg);

  const onCancel = () => {
    dispatch(actions.clearMsgAddTrip());
  };
  const onSuccessCancel = () => {
    dispatch(actions.clearMsgAddTrip());
    props.history.push("/TripsTable");
  };

  let forError = null;
  if (errorMsg) {
    forError = (
      <SweetAlert
        danger
        title="Warning"
        onConfirm={onCancel}
        onCancel={onCancel}
      >
        {errorMsg}
      </SweetAlert>
    );
  }

  let forSuccess = null;
  if (msg) {
    forSuccess = (
      <SweetAlert
        success
        title="Success"
        onConfirm={onSuccessCancel}
        onCancel={onSuccessCancel}
      >
        {msg}
      </SweetAlert>
    );
  }

  let arrowLeft = <i className="fas fa-angle-double-left"></i>;
  let arrowRight = <i className="fas fa-angle-double-right"></i>;

  return (
    <Fragment>
      <Link
        to="/TripsTable"
        className="btn btn-danger text-white font-weight-bold"
      >
        {t.translate("Back")}
      </Link>
      {loading == false && loadingReqTrips == false ? (
        <form onSubmit={submitNewTripHandler}>
          {forError}
          {forSuccess}
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3 form-group justify-content-center text-center">
                  <label className="text-white font-weight-bold text-center">
                    {t.translate("Trip Name")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Trip Name"
                    aria-label="Trip Name"
                    aria-describedby="Entering Trip Name"
                    onChange={(e) => setTripName(e.target.value)}
                    value={tripName}
                    required
                  />
                </div>
                <div className="col-md-3 form-group justify-content-center text-center">
                  <label className="text-white font-weight-bold text-center">
                    {t.translate("Area")}
                  </label>
                  <select
                    className="form-control "
                    onChange={(e) => setSelectedAreaId(e.target.value)}
                    required
                    // value={area_id ? area_id : 'disableEle'}
                    value={selectedAreaId}
                  >
                    <option value="">{t.translate("Choose Area")}</option>
                    {loading === false
                      ? areas.map((area: any) => {
                          // console.log(area);
                          return (
                            <option key={area.id} value={area.id}>
                              {area.areaname}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
                <div className="col-md-3 form-group justify-content-center text-center">
                  <label className="text-white font-weight-bold text-center">
                    {t.translate("Driver")}
                  </label>
                  <select
                    className="form-control "
                    onChange={(e) => setSelectedDriverId(e.target.value)}
                    aria-label="Driver"
                    required
                    // value={area_id ? area_id : 'disableEle'}
                    value={selectedDriverId}
                  >
                    <option value="">{t.translate("Choose Driver")}</option>
                    {loadingDriver === false
                      ? drivers.map((driver: any) => {
                          // console.log(area);
                          return (
                            <option key={driver.id} value={driver.id}>
                              {driver.drivername}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
                <div className="col-md-3 form-group justify-content-center text-center">
                  <label className="text-white font-weight-bold text-center">
                    {t.translate("Bus")}
                  </label>
                  <select
                    className="form-control"
                    aria-label="Default select example"
                    onChange={(e) => setSelectedBusId(e.target.value)}
                    value={selectedBusId}
                    required
                  >
                    <option value="">{t.translate("Choose Bus")}</option>
                    {loadingBus === false
                      ? buses.map((bus: any) => {
                          // console.log(area);
                          return (
                            <option key={bus.id} value={bus.id}>
                              {bus.busnumber}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* <div className='col-6 form-group justify-content-center text-center'>
              <label className='text-white font-weight-bold text-center'>
                Both Ways Price
              </label>
              <input
                type='text'
                className='form-control'
                placeholder='Enter Both Ways Price'
                required
                aria-label='Both Ways Price'
                aria-describedby='Entering Both Ways Price'
                onChange={(e) => setBothWayPrice(e.target.value)}
                value={bothWayPrice}
              />
            </div>
            <div className='col-6 form-group justify-content-center text-center'>
              <label className='text-white font-weight-bold text-center'>
                One Way Price
              </label>
              <input
                type='text'
                className='form-control'
                placeholder='Enter One Way Price'
                required
                aria-label='One Way Price'
                aria-describedby='Entering One Way  Price'
                onChange={(e) => setOneWAYPrice(e.target.value)}
                value={oneWayPrice}
              />
            </div>
  */}{" "}
            <div className="col-6 form-group justify-content-center text-center">
              <label className="text-white font-weight-bold text-center">
                {t.translate("Going To School Time")}
              </label>
              <input
                type="time"
                className="form-control"
                placeholder="Enter Going To School Time"
                aria-label="Going To School"
                required
                aria-describedby="Entering Going To School Time"
                onChange={(e) => setGoingToSchoolTime(e.target.value)}
                value={goingToSchoolTime}
              />
            </div>
            <div className="col-6 form-group justify-content-center text-center">
              <label className="text-white font-weight-bold text-center">
                {t.translate("Going To Home Time")}
              </label>
              <input
                type="time"
                className="form-control"
                placeholder="Enter Going To Home Time"
                aria-label="Going To Home"
                required
                aria-describedby="Entering Going To Home Time"
                onChange={(e) => setGoingToHomeTime(e.target.value)}
                value={goingToHomeTime}
              />
            </div>
          </div>

          <button
            // onClick={submitNewTripHandler}
            // to='/TripsTable'
            className="btn btn-block btn-warning text-white font-weight-bold"
          >
            {t.translate("Add Trip")}
          </button>
        </form>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
        </div>
      )}
    </Fragment>
  );
});
export default withRouter(EditTrip);
