import * as actionTypes from '../actionTypes';
import axios from 'axios';

export const clearMsgAllEmployee = () => {
  return {
    type: actionTypes.CLEAR_MSG_ALL_REQUESTS,
  };
};

export const fetchAllStart = () => {
  return {
    type: actionTypes.FETCH_ALL_REQUESTS_START,
  };
};
export const fetchAllSuccess = (gaterequests: any, count: any) => {
  return {
    type: actionTypes.FETCH_ALL_REQUESTS_SUCCESS,
    gaterequests,
    count,
  };
};
export const fetchAllFail = (error: any) => {
  return {
    type: actionTypes.FETCH_ALL_REQUESTS_FAIL,
    error,
  };
};

export const fetchAllRequests= (pageN: any, pageSize: any) => {
  return (dispatch: any) => {
    dispatch(fetchAllStart());
    let data = { pagenumber: pageN, pagesize: pageSize };
    axios
      .post(
        `admin/getgaterequests.php
        `,
        data
      )
      .then((res) => {
        console.log(res);
        dispatch(fetchAllSuccess(res.data.data.gaterequests, res.data.data.count));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fetchAllFail(err.response.data.msg));
        } else {
          dispatch(fetchAllFail('Internet Connection Failed'));
        }
      });
  };
};

//ِALLOW ENTRY 
export const clearMsgAllowEntry= () => {
  return {
    type: actionTypes.CLEAR_MSG_ALLOW_ENTRY,
  };
};

export const allowEntryStart = () => {
  return {
    type: actionTypes.ALLOW_ENTRY_START,
  };
};
export const allowEntrySuccess = (data: any) => {
  return {
    type: actionTypes.ALLOW_ENTRY_SUCCESS,
    data,
    
  };
};
export const allowEntryFail = (error: any) => {
  return {
    type: actionTypes.ALLOW_ENTRY_FAIL,
    error,
  };
};

export const allowEntry= (requestgate_id: any, allow: any) => {
  return (dispatch: any) => {
    dispatch(allowEntryStart());
    let data = { requestgate_id: requestgate_id, allow: allow };
    axios
      .post(
        `admin/allowentry.php
        `,
        data
      )
      .then((res) => {
        console.log(res);
        dispatch(allowEntrySuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(allowEntryFail(err.response.data.msg));
        } else {
          dispatch(allowEntryFail('Internet Connection Failed'));
        }
      });
  };
};


