import * as actionTypes from '../actionTypes';
import axios from 'axios';

export const sendDriverEndTrip = () => {
  return {
    type: actionTypes.SEND_DRIVER_END_TRIP,
  };
};
export const sendDriverEndTripSuccess = (data: any) => {
  return {
    type: actionTypes.SEND_DRIVER_ENDTRIP_SUCCESS,
    data,
  };
};
export const sendDriverEndTripFail = (error: any) => {
  return {
    type: actionTypes.FETCH_DRIVER_ENDTRIP_FAIL,
    error,
  };
};
export const clearMsgDriverEndTrip = () => {
  return {
    type: actionTypes.CLEAR_MSG_DRIVERENDTRIP,
  };
};

export const driverendtrip = (data: any) => {
  return (dispatch: any) => {
    dispatch(sendDriverEndTrip());

    axios
      .post(`driver/endtrip.php`, data)
      .then((res) => {
        console.log(res.data.data);

        dispatch(sendDriverEndTripSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(sendDriverEndTripFail(err.response.data.msg));
        } else {
          dispatch(sendDriverEndTripFail('Internet Connection Failed'));
        }
      });
  };
};
