import React, { Fragment, useState, useEffect, Dispatch, memo } from "react";
// import { useEffect, Dispatch } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
// import trips_tracker_sample from '../../../assets/trips_tracker_sample.png';
import { IStateType } from "../../../../store/models/root.interface";
import * as actions from "../../../../store/actions/index.actions";
import MapForAdminSortTrip from "../../../Maps/MapForAdminSortTrip";
// import MapForAdminSortTrip from '../../Maps/MapForAdminSortTrip';
import Loader from "react-loader-spinner";
import SweetAlert from "react-bootstrap-sweetalert";
// import Pagination from 'react-js-pagination';
// import classes from '../../Pagination.module.css';
interface tripFormProps{
  mytripId:any,
  mytripLoading:any,
  gotoH:any
}
const EditTripForm: React.FC<{mytripId: any,mytripLoading:any,gotoH:any}> = memo((props:any) => {
  let id = props.mytripId;

  const dispatch: Dispatch<any> = useDispatch();

  const areas = useSelector((state: IStateType) => state.assigntrip.areas);
  const loading = useSelector((state: IStateType) => state.managetrips.loading);
  // const loadingUnAssignTrip = useSelector(
  //   (state: IStateType) => state.managetrips.loadingUnAssignTrip
  // );
  const trip: any = useSelector((state: IStateType) => state.managetrips.trip);
  const loadingTrip = useSelector(
    (state: IStateType) => state.managetrips.loadingTrip
  );

  const drivers = useSelector((state: IStateType) => state.addTrip.drivers);
  const loadingDriver = useSelector(
    (state: IStateType) => state.addTrip.loadingDriver
  );
  const buses = useSelector((state: IStateType) => state.addTrip.buses);
  const loadingBus = useSelector(
    (state: IStateType) => state.addTrip.loadingBus
  );
  //for student
  const requestedTrips = useSelector(
    (state: IStateType) => state.addTrip.requestedTrips
  );
  const [arrReqTrips, setArrRqTrips] = useState<any[]>([...requestedTrips]);
  const [assignReqArr, setAssignReqArr] = useState<any[]>([]);

  const updateLoading = useSelector(
    (state: IStateType) => state.managetrips.updateLoading
  );

  const removeAssignReqHandler = (data: any) => {
    // e.target.disabled = true;
    // document.querySelector('button')
    setAssignReqArr((prevState) => prevState.filter((st) => st.id !== data.id));
    setArrRqTrips((prevState) => [...prevState, data]);
  };

  // for employee
  // const employees = useSelector((state: IStateType) => state.addTrip.employees);
  // const loadingEmployees = useSelector(
  //   (state: IStateType) => state.addTrip.loadingEmployees
  // );
  // const [arrEmpTrips, setArrEmpTrips] = useState<any[]>([...employees]);
  const [assignEmpTrips, setAssignEmpTrips] = useState<any[]>([]);

  // const addAssignEmpReqHandler = (data: any) => {
  //   // e.target.disabled = true;
  //   // document.querySelector('button')
  //   // setArrEmpTrips((prevState) => prevState.filter((st) => st.id !== data.id));
  //   setAssignEmpTrips((prevState) => [...prevState, data]);
  // };

  const removeAssignEmpReqHandler = (data: any) => {
    // e.target.disabled = true;
    // document.querySelector('button')
    setAssignEmpTrips((prevState) =>
      prevState.filter((st) => st.id !== data.id)
    );
    // setArrEmpTrips((prevState) => [...prevState, data]);
  };

  const [tripName, updateTripName] = useState<string>("");
  const [selectedAreaId, updateSelectedAreaId] = useState<string>("");
  const [selectedDriverId, updateSelectedDriverId] = useState<string>("");
  const [selectedBusId, updateSelectedBusId] = useState<string>("");
  const [twowaysfee, settwowaysfee] = useState<string>('');
  const [onewayfee, setonewayfee] = useState<string>('');
  const [tohometime, settohometime] = useState<string>('');
  const [toschooltime, settoschooltime] = useState<string>('');


  useEffect(() => {
    dispatch(actions.fetchArea());
    dispatch(actions.fetchDriver());
    dispatch(actions.fetchBus());
    //dispatch(actions.fetchRequestedTrips(activePageReq, pageSizeReq));
    // dispatch(actions.fetchEmployees(activePage, pageSize));

    dispatch(actions.fetchTrip(id));
  }, [dispatch]);

  useEffect(() => {
    if (loading === false && loadingTrip === false) {
      updateTripName(trip.tripname);
      updateSelectedAreaId(trip.area_id);
      updateSelectedDriverId(trip.driver_id);
      updateSelectedBusId(trip.bus_id);
      settwowaysfee(trip.twowaysfee);
      setonewayfee(trip.onewayfee);
      settohometime(trip.tohometime);
      settoschooltime(trip.toschooltime);
    }
  }, [loading, loadingTrip]);

  const submitUpdateTripHandler = (e: any) => {
    e.preventDefault();
    const finalAssignStdTrips = assignReqArr.map((reqtrip) => reqtrip.id);
    const finalAssignEmpTrips = assignEmpTrips.map((emp) => emp.id);
    const data = {
      id: trip.id,
      tripname: tripName,
      area_id: selectedAreaId,
      driver_id: selectedDriverId,
      bus_id: selectedBusId,
      onewayfee: onewayfee,
      twowaysfee:twowaysfee,
      toschooltime:toschooltime,
      tohometime:tohometime,
      status: false,
      requestedtrips: finalAssignStdTrips,
      employeestrips: finalAssignEmpTrips,
    };
    dispatch(actions.updateTrip(data));
    console.log(JSON.stringify(data));
  };

  // here return arr of object by {lat and lng}
  let [newArrPostions, setNewArrPostions] = useState<any>([]);
  let assignedtripsLatLng = null;
  if (loadingTrip === false) {
    assignedtripsLatLng = trip.assignedtrips.map((tsp: any) => {
      return {
        coords: { lat: +tsp.location_lat, lng: +tsp.location_lng },
        student_id: tsp.student_id,
        name: tsp.name,
        postion: tsp.postion,
      };
    });
    newArrPostions = assignedtripsLatLng;
  }
  let [newEmpArrPostions, setNewEmpArrPostions] = useState<any>([]);
  let assignedEmptripsLatLng = null;
  if (loadingTrip === false) {
    assignedEmptripsLatLng = trip.employeetrips.map((tsp: any) => {
      return {
        coords: { lat: +tsp.location_lat, lng: +tsp.location_lng },
        employee_id: tsp.employee_id,
        name: tsp.name,
        postion: tsp.postion,
      };
    });
    newEmpArrPostions = assignedEmptripsLatLng;
  }

  const unAssignedTrip = (e: any, student_id: any, assigned_trip_id: any) => {
    e.preventDefault();

    console.log(student_id);
    console.log(assigned_trip_id);
    const data = {
      student_id: student_id,
      assigned_trip_id: assigned_trip_id,
    };
    dispatch(actions.unAssignedTrip(data));
  };

  const errorMsg = useSelector((state: IStateType) => state.managetrips.error);
  const msg = useSelector((state: IStateType) => state.managetrips.msg);

  const onCancel = () => {
    dispatch(actions.clearMsgUpdateTrip());
  };
  const onSuccessCancel = () => {
    dispatch(actions.clearMsgUpdateTrip());
    props.gotoH("/TripsTable");
  };

  const onDeleteConfirem = () => {
    dispatch(actions.clearMsgUpdateTrip());
    dispatch(actions.fetchTrip(id));
  };
  const onSavePointsMsg = () => {
    dispatch(actions.clearMsgUpdateTrip());
  };

  let forError = null;
  if (errorMsg) {
    forError = (
      <SweetAlert
        danger
        title="Warning"
        onConfirm={onCancel}
        onCancel={onCancel}
      >
        {errorMsg}
      </SweetAlert>
    );
  }

  let forSuccess = null;
  if (msg) {
    if (msg === "Deleted") {
      forSuccess = (
        <SweetAlert
          success
          title="Success"
          onConfirm={onDeleteConfirem}
          onCancel={onDeleteConfirem}
        >
          {msg}
        </SweetAlert>
      );
    } else if (msg === "successfully points saved") {
      forSuccess = (
        <SweetAlert
          success
          title="Success"
          onConfirm={onSavePointsMsg}
          onCancel={onSavePointsMsg}
        >
          {msg}
        </SweetAlert>
      );
    } else {
      forSuccess = (
        <SweetAlert
          success
          title="Success"
          onConfirm={onSuccessCancel}
          onCancel={onSuccessCancel}
        >
          {msg}
        </SweetAlert>
      );
    }
  }
  let [newPoints, setNewPoints] = useState<any[]>([]);
  let [newEmpPoints, setEmpNewPoints] = useState<any[]>([]);
  const submitSavePoints = (data: any) => {
    newPoints = data;
  };
  const submitEmployeeSavePoints = (data: any) => {
    newEmpPoints = data;
  };
  const onClickSubmitSavePoints = () => {
    let data = {
      trip_id: id,
      postions: [...newPoints],
      emppositions: [...newEmpPoints],
    };
    if (newPoints.length !== 0 || newEmpPoints.length !== 0) {
      dispatch(actions.setPoints(data));
    }
  };
  return (
      <React.Fragment>
        {loading === false &&
            loadingDriver === false &&
            loadingBus === false &&
            loadingTrip === false &&
            updateLoading === false ? 
            (
            <form>
                {forError}
                {forSuccess}
                <div className="row">
                    <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-3 form-group justify-content-center text-center">
                        <label className="text-white font-weight-bold text-center">
                            Trip Name
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Trip Name"
                            aria-label="Trip Name"
                            aria-describedby="Entering Trip Name"
                            key='1'
                            onChange={(e) => updateTripName(e.target.value)}
                            value={tripName}
                            required
                        />
                        </div>
                        <div className="col-md-3 form-group justify-content-center text-center">
                        <label className="text-white font-weight-bold text-center">
                            Area
                        </label>
                        <select
                            className="form-control"
                            aria-label="Area"
                            onChange={(e) => updateSelectedAreaId(e.target.value)}
                            value={selectedAreaId}
                            required
                        >
                            <option value="">Choose Area</option>
                            {loading === false
                            ? areas.map((area: any) => {
                                return (
                                    <option key={area.id} value={area.id}>
                                    {area.areaname}
                                    </option>
                                );
                                })
                            : null}
                        </select>
                        </div>
                        <div className="col-md-3 form-group justify-content-center text-center">
                        <label className="text-white font-weight-bold text-center">
                            Driver
                        </label>
                        <select
                            className="form-control"
                            aria-label="Driver"
                            onChange={(e) => updateSelectedDriverId(e.target.value)}
                            value={selectedDriverId}
                            required
                        >
                            <option value="">Choose Driver</option>
                            {loadingDriver === false
                            ? drivers.map((driver: any) => {
                                // console.log(area);
                                return (
                                    <option key={driver.id} value={driver.id}>
                                    {driver.drivername}
                                    </option>
                                );
                                })
                            : null}
                        </select>
                        </div>
                        <div className="col-md-3 form-group justify-content-center text-center">
                        <label className="text-white font-weight-bold text-center">
                            Bus
                        </label>
                        <select
                            className="form-control"
                            aria-label="Default select example"
                            onChange={(e) => updateSelectedBusId(e.target.value)}
                            value={selectedBusId}
                            required
                        >
                            <option value="">Choose Bus</option>
                            {loadingBus === false
                            ? buses.map((bus: any) => {
                                // console.log(area);
                                return (
                                    <option key={bus.id} value={bus.id}>
                                    {bus.busnumber}
                                    </option>
                                );
                                })
                            : null}
                        </select>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row">
                    {/* <div className="col-6 form-group justify-content-center text-center">
                      <label className="text-white font-weight-bold text-center">
                      Both Ways Price
                      </label>
                      <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Both Ways Price"
                          aria-label="Both Ways Price"
                          aria-describedby="Entering Both Ways Price"
                          key="2"
                          onChange={(e) => settwowaysfee(e.target.value)}
                          value={twowaysfee}
                          required
                      />
                    </div>
                    <div className="col-6 form-group justify-content-center text-center">
                      <label className="text-white font-weight-bold text-center">
                          One Way Price
                      </label>
                      <input
                          type="text"
                          className="form-control"
                          placeholder="Enter One Way Price"
                          aria-label="One Way Price"
                          aria-describedby="Entering One Way Price"
                          key='3'
                          onChange={(e) => setonewayfee(e.target.value)}
                          value={onewayfee}
                          required
                      />
                    </div> */}
                    <div className="col-6 form-group justify-content-center text-center">
                    <label className="text-white font-weight-bold text-center">
                        Going To School Time
                    </label>
                    <input
                        type="time"
                        className="form-control"
                        placeholder="Enter Going To School Time"
                        aria-label="Going To School"
                        aria-describedby="Entering Going To School Time"
                        onChange={(e) => settoschooltime(e.target.value)}
                        value={toschooltime}
                        required
                    />
                    </div>
                    <div className='col-6 form-group justify-content-center text-center'>
                    <label className='text-white font-weight-bold text-center'>
                    Going To Home Time
                    </label>
                    <input
                        type='time'
                        className='form-control'
                        placeholder='Enter Going To Home Time'
                        aria-label='Going To Home'
                        required
                        aria-describedby='Entering Going To Home Time'
                        onChange={(e) => settohometime(e.target.value)}
                        value={tohometime}
                    />
                    </div>
                </div>
                <button
                    // to='/TripsTable'
                    type="button"
                    onClick={(e) => submitUpdateTripHandler(e)}
                    className="btn btn-block btn-warning text-white font-weight-bold mb-2"
                >
                    Update
                </button>
            </form>
            ):
            (
                <div className="d-flex justify-content-center align-items-center mt-5">
                    <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
                </div>
            )
        }
    </React.Fragment>
  );
});
export default EditTripForm;
