import * as actionTypes from '../actionTypes';
import axios from 'axios';

//Fetch AREAS
export const fetchDriverStartTrip = () => {
  return {
    type: actionTypes.FETCH_DRIVER_START_TRIP,
  };
};
export const fetchDriverStartTripSuccess = (msg: any, data: any) => {
  return {
    type: actionTypes.FETCH_DRIVERTRIP_START_SUCCESS,
    msg,
    data,
  };
};
export const fetchDriverStartTripFail = (error: any) => {
  return {
    type: actionTypes.FETCH_DRIVERTRIP_START_FAIL,
    error,
  };
};
export const clearMsgDriverStartTrip = () => {
  return {
    type: actionTypes.CLEAR_MSG_DRIVERSTARTTRIP,
  };
};

export const driverstarttrip = (data: any) => {
  return (dispatch: any) => {
    dispatch(fetchDriverStartTrip());

    axios
      .post(`driver/starttrip.php`, data)
      .then((res) => {
        console.log(res.data.data);

        dispatch(fetchDriverStartTripSuccess('started', res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fetchDriverStartTripFail(err.response.data.msg));
        } else {
          dispatch(fetchDriverStartTripFail('Internet Connection Failed'));
        }
      });
  };
};
